import { projectModules, projectNames } from "../common/constants";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { Login } from "../pages/auth/Login";
import SetPassword from "../pages/auth/SetPassword";
import Notification from "../pages/main/Notification";
import BtrckDashboard from "../pages/main/bTrack/Dashboard";
import { GetIssueDetails } from "../pages/main/bTrack/GetIssueDetails";
import BtrckGlobalSetting from "../pages/main/bTrack/GlobalSetting";
import IssueManagement from "../pages/main/bTrack/IssueManagement";
import BtrckLabelManagment from "../pages/main/bTrack/LabelManagment";
import ProfileDetails from "../pages/main/component/profile/ProfileDetails";
import PublicVerification from "../pages/public/PublicVerification";

const adminPublicRoutes = [
  {
    path: `/${projectNames.admin}/public-verification/:id`,
    component: PublicVerification
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/set-password/:verifyKey",
    component: SetPassword
  },
  {
    path: "/reset-password/:verifyKey",
    component: SetPassword
  },
  {
    path: "/forgot-password",
    component: ForgotPassword
  }
];

export default adminPublicRoutes;

export const bTrackPrivateRoutes = [
  {
    path: `/${projectNames.btrack}/dashboard`,
    component: BtrckDashboard,
    module: projectModules[projectNames.btrack].DASHBOARD
  },
  {
    path: `/${projectNames.btrack}/issue-management`,
    component: IssueManagement,
    module: projectModules[projectNames.btrack].ISSUE_MANAGEMENT
  },
  {
    path: `/${projectNames.btrack}/issue-management/:id`,
    component: GetIssueDetails,
    module: projectModules[projectNames.btrack].ISSUE_MANAGEMENT
  },
  {
    path: `/${projectNames.btrack}/label-management`,
    component: BtrckLabelManagment,
    module: projectModules[projectNames.btrack].LABEL_MANAGEMENT
  },
  {
    path: `/${projectNames.btrack}/global-settings`,
    component: BtrckGlobalSetting,
    module: projectModules[projectNames.btrack].GLOBAL_SETTINGS
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];
