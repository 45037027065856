import { FC } from "react";
import { Input, Label } from "reactstrap";

interface IRadioButtonComponentArray {
  value: number;
  label: string;
  name: string;
}

interface IRadioButtonComponent {
  redioButtonArray: IRadioButtonComponentArray[];
  setStatus: (value: React.SetStateAction<number>) => void;
  handleStatusChange: (statusValue: any) => void;
  status: number;
}

const RadioButtonComponent: FC<IRadioButtonComponent> = ({ redioButtonArray, handleStatusChange, setStatus, status }) => {
  return (
    <>
      <hr className="m-0" style={{ height: "0.5px" }} />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 mt-10 color-black2 fw-bold fw-normal text-upper">Change Status</p>
        </div>
        <div className="col-9">
          <div className="d-flex flex-wrap">
            {redioButtonArray.map((data, i) => (
              <div
                key={`${i}${data.label}`}
                className={`cursor-pointer ${status === data.value ? "disable" : ""} cutome-radio ${i > 0 ? "ml-20" : ""}`}
                onClick={() => {
                  if (status !== data.value) {
                    handleStatusChange(data.value);
                    setStatus(data.value);
                  }
                }}>
                <Input className="cursor-pointer" checked={status === data.value} type="radio" name={data?.name} value={data?.value} />
                <div>
                  <Label className="cursor-pointer">{data?.label}</Label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioButtonComponent;
