import { useFormik } from "formik";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { getPriceAndPlanDetail, updatePlan } from "../../../../actions/bMessage/getBMessageAdminInfoAPI";
import { handleTrim, toastSuccess } from "../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import PageHeader from "../../bvote/common/PageHeader";

export const MainData = createContext<any>({});

const PriceAndPlanForm = () => {
  const params: any = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<any>();

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    getPriceAndPlanDetail(id)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.pricingPlan[0]);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        history.push(`/${projectNames.bmessage}/price-plan`);
      });
  };

  useEffect(() => {
    getViewAction(params.id);
    // eslint-disable-next-line
  }, []);

  const formikData = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(validationMessages.appName.required).min(3, "Please enter minimum 3 characters").max(60, "Maximum allowed characters are 60")
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let parameter: any = {};
      parameter.id = params.id;
      parameter.name = values.name.trim();

      updatePlan(parameter).then((res) => {
        if (res.status === 200) {
          toastSuccess(res.message);
          history.push(`/${projectNames.bmessage}/price-plan`);
          dispatch(setLoading(false));
        }
      });
    }
  });

  const { values, errors, setFieldValue, handleSubmit } = formikData;
  useEffect(() => {
    if (data) {
      setFieldValue("name", data?.name);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <MainData.Provider value={"Edit Price and Plan"}>
      <div>
        <div className="holders">
          <PageHeader module={"Edit Price and Plan"} />
          <div className="rounded-10 bg-white p-20">
            <Row>
              <Col md={6}>
                <Label for="biglogo" className="mt-3">
                  Plan Name <span className="text-red">*</span>
                </Label>
                <Input
                  invalid={Boolean(errors?.name)}
                  placeholder="Edit Plan Name"
                  className="mt-1"
                  name="name"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                />
                {errors?.name ? <p className="text-danger">{errors?.name}</p> : null}
              </Col>
            </Row>
            <div className="d-flex mt-3 mb-2">
              {/* @ts-ignore */}
              <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={handleSubmit}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainData.Provider>
  );
};

export default PriceAndPlanForm;
