import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import bgImage from "../../../src/assets/images/login-bg.png";
import edexaLogo from "../../../src/assets/images/svg/edexa.svg";
import { onGetVerifyPasswordKey, onPasswordSetUserAPI } from "../../actions/portal/adminUser/adminUserActions";
import { toastSuccess } from "../../common/common-functions";
import { onlyNewpassword, validationMessages } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import "./login.css";

const SetPassword = () => {
  const params = useParams<{ verifyKey: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [verifyKey, setVerifyKey] = useState("");
  const [email, setEmail] = useState("");
  const [eye, setEyes] = useState({
    pass: false,
    compass: false
  });

  // @ts-ignore
  const toggleEye = (key: string) => setEyes({ ...eye, [key]: !eye[key] });

  const handleVerifyPasswordKey = useCallback(() => {
    dispatch(setLoading(true));
    onGetVerifyPasswordKey({
      verifyKey: params?.verifyKey
    })
      .then((res) => {
        if (res.status === 200) {
          setVerifyKey(res?.data?.verifyKey);
          setEmail(res?.data?.email);
        }
        dispatch(setLoading(false));
      })
      .catch((error) => history.push("/login"));
  }, [dispatch, history, params?.verifyKey]);

  useEffect(() => {
    params?.verifyKey && handleVerifyPasswordKey();
  }, [params?.verifyKey, handleVerifyPasswordKey]);

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      isSubmit: false
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(validationMessages.password.required).matches(onlyNewpassword, validationMessages.password.matches),
      confirmPassword: Yup.string()
        .required(validationMessages.confirmPassword.requiredConfirm)
        .oneOf([Yup.ref("password"), null], validationMessages.confirmPassword.passwordMatch)
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let data = {
        verifyKey,
        password: passwordFormik.values.password,
        confirmPassword: passwordFormik.values.confirmPassword
      };
      onPasswordSetUserAPI(data).then((res) => {
        if (res?.status === 200) {
          setVerifyKey("");
          passwordFormik.resetForm();
          history.push("/login");
          toastSuccess(res?.message);
        }
        dispatch(setLoading(false));
      });
    }
  });

  let { errors, handleChange, touched } = passwordFormik;
  let { password, confirmPassword } = errors;

  return (
    <div className="App">
      <img className="bannerImage" src={bgImage} alt="Banner Logo" />
      <div className="loginSection">
        <div className="logoAndContent">
          <img className="edexaLogo" src={edexaLogo} alt="logo" />
          <div className="content">
            <h2>
              Building the future ecosystem based on <b>confidence</b>, <b>care</b> and <b>clarity</b>
            </h2>
            <p className="mainContainer__contentUnderHeading">
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
              normal distribution of letters
            </p>
          </div>
          <div className="formSection rounded-10 m-auto ">
            <div className="auth-wrapper">
              <div className="formSection__innerDiv">
                <h2>Set Password</h2>
                <p className="mainContainer__registerFormPara">Continue to edeXa Central Admin</p>
                <p className="mainContainer__registerFormPara mb-20">{email}</p>
                <div className="inputFIeldsDiv">
                  <div className={`mainContainer__emailInputFieldDiv mb-10 ${touched?.password ? (errors?.password ? "error-border" : "") : ""}`} style={{ position: "relative" }}>
                    <input style={{ paddingRight: "45px" }} type={eye.pass ? "text" : "password"} placeholder="Password" className="mainContainer__email" name="password" onChange={handleChange} />
                    {eye.pass ? (
                      <i className="fas fa-eye eye-login" onClick={() => toggleEye("pass")}></i>
                    ) : (
                      <i
                        className="fas fa-eye-slash eye-login"
                        onClick={() => toggleEye("pass")}
                        onKeyPress={(e) => {
                          e.key.toLowerCase() === "enter" && passwordFormik.handleSubmit();
                        }}></i>
                    )}
                    {passwordFormik.values.isSubmit && password && <div className="text-danger text-left font-14 error-msg-set">{password}</div>}
                  </div>
                  <div className={`mainContainer__passwordFields mb-10 ${touched?.confirmPassword ? (errors?.confirmPassword ? "error-border" : "") : ""}`} style={{ position: "relative" }}>
                    <input
                      style={{ paddingRight: "45px" }}
                      type={eye.compass ? "text" : "password"}
                      className="mainContainer__password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        e.key.toLowerCase() === "enter" && passwordFormik.handleSubmit();
                      }}
                    />
                    {eye.compass ? <i className="fas fa-eye eye-login" onClick={() => toggleEye("compass")}></i> : <i className="fas fa-eye-slash eye-login" onClick={() => toggleEye("compass")}></i>}
                    {passwordFormik.values.isSubmit && confirmPassword && <div className="text-danger text-left font-14 error-msg-set">{confirmPassword}</div>}
                  </div>
                </div>
                <button
                  onClick={() => {
                    passwordFormik.setFieldValue("isSubmit", true);
                    passwordFormik.handleSubmit();
                  }}
                  className="mainContainer__submit">
                  Submit
                </button>
                <div className="forgot-password fw-bold  mt-0" style={{ color: "#073d83" }}>
                  <span className="cursor-pointer" onClick={() => history.push("/login")}>
                    Login?
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
