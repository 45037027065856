import { FC } from "react";
import { Col, Input, Row } from "reactstrap";
import SwitchGlobalSetting from "../../../SwitchGlobalSetting";

interface ISwitchMarketPlaceComponent {
  item: any;
  handleSwitch: any;
  handleValue: any;
  index: number;
}

const SwitchMarketPlaceComponent: FC<ISwitchMarketPlaceComponent> = ({ item, handleSwitch, handleValue, index }) => {
  return (
    <Row>
      <Col sm={4}>
        <SwitchGlobalSetting
          checked={item.isActive}
          className="w-100 m-2"
          onChange={(e: any) => {
            handleSwitch(e, index, item.id, item.name);
          }}
          label={item?.name}
          disabled={false}
        />
      </Col>
      {item.isActive ? (
        item?.name === "Demo Request" ? null : (
          <Col sm={8}>
            <Input placeholder="Web App" className="m-1" invalid={item?.isActive ? (item?.url ? false : true) : true} onChange={(e) => handleValue(e.target.value, index, item.id)} value={item?.url} />
            <span className="text-danger">{item?.isActive ? (item?.url ? null : "Filed is required") : null}</span>
          </Col>
        )
      ) : null}
    </Row>
  );
};

export default SwitchMarketPlaceComponent;
