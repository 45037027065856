import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const deleteCategoryAPI = (id: string, data: any): Promise<IdeleteCategoryAPI> => {
  return HTTPService.deleteCategory(StakingPortalApiEndPoint.category.delete + `/${id}`, data);
};

export interface IdeleteCategoryAPIData {}

interface IdeleteCategoryAPI {
  message: string;
  status: number;
  data: IdeleteCategoryAPIData;
}
