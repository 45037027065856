import { getDecryptedLocalStorage } from "../../common/common-functions";
import { localstorageKeys } from "../../common/constants";
import { NOTIFICATION_ON, PROFILEUPDATE, USERDETAILS } from "./type";

const initialState = {
  user: getDecryptedLocalStorage(localstorageKeys.userInfo) || {}
};

export const userDetailsReducer = (state = initialState, action: any) => {
  const newState = { ...state };
  switch (action.type) {
    case USERDETAILS:
      newState.user = action.payload;
      break;
    case PROFILEUPDATE:
      newState.user.firstName = action.payload.firstName;
      newState.user.lastName = action.payload.lastName;
      newState.user.profilePic = action.payload.profilePic;
      break;
    case NOTIFICATION_ON:
      newState.user.notification = action.payload;
      break;
    default:
  }

  return newState;
};
