import { bMessageURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const bMesssageDashboardAPI = (): Promise<IbMesssageDashboardAPI> => {
  return HTTPService.get(bMessageURL.dashboard);
};

export interface IbMesssageDashboardAPIData {
  activeUsers: number;
  publicMessages: number;
  totalMessages: number;
}

interface IbMesssageDashboardAPI {
  status: number;
  message: string;
  data: IbMesssageDashboardAPIData;
}
