import { useFormik } from "formik";
import { useState } from "react";
import { Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import { handleTrim } from "../../../../common/common-functions";
import { validationMessages } from "../../../../common/constants";

interface UniverseRequestStatusConfirmationPopupProps {
  isVisible: boolean;
  loader: boolean;
  status: string;
  handleConfirmation: (requestId: any, currentSelectedStatus: any, networkData?: any) => any;
  currentSelectedStatus: any;
  toggleStatusModel: () => void;
  requestId: any;
  networkData?: any;
}

const UniverseRequestStatusConfirmationPopupListDetails: React.FC<UniverseRequestStatusConfirmationPopupProps> = ({
  isVisible,
  loader,
  status,
  handleConfirmation,
  currentSelectedStatus,
  toggleStatusModel,
  requestId,
  networkData
}) => {
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      peerUrl: "",
      exploreUrl: "",
      certAuthUrl: "",
      restApiUrl: ""
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required(validationMessages.userName.required),
      password: Yup.string().required(validationMessages.password.required),
      peerUrl: Yup.string(),
      exploreUrl: Yup.string(),
      certAuthUrl: Yup.string(),
      restApiUrl: Yup.string()
    }),
    onSubmit: (values) => {
      if (!values.userName || !values.password) {
        formik.setFieldError("userName", !values.userName ? validationMessages.userName.required : undefined);
        formik.setFieldError("password", !values.password ? validationMessages.password.required : undefined);
        return;
      }
      const formData = {
        userName: values?.userName,
        password: values?.password,
        ...(values?.peerUrl && { peerUrl: values?.peerUrl }),
        ...(values?.exploreUrl && { exploreUrl: values?.exploreUrl }),
        ...(values?.certAuthUrl && { certAuthUrl: values?.certAuthUrl }),
        ...(values?.restApiUrl && { restApiUrl: values?.restApiUrl })
      };

      handleConfirmation(requestId, currentSelectedStatus, formData);
    }
  });
  const [inValid, setInValid] = useState({
    isValid: false,
    message: ""
  });
  const [showPassword, setShowPassword] = useState(false);

  const { userName, password, peerUrl, exploreUrl, certAuthUrl, restApiUrl } = formik.values;
  const { errors, setFieldValue, touched, handleBlur, handleSubmit } = formik;

  const handleChange = (e: any) => {
    if (inValid.isValid) {
      setInValid({ message: "", isValid: false });
    }
    const name = e.target.name;
    const value = e.target.value;
    handleTrim(name, value, setFieldValue);
  };

  return (
    <>
      {status === "Accepted" ? (
        <>
          <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
            <ModalBody>
              <div style={{ padding: "30px" }}>
                <Form>
                  <FormGroup>
                    <Label className="fw-normal py-2">User Name</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="userName"
                        id="userName"
                        value={userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Your User Name"
                        onKeyPress={(e) => {
                          e.key.toLowerCase() === "enter" && handleSubmit();
                        }}
                        invalid={Boolean(inValid.isValid || (touched.userName && errors.userName))}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className="h-100 bg-transparent d-flex justify-content-center"
                          style={{
                            borderColor: inValid.isValid || (touched.userName && errors.userName) ? "#ff0000" : ""
                          }}>
                          <i className="fas fa-user icon-sm color-light-grey"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback invalid="true" className="text-left">
                        {inValid.isValid ? inValid.message : touched.userName && errors.userName}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-normal py-2">Password</Label>
                    <div className="position-relative">
                      <InputGroup>
                        <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Enter Your Password"
                          value={password}
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            e.key.toLowerCase() === "enter" && handleSubmit();
                          }}
                          onBlur={handleBlur}
                          invalid={Boolean(inValid.isValid || (touched.password && errors.password))}
                        />
                        <InputGroupAddon addonType="append" onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                          <InputGroupText
                            className="h-100 bg-transparent d-flex justify-content-center"
                            style={{
                              borderColor: touched.password && errors.password ? "#ff0000" : ""
                            }}>
                            <i className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"} icon-sm color-light-grey`}></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        {touched.password && errors.password && <FormFeedback className="text-left">{errors.password}</FormFeedback>}
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-normal py-2">Peer</Label>
                    <Input
                      type="text"
                      name="peerUrl"
                      id="peerUrl"
                      value={peerUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        e.key.toLowerCase() === "enter" && handleSubmit();
                      }}
                      placeholder={`${networkData?.userId.split("_")[1]}.${networkData?.id.split("_")[1]}.peer.io-world.com`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-normal py-2">Blockchain Explorer</Label>
                    <Input
                      type="text"
                      name="exploreUrl"
                      id="exploreUrl"
                      value={exploreUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        e.key.toLowerCase() === "enter" && handleSubmit();
                      }}
                      placeholder={`${networkData?.userId.split("_")[1]}.${networkData?.id.split("_")[1]}.explore.io-world.com`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-normal py-2">Certificate Authority URL</Label>
                    <Input
                      type="text"
                      name="certAuthUrl"
                      id="certAuthUrl"
                      value={certAuthUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        e.key.toLowerCase() === "enter" && handleSubmit();
                      }}
                      placeholder={`${networkData?.userId.split("_")[1]}.${networkData?.id.split("_")[1]}.certAuth.io-world.com`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-normal py-2">Rest API URL</Label>
                    <Input
                      type="text"
                      name="restApiUrl"
                      id="restApiUrl"
                      value={restApiUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        e.key.toLowerCase() === "enter" && handleSubmit();
                      }}
                      placeholder={`${networkData?.userId.split("_")[1]}.${networkData?.id.split("_")[1]}.api.io-world.com`}
                    />
                  </FormGroup>
                </Form>
              </div>
              <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
                <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={() => toggleStatusModel()}>
                  <button className="confimatio-button text-danger">Cancel</button>
                </div>
                {/* @ts-ignore */}
                <div className="col-6 p-10 d-flex cursor-pointer justify-content-center" onClick={() => handleSubmit(requestId)}>
                  <span className={`confimatio-button text-success d-flex align-items-center ${loader && "disable"} `}>
                    Submit
                    {loader && (
                      <Spinner className="ml-20" color="primary" size={"sm"}>
                        Loading...
                      </Spinner>
                    )}
                  </span>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <>
          <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
            <ModalBody>
              <div style={{ padding: "30px" }}>
                <h4 className="fw-bold text-center">Change status to {status}</h4>
                <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}></p>
              </div>
              <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
                <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={() => toggleStatusModel()}>
                  <button className="confimatio-button text-danger">No</button>
                </div>
                <div className="col-6 p-10 d-flex cursor-pointer justify-content-center" onClick={() => handleConfirmation(requestId, currentSelectedStatus)}>
                  <span className={`confimatio-button text-success d-flex align-items-center ${loader && "disable"} `}>
                    Yes
                    {loader && (
                      <Spinner className="ml-20" color="primary" size={"sm"}>
                        Loading...
                      </Spinner>
                    )}
                  </span>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default UniverseRequestStatusConfirmationPopupListDetails;
