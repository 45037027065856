import { projectModules, projectNames } from "../../common/constants";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import BlockChainListComponent from "../../pages/main/nft/blockChain/BlockChainListComponent";
import CategoryManagement from "../../pages/main/nft/categoryManagement/CategoryManagement";
import NftLabelManagement from "../../pages/main/nft/labelManagement/NftLabelManagement";
import NftDashboard from "../../pages/main/nft/NftDashboard";
import NftGlobalSetting from "../../pages/main/nft/nftGlobalSetting/NftGlobalSetting";
import NFTManagement from "../../pages/main/nft/nftManagement/NFTManagement";
import NftManageRequestDemo from "../../pages/main/nft/requestDemo/NftManageRequestDemo";
import { NftRequestDemoDetailsComponent } from "../../pages/main/nft/requestDemo/NftRequestDemoDeatilsComponent";
import Notification from "../../pages/main/Notification";

const NftPrivateRoutes = [
  {
    path: `/${projectNames.nft}/dashboard`,
    component: NftDashboard,
    module: projectModules[projectNames.nft].DASHBOARD
  },
  {
    path: `/${projectNames.nft}/global-settings`,
    component: NftGlobalSetting,
    module: projectModules[projectNames.nft].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.nft}/request-demo`,
    component: NftManageRequestDemo,
    module: projectModules[projectNames.nft].REQUEST_DEMO
  },
  {
    path: `/${projectNames.nft}/category-management`,
    component: CategoryManagement,
    module: projectModules[projectNames.nft].CATEGORY_MANAGEMENT
  },
  {
    path: `/${projectNames.nft}/nft-management`,
    component: NFTManagement,
    module: projectModules[projectNames.nft].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.nft}/label-management`,
    component: NftLabelManagement,
    module: projectModules[projectNames.nft].LABEL_MANAGEMENT
  },
  {
    path: `/${projectNames.nft}/request-demo/:id`,
    component: NftRequestDemoDetailsComponent,
    module: projectModules[projectNames.nft].REQUEST_DEMO
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  },
  {
    path: `/${projectNames.nft}/block-chain`,
    component: BlockChainListComponent,
    module: projectModules[projectNames.nft].REQUEST_DEMO
  }
];

export default NftPrivateRoutes;
