import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const getRequestDemoAPI = (params: IgetRequestDemoAPIParams): Promise<IgetRequestDemoAPI> => {
  return HTTPService.get(bvoteURL.requestDemo.list, { params });
};
interface IgetRequestDemoAPIParams {
  limit: number;
  page: number;
  search?: string;
}

export interface IgetRequestDemoAPIData {
  createdAt: string;
  email: string;
  id: string;
  message: string;
  mobile: string;
  name: string;
  updatedAt: string;
}

interface IgetRequestDemoAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IgetRequestDemoAPIData[];
  };
}
