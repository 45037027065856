import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row, Spinner, Tooltip } from "reactstrap";
import { StakingdemoRequestViewAPI } from "../../../../actions/staking/comman/stakingdemoRequestViewAPI";
import BackArrowComponent from "../../../../common/backArrow";
import { getFormattedDate } from "../../../../common/common-functions";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";

const ColumnComponent = ({ title, value, color, capitalize }: any) => {
  return (
    <>
      <hr className="m-0" />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 fw-bold color-black2 fw-normal text-upper">{title}</p>
        </div>
        <div className="col-9">
          <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {title === "updatedAt" || title === "createdAt" ? getFormattedDate(value) : value}
          </p>
        </div>
      </div>
    </>
  );
};

const StakingRequestDemoViewComponent = () => {
  const history = useHistory();
  const params: any = useParams();
  const loading = useSelector((state: IRootReducerTypes) => state.loading);
  const dispatch = useDispatch();

  const [data, setData] = useState<any>();
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    StakingdemoRequestViewAPI(id).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    if (params?.id) {
      getViewAction(params?.id);
    }
    // eslint-disable-next-line
  }, [params?.id]);

  return (
    <div>
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Demo Request Details</p>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        <Row className="mt-10">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <p className="sub-title m-b-5">Demo Request Information</p>
              </div>
              {loading?.isLoading ? (
                <div className="d-flex justify-content-center my-4">
                  <Spinner size={"sm"} />
                </div>
              ) : (
                <>
                  <ColumnComponent title={"name"} value={data?.name} />
                  <ColumnComponent title={"email"} value={data?.email} />
                  <ColumnComponent title={"organization Name"} value={data?.organizationName} />
                  <ColumnComponent title={"description"} value={data?.description} />
                  <ColumnComponent title={"createdAt"} value={data?.createdAt} />
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StakingRequestDemoViewComponent;
