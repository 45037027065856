import MetaFunction from "../../../../common/MetaTag";

import AppshortcutsDragAndDrop from "./AppShortcutsDragAndDrop";

// @ts-ignore
const AppShortcutsListComponent = ({ getCategoryManagementListFromApi, blockChainData, isLoading, setBlockChainData, updateAppOrder, saveRef, disableSaveButtonIfNoChangeInList }) => {
  return (
    <>
      <MetaFunction meta="Blockchain" />
      <div style={{ minHeight: "85vh" }}>
        <div className=" rounded-10 bg-white blockchain-main-section">
          <div className="row block-chain-header">
            <div className="col-4 text-break">App Shortcuts order</div>
          </div>
          {blockChainData?.length === 0 ? (
            isLoading ? (
              <div className="p-1">Loading...</div>
            ) : (
              <div className="p-1">No Apps are available</div>
            )
          ) : (
            <AppshortcutsDragAndDrop
              blockChainObject={blockChainData}
              setBlockChainData={setBlockChainData}
              updateAppOrder={updateAppOrder}
              saveRef={saveRef}
              disableSaveButtonIfNoChangeInList={disableSaveButtonIfNoChangeInList}
              getCategoryManagementListFromApi={getCategoryManagementListFromApi}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AppShortcutsListComponent;
