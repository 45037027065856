import { categoryType } from "./type";

const resetData = {
  createdAt: "",
  highlight: false,
  name: "",
  nftCount: 0,
  rank: 0,
  _id: ""
};

const initialState: IcategoryModalReducer = {
  isOpen: false,
  data: resetData,
  callAPI: false,
  totalCount: 0
};

const categoryModalReducer = (state = initialState, action: { type: string; payload: any }) => {
  let newState = { ...state };

  switch (action.type) {
    case categoryType.CATEGORY_OPEN:
      newState.isOpen = action.payload;
      break;
    case categoryType.CATEGORY_ID:
      newState.data = action.payload.data;
      newState.totalCount = action.payload.totalCount;
      break;
    case categoryType.CATEGORY_RESET:
      newState.isOpen = false;
      newState.data = resetData;
      newState.totalCount = 0;
      break;
    case categoryType.CATEGORY_CALLAPI:
      newState.callAPI = action.payload;
      break;
  }
  return newState;
};

export default categoryModalReducer;

export interface IcategoryModalReducer {
  isOpen: boolean;
  data: IcategoryModalIdAction;
  callAPI: boolean;
  totalCount: number;
}

export interface IcategoryModalIdAction {
  createdAt: string;
  highlight: boolean;
  name: string;
  nftCount: number;
  rank: number;
  _id: string;
}
