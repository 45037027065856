import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Row, Tooltip } from "reactstrap";
import { getPeerDetails, updatePeerDetails } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import BackArrowComponent from "../../../../common/backArrow";
import { toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, projectNames, STATUS_VALUE } from "../../../../common/constants";
import MetaFunction from "../../../../common/MetaTag";
import { setLoading } from "../../../../store/loader/action";
import ColumnComponent from "../../../common/ColumanComponent";
import StatusComponent from "../../../common/StatusComponent";
import "../../component/kycDetails.css";
import { UniversalRejectionPopup } from "../../component/UniversalRejectionPopup";
import RadioButtonComponent from "../common/RadioButtonComponent";
import UniverseRequestStatusConfirmationPopup from '../../component/UniverseRequestStatusConfirmationPopup';

export const PeerDetailsComponent = () => {
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const redioButton = [
    { name: "status", label: "Accepted", value: STATUS_VALUE.ACCEPTED },
    { name: "status", label: "Rejected", value: STATUS_VALUE.REJECTED },
    { name: "status", label: "In-Progress", value: STATUS_VALUE.IN_PROGRESS }
  ];
  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);

  const [universalStatusToggle, setUniversalStatusToggle] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState();
  const [status, setStatus] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [peerDetails, setPeerDetails] = useState<any>({});

  const toggleStatusModel = () => {
    setStatus(peerDetails?.status);
    setStatusModal(!statusModal);
  };

  useEffect(() => {
    if (currentSelectedStatus !== "2") {
    }
  }, [currentSelectedStatus]);
  const handleStatusChange = (statusValue: any) => {
    if (statusValue === 2) {
      // open the reason for rejection popup
      setUniversalStatusToggle(true);
    } else {
      setCurrentSelectedStatus(statusValue);
      toggleStatusModel();
    }
  };

  const handleUniversalListStatusChange = (id: any, status: any, reason: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updatePeerDetails(id, status, reason && reason).then((res) => {
      if (res.status === 200) {
        setStatusModalLoader(false);
        setUpdateDetails(!updateDetails);
        toastSuccess(res.message);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getPeerDetails(params.id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setLoading(false));
          setPeerDetails(response?.data);
        }
      })
      .catch((err) => {
        history.push(`/${projectNames.universe}/peers/`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateDetails]);

  return (
    <>
      <MetaFunction meta="Peer Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Peer Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(peerDetails).length > 0 && (
          <Row className="mt-10">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">Peer Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    {peerDetails?.statusReason && <ColumnComponent title="Status Reason" value={peerDetails?.statusReason} capitalize={true} />}
                    <StatusComponent status={peerDetails?.status} />
                    {peerDetails?.status === STATUS_VALUE.IN_PROGRESS || peerDetails?.status === STATUS_VALUE.PANDING ? (
                      <RadioButtonComponent handleStatusChange={handleStatusChange} setStatus={setStatus} redioButtonArray={redioButton} status={status} />
                    ) : null}
                    <ColumnComponent title="Peer Name" value={peerDetails?.name} />
                    <ColumnComponent title="User Name" value={`${peerDetails?.peerUserId?.username ? peerDetails?.peerUserId?.username : peerDetails?.peerUserId?.email.split("@")[0]}`} />
                    <ColumnComponent title="User Email" value={`${peerDetails?.peerUserId?.email}`} />
                    <ColumnComponent title="Region" value={peerDetails?.peerRegionId?.name} capitalize={true} />
                    <ColumnComponent title="Size" value={peerDetails?.size} capitalize={true} />
                    <ColumnComponent title={CREATEDATE} value={peerDetails?.createdAt ? moment(peerDetails?.createdAt).format("DD/MM/YYYY | hh:mm A") : ""} />
                    <ColumnComponent title="Updated Date | Time" value={peerDetails?.updatedAt ? moment(peerDetails?.updatedAt).format("DD/MM/YYYY | hh:mm A") : ""} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <UniversalRejectionPopup
          isOpen={universalStatusToggle}
          setUniversalStatusToggle={setUniversalStatusToggle}
          isLoading={isLoadingState}
          reason={rejectReason}
          setStatus={setStatus}
          preState={peerDetails?.status}
          setReason={setRejectReason}
          id={peerDetails.id}
          handleUniversalListStatusChange={handleUniversalListStatusChange}
        />
        <UniverseRequestStatusConfirmationPopup
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleUniversalListStatusChange}
          currentSelectedStatus={currentSelectedStatus}
          requestId={peerDetails.id}
          toggleStatusModel={toggleStatusModel}
          status={status === STATUS_VALUE.ACCEPTED ? "Accepted" : "In-Progress"}
        />
      </div>
    </>
  );
};
