import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingDappsListAPI = (params: any): Promise<IStakingDappsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.dapps.list, { params });
};

export interface IStakingDappsAPIData {
  blockchain: string;
  companyName: string;
  dAppName: string;
  description: string;
  email: string;
  fullName: string;
  nickName: string;
  postion: string;
  projectDescription: string;
  timezone: string;
  updatedAt: string;
  website: string;
  _id: string;
}

interface IStakingDappsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IStakingDappsAPIData[];
  };
}
