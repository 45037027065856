import { GET_ROLES_LISTING } from "./type";

const initialState = {
  createdAt: "",
  id: "",
  permission: {},
  roleId: "",
  roleLevel: "",
  roleName: "",
  status: "",
  updatedAt: ""
};
export const rolePermissionReducer = (state = initialState, action: any) => {
  let newState = { ...state };
  // eslint-disable-next-line
  switch (action.type) {
    case GET_ROLES_LISTING:
      newState = action.value;
  }
  return newState;
};
