import { getDecryptedLocalStorage } from "../../common/common-functions";
import { localstorageKeys } from "../../common/constants";
import { ISLOGGEDIN, ONLOGOUT } from "./type";

const getUserToken = () => {
  const userToken = getDecryptedLocalStorage(localstorageKeys.userToken) || "";
  if (userToken) {
    return {
      isLoggedIN: true,
      userToken
    };
  } else {
    return {
      isLoggedIN: false,
      userToken: null
    };
  }
};

export const authReducer = (state = getUserToken(), action: { type: string; payload: { isLoggedIN: boolean; userToken: string } }) => {
  let newState = { ...state };

  switch (action.type) {
    case ISLOGGEDIN:
      newState = {
        userToken: action.payload.userToken,
        isLoggedIN: true
      };
      break;

    case ONLOGOUT:
      newState = {
        userToken: null,
        isLoggedIN: false
      };
      break;
  }
  return newState;
};
