import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { getFormattedDate, nameBeforeAtTheRateInEmail } from "../../../../common/common-functions";
import { projectModules, projectNames, rowsPerPage } from "../../../../common/constants";
import EmailComponent from "../../../../utils/components/EmailComponent";
import { EditRestricted } from "../../../common/restrictions";

import "../../component/kycDetails.css";
import ConfirmationModal from "../../DeleteConfirmationModal";

export const ByouTableComponent = ({ listData, totalCount, tableHeaders, type, paginationConfig, setPaginationConfig, handleDrop }) => {
  const history = useHistory();

  const { isLoading } = useSelector((state) => ({
    isLoading: state.loading.isLoading
  }));

  const [dropdownPage, setDropdownPage] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);
  // eslint-disable-next-line no-unused-vars
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values) => {}
  });

  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  const renderView = (list, i) => {
    if (type === "user-management") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list.firstName + list.lastName || nameBeforeAtTheRateInEmail(list?.email)}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={`${list?.email}`} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.role}`}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue" style={{ cursor: "pointer", fontWeight: "700" }}>
            <EditRestricted module={projectModules[projectNames.byou]?.USER_MANAGEMENT}>
              <i
                className="fas fa-edit color-blue cursor-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => history.push(`/${projectNames.byou}/user-edit/${list?.id}`)}></i>
            </EditRestricted>
          </td>
        </tr>
      );
    }
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };

  const onHandleRowPerPageChange = (el) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  return (
    <>
      <Scrollbars
        autoHide
        style={{ height: 630 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        className="table-section border-grey bvote-table">
        <Table>
          <thead>
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th key={index} className="color-black1 font-14 fw-bold">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData?.length > 0 ? (
              listData?.map((list, i) => {
                return renderView(list, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10" />
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount ? totalCount : 0}`}</p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? goToFirstPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? handlePrevious : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          {/* @ToDo
                  This component generates error for onclick.
                */}
          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? handleNext : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? goToLastPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />

      {/* <UniverseRequestStatusConfirmationPopup
        isVisible={isVisible}
        handleConfirmation={handleUniversalListStatusChange}
        loader={statusModalLoader}
        currentSelectedStatus={currentSelectedStatus}
        requestId={channelDetails?.id?.toString()}
        toggleStatusModel={toggleStatusModel}
        status="Completed"
      /> */}
    </>
  );
};
