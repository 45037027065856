import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { StakingDemoRequestAPI } from "../../../../actions/staking/demoRequest/stakingDemoRequestAPI";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import SearchFilter from "../../bvote/component/SearchFilter";
import { StakingTableComponent } from "../common/ListTable";

const StakingDemoRequestList = () => {
  const module = "App Demo Request";
  const tableHeaders = ["Name", "Email", "OrganizationName", "Description", CREATEDATE, "Actions"];
  const dispatch = useDispatch();

  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);

  const StakingAvailableAppsListAction = () => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    StakingDemoRequestAPI(params).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    StakingAvailableAppsListAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const handleClear = () => {
    if (searchField === "" && paginationConfig?.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className={"d-flex flex-wrap justify-content-between"}>
                <div className="d-flex flex-wrap mb-20">
                  <SearchFilter placeHolder="Search By Name Email" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={handleClear}>
                    Clear
                  </button>
                </div>
                <StakingTableComponent
                  listData={listingData}
                  totalCount={totalListCount}
                  tableHeaders={tableHeaders}
                  type={"demoRequest"}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                  getCategoryManagementListFromApi={StakingAvailableAppsListAction}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default StakingDemoRequestList;
