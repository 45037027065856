import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Row, Tooltip } from "reactstrap";
import { getPriceAndPlanDetail } from "../../../../actions/bMessage/getBMessageAdminInfoAPI";
import BackArrowComponent from "../../../../common/backArrow";
import { getFormattedDate } from "../../../../common/common-functions";
import { CREATEDATE, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";

const ColumnComponent = ({ title, value, color, capitalize }: any) => {
  return (
    <>
      <hr className="m-0" />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 fw-bold color-black2 fw-normal text-upper">{title}</p>
        </div>
        <div className="col-9">
          <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {title === "Status" ? <>{value ? "Active" : "InActive"}</> : title === "updatedAt" || title === "createdAt" ? getFormattedDate(value) : value}
          </p>
        </div>
      </div>
    </>
  );
};

const PricePlanDetail = () => {
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);
  // eslint-disable-next-line
  const [key, setKey] = useState<any[]>([]);
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    getPriceAndPlanDetail(id).then((res) => {
      if (res.status === 200) {
        setData(res.data.pricingPlan);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    if (params?.id) {
      getViewAction(params?.id);
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const pricePlanDetail = location?.pathname === `/${projectNames.bmessage}/price-plan/${params?.id}`;

  const infoOfTileAndSubTitle = () => {
    if (pricePlanDetail) {
      return { title: "Plan Detail", subTitle: "Plan Information" };
    }
  };

  const info = infoOfTileAndSubTitle();

  return (
    <div>
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="no-first-letter-capital-title kycDetailPageTitle">{info?.title}</p>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        <Row className="mt-10">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <p className="sub-title m-b-">{info?.subTitle}</p>
              </div>

              {pricePlanDetail && (
                <>
                  <ColumnComponent title="Name" value={data?.name} />
                  <ColumnComponent title="Price" value={`$ ${data?.price}`} />
                  <ColumnComponent title="Status" value={data?.status} />
                  <ColumnComponent title="Description" value={data?.description} />
                  <ColumnComponent title={CREATEDATE} value={getFormattedDate(data?.createdAt)} />
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PricePlanDetail;
