import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Modal, ModalBody } from "reactstrap";
import { updateNftAPI } from "../../actions/nft/nftManagement/updateNftAPI";
import { toastSuccess } from "../../common/common-functions";
import { setLoading, setNftModalOpenStatus } from "../../store/loader/action";
import { NFTRejectReasonModal } from "../main/nft/nftManagement/component/NFTRejectReasonModal";

const NFTStatusComponent = ({ status, id }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { nftRejectModalOpenStatus, isLoading } = useSelector((state) => ({
    nftRejectModalOpenStatus: state.loading.nftRejectModalOpenStatus,
    isLoading: state.loading.isLoading,
  }));

  const toggleRejectionModalVisibility = () => {
    dispatch(setNftModalOpenStatus(!nftRejectModalOpenStatus));
  };

  useEffect(() => {
    setChecked(status === false ? false : true);
  }, [status]);

  const handleConformation = (id) => {
    setSelectedId(id);
    dispatch(setNftModalOpenStatus(true));
  };

  const handleInputChange = (reason) => {
    dispatch(setLoading(true));
    let params = {
      reason: checked ? "Error Rectification" : reason,
      action: checked ? false : true,
    };
    updateNftAPI(checked ? id : selectedId, params).then((res) => {
      if (res?.status === 200) {
        toastSuccess(res?.message);
        setChecked(!checked);
        setVisible(false);
      }
      dispatch(setLoading(false));
    });
  };

  return (
    <>
      <div
        className="d-flex align-baseline"
        data-toggle="tooltip"
        data-placement="top"
      >
        {/* <EditRestricted module={projectModules[projectNames.admin].MODULE_MANAGEMENT}> */}
        <Switch
          height={18}
          width={34}
          onColor="#007de4"
          offColor="#b1abab"
          checked={checked}
          onChange={() => setVisible(true)}
          uncheckedIcon
          checkedIcon
          className="mr-10"
        />
        {/* </EditRestricted> */}
        <span>{checked ? "Ban" : "Unban"}</span>
      </div>
      <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center">Confirmation</h4>
            <p
              className="fw-bold text-center font-18 "
              style={{ color: "#868686" }}
            >
              Are you sure, you want to {checked ? "Unban" : "Ban"} NFT?
            </p>
          </div>
          <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
            <div
              className="col-6 p-10 d-flex cursor-pointer"
              style={{ borderRight: "1px solid #dadce0" }}
              onClick={() => {
                setVisible(false);
              }}
            >
              <button className="confimatio-button text-danger">No</button>
            </div>
            <div
              className="col-6 p-10 d-flex cursor-pointer"
              onClick={() =>
                checked ? handleInputChange() : handleConformation(id)
              }
            >
              <button className="confimatio-button text-success">Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {selectedId !== "" && (
        <NFTRejectReasonModal
          checked={checked}
          isOpen={nftRejectModalOpenStatus}
          isLoading={isLoading}
          toggleVisibility={toggleRejectionModalVisibility}
          handleRejectReason={handleInputChange}
          setVisible={setVisible}
        />
      )}
    </>
  );
};

export default NFTStatusComponent;
