import { faSquarePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikErrors } from "formik";
import { FC } from "react";
import { Col, Input, Label, Row } from "reactstrap";

interface IFeaturesComponent {
  setFiledValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<any>>;
  values: string[];
  errors: any;
  isError: boolean;
}

const FeaturesComponent: FC<IFeaturesComponent> = ({ setFiledValue, values, errors, isError }) => {
  const handleIncrement = () => {
    let newArray = [...values];
    let filterEmptyData = newArray?.filter((ele, index) => {
      return ele === "";
    });

    if (filterEmptyData[0] !== "") {
      newArray.push("");
      setFiledValue("features", newArray);
    }
  };

  const handleDecrement = (index: number) => {
    let newArray = [...values];
    newArray.splice(index, 1);
    setFiledValue("features", newArray);
  };

  const handleChange = (index: number, value: string) => {
    let newArray = [...values];
    newArray[index] = value;
    setFiledValue("features", newArray);
  };

  return (
    <>
      <Label for="biglogo" className="mt-3">
        Features <span className="text-red">*</span>
      </Label>
      {values.map((data, i) => (
        <>
          <Row className={`${i === 0 ? "mt-1" : "mt-2"}`}>
            <Col sm={10} key={i}>
              <Input
                placeholder="Features"
                value={data}
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    handleChange(i, "");
                  } else {
                    handleChange(i, e.target.value);
                  }
                }}
                invalid={Boolean(isError && errors?.features?.[i])}
              />
              {isError ? errors?.features?.[i] ? <span className="text-danger">{errors?.features?.[i]}</span> : null : null}
            </Col>

            {i === 0 ? (
              <Col sm={2}>
                <button title="Add Features" className="btn-sm btn btn-primary bg-blue border-0 shadow-none ml-10 button-width-com" onClick={handleIncrement}>
                  <FontAwesomeIcon icon={faSquarePlus} />
                </button>
              </Col>
            ) : null}
            {i !== 0 ? (
              <Col sm={2}>
                <button title="Remove Features" className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => handleDecrement(i)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </Col>
            ) : null}
          </Row>
        </>
      ))}
    </>
  );
};

export default FeaturesComponent;
