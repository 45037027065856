import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDomainsList } from "../../../../../actions/portal/adminActions";

import MetaFunction from "../../../../../common/MetaTag";
import { setLoading } from "../../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../../store/rootRededucer";

import AppshortcutsDragAndDrop from "./AppShortcutsDragAndDrop";

const AppShortcutsListComponent = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: IRootReducerTypes) => state.loading);

  const [blockChainData, setBlockChainData] = useState([]);

  // eslint-disable-next-line
  const getBlockChainData = () => {
    dispatch(setLoading(true));
    getDomainsList().then((res) => {
      if (res.status === 200) {
        setBlockChainData(res.data.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getBlockChainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaFunction meta="Blockchain" />
      <div style={{ minHeight: "85vh" }}>
        <div className=" rounded-10 bg-white blockchain-main-section">
          <div className="row block-chain-header">
            <div className="col-4 text-break">Domains order</div>
          </div>
          {blockChainData.length === 0 ? (
            isLoading ? (
              <div className="p-1">Loading...</div>
            ) : (
              <div className="p-1">No Domains are available</div>
            )
          ) : (
            <AppshortcutsDragAndDrop blockChainObject={blockChainData} setBlockChainData={setBlockChainData} />
          )}
        </div>
      </div>
    </>
  );
};

export default AppShortcutsListComponent;
