import { bStampURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const getbstampUserList = (params?: any) => {
  return HTTPService.get(bStampURL.getUserManagement, { params });
};

export const getbstampOrganizationList = (params?: any) => {
  return HTTPService.get(bStampURL.getOrgManagement, { params });
};
