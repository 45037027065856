import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select, { SingleValue } from "react-select";
import { Col, Row } from "reactstrap";
import { getNFTManagementAPI } from "../../../../actions/nft/nftManagement/getNftListAPI";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE, ReactSelectCustomstyle, initialPaginationConfig } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import CustomTooltip from "../../../common/ToolTip";
import SearchFilter from "../../banji/component/SearchFilter";
import { BvoteTableComponent } from "../common/ListTable";

const tableHeaders = ["NFT Title", "Owner", CREATEDATE, "Actions"];

const NFTManagement = () => {
  const [nftStatusValue, setNFTStatusValue] = useState<SingleValue<{ value: string; label: string }>>();
  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState<paginationObject>(initialPaginationConfig);

  const module = "NFT Management";
  const NFTStatus = [
    { value: "", label: "All" },
    { value: "ban", label: "Ban NFT" },
    { value: "unban", label: "Unban NFT" }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    getNftManagementList();
  }, [paginationConfig, nftStatusValue]);

  const getNftManagementList = () => {
    dispatch(setLoading(true));
    let paginationConfigTemp: any = {};
    if (search) {
      paginationConfigTemp = paginationConfig;
    } else {
      paginationConfigTemp.page = paginationConfig?.page;
      paginationConfigTemp.limit = paginationConfig?.limit;
    }
    if (nftStatusValue && nftStatusValue.value) {
      paginationConfigTemp.filter = nftStatusValue?.value === "ban" ? true : false;
    } else {
      delete paginationConfigTemp.filter;
    }
    getNFTManagementAPI(paginationConfigTemp).then((res: any) => {
      if (res?.status === 200) {
        setTotalListCount(res.count);
        setListingData(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  const resetFilters = () => {
    // @ts-ignore
    if (search === "" && paginationConfig?.search === "" && nftStatusValue === "") {
    } else {
      setSearch("");
      setPaginationConfig(initialPaginationConfig);
      setNFTStatusValue({ label: NFTStatus[0]?.label, value: NFTStatus[0]?.value });
    }
  };

  const handleNFTStatusFilter = (data: any) => {
    setNFTStatusValue(data);
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter placeHolder="Search By NFT Title" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  <Select
                    defaultValue={
                      NFTStatus?.length > 0 && {
                        label: NFTStatus[0]?.label,
                        value: NFTStatus[0]?.value
                      }
                    }
                    placeholder="NFT Status"
                    onChange={(e) => handleNFTStatusFilter(e)}
                    options={NFTStatus}
                    value={nftStatusValue}
                    className="me-2 ml-10 z-index"
                    styles={ReactSelectCustomstyle}
                  />
                  <button className="btn border-blue  ml-10 button-width-com" onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
              </div>
              <div className="ml-10 mb-6 d-flex align-items-center gap-1">
                <span className="fw-bold">Note:</span><span className="gray">Only public and banned NFT's will be listed here.</span>
                <CustomTooltip direction="right" content="Only public NFT's can be banned. Once banned, they will be set to private and no longer visible to users.">
                  <FontAwesomeIcon className="cursor-pointer" icon={faInfoCircle} />
                </CustomTooltip>
              </div>
              {/* @ts-ignore */}
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"nft-management"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default NFTManagement;

interface paginationObject {
  search?: string;
  page: number;
  limit: number;
  status?: boolean;
}
