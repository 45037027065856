import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingAvailableAppsListAPI = (params?: any): Promise<IStakingAvailableAppsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.availableApps.list, {
    params
  });
};

export const addNewAppType = (data: any) => {
  return HTTPService.post(StakingPortalApiEndPoint.availableApps.create, data);
};

export const getAppTypeInfo = (params: any) => {
  return HTTPService.get(StakingPortalApiEndPoint.availableApps.get + params);
};

export const updateAppType = ({ _id, ...rest }: any) => {
  return HTTPService.put(StakingPortalApiEndPoint.availableApps.update + _id, rest);
};

export interface IStakingAvailableAppsAPIData {
  createdAt: string;
  icon: string;
  url?: string;
  name: string;
  updatedAt: string;
  visible: boolean;
  isActive?: boolean;
  _id: string;
}

interface IStakingAvailableAppsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    rows: IStakingAvailableAppsAPIData[];
  };
}
