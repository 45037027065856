import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Input, Label, Row, Tooltip } from "reactstrap";
import { getReqestDemoDetailsAPI } from "../../../../actions/nft/requestDemo/getRequestDemoDetailsAPI";
import { updateRequestDemoDetailsAPI } from "../../../../actions/nft/requestDemo/updateRequestDemoDetails";
import "../../../../assets/css/universe-detail-page.css";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, RequestDemoStatus, projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import "../../component/kycDetails.css";
import { RequestDemoRejectionPopup } from "./RequestDemoRejectionPopup";
import RequestDemoStatusConfirmationPopup from "./RequestDemoStatusConfirmationPopup";

export const NftRequestDemoDetailsComponent = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: IRootReducerTypes) => ({
    isLoading: state.loading.isLoading
  }));
  const isLoadingState = useSelector((state: IRootReducerTypes) => state?.loading?.isLoading);

  const [numberStatus, setNumberStatus] = useState(0);
  const [rejectReason, setRejectReason] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState(3);
  const [universalStatusToggle, setUniversalStatusToggle] = useState(false);
  const [universalDetails, setUniversalDetails] = useState({
    fullName: "",
    phoneNumber: "",
    description: "",
    status: 0,
    reference: "",
    remarks: "",
    _id: "",
    email: "",
    createdAt: "",
    updatedAt: ""
  });

  const toggleStatusModel = () => setStatusModal(!statusModal);

  const ColumnComponent = ({ title, value, color, capitalize }: any) => {
    return (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20">
          <div className="info-name col-2">
            <p className="font-13 color-black2 fw-bold fw-normal text-upper">{title}</p>
          </div>
          <div className="col-9">
            <p className={`color-black2 font-14  info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
              {value}
            </p>
          </div>
        </div>{" "}
      </>
    );
  };

  const StatusComponent = ({ capitalize, color }: any) => {
    return (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20">
          <div className="info-name col-2">
            <p className="font-14 fw-bold color-black2 fw-normal text-upper">Status</p>
          </div>
          <div className="col-10" style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                backgroundColor: getKyCStatus(universalDetails.status).color,
                height: 10,
                borderRadius: 5,
                marginRight: 5
              }}></div>
            <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
              {getKyCStatus(universalDetails.status).label}
            </p>
          </div>
        </div>
      </>
    );
  };

  const handleStatusChange = (status: any) => {
    if (status === "2") {
      setCurrentSelectedStatus(status);
      toggleStatusModel();
    } else if (status === "4") {
      setCurrentSelectedStatus(status);
      toggleStatusModel();
    } else {
      setUniversalStatusToggle(true);
    }
  };

  const DropDownComponent = () => {
    function decideAction(status: any) {
      if (status === 0) {
        return [
          { label: RequestDemoStatus.Declined.name, status: 1 },
          { label: RequestDemoStatus.Scheduled.name, status: 2 },
          { label: RequestDemoStatus.Completed.name, status: 3 },
          { label: RequestDemoStatus.Approved.name, status: 4 }
        ];
      } else if (status === 1) {
        return [
          { label: RequestDemoStatus.Scheduled.name, status: 2 },
          { label: RequestDemoStatus.Completed.name, status: 3 },
          { label: RequestDemoStatus.Approved.name, status: 4 }
        ];
      } else if (status === 2) {
        return [
          { label: RequestDemoStatus.Scheduled.name, status: 2 },
          { label: RequestDemoStatus.Completed.name, status: 3 },
          { label: RequestDemoStatus.Approved.name, status: 4 }
        ];
      } else if (status === 3) {
        return [{ label: RequestDemoStatus.Approved.name, status: 4 }];
      } else if (status === 4) {
        return [{ label: RequestDemoStatus.Completed.name, status: 3 }];
      } else {
        return null;
      }
    }
    let statusArray = decideAction(universalDetails.status);

    return statusArray && statusArray.length > 0 ? (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20" style={{ alignItems: "center" }}>
          <div className="info-name col-2">
            <p className="font-14 fw-bold color-black2 fw-normal text-upper">Change Status</p>
          </div>
          <div className="col-6 row justify-content-between align-items-center status-buttons">
            {statusArray.map((option: any, index: any) => {
              return (
                <Label key={index} role="button" className="status-radio-buttons col-4">
                  <Input
                    type="radio"
                    value={option.status}
                    name="status"
                    className="me-2"
                    onChange={(e) => {
                      setNumberStatus(Number(e.target.value));
                      handleStatusChange(e.target.value);
                    }}
                    checked={universalDetails.status === option.status}
                  />
                  {option.label}
                </Label>
              );
            })}
          </div>
        </div>
      </>
    ) : (
      <div></div>
    );
  };

  const getKyCStatus = (statusCode: any) => {
    let result: {
      label: string;
      class: string;
      color?: string;
    } = {
      label: "",
      class: ""
    }; // eslint-disable-next-line
    switch (statusCode) {
      case 0:
        result = {
          label: RequestDemoStatus.InProgress.name,
          class: "pending",
          color: "#eaac20"
        };
        break;

      case 1:
        result = {
          label: RequestDemoStatus.Declined.name,
          class: "deactive",
          color: "#ea2027"
        };
        break;
      case 2:
        result = {
          label: RequestDemoStatus.Scheduled.name,
          class: "inprocess",
          color: "#20eadb"
        };
        break;

      case 3:
        result = {
          label: RequestDemoStatus.Completed.name,
          class: "active",
          color: "#28c76f"
        };
        break;

      case 4:
        result = {
          label: RequestDemoStatus.Approved.name,
          class: "active",
          color: "#2d7de4"
        };
        break;

      default:
        result = {
          label: "Not Started",
          class: "pending"
        };
        break;
    }
    return result;
  };

  const handleUniversalListStatusChange = (id: any, status: any, reason: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateRequestDemoDetailsAPI({
      id,
      status: numberStatus,
      ...(reason && { remarks: reason })
    }).then((res) => {
      if (res.status === 200) {
        setStatusModalLoader(false);
        setUpdateDetails(!updateDetails);
        toastSuccess(res.message);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getReqestDemoDetailsAPI(params).then((response) => {
      if (response.status === 200) {
        setUniversalDetails(response.data);
        dispatch(setLoading(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateDetails]);

  return (
    <>
      <MetaFunction meta="Request Demo Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Request Demo Details</p>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(universalDetails).length > 0 && (
          <Row className="mt-10">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">User Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    {universalDetails.remarks && <ColumnComponent title="Status Reason" value={universalDetails.remarks} capitalize={true} />}
                    <StatusComponent />
                    {handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].REQUEST_DEMO, "update") && <DropDownComponent />}
                    <ColumnComponent title="Full Name" value={universalDetails.fullName} />
                    <ColumnComponent title="Email" value={`${universalDetails.email}`} />
                    <ColumnComponent title="Description" value={universalDetails.description} />
                    <ColumnComponent title="Phone number" value={universalDetails.phoneNumber} />
                    <ColumnComponent title="Reference" value={universalDetails.reference} capitalize={true} />
                    <ColumnComponent title={CREATEDATE} value={universalDetails.createdAt ? moment(universalDetails.createdAt).format("DD/MM/YYYY | hh:mm A") : ""} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <RequestDemoRejectionPopup
          isOpen={universalStatusToggle}
          setUniversalStatusToggle={setUniversalStatusToggle}
          isLoading={isLoading}
          reason={rejectReason}
          setReason={setRejectReason}
          id={universalDetails._id}
          handleUniversalListStatusChange={handleUniversalListStatusChange}
        />
        {/* @ts-ignore */}
        <RequestDemoStatusConfirmationPopup
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleUniversalListStatusChange}
          currentSelectedStatus={currentSelectedStatus}
          requestId={universalDetails._id.toString()}
          toggleStatusModel={toggleStatusModel}
          status={String(numberStatus)}
        />
      </div>
    </>
  );
};
