import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { getFormattedDate, getGeneralStatus, getKyCStatus, nameBeforeAtTheRateInEmail } from "../../../../common/common-functions";
import { projectNames, rowsPerPage } from "../../../../common/constants";
import EmailComponent from "../../../../utils/components/EmailComponent";
import useHandleUNiversalStatusChange from "../../../../utils/hooks/useHandleUNiversalStatusChange";
import CustomTooltip from "../../../common/ToolTip";
import "../../component/kycDetails.css";
import ConfirmationModal from "../../DeleteConfirmationModal";

export const UniverseTableComponent = ({ listData, totalCount, tableHeaders, type, paginationConfig, setPaginationConfig }) => {
  const history = useHistory();
  const { callApi } = useHandleUNiversalStatusChange();

  const { isLoading } = useSelector((state) => ({
    isLoading: state.loading.isLoading
  }));

  const [dropdownPage, setDropdownPage] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);
  // eslint-disable-next-line no-unused-vars
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values) => {}
  });

  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  const invitationStatusToolTips = (statusNumber) => {
    if (statusNumber === 0) {
      return "Pending";
    } else if (statusNumber === 1) {
      return "Approved";
    } else if (statusNumber === 2) {
      return "Rejected";
    } else if (statusNumber === 3) {
      return "inprogress";
    }
  };

  const getInvitationStatuses = (invitations) => {
    return (
      <>
        {invitations.map((invitation) => {
          return (
            <>
              <CustomTooltip direction="bottom" content={invitationStatusToolTips(invitation.status)}>
                <td className="font-16 color-light-grey pl-35 position-relative status-data">
                  <span className={`status ${getKyCStatus(invitation?.status).class}`}></span>

                  {invitation?.count}
                </td>
              </CustomTooltip>
            </>
          );
        })}
      </>
    );
  };

  const renderView = (list, i) => {
    if (type === "cloud") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.userInfo.name || list?.userInfo.email.substring(0, list?.userInfo.email.indexOf("@"))} `}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={`${list?.userInfo.email}`} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.envName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.networkName}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/universe-request/${list.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "channelName") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.channelName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.totalParticipate}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-16 color-light-grey">{getInvitationStatuses(list?.invitation)}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/channel-listing/${list.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "token-engine") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.tkEngUserId?.name}`}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={`${list?.tkEngUserId?.email}`} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.chainCode}`}</td>
          <td className="font-16 color-light-grey">{`${list?.channel}`}</td>
          <td className="font-16 color-light-grey">{`${list?.participationType}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list?.status).class}`}></span>
            {getKyCStatus(list?.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/token-engine/${list?.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "channelInvitations") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.invitationChannelId?.channelName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.invitationSentBy?.name}`}</td>
          <td className="font-16 color-light-grey">{`${list?.invitationSentTo?.name}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/channel-invitations/${list.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
            <i
              className="fa fa-check-circle color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit admin"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => callApi(list.id, 1)}></i>
          </td>
        </tr>
      );
    } else if (type === "invitation") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.channelName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.channelOwner?.name}`}</td>
          <td className="font-16 color-light-grey">{`${list?.totalParticipate}`}</td>
          <td className="font-16 color-light-grey">{`${list?.invitation.length}`}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-16 color-light-grey">{`${list?.updatedAt && getFormattedDate(list?.updatedAt)}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/channel-listing/${list.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "templateListing") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name} `}</td>
          <td className="font-16 color-light-grey">{`${list?.fields}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list?.visible === true ? 1 : 0).class}`}></span>
            {list?.visible === true ? "Visible" : "Not visible"}
          </td>

          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/templates/${list?.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
            <i
              className="fas fa-edit color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => history.push(`/${projectNames.universe}/templates-edit/${list?.id}`)}></i>
          </td>
        </tr>
      );
    } else if (type === "requestHistory") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.moduleName} `}</td>
          <td className="font-16 color-light-grey">{`${list?.requestName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.reqHstRequestedBy?.name || nameBeforeAtTheRateInEmail(list?.reqHstRequestedBy?.email)}`}</td>
          <td className="font-16 color-light-grey">{`${list?.requestDate && getFormattedDate(list?.requestDate)}`}</td>

          <td className="font-16 color-light-grey">{`${list?.updatedAt && getFormattedDate(list?.updatedAt)}`}</td>

          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-14 blue">
            <i
              style={{ cursor: "pointer", fontWeight: "700" }}
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => {
                if (list?.moduleName === "Channel") {
                  history.push(`/${projectNames.universe}/channel-listing/${list?.moduleId}`);
                } else if (list?.moduleName === "Network") {
                  history.push(`/${projectNames.universe}/universe-request/${list?.moduleId}`);
                } else if (list?.moduleName === "Contract") {
                  history.push(`/${projectNames.universe}/contracts-listing/${list?.moduleId}`);
                } else if (list?.moduleName === "Chaincode") {
                  history.push(`/${projectNames.universe}/chain-code-management/${list?.moduleId}`);
                } else if (list?.moduleName === "Peer") {
                  history.push(`/${projectNames.universe}/peers/${list?.moduleId}`);
                } else {
                }
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "chain-code") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.chainCodeUserId?.name || nameBeforeAtTheRateInEmail(list?.chainCodeUserId?.email)}`}</td>
          <td className="font-16 color-light-grey">{`${list?.chainCodeChannelId?.channelName}`}</td>
          <td className="font-16 color-light-grey">{`${list?.chainCodeTemplateId?.name}`}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list.status).class}`}></span>
            {getKyCStatus(list.status).label}
          </td>
          <td className="font-14 blue">
            <i
              style={{ cursor: "pointer", fontWeight: "700" }}
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => {
                history.push(`/${projectNames.universe}/chain-code-management/${list?.id}`);
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "peers") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name} `}</td>
          <td className="font-16 color-light-grey">{`${list?.peerUserId?.username ? list?.peerUserId?.username : list?.peerUserId?.name}`}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-16 color-light-grey">{`${list?.updatedAt && getFormattedDate(list?.updatedAt)}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list?.status).class}`}></span>
            {getKyCStatus(list?.status).label}
          </td>
          <td className="font-14 blue">
            <i
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/peers/${list?.id}`)}></i>
          </td>
        </tr>
      );
    } else if (type === "smart-contracts") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name} `}</td>
          <td className="font-16 color-light-grey">{`${list?.cntrctUser?.name || nameBeforeAtTheRateInEmail(list?.cntrctUser?.email)}`}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list?.status).class}`}></span>
            {getKyCStatus(list?.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/contracts-listing/${list?.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "installations") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.contrChlContract?.name} `}</td>
          <td className="font-16 color-light-grey">{`${list?.contrChlUser?.name || nameBeforeAtTheRateInEmail(list?.contrChlUser?.email)}`}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={`${list?.contrChlUser?.email} `} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.contrChlChannel?.channelName} `}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getKyCStatus(list?.status).class}`}></span>
            {getKyCStatus(list?.status).label}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              onClick={() => history.push(`/${projectNames.universe}/installations/${list?.id}`)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "domains") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name} `}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getGeneralStatus(list?.visible === true ? 1 : 0).class}`}></span>
            {list?.visible === true ? "Visible" : "Not Visible"}
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getGeneralStatus(list?.disable === true ? 0 : 1).class}`}></span>
            {list?.disable === true ? "Disabled" : "Enabled"}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)} `}</td>
          <td className="font-16 color-light-grey">{`${list?.updatedAt && getFormattedDate(list?.updatedAt)} `}</td>
          <td className="font-14 blue fw-bold">
            <i
              className="fas fa-edit color-blue cursor-pointer"
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              onClick={() => history.push(`/${projectNames.universe}/domain-edit/${list?.id}`)}></i>
          </td>
        </tr>
      );
    } else if (type === "regions") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.region} `}</td>
          <td className="font-16 color-light-grey">{`${list?.name} `}</td>
          <td className="font-16 color-light-grey">{`${list?.networkDomain?.name} `}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getGeneralStatus(list?.visible === true ? 1 : 0).class}`}></span>
            {list?.visible === true ? "Visible" : "Not Visible"}
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getGeneralStatus(list?.disable === true ? 0 : 1).class}`}></span>
            {list?.disable === true ? "Disabled" : "Enabled"}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)} `}</td>
          <td className="font-14 blue">
            <i
              style={{ cursor: "pointer", fontWeight: "700" }}
              className="fas fa-edit color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              onClick={() => history.push(`/${projectNames.universe}/region-edit/${list?.id}`)}></i>
          </td>
        </tr>
      );
    }
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };

  const onHandleRowPerPageChange = (el) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  return (
    <>
      <Scrollbars
        autoHide
        style={{ height: 600 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        className="table-section border-grey bvote-table">
        <Table>
          <thead>
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th key={index} className="color-black1 font-14 fw-bold">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData?.length > 0 ? (
              listData?.map((list, i) => {
                return renderView(list, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10" />
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount}`}</p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? goToFirstPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? handlePrevious : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? handleNext : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? goToLastPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};
