import { KYCREJECTMODALLOADER, NFTREJECTMODALLOADER, SETLOADING } from "./type";

const initialState = {
  isLoading: false,
  kycRejectModalOpenStatus: false,
  nftRejectModalOpenStatus: false,
};

const loadingReducer = (
  state = initialState,
  action: { type: string; payload: boolean }
) => {
  const newState = { ...state };
  switch (action.type) {
    case SETLOADING:
      newState.isLoading = action.payload;
      break;

    case KYCREJECTMODALLOADER:
      newState.kycRejectModalOpenStatus = action.payload;
      break;

    case NFTREJECTMODALLOADER:
      newState.nftRejectModalOpenStatus = action.payload;
      break;
  }
  return newState;
};

export { loadingReducer };
