import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateChannelInvitation } from "../../actions/portal/adminActions";
import { toastSuccess } from "../../common/common-functions";
import { setLoading } from "../../store/loader/action";

// Purpose of this Hook:I have to change the status of the invitation at Channel Detail, Channel Invitation listing and Channel Invitation detail page, so to avoid repeated code, I had to create this Custom hook.

const useHandleUNiversalStatusChange = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [statusModal, setStatusModal] = useState(false);
  // eslint-disable-next-line
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);

  const callApiHandleUNiversalStatusChange = (id: any, status: any, reason?: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateChannelInvitation(id, status, reason && reason).then(() => {
      dispatch(setLoading(false));
      setStatusModalLoader(false);
      setUpdateDetails(!updateDetails);
      toastSuccess("Status changed successfully");
    });
  };
  return { callApi: callApiHandleUNiversalStatusChange };
};

export default useHandleUNiversalStatusChange;
