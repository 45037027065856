import { FC } from "react";
import { getUniverseStatusClass } from "../../common/common-functions";

interface IStatusComponent {
  capitalize?: any;
  color?: any;
  status: any;
}
const StatusComponent: FC<IStatusComponent> = ({ capitalize, color, status }) => {
  return (
    <>
      <hr className="m-0" style={{ height: "0.5px" }} />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-14 fw-bold color-black2 fw-normal text-upper">Status</p>
        </div>
        <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: getUniverseStatusClass(status).color,
              width: 10,
              height: 10,
              borderRadius: 5,
              marginRight: 5
            }}></div>
          <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {getUniverseStatusClass(status).label}
          </p>
        </div>
      </div>
    </>
  );
};

export default StatusComponent;
