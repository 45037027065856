import { AES, enc } from "crypto-js";
import { isString } from "lodash";
import moment from "moment";
import toast from "react-hot-toast";
import ModuleDropdown from "../component/moduleDropdown/ModuleDropdown";
import { onUserLogout } from "../store/auth/action";
import store from "../store/index";
import { setCurrentProject } from "../store/project/action";
import { cookieKeys, dateFormat, minDateFormat, projectNames, queryParameterStatus, toasterPosition, validatorStatus } from "./constants";

export const toastSuccess = (message: string) => {
  toast.remove();
  toast.success(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      minWidth: 150,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: "1px solid #073E84"
    },
    iconTheme: { primary: "#073E84 ", secondary: "#fff" }
  });
};

export const toastError = (message: string) => {
  toast.remove();
  toast.error(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      fontWeight: 500,
      padding: 10,
      marginBottom: 60,
      border: "1px solid #ff0000"
    }
  });
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};

export const editFormDefaultValues = {
  gender: [
    { name: "Male", checked: true },
    { name: "Female", checked: false },
    { name: "Other", checked: false }
  ],
  year: { min: 1950, max: new Date().getFullYear() },
  monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
};
// const cryptoKey = "skdjf348u834#$%$fsFsD$@$;aljdf";
export const handleLogout = () => {
  localStorage.clear();
  removeDecryptedCookie(cookieKeys.cookieUser);
  removeDecryptedCookie(cookieKeys.cryptoSecretKey);
  removeDecryptedCookie(cookieKeys.cookieInitial);
  store.dispatch(onUserLogout());
  // history.push("/");
  // if user logout then set current project back to admin[root] so user can call login api again without reloading the page.
  // If below line is removed then the login api will not work until user reload the page again.
  // Scenario - user logout by clicking button and then trying to login again from login page without reloading the whole page.
  store.dispatch(setCurrentProject(projectNames.admin));
};

export const encryptData = (data: any) => {
  return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey);
};

export const decryptData = (data: any) => {
  try {
    const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
    if (bytes.toString()) {
      const result = JSON.parse(bytes.toString(enc.Utf8));
      return result;
    }
  } catch (e) {
    // store.dispatch(onUserLogOut());
    localStorage.clear();

    removeDecryptedCookie(cookieKeys.cookieUser);
  }
};
export const removeDecryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=${window.location.hostname.replace("accounts", "")};path=/;`;
  }
};

/**
 * @encrypt_local_storage
 *
 * @param key
 * @param value
 * @returns {null}
 */

export const setEncryptedLocalStorage = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    localStorage.setItem(keyName, encryptedString.toString());
  }
};

export const dropDownCustomStyles = {
  option: (styles: any) => ({
    ...styles,
    cursor: "pointer"
  }),
  control: (styles: any) => ({
    ...styles,
    cursor: "pointer"
  })
};

/**
 * @decrypt_local_storge
 *
 * @param key
 * @returns {json || string} returns decrypted value
 */
export const getDecryptedLocalStorage = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();

    const localStorageData = localStorage.getItem(keyName);
    if (localStorageData) {
      return decryptData(localStorageData);
    } else {
      localStorage.clear();

      removeDecryptedCookie(cookieKeys.cookieUser);
    }
  }
};

export const getFormattedDate = (utcDate: string) => {
  return moment(utcDate).format(dateFormat);
};

export const getMonthName = (utcDate: string) => {
  return moment(utcDate).format("MMMM");
};

export const getMinimumDate = (utcDate: any) => {
  return moment(utcDate).format(minDateFormat);
};

export const checkIfValidDate = (value: any) => {
  // if its a string, not a date we return false to show the actual value itself
  if (isString(value)) {
    return false;
  } else {
    return moment(new Date(value)).isValid();
  }
};

export const generate5DigitRandomNumber = () => {
  return Math.floor(Math.random() * 90000) + 10000;
};

export const getMinDateTomorrow = () => {
  return new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + (new Date().getDate() + 1).toString();
};

export const goToPage = (url: string | undefined) => {
  window.open(url, "_blank");
};

export const signInWithOtherApps = (item: string) => {
  let url: string = "";
  switch (item) {
    case "banji":
      url = `${process.env.REACT_APP_banjiDomain}`;
      break;
    case "bMessage":
      url = `${process.env.REACT_APP_bMessageDomain}`;
      break;
    case "bStamp":
      url = `${process.env.REACT_APP_bStampDomain}`;
      break;
    case "portal":
      url = `${process.env.REACT_APP_customerPortalDomain}`;
      break;
  }

  if (url) {
    window.open(url, "_blank");
  }
};

export const RandomColorGenerator = () => {
  return (
    "#" +
    Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padEnd(6, "0")
  );
};

export const GetButton = (props: any) => {
  return (
    <button
      type="button"
      className="btn-comman"
      style={{
        color: props.tcolor,
        backgroundColor: props.bcolor,
        marginLeft: props.ml ? props.ml : 0,
        position: props.pos,
        right: props.rght
      }}
      onClick={props.onClick}>
      {props.text}
    </button>
  );
};

/**
 *
 * @param bytes - size in byte
 * @param decimals - decimal limit
 * @returns size in Bytes, KB, MB, GB, TB, PB, EB, ZB, YB
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * @userPermissions
 */

// this functions perform a permissions check
export const grantPermission = (requestedRoles: boolean) => {
  /**1
   * @thoughts
   * Logic can be more complicated in future
   */
  if (requestedRoles) return true;
  return false;
};

// title case
export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  //rohan
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const IsValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
// get kyc status image
export const getKyCStatus = (statusCode: number) => {
  let result = {
    label: "",
    class: ""
  }; // eslint-disable-next-line
  switch (statusCode) {
    case 0:
      result = {
        label: "Pending",
        class: "pending"
      };
      break;
    case 1:
      result = {
        label: "Approved",
        class: "active"
      };
      break;
    case 2:
      result = {
        label: "Rejected",
        class: "deactive"
      };
      break;

    case 3:
      result = {
        label: "In-Process",
        class: "inprocess"
      };
      break;
    case 4:
      result = {
        label: "Visible",
        class: "active"
      };
      break;
    case 5:
      result = {
        label: "Disable",
        class: "deactive"
      };
      break;
    default:
      result = {
        label: "Not Started",
        class: "pending"
      };
      break;
  }
  return result;
};

export const getGeneralStatus = (statusCode: number) => {
  let result = {
    label: "",
    class: ""
  }; // eslint-disable-next-line
  switch (statusCode) {
    case 0:
      result = {
        label: "Pending",
        class: "pending"
      };
      break;
    case 1:
      result = {
        label: "Approved",
        class: "active"
      };
      break;
    case 2:
      result = {
        label: "Rejected",
        class: "deactive"
      };
      break;

    case 3:
      result = {
        label: "In-Process",
        class: "inprocess"
      };
      break;

    default:
      result = {
        label: "Not Started",
        class: "pending"
      };
      break;
  }
  return result;
};

// convert an image to base64
export const getBase64 = (file: any) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        return resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(false);
      };
    });
  } else {
    return new Promise((resolve, reject) => resolve(""));
  }
};
// base 64 function ends here

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

// name handle
export const handleName = (email: string) => {
  let name = email.split("@")[0].replace(/[^a-zA-Z0-9]/g, " ");
  return name;
};

// check for null or empty string
// if string contains only spaces return false
// if string is null, undefined or empty then also return false

// it will return true if the string is empty null or undefined
export const isStringEmptyOrNull = (string: string) => {
  return !Boolean(string && string?.trim());
};

// get url arams
export const urlParams = new URLSearchParams(window.location.search);

export const handleAppActionPermission = (projectName: String, moduleName?: String | "", action?: string | "") => {
  if (moduleName === "channelmanagement") {
    if (moduleName || action) {
      const currentPermission: any = store.getState().permissionsReducer.permissions;
      // @ts-ignore
      return currentPermission?.Universe[moduleName]?.[action] ? false : true;
    }
  } else {
    const currentPermission: any = store.getState().permissionsReducer.permissions;

    // @ts-ignore
    return currentPermission?.[projectName]?.[moduleName]?.[action] ? false : true;
  }
  return false;
};

export const nameBeforeAtTheRateInEmail = (email?: any) => {
  return email?.substring(0, email?.lastIndexOf("@"));
};

export const openDropDown = () => { };

export const moduleDropdown = (nameOfModule: string, icon: any, type: string, isSidebarOpen: boolean, route: any, subModuleExist: boolean) => {
  if (nameOfModule === "Customer Request") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }

  if (nameOfModule === "Smart Contracts") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }

  if (nameOfModule === "Channel Management") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }

  if (nameOfModule === "Apps & API") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }

  if (nameOfModule === "Domains & Regions") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }
  if (nameOfModule === "News Management") {
    return <ModuleDropdown icon={icon} nameOfModule={nameOfModule} type={type} isSidebarOpen={isSidebarOpen} subModuleExist={subModuleExist} />;
  }
};

// Sorting - Array of Objects

export function compare(a: any, b: any) {
  if (a.placeholder.toLowerCase() < b.placeholder.toLowerCase()) {
    return -1;
  }
  if (a.placeholder.toLowerCase() > b.placeholder.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function validURL(str: string) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export const handleProjectName = (pName: string) => {
  pName = String(pName).toLocaleLowerCase();

  if (pName === "admin") {
    return "Admin";
  } else if (pName === "banji") {
    return "Banji";
  } else if (pName === "bvote") {
    return "bVote";
  } else if (pName === "byou") {
    return "bYou";
  } else if (pName === "bnft" || pName === "bNFT") {
    return "bNFT";
  } else if (pName === "bmessage") {
    return "bMessage";
  } else if (pName === "bstamp") {
    return "bStamp";
  } else if (pName === "staking") {
    return "edeXa Network";
  } else if (pName === "byou") {
    return "bYou";
  } else if (pName === "universe") {
    return "Universe";
  } else if (pName === "btrack") {
    return "bTrack";
  }
  return pName;
};

export const getUniverseStatusClass = (statusCode: any) => {
  let result: any = {
    label: "",
    class: ""
  }; // eslint-disable-next-line
  switch (statusCode) {
    case 0:
      result = {
        label: "Pending",
        class: "pending",
        color: "#eaac20"
      };
      break;
    case 1:
      result = {
        label: "Approved",
        class: "active",
        color: "#28c76f"
      };
      break;
    case 2:
      result = {
        label: "Rejected",
        class: "deactive",
        color: "#ea2027"
      };
      break;

    case 3:
      result = {
        label: "In-Process",
        class: "inprocess",
        color: "#F75C1E"
      };
      break;

    default:
      result = {
        label: "Not Started",
        class: "pending"
      };
      break;
  }
  return result;
};

export const handleStatusFilter = (data: string) => {
  if (data === "Pending") {
    return "0";
  } else if (data === "Approved") {
    return "1";
  } else if (data === "Rejected") {
    return "2";
  } else if (data === "In_Progress") {
    return "3";
  } else {
    return "";
  }
};

export const handleTrim = (eventName: string, eventValue: string, setValue: any) => {
  if (eventValue.trim() === "") {
    setValue(eventName, "");
  } else {
    setValue(eventName, eventValue);
  }
};

export const validateEmail = (email: string) => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const handleSearchPlaceHolder = (placeHolder: string) => {
  return `Search By ${placeHolder}`;
};

export const handleData = (state: string) => {
  if (String(state) === validatorStatus.PENDING) {
    return "Pending";
  } else if (String(state) === validatorStatus.EMAIL_SENT) {
    return "Email sent";
  } else if (String(state) === validatorStatus.REJECTED) {
    return "Rejected";
  } else if (String(state) === validatorStatus.SUPPORT_SYSTEM) {
    return "Support system is required";
  } else if (String(state) === validatorStatus.NODE_OBSERVER) {
    return "Node added as Observer";
  } else if (String(state) === validatorStatus.NODE_VALIDATOR) {
    return "Node added as Validator";
  } else if (String(state) === validatorStatus.IN_PROGRESS) {
    return "In-progress";
  }
};

export const handleLabel = (status: string) => {
  let label: string = "";
  switch (status) {
    case validatorStatus.EMAIL_SENT:
      label = "Are you sure you want to send an invitation link to this validator? Please ensure that you have verified their credentials.";
      break;
    case validatorStatus.REJECTED:
      label = "Are you sure you want to reject this validator request?";
      break;
    case validatorStatus.IN_PROGRESS:
      label = "Are you sure you want to add wallet address for this validator request?";
      break;
    default:
      label = "Are you sure you want to update this validator request?";
      break;
  }
  return label;
};

export const handleQueryParameterData = (state: number | string) => {
  if (state === queryParameterStatus.GENERAL) {
    return "General";
  } else if (state === queryParameterStatus.SOLUTION_PARTNERS) {
    return "Solution Partners";
  } else if (state === queryParameterStatus.TECHNOLOGY_PARTNERS) {
    return "Technology Partners";
  } else if (state === queryParameterStatus.EDUCATION_PARTNERS) {
    return "Education Partners";
  } else if (state === queryParameterStatus.ENROLL_BUSINESS) {
    return "Enroll Business";
  } else if (state === queryParameterStatus.BOUNTY_BUGS) {
    return "Bounty Bugs";
  }
};
