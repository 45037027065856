import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getUniversalList } from "../../../../actions/portal/adminActions";
import MetaFunction from "../../../../common/MetaTag";
import { handleStatusFilter } from "../../../../common/common-functions";
import { ReactSelectCustomstyle, TableStatusFilter, initialPaginationConfig, projectModules, projectNames } from "../../../../common/constants";
import { categoryModalIsOpenAction } from "../../../../store/categoryModal/action";
import { total_universe_requests_count } from "../../../../store/dashboardCount/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import { CreateRestricted } from "../../../common/restrictions";
import SearchFilter from "../../banji/component/SearchFilter";
import { UniverseTableComponent } from "../common/ListTable";

const UniverseManagement = () => {
  const dispatch = useDispatch();
  const module = "Universe Request";
  const tableHeaders = ["Name", "Email", "Environment Name", "Network Name", "Status", "Action"];

  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [userTypeFilterValue, setUserTypeFilterValue] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "All" });
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });

  const handleUserRegisterFilter = (data: any) => {
    setUserTypeFilterValue(data);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      filter: handleStatusFilter(data?.value)
    });
  };

  const getUniversalListing = () => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    if (paginationConfig.filter) {
      params.filter = paginationConfig.filter;
    }
    getUniversalList(params).then((res) => {
      if (res.status === 200) {
        dispatch(setLoading(false));
        dispatch(total_universe_requests_count(res.data.count));
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getUniversalListing();
    // eslint-disable-next-line
  }, [paginationConfig, searchField]);

  const resetFilters = () => {
    let object = { value: "", label: "All" };
    if (searchField === "" && JSON.stringify(userTypeFilterValue) === JSON.stringify(object) && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig((prev) => ({ ...prev, filter: "", search: "" }));
      setUserTypeFilterValue({ value: "", label: "All" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <Select
                    placeholder="Status"
                    onChange={(e) => handleUserRegisterFilter(e)}
                    options={TableStatusFilter}
                    className="me-2 z-index"
                    styles={ReactSelectCustomstyle}
                    value={userTypeFilterValue}
                  />
                  <SearchFilter placeHolder="Search By All Text Filed" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.nft].CATEGORY_MANAGEMENT}>
                  <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => dispatch(categoryModalIsOpenAction(true))}>
                    Add
                  </button>
                </CreateRestricted>
              </div>
              <UniverseTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type="cloud"
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default UniverseManagement;
