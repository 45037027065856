import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getListBlogsAPI } from "../../../../actions/staking/blogs/getListBlogsAPI";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE, ReactSelectCustomstyle, edeXaNetwork_page, initialPaginationConfig, projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import { CreateRestricted } from "../../../common/restrictions";
import SearchFilter from "../../bvote/component/SearchFilter";
import { StakingTableComponent } from "../common/ListTable";

const Blogs = () => {
  const module = "News";
  const tableHeaders = ["Title", "Short Description", "Author Name", "Category Name", "Status", CREATEDATE, "Actions"];
  const history = useHistory();
  const dispatch = useDispatch();
  const TableStatusFilter = [
    { value: "", label: "All" },
    { value: "true", label: "Publish" },
    { value: "false", label: "Non-Publish" }
  ];
  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);
  const [userTypeFilterValue, setUserTypeFilterValue] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "All" });

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    if (paginationConfig.filter) {
      params.filter = paginationConfig.filter;
    }
    getListBlogsAPI(params).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };
  useEffect(() => {
    getCategoryManagementListFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const handleClear = () => {
    if (searchField === "" && paginationConfig?.search === "" && paginationConfig?.filter === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "", filter: "" });
      setUserTypeFilterValue({ value: "", label: "All" });
    }
  };
  const handleRedirect = () => history.push(`/${projectNames.edexanetwork}/news/create`);
  const handleUserRegisterFilter = (data: any) => {
    setUserTypeFilterValue(data);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      filter: String(data?.value)
    });
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className={"d-flex flex-wrap justify-content-between"}>
                <div className="d-flex justify-content-between w-100 flex-wrap mb-20">
                  <div className="d-flex">
                    <Select
                      placeholder="Status"
                      onChange={(e) => handleUserRegisterFilter(e)}
                      options={TableStatusFilter}
                      className="me-2 z-index"
                      styles={ReactSelectCustomstyle}
                      value={userTypeFilterValue}
                    />
                    <SearchFilter
                      placeHolder="Search By Title/Short Description"
                      paginationConfig={paginationConfig}
                      setPaginationConfig={setPaginationConfig}
                      search={searchField}
                      setSearch={setSearchField}
                    />
                    {/**
                     * @clear_filters
                     * @button
                     */}
                    <button className="btn border-blue  ml-10 button-width-com  " onClick={handleClear}>
                      Clear
                    </button>
                  </div>
                  {/* @ts-ignore */}
                  <CreateRestricted module={projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT}>
                    <button className="btn bg-blue border-0 text-white shadow-none  ml-10 button-width-com" onClick={handleRedirect}>
                      Add
                    </button>
                  </CreateRestricted>
                </div>
                <StakingTableComponent
                  listData={listingData}
                  totalCount={totalListCount}
                  tableHeaders={tableHeaders}
                  type={edeXaNetwork_page.BLOGS}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                  recall={getCategoryManagementListFromApi}
                  getCategoryManagementListFromApi={getCategoryManagementListFromApi}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
