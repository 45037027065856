import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingDemoRequestAPI = (params?: any): Promise<IStakingAvailableAppsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.demoRequest.list, { params });
};
export interface IStakingAvailableAppsAPIData {
  createdAt: string;
  description: string;
  updatedAt: string;
  email: string;
  id: string;
  name: boolean;
  organizationName?: boolean;
  _id: string;
}

interface IStakingAvailableAppsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IStakingAvailableAppsAPIData[];
  };
}
