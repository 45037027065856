import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textEditor.css";

const TextEditor = ({ value, setValue }: any) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleChange = (e: any) => {
    setEditorState(e);
  };

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      setEditorState(EditorState.createWithContent(state));
    }
  }, [value]);

  return (
    <Editor
      editorState={editorState}
      placeholder="Enter the blog content here..."
      onEditorStateChange={(e: any) => {
        if (convertToRaw(e.getCurrentContent()).blocks[0].text === "") {
          setValue("content", "");
        } else {
          setValue("content", draftToHTML(convertToRaw(e.getCurrentContent())));
        }
        handleChange(e);
      }}
      handlePastedText={() => false}
      toolbar={{
        options: ["inline", "blockType", "fontSize"],
        inline: {
          inDropdown: false,
          className: "test",
          component: undefined,
          dropdownClassName: undefined,
          options: ["bold", "italic", "underline"],
          bold: { className: "test", style: { color: "red" } },
          italic: { className: undefined },
          underline: { className: undefined }
        }
      }}
    />
  );
};

export default TextEditor;
