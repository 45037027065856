import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { onDeleteNotification, onGetNotification, onUpdateNotification } from "../../actions/notificationActions";
import { StakingAdminInfoAPI } from "../../actions/staking/stakingAdminInfoAPI";
import { universeGetAdminInfoAPI } from "../../actions/universe/univerUserInfo";
import MetaFunction from "../../common/MetaTag";
import { getDecryptedLocalStorage, setEncryptedLocalStorage, toastSuccess } from "../../common/common-functions";
import { localstorageKeys, projectNames } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import { setCurrentProject } from "../../store/project/action";
import { onNotifcationOn } from "../../store/user/actions";
import Footer from "../common/Footer";
import "./notification.css";

const Notification = () => {
  const observer = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [totalListData, SettotalListData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    let userData = getDecryptedLocalStorage(localstorageKeys?.userInfo);
    userData.notification = 0;
    dispatch(onNotifcationOn(0));
    setEncryptedLocalStorage(localstorageKeys?.userInfo, userData);
    //eslint-disable-next-line
  }, [])

  const getNotification = () => {
    dispatch(setLoading(true));
    let params = {
      page: currentPage,
      limit: 20
    };
    onGetNotification(params)
      .then((response) => {
        if (response?.data?.status === 200) {
          SettotalListData(response?.data?.data?.count);
          setNotificationData([...notificationData, ...response?.data?.data?.data]);
          if (notificationData.length >= response?.data?.data?.count) {
            setHasMore(false);
          }
          if (response?.data?.data?.data?.length === 0) {
            setHasMore(false);
          }
          let data = {
            ids: []
          };
          data.ids = response?.data?.data?.data.filter((data) => data.status === "0").map((data) => data.id);
          data.ids.length > 0 && onUpdateNotification(data).catch((err) => dispatch(setLoading(false)));
          data.ids.length > 0 && dispatch(onNotifcationOn(0));
        } else {
          setNotificationData([]);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // delete api call
  const getDelete = (data) => {
    dispatch(setLoading(true));
    onDeleteNotification(data)
      .then((response) => {
        if (response?.data?.status === 200) {
          toastSuccess(response?.data?.message);
          if (data.clearAll) {
            setNotificationData([]);
          } else {
            let temdata = notificationData.filter((tmp) => tmp.id !== data.ids[0]);
            setNotificationData(temdata);
          }
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // All notification clear
  const handledAllDeleteNotification = (clear, id) => {
    let data = {
      clearAll: clear ? true : false,
      ...(!clear && { ids: [id] })
    };
    getDelete(data);
  };

  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const { currentProject } = useSelector((state) => state.projectNameReducer);
  const handleModule = (module) => {
    if (module === "onboarding") {
      if (currentProject !== projectNames.universe) {
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/universe-request`;
    } else if (module === "kyc") {
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.admin);
      dispatch(setLoading(false));
      dispatch(setCurrentProject(projectNames.admin));
      return `/${projectNames.admin}/kyc-management`;
    } else if (module === "validator") {
      dispatch(setCurrentProject(projectNames.edexanetwork));
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.edexanetwork);
      StakingAdminInfoAPI();
      dispatch(setLoading(false));
      return `/${projectNames.edexanetwork}/validate-request`;
    } else if (module === "requestdemo") {
      dispatch(setCurrentProject(projectNames.edexanetwork));
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.edexanetwork);
      StakingAdminInfoAPI();
      dispatch(setLoading(false));
      return `/${projectNames.edexanetwork}/app-demo-request`;
    } else if (module === "dapprequest") {
      dispatch(setCurrentProject(projectNames.edexanetwork));
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.edexanetwork);
      StakingAdminInfoAPI();
      dispatch(setLoading(false));
      return `/${projectNames.edexanetwork}/dapp-customer-request`;
    } else if (module === "contactus") {
      dispatch(setCurrentProject(projectNames.edexanetwork));
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.edexanetwork);
      StakingAdminInfoAPI();
      dispatch(setLoading(false));
      return `/${projectNames.edexanetwork}/contact-us`;
    } else if (module === "channel") {
      if (currentProject !== projectNames.universe) {
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/channel-listing`;
    } else if (module === "chaincode") {
      if (currentProject !== projectNames.universe) {
        dispatch(setLoading(false));
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        dispatch(setCurrentProject(projectNames.universe));
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/chain-code-management`;
    } else if (module === "network") {
      if (currentProject !== projectNames.universe) {
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/universe-request`;
    } else if (module === "contract") {
      if (currentProject !== projectNames.universe) {
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/contracts-listing`;
    } else if (module === "peer") {
      if (currentProject !== projectNames.universe) {
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        universeGetAdminInfoAPI().then(() => {
          dispatch(setLoading(false));
        });
      }
      return `/${projectNames.universe}/peers`;
    } else {
      dispatch(setLoading(false));
      dispatch(setCurrentProject(projectNames.admin));
      setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.admin);
      return "";
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, [currentPage])

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && totalListData > notificationData.length) {
          incrementPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationData]
  );

  return (
    <>
      <MetaFunction meta={"notification"} />
      <div className="holders">
        <p className="main-title m-b-20">Notification</p>
        <div className="rounded-10 bg-white" style={{ minHeight: "70vh" }}>
          <div className="d-flex align-items-start justify-content-between flex-wrap info-title p-20" style={{ borderBottom: "1px solid #C8C9CA" }}>
            <div>
              <p className="font-20 fw-bold">Notification edeXa Account</p>
              <p className="font-14 fw-normal color-light-grey">You will be notified with all notifications, without let you to missout any important occurence.</p>
            </div>
            <div className="d-flex align-items-center">
              {notificationData.length > 0 && (
                <div className="font-14 color-blue fw-bold cursor-pointer " onClick={() => handledAllDeleteNotification(true, null)}>
                  Clear All Notifications
                </div>
              )}
            </div>
          </div>
          <div className="scrollbar-notification">
            <Scrollbars autoHide style={{ width: "100%" }} autoHeight autoHeightMin={0} autoHeightMax={800} renderTrackVertical={(props) => <div {...props} className="trackVertical" />}>
              <hr className="m-0" />
              {notificationData.length === 0 ? (
                <>
                  <div className="security-box password-box align-notification p-20" style={{ cursor: "default" }}>
                    <div className="d-flex align-items-center info-field">
                      <p className="font-14 fw-normal color-light-grey">No more notification </p>
                    </div>
                  </div>
                </>
              ) : (
                notificationData.map((data, i) => {
                  const isLastElement = notificationData.length === i + 1;
                  return isLastElement ? (
                    <>
                      <div
                        key={i}
                        ref={lastElementRef}
                        className="security-box password-box align-notification p-20"
                        style={{
                          cursor: "default",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px"
                        }}>
                        <div
                          className="d-flex align-items-center info-field w-75 cursor-pointer"
                          onClick={() => {
                            history.push(handleModule(data?.module));
                          }}>
                          <div className="notification-info">
                            <div style={{ flexBasis: "30px" }}>
                              <p
                                className="font-12 fw-normal notification-text"
                                style={{
                                  background: data?.status === "0" ? "#007de4" : "#DADCE0"
                                }}></p>
                            </div>
                            <p className="font-14  fw-normal color-light-grey">{data?.message}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-right info-image align-items-center mr-15">
                          <p className="font-14  text-right fw-normal color-light-grey">
                            <em>{moment(data.createdAt).startOf("minute").fromNow()}</em>
                          </p>
                          <p className="font-14  text-right fw-normal color-light-grey">
                            <i className="far fa-times-circle cursor-pointer" onClick={() => handledAllDeleteNotification(false, data.id)} style={{ marginLeft: "10px" }}></i>
                          </p>
                        </div>
                      </div>
                      {i !== notificationData.length - 1 && <hr className="m-0" />}
                    </>
                  ) : (
                    <>
                      <div
                        key={i}
                        className="security-box password-box align-notification p-20"
                        style={{
                          cursor: "default",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}>
                        <div
                          className="d-flex align-items-center info-field w-75 cursor-pointer"
                          onClick={() => {
                            history.push(handleModule(data?.module));
                          }}>
                          <div className="notification-info">
                            <div style={{ flexBasis: "30px" }}>
                              <p
                                className="font-12 fw-normal notification-text"
                                style={{
                                  background: data?.status === "0" ? "#007de4" : "#DADCE0"
                                }}></p>
                            </div>
                            <p className="font-14 fw-normal color-light-grey ">{data?.message}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-right info-image align-items-center mr-15">
                          <p className="font-14  text-right fw-normal color-light-grey">
                            <em>{moment(data.createdAt).startOf("minute").fromNow()}</em>
                          </p>
                          <p className="font-14  text-right fw-normal color-light-grey">
                            <i className="far fa-times-circle cursor-pointer" onClick={() => handledAllDeleteNotification(false, data.id)} style={{ marginLeft: "10px" }}></i>
                          </p>
                        </div>
                      </div>
                      {i !== notificationData.length - 1 && <hr className="m-0" />}
                    </>
                  );
                })
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Notification;
