import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IbMessageGetGloablSettingAPIData, bMessageGetGloablSettingAPI } from "../../../../actions/bMessage/globalSetting/bMessageGetGlobalSettingAPI";
import { bMessageUpdateGlobalSettingAPI } from "../../../../actions/bMessage/globalSetting/bMessageUpdateGlobalSettingAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import ConfirmationModal from "../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";

const BMessageGlobalSetting = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<IbMessageGetGloablSettingAPIData>({
    underMaintenance: 0
  });
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values?: string) => {}
  });

  const getGlobalSettingAction = () => {
    bMessageGetGloablSettingAPI().then((res) => {
      if (res.status === 200) {
        setValues({ ...values, underMaintenance: res.data.underMaintenance });
      }
    });
  };

  useEffect(() => {
    getGlobalSettingAction();
    // eslint-disable-next-line
  }, []);

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);
    dispatch(setLoading(true));
    let paramater: any = {
      [filedName]: data ? "1" : "0"
    };
    bMessageUpdateGlobalSettingAPI(paramater)
      .then((res) => {
        if (res?.status === 200) {
          setValues({ ...values, [filedName]: data ? 1 : 0 });
          toastSuccess(res?.message);
          dispatch(resetVisible());
          dispatch(setLoading(false));
        }
      })
      .catch((err) => dispatch(resetVisible()));
  };

  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.underMaintenance === 1 ? true : false}
              onChange={(e: any) => handleSwitch("underMaintenance", e, "Under Maintenance")}
              label="Under Maintenance"
              tooltip="Whole bMessage cannot be accessed by customer by enabling the under maintenace"
              disabled={handleAppActionPermission(projectNames.bmessage, projectModules[projectNames.bmessage]?.GLOBAL_SETTINGS, "update")}
            />
          </div>
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default BMessageGlobalSetting;
