// @ts-nocheck
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import edexaLogo from "../../../src/assets/images/svg/edexa.svg";
import { forgotPassword } from "../../actions/auth";
import { toastSuccess } from "../../common/common-functions";
import { validationMessages } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import "./login.css";

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(validationMessages.email.invalid).required(validationMessages.email.required)
    }),
    onSubmit: (value) => {
      dispatch(setLoading(true));
      forgotPassword(value?.email).then((res) => {
        if (res.status === 200) {
          history.push("/login");
          toastSuccess(res.message);
          dispatch(setLoading(false));
        }
      });
    }
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = formik;
  return (
    <div className="App bannerImage">
      <div className="loginSection">
        <div className="logoAndContent">
          <img className="edexaLogo" src={edexaLogo} alt="logo" />
          <div className="content">
            <h2>What is edeXa central Admin Panel? and who uses this panel?</h2>
            <p className="mainContainer__contentUnderHeading">
              edeXa central admin lets user to manage <b>all products</b> of the edeXa and its <b>users</b>. Only permitted users can be able to login. Please login to get into the admin panel.
            </p>
          </div>
          <div
            className="small-formSection rounded-10 m-auto "
            style={{
              minHeight: `${touched?.email ? (errors?.email || errors?.email ? "390px" : "") : ""} `
            }}>
            <div className="auth-wrapper">
              <div className="formSection__innerDiv" style={{ padding: "0px 40px" }}>
                <h2>Forgot Password</h2>
                <p className="mainContainer__registerFormPara"> Please add your email ID</p>
                <div className="inputFIeldsDiv">
                  <div className={`mainContainer__emailInputFieldDiv ${touched.email && errors.email ? "error-border" : null}`} style={{ position: "relative" }}>
                    {/* @ts-ignore */}
                    <InputGroup>
                      {/* @ts-ignore */}
                      <Input
                        type="email"
                        placeholder="Email"
                        className="font-14 fw-normal color-black2 bg-white icon-right custom-input mb-0"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          e.key.toLowerCase() === "enter" && handleSubmit();
                        }}
                        invalid={Boolean(touched.email && errors.email)}
                      />
                      {/* @ts-ignore */}
                      <InputGroupAddon addonType="append">
                        {/* @ts-ignore */}
                        <InputGroupText
                          className="h-100 bg-transparent d-flex justify-content-center"
                          style={{
                            borderColor: touched.email && errors.email ? "#ff0000" : ""
                          }}>
                          <i className="fas fa-user icon-sm color-light-grey"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      {/* @ts-ignore */}
                      {touched.email && errors.email && <FormFeedback className="text-left">{errors.email}</FormFeedback>}
                    </InputGroup>
                  </div>
                </div>
                <button className={`mt-3 ${errors?.email ? "mainContainer__submit__if__errorOccuered" : "mainContainer__submit"}`} onClick={() => handleSubmit()}>
                  Forgot Password
                </button>
                <p className="forgot-password text-center" style={{ color: "#073D83", cursor: "pointer" }} onClick={() => history.push("/login")}>
                  Back to Login
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
