import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getGlobalSettingsApi, updateGlobalSettingApi } from "../../../actions/portal/byouActions";
import MetaFunction from "../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../common/common-functions";
import { projectModules, projectNames } from "../../../common/constants";
import { resetVisible, setVisible } from "../../../store/confirmationModule/action";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import ConfirmationModal from "../DeleteConfirmationModal";
import SwitchGlobalSetting from "../SwitchGlobalSetting";

const GlobalSettingsOfByou = () => {
  const dispatch = useDispatch();
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => { }
  });

  const groupFormik = useFormik({
    initialValues: {
      isUnderMaintenance: false
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let parameter: any = {};
      parameter.isUnderMaintenance = values?.isUnderMaintenance === true ? 1 : 0;
      updateGlobalSettingApi(parameter).then((res) => {
        if (res.status === 200) {
          dispatch(setLoading(false));
          toastSuccess(res.message);
        }
      });
    }
  });

  const { values, setFieldValue } = groupFormik;

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);
    dispatch(setLoading(true));
    let paramater = {
      [filedName]: data ? 1 : 0
    };
    updateGlobalSettingApi(paramater)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue(filedName, data);
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getGlobalSettingsApi().then((response) => {
      setFieldValue("isUnderMaintenance", response?.data?.isUnderMaintenance === 1 ? true : false);
      dispatch(setLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.isUnderMaintenance}
              onChange={(e: boolean) => {
                handleSwitch("isUnderMaintenance", e, "Under Maintenance");
              }}
              label="Under Maintenance"
              disabled={handleAppActionPermission(projectNames.byou, projectModules[projectNames.byou]?.GLOBAL_SETTINGS, "update")}
              tooltip={`Enabling Under Maintenance will temporarily make the bYou application unavailable. ${"\n"} This affects access to the Issuer Portal on the web and the Holder and Verifier apps on Android and iOS.`}
            />
          </div>
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default GlobalSettingsOfByou;
