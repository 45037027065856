import { Modal, ModalBody, Spinner } from "reactstrap";

const UniverseRequestStatusConfirmationPopup = ({
  isVisible,
  loader,
  status,
  handleConfirmation,
  currentSelectedStatus,
  toggleStatusModel,
  requestId
}: {
  isVisible: boolean;
  loader: boolean;
  status: string;
  currentSelectedStatus?: number;
  handleConfirmation: Function;
  toggleStatusModel: Function;
  requestId?: string;
}) => {
  return (
    <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
      <ModalBody>
        <div style={{ padding: "30px" }}>
          <h4 className="fw-bold text-center">Are you sure to change the status to {status} ?</h4>
          {/* <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
            {label}
          </p> */}
        </div>
        <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
          <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={() => toggleStatusModel()}>
            <button className="confimatio-button text-danger">No</button>
          </div>
          <div className="col-6 p-10 d-flex cursor-pointer justify-content-center" onClick={() => handleConfirmation(requestId, currentSelectedStatus)}>
            <span className={`confimatio-button text-success d-flex align-items-center ${loader && "disable"} `}>
              Yes
              {loader && (
                <Spinner className="ml-20" color="primary" size={"sm"}>
                  Loading...
                </Spinner>
              )}
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UniverseRequestStatusConfirmationPopup;
