import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingValidatorsListAPI = (params: any): Promise<IStakingValidatorsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.validators.list, { params });
};

export interface IStakingValidatorsAPIData {
  companyName: string;
  createdAt: string;
  email: string;
  name: string;
  updatedAt: string;
  _id: string;
}

interface IStakingValidatorsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IStakingValidatorsAPIData[];
  };
}
