import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Row, Tooltip } from "reactstrap";
import { getUniversalDetail, updateUniversalListStatus, updateUniversalListStatusForApprovedRequest } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, STATUS_VALUE, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import ColumnComponent from "../../../common/ColumanComponent";
import StatusComponent from "../../../common/StatusComponent";
import { UniversalRejectionPopup } from "../../component/UniversalRejectionPopup";
import "../../component/kycDetails.css";
import RadioButtonComponent from "../common/RadioButtonComponent";
import UniverseRequestStatusConfirmationPopupListDetails from "./UniverseRequestStatusConfirmationPopupListDetails";

export const UniversalDetailsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);
  const redioButton = [
    { name: "status", label: "Accepted", value: STATUS_VALUE.ACCEPTED },
    { name: "status", label: "Rejected", value: STATUS_VALUE.REJECTED },
    { name: "status", label: "In-Progress", value: STATUS_VALUE.IN_PROGRESS }
  ];

  const [status, setStatus] = useState(0);
  const [rejectReason, setRejectReason] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState();
  const [universalStatusToggle, setUniversalStatusToggle] = useState(false);
  const [universalDetails, setUniversalDetails] = useState<any>({
    algorithmType: "",
    createdAt: "",
    id: "",
    envName: "",
    networkDescription: "",
    networkName: "",
    provider: "",
    region: "",
    status: "",
    statusReason: "",
    userInfo: {}
  });

  const toggleStatusModel = () => {
    setStatus(universalDetails.status);
    setStatusModal(!statusModal);
  };

  const handleStatusChange = (statusValue: any) => {
    if (statusValue === STATUS_VALUE.REJECTED) {
      // open the reason for rejection popup
      setUniversalStatusToggle(true);
    } else {
      setCurrentSelectedStatus(statusValue);
      toggleStatusModel();
    }
  };

  const handleUniversalListStatusChangeForRejectedRequest = (id: any, status: any, reason: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateUniversalListStatus({ id, status, ...(reason && { reason }) }).then((res) => {
      if (res.status === 200) {
        setStatusModalLoader(false);
        setUpdateDetails(!updateDetails);
        history.push(`/${projectNames?.universe}/universe-request`);
        toastSuccess(res.message);
        dispatch(setLoading(false));
      }
    });
  };

  const handleUniversalListStatusChangeForApprovedRequest = (id: any, status: any, values: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateUniversalListStatusForApprovedRequest({ id, status, ...(values && { values }) }).then((res) => {
      if (res.status === 200) {
        setStatusModalLoader(false);
        setUpdateDetails(!updateDetails);
        history.push(`/${projectNames?.universe}/universe-request`);
        toastSuccess(res.message);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getUniversalDetail(params.id)
      .then((response) => {
        if (response.status === 200) {
          setUniversalDetails(response?.data);
          setStatus(response?.data?.status);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        history.push(`/${projectNames.universe}/universe-request`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateDetails]);

  return (
    <>
      <MetaFunction meta="Universal List Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Universal List Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(universalDetails).length > 0 && (
          <Row className="mt-10">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">Universal Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    {universalDetails?.statusReason && <ColumnComponent title="Status Reason" value={universalDetails?.statusReason} capitalize={true} />}
                    <StatusComponent status={universalDetails?.status} />
                    {universalDetails?.status === STATUS_VALUE.IN_PROGRESS || universalDetails?.status === STATUS_VALUE.PANDING ? (
                      <RadioButtonComponent handleStatusChange={handleStatusChange} setStatus={setStatus} redioButtonArray={redioButton} status={status} />
                    ) : null}
                    <ColumnComponent title="Name" value={universalDetails?.userInfo?.first_name ? `${universalDetails?.userInfo?.first_name} ${universalDetails?.userInfo?.last_name}` : "-"} />
                    <ColumnComponent title="Email" value={`${universalDetails?.userInfo?.email}`} />
                    <ColumnComponent title="Algorithm Type" value={universalDetails?.algorithmType} />
                    <ColumnComponent title={CREATEDATE} value={universalDetails?.createdAt ? moment(universalDetails?.createdAt).format("DD/MM/YYYY | hh:mm A") : ""} />
                    <ColumnComponent title="Name" value={universalDetails?.envName} />
                    <ColumnComponent title="Network Description" value={universalDetails?.networkDescription} capitalize={true} />
                    <ColumnComponent capitalize={true} title="Network Name" value={universalDetails?.networkName} />
                    <ColumnComponent title="Provider" value={universalDetails?.provider} capitalize={true} />
                    <ColumnComponent title="Region" value={universalDetails?.networkRegionType?.name} capitalize={true} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <UniversalRejectionPopup
          isOpen={universalStatusToggle}
          setUniversalStatusToggle={setUniversalStatusToggle}
          isLoading={isLoadingState}
          reason={rejectReason}
          setStatus={setStatus}
          preState={universalDetails?.status}
          setReason={setRejectReason}
          id={universalDetails.id}
          handleUniversalListStatusChange={handleUniversalListStatusChangeForRejectedRequest}
        />
        <UniverseRequestStatusConfirmationPopupListDetails
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleUniversalListStatusChangeForApprovedRequest}
          currentSelectedStatus={currentSelectedStatus}
          requestId={universalDetails.id.toString()}
          toggleStatusModel={toggleStatusModel}
          status={currentSelectedStatus === STATUS_VALUE.ACCEPTED ? "Accepted" : "In-progress"}
          networkData={universalDetails}
        />
      </div>
    </>
  );
};
