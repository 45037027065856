import { TOGGLESIDEBAR } from "./type";

const initialState = {
  isSidebarOpen: true
};

export const sideBarToggleReducer = (state = initialState, action: any) => {
  const newState = { ...state };
  // eslint-disable-next-line
  switch (action.type) {
    case TOGGLESIDEBAR:
      newState.isSidebarOpen = action.value;
  }
  return newState;
};
