import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingMarketPlaceUpdateAPI = (id: string, data?: any): Promise<IStakingMarketPlaceUpdateAPI> => {
  return HTTPService.put(`${StakingPortalApiEndPoint.marketPlace.update}/${id}`, data);
};

interface IStakingMarketPlaceUpdateAPI {
  message: string;
  status: number;
}
