import { useState } from "react";

const ImageComponent = ({ app }: any) => {
  const [error, setError] = useState(false);

  return (
    <>
      {app.appPermission.logoIcon ? (
        <img className={`app-icons ${error ? "display-none" : ""}`} src={app.appPermission.logoIcon} alt={app.appId} onError={() => setError(true)} />
      ) : (
        <span className="app-custom">{app.appPermission.name}</span>
      )}
      {error ? <span>{app.appPermission.name}</span> : null}
    </>
  );
};

export default ImageComponent;
