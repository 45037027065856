import { getDecryptedLocalStorage } from "../../common/common-functions";
import { localstorageKeys, projectNames } from "../../common/constants";
import { CURRENTPROJECT } from "./type";

/**
 * It's implemented
 * @todo - check which project permission user have onlogin
 *  - and as per that project render only those routes and redirect user to those routes directly
 *  - instead of projectNames.admin it will get project name from localstorage and localstorage will be set on user login by checking the permission array
 */
const initialState = {
  // if user is not logged in then local storage wil return undefine and the login api will not work
  // because the url will be something edexa.com/undefinedadmin/login but it should be
  currentProject: getDecryptedLocalStorage(localstorageKeys.projectName) || projectNames.admin
  // currentProject: projectNames.admin,
};

const projectNameReducer = (state = initialState, action: { type: string; payload: string }) => {
  const newState = { ...state };
  switch (action.type) {
    case CURRENTPROJECT:
      newState.currentProject = action.payload;
      break;

    default:
  }
  return newState;
};

export { projectNameReducer };
