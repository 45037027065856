import { projectModules, projectNames } from "../../common/constants";
import BMessageDashboard from "../../pages/main/bMessage/BmessageDashboard";
import BMessageGlobalSetting from "../../pages/main/bMessage/bMessageGlobalSetting/BmessageGlobalSetting";
import BMessageLabelManagament from "../../pages/main/bMessage/bMessageLabelManagement/BmessageLabelManagament";
import PricePlanDetail from "../../pages/main/bMessage/common/PricePlanDetail";
import PriceAndPlan from "../../pages/main/bMessage/component/priceAndPlan/PriceAndPlan";
import PriceAndPlanForm from "../../pages/main/bMessage/view/PriceAndPlanForm";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import Notification from "../../pages/main/Notification";

const bMessagePrivateRoutes = [
  {
    path: `/${projectNames.bmessage}/dashboard`,
    component: BMessageDashboard,
    module: projectModules[projectNames.bmessage].DASHBOARD
  },
  {
    path: `/${projectNames.bmessage}/price-plan`,
    component: PriceAndPlan,
    module: projectModules[projectNames.bmessage].PRICE_AND_PLAN
  },
  {
    path: `/${projectNames.bmessage}/price-plan/:id`,
    component: PricePlanDetail,
    module: projectModules[projectNames.bmessage].PRICE_AND_PLAN
  },
  {
    path: `/${projectNames.bmessage}/price-plan-edit/:id`,
    component: PriceAndPlanForm,
    module: projectModules[projectNames.bmessage].PRICE_AND_PLAN
  },
  {
    path: `/${projectNames.bmessage}/label-management`,
    component: BMessageLabelManagament,
    module: projectModules[projectNames.bmessage].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.bmessage}/global-settings`,
    component: BMessageGlobalSetting,
    module: projectModules[projectNames.bmessage].GLOBAL_SETTINGS
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];

export default bMessagePrivateRoutes;
