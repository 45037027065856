import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingDeleteMarketPlaceAPI = (id?: any): Promise<IStakingAddMarketPlaceAPI> => {
  return HTTPService.delete(StakingPortalApiEndPoint.marketPlace.delete + id);
};

interface IStakingAddMarketPlaceAPI {
  message: string;
  status: number;
}
