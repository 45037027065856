import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { Input, Modal, ModalBody } from "reactstrap";
import { onRoleActiveInactive } from "../../actions/portal/rolesPermission/rolesPermissionActions";
import { toastSuccess } from "../../common/common-functions";
import { projectModules, projectNames } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import { EditRestricted } from "./restrictions";

const RollStausComponent = ({ isActive, id }) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setChecked(isActive === "1" ? true : false);
  }, [isActive]);

  const handleInputChange = (e) => {
    dispatch(setLoading(true));
    let data = {
      id: id,
      status: checked ? "0" : "1",
      updateUsers: `${checked ? true : checkbox}`
    };
    onRoleActiveInactive(data).then((res) => {
      if (res.status === 200) {
        toastSuccess(res?.message);
        setChecked(!checked);
        setVisible(false);
        setCheckbox(false);
      }
      dispatch(setLoading(false));
    });
  };

  return (
    <>
      <div className="d-flex align-baseline">
        <EditRestricted module={projectModules[projectNames.admin].ROLE_MANAGEMENT}>
          <Switch height={18} width={34} checked={checked} id="rollStatus" onColor="#007de4" offColor="#b1abab" onChange={() => setVisible(true)} uncheckedIcon checkedIcon className="mr-10" />
        </EditRestricted>
        <label htmlFor="rollStatus" className="cursor-pointer">
          {checked ? "Active" : "Inactive"}
        </label>
      </div>
      <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center">Confirmation</h4>
            <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
              Are your sure to {checked ? "Inactive" : "Active"} the Role?
            </p>
            <div className="text-center mt-10 d-flex justify-content-center">
              {checked ? (
                <p className="font-18" style={{ color: "rgb(134, 134, 134)", fontSize: "14px" }}>
                  Note : To whom this role is assigned, those users will not be able to login
                </p>
              ) : (
                <Input id="updateUser" style={{ fontSize: "13px" }} type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
              )}
              {!checked && (
                <label htmlFor="updateUser" className="ml-10" style={{ color: "rgb(134, 134, 134)", fontSize: "14px" }}>
                  Let all users to login again
                </label>
              )}
            </div>
          </div>
          <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
            <div
              className="col-6 p-10 d-flex cursor-pointer"
              style={{ borderRight: "1px solid #dadce0" }}
              onClick={() => {
                setVisible(false);
                setCheckbox(false);
              }}>
              <button className="confimatio-button text-danger">No</button>
            </div>
            <div className="col-6 p-10 d-flex cursor-pointer" onClick={handleInputChange}>
              <button className="confimatio-button text-success">Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RollStausComponent;
