import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingMarketPlaceAPI = (params?: any): Promise<IgetMarketPlaceListAPIinfo> => {
  return HTTPService.get(StakingPortalApiEndPoint.marketPlace.list, { params });
};

interface IgetMarketPlaceListAPIinfoData {
  appId: string;
  appUrl: string;
  availableOn: string[];
  createdAt: string;
  description: string;
  desktopUrl: string;
  documentUrl: string;
  features: string[];
  iosUrl: string;
  isAvailable: number;
  logoIcon: string;
  logoIconBig: string;
  name: string;
  order: number;
  postmanScript: string;
  ratings: number;
  redirectUrl: string;
  shortDescription: string;
  subtitle: string;
  updatedAt: string;
  _id: string;
}

interface IgetMarketPlaceListAPIinfo {
  message: string;
  status: number;
  data: {
    count: number;
    data: IgetMarketPlaceListAPIinfoData[];
  };
}
