import axios from 'axios'
import store from '../store/index'
import { setLoading } from '../store/loader/action'
import { projectNameReducer } from '../store/project/reducer'
import {
  adminInfoAPI,
  bMessageURL,
  bStampURL,
  bvoteURL,
  byouURL
} from './api-endpoints'
import {
  getDecryptedLocalStorage,
  handleLogout,
  toastError
} from './common-functions'
import { localstorageKeys, projectNames } from './constants'

const axiosInstance = axios.create()

const errorInterceptor = (errorResponse) => {
  store.dispatch(setLoading(false))
  const data = errorResponse?.data
  if (data) {
    const { message, status } = data
    if (status === 401) {
      handleLogout()
    } else if (status === 406) {
      const currentProject = store.getState(projectNameReducer)
        ?.projectNameReducer.currentProject
      switch (currentProject) {
        case projectNames.banji:
          HTTPService.adminBanjiPost(adminInfoAPI)
            .then((res) => {})
            .catch((err) => {
              // if admin info api gives error that means we have to logout user;
              handleLogout()
            })
          break
        case projectNames.bvote:
          HTTPService.adminBVotePost(bvoteURL.adminInfo)
            .then((res) => {})
            .catch((err) => {
              // if admin info api gives error that means we have to logout user;
              handleLogout()
            })
          break

        case projectNames.bstamp:
          HTTPService.adminbStampPost(bStampURL.adminInfo)
            .then((res) => {})
            .catch((err) => {
              // if admin info api gives error that means we have to logout user;
              handleLogout()
            })
          break

        case projectNames.byou:
          HTTPService.adminByouPost(byouURL.adminInfo)
            .then((res) => {})
            .catch((err) => {
              // if admin info api gives error that means we have to logout user;
              handleLogout()
            })
          break

        case projectNames.bmessage:
          HTTPService.adminbMessagePost(bMessageURL.adminInfo)
            .then((res) => {})
            .catch((err) => {
              // if admin info api gives error that means we have to logout user;
              handleLogout()
            })
          break

        default:
      }
    } else if (status === 423) {
      /* NOTE: commenting this because if admin does not have any permission then will show message */
      // handleLogout();
    }
    if (
      !String(errorResponse.request.responseURL).includes('/login')
    ) {
      message && toastError(message)
    }
  }
}
const getCurrentAPIURL = (storeValues) => {
  const currentProject = getDecryptedLocalStorage(
    localstorageKeys.projectName
  )
  if (currentProject === projectNames.admin) {
    return process.env.REACT_APP_API_DOMAIN_admin
  } else if (currentProject === projectNames.banji) {
    return process.env.REACT_APP_API_DOMAIN_Banji
  } else if (currentProject === projectNames.bvote) {
    return process.env.REACT_APP_API_DOMAIN_bVote
  } else if (currentProject === projectNames.bmessage) {
    return process.env.REACT_APP_API_DOMAIN_bMessage
  } else if (currentProject === projectNames.nft) {
    return process.env.REACT_APP_API_DOMAIN_NFT
  } else if (currentProject === projectNames.bstamp) {
    return process.env.REACT_APP_API_DOMAIN_bStamp
  } else if (currentProject === projectNames.byou) {
    return process.env.REACT_APP_API_DOMAIN_bYou
  } else if (currentProject === projectNames.universe) {
    return process.env.REACT_APP_API_DOMAIN_universe
  } else if (currentProject === projectNames.edexanetwork) {
    return process.env.REACT_APP_API_DOMAIN_staking
  } else if (currentProject === projectNames.btrack) {
    return process.env.REACT_APP_API_DOMAIN_bTrack
  } else {
    return process.env.REACT_APP_API_DOMAIN_admin
  }
}

axiosInstance.interceptors.request.use(
  (req) => {
    const userToken = getDecryptedLocalStorage(
      localstorageKeys.userToken
    )
    if (userToken) {
      req.headers.Authorization = `Bearer ${userToken}`
    }
    return req
  },
  (err) => {
    return Promise.reject(err)
  }
)

axiosInstance.interceptors.response.use(
  (req) => {
    return req
  },
  (err) => {
    errorInterceptor(err.response)
    return Promise.reject(err)
  }
)

export default class HTTPService {
  static get(url, params) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static put(url, body) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static patch(url, body) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static post(url, body) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static postQR(url, body, config) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${currentAPIURL}${url}`, body, config)
        .then((response) => resolve(response))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminBanjiPost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_Banji
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminBVotePost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_bVote
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminByouPost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_bYou
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminbStampPost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_bStamp
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminbMessagePost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_bMessage
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static adminNftPost(url, body) {
    const currentAPIURL = process.env.REACT_APP_API_DOMAIN_NFT
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static delete(url, body, params) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`${currentAPIURL}${url}`, params, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }
  static deleteCategory(url, body) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`${currentAPIURL}${url}`, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }

  static postAccount(url, body) {
    const currentAPIURL = getCurrentAPIURL(
      store.getState(projectNameReducer)
    )
    axiosInstance.defaults.baseURL = process.env.REACT_APP_ACCOUNT_API
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${currentAPIURL}${url}`, body)
        .then((response) => {
          axiosInstance.defaults.baseURL =
            process.env.REACT_APP_APIBASEURL
          resolve(response.data)
        })
        .catch((error) => reject(error.response || error))
    })
  }

  // this are all notification api
  static postNotification(url, body) {
    const currentAPIURL = process.env.REACT_APP_APIURL
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }
  static putNotification(url, body) {
    const currentAPIURL = process.env.REACT_APP_APIURL
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`${currentAPIURL}${url}`, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }
  static getNotification(url, params) {
    const currentAPIURL = process.env.REACT_APP_APIURL
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${currentAPIURL}${url}`, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error))
    })
  }
}
