import { byouURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const getbyouUserList = (params?: any) => {
  return HTTPService.get(byouURL.userListInfo, { params });
};

export const getlabelListing = (params?: any) => {
  return HTTPService.get(byouURL.getLabel, { params });
};

export const updatebYouLabel = (body: any) => {
  return HTTPService.put(byouURL?.updateLabel, body);
};

export const getDetailOfUser = (params?: any) => {
  return HTTPService.get(`${byouURL.getDetailInfo}?id=${params}`);
};

export const updateUser = (params?: any) => {
  return HTTPService.put(byouURL?.updateUserInfo, params);
};

export const getGlobalSettingsApi = () => {
  return HTTPService.get(byouURL.getGlobalSetting);
};

export const updateGlobalSettingApi = (params?: any) => {
  return HTTPService.put(byouURL?.updateGlobalSetting, params);
};

export const createNewIssuer = (params: string) => {
  return HTTPService.post(byouURL?.createIssuer, params);
};
