/* eslint-disable @typescript-eslint/no-unused-vars */
import "@pathofdev/react-tag-input/build/index.css";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { createCategoryAPI } from "../../../../actions/staking/category/createCategoryAPI";
import { IgetCategoryAPIData, getCategoryAPI } from "../../../../actions/staking/category/getCategoryAPI";
import { updateCategoryAPI } from "../../../../actions/staking/category/updateCategoryAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleTrim, toastSuccess } from "../../../../common/common-functions";
import { BlogsAction, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";
import PageHeader from "../../bvote/common/PageHeader";

const CategoryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const module: BlogsAction = location?.pathname.includes(BlogsAction.CREATE) ? BlogsAction.CREATE : BlogsAction.UPDATE;
  const moduleName = location?.pathname.includes(BlogsAction.CREATE) ? "Category Create" : "Category Update";
  const [data, setData] = useState<IgetCategoryAPIData>();
  const [disable, setDisable] = useState(location?.pathname.includes(BlogsAction.CREATE) ? false : true);
  const [updateData, setUpdateDate] = useState({
    name: "",
    visible: false
  });
  const CreateUpdatePermission = useSelector((state: IRootReducerTypes) => state.permissionsReducer?.permissions?.[projectNames.edexanetwork].blogmanagement);

  const formikData = useFormik({
    initialValues: {
      name: "",
      visible: false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Category name is required").min(3, "Please enter minimum 3 characters")
    }),
    onSubmit: (values) => {
      if (module === BlogsAction.CREATE) {
        dispatch(setLoading(true));
        let data = {
          name: values.name.trim(),
          visible: values.visible
        };
        createCategoryAPI(data).then((res) => {
          if (res.status === 200) {
            dispatch(setLoading(false));
            toastSuccess(res.message);
            history.push(`/${projectNames.edexanetwork}/category`);
          }
        });
      } else {
        if (params?.id) {
          dispatch(setLoading(true));
          let data = {
            name: values.name.trim(),
            visible: values.visible
          };
          updateCategoryAPI(params?.id, data).then((res) => {
            if (res.status === 200) {
              dispatch(setLoading(false));
              history.push(`/${projectNames.edexanetwork}/category`);
              toastSuccess(res.message);
            }
          });
        }
      }
    }
  });
  const { values, errors, setFieldValue, handleSubmit, handleBlur, touched } = formikData;

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoading(true));
      getCategoryAPI(params?.id)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            Object.keys(values).forEach((key: any, value: any) => {
              // @ts-ignore
              setFieldValue(key, res.data[key]);
              setUpdateDate((preState) => {
                // @ts-ignore
                return { ...preState, [key]: res.data[key] };
              });
            });
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          history.push(`/${projectNames.edexanetwork}/category`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (!location?.pathname.includes(BlogsAction.CREATE)) {
      if (JSON.stringify(updateData) === JSON.stringify(values)) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [values, updateData, location?.pathname]);

  return (
    <div className="holders">
      <PageHeader module={moduleName} />
      <MetaFunction meta={moduleName} />
      <div className="rounded-10 bg-white p-20">
        <Row>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label for="name" className="fw-bold">
                  Category Name <span className="text-red">*</span>
                </Label>
                <Input
                  placeholder="Enter category name..."
                  className="mt-1"
                  id="name"
                  name="name"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                  onBlur={handleBlur}
                  invalid={Boolean(touched?.name ? errors?.name : false)}
                />
                {touched?.name ? errors?.name ? <p className="text-danger">{errors?.name}</p> : null : null}
              </Col>
              <Col md={12}>
                <SwitchGlobalSetting
                  className="mb-0 fw-bold"
                  checked={values.visible}
                  onChange={(e: boolean) => setFieldValue("visible", e)}
                  label="Visible"
                  tooltip={!values?.visible ? "Please enable to view the category in the edexa.network" : "Please disable to remove the category from the edexa.network"}
                />
              </Col>
              <div className="d-flex mt-20 mb-2">
                {CreateUpdatePermission?.create || CreateUpdatePermission?.update ? (
                  <>
                    <button type="submit" disabled={disable} onClick={() => handleSubmit()} className="btn-sm fw-bold btn btn-primary bg-blue border-0 shadow-none button-width-com">
                      {location?.pathname?.includes("create") ? "Submit" : "Update"}{" "}
                    </button>
                  </>
                ) : null}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CategoryForm;
