import { useState } from "react";
import { toastError } from "../../../../common/common-functions";
import { validationMessages } from "../../../../common/constants";

const ColumnComponent = ({ setProfileImage, profileImage, photo, linkOpen }) => {
  const [profile, setProfile] = useState(photo);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      <hr className="m-0" />
      <div className="d-flex align-items-center info-field flex-wrap p-20">
        <div className="info-name">
          <p className="font-12 color-black2 fw-normal">PHOTO</p>
        </div>
        <div className="d-flex align-items-center info-image">
          <div className="photo d-flex align-items-center justify-content-center">
            {profile ? <img className="h-100 w-100 object-fit-cover" src={profile} alt="profile" /> : <i className="fas fa-user font-30 color-white"></i>}
          </div>
          <input
            style={{ display: "none" }}
            type="file"
            id="user_profile"
            accept="image/*"
            onChange={(e) => {
              if (!e.target.files) return null;
              const file = e.target.files[0];
              setProfileImage(file);
              if (!file?.type.includes("image")) return toastError(validationMessages.invalidFile);
              getBase64(file).then((data) => setProfile(data));
            }}
          />
          {linkOpen ? (
            <span to="" className="font-14 fw-bold color-blue" style={{ cursor: "pointer" }} onClick={() => document.getElementById("user_profile").click()}>
              Change photo
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ColumnComponent;
