import React, { FC } from "react";
import Switch from "react-switch";
import { Label } from "reactstrap";
import CustomTooltip from "../common/ToolTip";

const SwitchGlobalSetting: FC<ISwitchGlobalSetting> = ({ checked, onChange, disabled, label, tooltip, direaction, className, handleBlur }) => {
  return (
    <div className={`col-md-3 d-flex align-items-center ${className ? className : ""}`} style={{ margin: "20px 0px" }}>
      <Switch
        onBlurCapture={handleBlur}
        height={18}
        width={32}
        onColor="#007de4"
        id={label}
        disabled={disabled}
        offColor="#b1abab"
        checked={checked}
        onChange={onChange}
        uncheckedIcon
        checkedIcon
        className="mr-10"
      />
      <Label className="color-light-grey color-black1 cursor-pointer" htmlFor={label}>
        {label}
        {tooltip ? (
          <CustomTooltip direction={direaction ? direaction : "right"} content={<p className="p-2">{tooltip}</p>}>
            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px" }}></i>
          </CustomTooltip>
        ) : null}
      </Label>
    </div>
  );
};

export default React.memo(SwitchGlobalSetting);

interface ISwitchGlobalSetting {
  checked: boolean;
  onChange: (e: boolean) => void;
  disabled?: boolean;
  label: string;
  tooltip?: string;
  direaction?: string;
  className?: string;
  handleBlur?: any;
}
