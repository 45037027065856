import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { onSuspendAPI } from "../../../../actions/portal/customerAction/customerAction";
import { toastSuccess } from "../../../../common/common-functions";
import { setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";

const SuspendComponent = ({ checked, disabled, user_id, setDeleteActionFunction }: any) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(false);

  useEffect(() => {
    setStatus(checked === 1 ? true : false);
  }, [checked]);

  const userDelete = (data: any) => {
    dispatch(setLoading(true));
    let body = {
      userId: data?.userId,
      status: data?.status ? 1 : 0,
      reason: data?.reason,
      notify: data?.notify
    };
    onSuspendAPI(body).then((res: any) => {
      if (res?.status === 200) {
        toastSuccess(res?.message);
        setStatus(!status);
        dispatch(
          setVisible({
            isVisible: false,
            deleteId: "",
            label: "",
            deleteStatus: ""
          })
        );
      }
      dispatch(setLoading(false));
    });
  };

  const handleChange = () => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: { userId: user_id, status: !status },
        label: `Are you sure you want to ${status ? "Suspend" : "Active"} user?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: userDelete });
  };

  return (
    <>
      <Switch height={18} width={34} onColor="#007de4" disabled={disabled} offColor="#b1abab" checked={status} onChange={handleChange} uncheckedIcon checkedIcon className="mr-10" />
      {status ? "Active" : "Suspend"}
    </>
  );
};

export default SuspendComponent;
