import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getNFTManagementAPI = (params: IgetNFTManagementAPI): Promise<IgetNFTManagementRES> => {
  return HTTPService.get(nftURL.getNftList, { params });
};

export interface IgetNFTManagementAPI {
  id?: string;
  page: number;
  limit: number;
  status: boolean;
}

export interface IgetNFTManagementRESData {
  _id: string;
  title: string;
  isBanned: boolean;
  createdAt: string;
  owner: {
    _id: string;
    username: string;
    email: string;
  };
}

export interface IgetNFTManagementRES {
  status: number;
  message: string;
  count: number;
  data: IgetNFTManagementRESData[];
}
