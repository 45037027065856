import { adminInfoAPI, forPasswordAPI, loginAPI, userInfoAPI } from "../common/api-endpoints";
import HTTPService from "../common/httpservice";

export const getUserInfo = (token: string) => {
  const headers = {
    Authorization: "Bearer " + token
  };
  return HTTPService.get(userInfoAPI, { headers });
};

// create a bridge for the current project
export const getBanjiAdminInfo = () => {
  return HTTPService.adminBanjiPost(adminInfoAPI);
};

export const userLogin = (params: any): Promise<IuserLogin> => {
  return HTTPService.post(loginAPI, params);
};

interface IuserLoginDataUser {
  adminRole: {
    roleLevel: number;
    roleName: string;
    isActive: string;
    isDeleted: string;
  };
  createdAt: string;
  createdBy: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  notification: number;
  permission: any;
  profilePic: string;
  roleId: string;
  updatedAt: string;
  userId: string;
}

interface IuserLoginData {
  token: string;
  user: IuserLoginDataUser;
}

interface IuserLogin {
  message: string;
  status: number;
  data: IuserLoginData;
}

export const forgotPassword = (email: string): Promise<IforgotPassword> => {
  return HTTPService.post(forPasswordAPI, { email });
};

interface IforgotPassword {
  message: string;
  status: number;
}
