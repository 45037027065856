// @ts-nocheck
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import edexaLogo from "../../../src/assets/images/svg/edexa.svg";
import MetaFunction from "../../../src/common/MetaTag";
import { userLogin } from "../../actions/auth";
import { handleTrim, setEncryptedLocalStorage } from "../../common/common-functions";
import { localstorageKeys, validationMessages } from "../../common/constants";
import { onUserLogIn } from "../../store/auth/action";
import { setLoading } from "../../store/loader/action";
import { setPermissions } from "../../store/permissions/action";
import { setCurrentProject } from "../../store/project/action";
import { onUserDetails } from "../../store/user/actions";
import "./login.css";

export const Login = () => {
  const dispatch = useDispatch();
  const [eye, setEyes] = useState(false);
  const toggleEye = () => setEyes(!eye);
  const [inValid, setInValid] = useState({
    isValid: false,
    message: ""
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(validationMessages.email.invalid).required(validationMessages.email.required),
      password: Yup.string().required(validationMessages.password.required)
    }),
    onSubmit: (value) => {
      dispatch(setLoading(true));
      userLogin({ email: value.email.trim(), password: value.password.trim() })
        .then((res) => {
          // save encrypted data to localstorage
          setEncryptedLocalStorage(localstorageKeys.userToken, res.data.token);
          setEncryptedLocalStorage(localstorageKeys.userInfo, res.data.user);
          let permissions = res?.data?.user?.permission;
          setEncryptedLocalStorage(localstorageKeys.permissions, permissions);
          // setting the last coming project as currentProject // data is coming form api
          // adding a new permission to this api without consulting with frontend can lead to render no routes on dom
          // because code will search for currentProject to render routes and those routes haven't yet implemented.
          setEncryptedLocalStorage(localstorageKeys.projectName, Object.keys(res?.data?.user?.permission)[8]);
          dispatch(setPermissions(permissions));
          if (res.data.user && !res.data.user.permission.hasOwnProperty("Staking") && res.data.user.permission.hasOwnProperty("banji")) {
            getBanjiAdminInfo().then(() => {
              // update redux store with user details and change login state
              dispatch(onUserLogIn({ isLoggedIN: true, userToken: res.data.token }));
              dispatch(onUserDetails(res.data.user));
              dispatch(setLoading(false));
            });
          } else {
            try {
              dispatch(setCurrentProject(Object.keys(res?.data?.user?.permission)[8]));
            } catch (error) {
              console.error("Unable to set current project, make sure you have enough permissions");
            }
            dispatch(onUserLogIn({ isLoggedIN: true, userToken: res.data.token }));
            dispatch(onUserDetails(res.data.user));
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          if (err?.data?.status === 400) {
            formik.resetForm();
            setInValid({
              isValid: true,
              message: err?.data?.message
            });
          }
        });
    }
  });
  const { email, password } = formik.values;
  const { errors, setFieldValue, touched, handleBlur, handleSubmit } = formik;

  const handleChange = (e: any) => {
    if (inValid.isValid) {
      setInValid({ message: "", isValid: false });
    }
    const name = e.target.name;
    const value = e.target.value;
    handleTrim(name, value, setFieldValue);
  };

  return (
    <div className="App bannerImage">
      <MetaFunction meta="Login" />
      <div className="loginSection">
        <div className="logoAndContent">
          <img className="edexaLogo" src={edexaLogo} alt="logo" />
          <div className="content">
            <h2>What is edeXa central Admin Panel? and who uses this panel?</h2>
            <p className="mainContainer__contentUnderHeading">
              edeXa central admin lets user to manage <b>all products</b> of the edeXa and its <b>users</b>. Only permitted users can be able to login. Please login to get into the admin panel.
            </p>
          </div>
          <div className="formSection rounded-10 m-auto ">
            <div className="auth-wrapper">
              <div className="formSection__innerDiv">
                <h2>Login</h2>
                <p className="mainContainer__registerFormPara">Login to edeXa Central Admin</p>
                <div className="inputFIeldsDiv px-5">
                  <div className={`mainContainer__emailInputFieldDiv ${inValid.isValid && "error-border"} ${touched.email && errors?.email ? "error-border" : ""}`} style={{ position: "relative" }}>
                    <InputGroup>
                      <Input
                        type="email"
                        placeholder="Email"
                        className="font-14 fw-normal color-black2 bg-white icon-right custom-input mb-0"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          e.key.toLowerCase() === "enter" && handleSubmit();
                        }}
                        invalid={Boolean(inValid.isValid || (touched.email && errors.email))}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className="h-100 bg-transparent d-flex justify-content-center"
                          style={{
                            borderColor: inValid.isValid || (touched.email && errors.email) ? "#ff0000" : ""
                          }}>
                          {" "}
                          <i className="fas fa-user icon-sm color-light-grey"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback invalid="true" className="text-left">
                        {inValid.isValid ? inValid.message : touched.email && errors.email}
                      </FormFeedback>
                    </InputGroup>
                  </div>
                  <div className={`mainContainer__passwordFields ${touched.password && errors?.password ? "error-border" : ""}`} style={{ position: "relative" }}>
                    <InputGroup className="z-index mt-3">
                      <Input
                        name="password"
                        value={password}
                        type={!eye ? "password" : "text"}
                        placeholder="Password"
                        className="font-14 mb-0 fw-normal color-black2 bg-white icon-right custom-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          e.key.toLowerCase() === "enter" && handleSubmit();
                        }}
                        invalid={Boolean(touched.password && errors.password)}
                      />
                      <InputGroupAddon addonType="append" onClick={() => toggleEye()} style={{ cursor: "pointer" }}>
                        <InputGroupText
                          className="h-100 bg-transparent d-flex justify-content-center"
                          style={{
                            borderColor: touched.password && errors.password ? "#ff0000" : ""
                          }}>
                          <i className={`fas ${!eye ? "fa-lock" : "fa-unlock"} icon-sm color-light-grey`}></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      {touched.password && errors.password && <FormFeedback className="text-left">{errors.password}</FormFeedback>}
                    </InputGroup>
                  </div>
                  <div className="d-flex justify-content-end w-100 mt-3">
                    <Link to={"/forgot-password"}>Forgot password?</Link>
                  </div>
                </div>
                <button type="submit" className="mainContainer__submit mt-3" onClick={() => handleSubmit()}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
