import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingGetGlobalSetting = (): Promise<IStakingGetGlobalSetting> => {
  return HTTPService.get(StakingPortalApiEndPoint.globalSetting.get);
};

export interface IStakingGetGlobalSettingData {
  id: string;
  _id: string;
  email: string;
  trendingAppLimit: string;
  trendingAppPrecedence: string;
}

interface IStakingGetGlobalSetting {
  message: string;
  status: number;
  data: IStakingGetGlobalSettingData;
}
