/* eslint-disable prettier/prettier */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { StakingGetGlobalSetting } from "../../../../actions/staking/globalSetting/stakingGetGlobalSetting";
import { StakingUpdateGlobalSetting } from "../../../../actions/staking/globalSetting/stakingUpdateGlobalSetting";
import MetaFunction from "../../../../common/MetaTag";
import {
  handleAppActionPermission,
  toastSuccess,
} from "../../../../common/common-functions";
import { projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import CustomTooltip from "../../../common/ToolTip";
import EditRestricted from "../../../common/restrictions/EditRestricted";

const StakingGlobalSettings = () => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState<boolean>(false);
  const [selectd, setSelectd] = useState<ISelected | null>(null);
  const userTypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "rating", label: "Rating" },
  ];
  const formik = useFormik({
    initialValues: {
      trendingAppLimit: undefined,
      trendingAppPrecedence: "",
      email: "",
      id: "",
    },
    validationSchema: Yup.object().shape({
      trendingAppLimit: Yup.number()
        .required("trendingAppLimit required")
        .typeError("Place enter only number"),
      trendingAppPrecedence: Yup.string().required(
        "trendingAppPrecedence required"
      ),
      email: Yup.string().required("Email required"),
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let newData: any = {
        trendingAppLimit: values.trendingAppLimit,
        trendingAppPrecedence: values.trendingAppPrecedence,
        email: values.email,
      };

      StakingUpdateGlobalSetting(values.id, newData).then((res: any) => {
        if (res?.status === 200) {
          toastSuccess(res?.message);
          setIsError(false);
          dispatch(setLoading(false));
        }
      });
    },
  });

  const { setFieldValue, values, errors, handleSubmit } = formik;

  // fetch global setting data with to api call
  const getGlobalSetting = () => {
    dispatch(setLoading(true));
    StakingGetGlobalSetting().then((res) => {
      if (res?.status === 200) {
        setFieldValue("id", res.data.id);
        setFieldValue("trendingAppLimit", res.data.trendingAppLimit);
        setFieldValue("trendingAppPrecedence", res.data.trendingAppPrecedence);
        setFieldValue("email", res.data?.email);
        let newValue: any = userTypeOptions.find(
          (data) => data.value === res.data.trendingAppPrecedence
        );
        setSelectd(newValue);
        dispatch(setLoading(false));
      }
    });
  };

  // eslint-disable-next-line
  useEffect(() => getGlobalSetting(), []);

  interface ISelected {
    value: string;
    label: string;
  }

  const handleChange = (e: any) => {
    setSelectd(e);
    setFieldValue("trendingAppPrecedence", e.value);
  };

  const handleAction = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    // @ts-ignore
    setFieldValue([name], value);
  };
  const handleSubmitAction = () => {
    setIsError(true);
    handleSubmit();
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Label for="trendingAppLimit">
                  Trending App Limit
                  <CustomTooltip
                    direction="right"
                    content={
                      <p className="p-2">
                        No. of app which have appear in the trending section in
                        edexa network
                      </p>
                    }
                  >
                    <i
                      className="fas fa-info-circle cursor-pointer transiton-02s position-relative"
                      style={{ marginLeft: "4px" }}
                    ></i>
                  </CustomTooltip>
                </Label>
                <Input
                  disabled={
                    handleAppActionPermission(
                      projectNames.edexanetwork,
                      projectModules[projectNames.edexanetwork].GLOBAL_SETTINGS,
                      "update"
                    ) || false
                  }
                  id="trendingAppLimit"
                  placeholder="trendingAppLimit"
                  name="trendingAppLimit"
                  onChange={handleAction}
                  value={values.trendingAppLimit}
                />
                {isError ? (
                  errors.trendingAppLimit ? (
                    <p className="text-danger">{errors?.trendingAppLimit}</p>
                  ) : null
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Label for="email" className="mt-3">
                  Email
                  <CustomTooltip
                    direction="right"
                    content={
                      <p className="p-2">
                        Added mail IDs will receive request which sent by the
                        edexa network portal (Multiple Mail Id's can be added by
                        adding commas(,))
                      </p>
                    }
                  >
                    <i
                      className="fas fa-info-circle cursor-pointer transiton-02s position-relative"
                      style={{ marginLeft: "4px" }}
                    ></i>
                  </CustomTooltip>
                </Label>
                <Input
                  disabled={
                    handleAppActionPermission(
                      projectNames.edexanetwork,
                      projectModules[projectNames.edexanetwork].GLOBAL_SETTINGS,
                      "update"
                    ) || false
                  }
                  id="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleAction}
                  value={values.email}
                />
                {isError ? (
                  errors.email ? (
                    <p className="text-danger">{errors?.email}</p>
                  ) : null
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Label className="mt-3">
                  Order App Precedence
                  <CustomTooltip
                    direction="right"
                    content={
                      <p className="p-2">
                        Choose rating, if you want to show the trending apps as
                        per to the rating given by customer (or) choose admin if
                        you wants to show as per to the enable status of the
                        trending in dApps
                      </p>
                    }
                  >
                    <i
                      className="fas fa-info-circle cursor-pointer transiton-02s position-relative"
                      style={{ marginLeft: "4px" }}
                    ></i>
                  </CustomTooltip>
                </Label>
                <Select
                  isDisabled={
                    handleAppActionPermission(
                      projectNames.edexanetwork,
                      projectModules[projectNames.edexanetwork].GLOBAL_SETTINGS,
                      "update"
                    ) || false
                  }
                  placeholder="Availability of App"
                  options={userTypeOptions}
                  onChange={handleChange}
                  className="mt-1 z-index"
                  value={selectd}
                />
                {isError ? (
                  errors.trendingAppPrecedence ? (
                    <p className="text-danger">
                      {errors?.trendingAppPrecedence}
                    </p>
                  ) : null
                ) : null}
              </Col>
            </Row>
          </div>
          {/* @ts-ignore */}
          <EditRestricted
            module={projectModules[projectNames.edexanetwork]?.GLOBAL_SETTINGS}
          >
            <button
              type="submit"
              onClick={() => handleSubmitAction()}
              className="btn mt-3 btn-primary bg-blue border-0 shadow-none button-width-com"
            >
              Submit
            </button>
          </EditRestricted>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StakingGlobalSettings;
