import { FC, useEffect, useState } from "react";
import { Col, Label, Row, Spinner } from "reactstrap";
import { IStakingAvailableAppsAPIData, StakingAvailableAppsListAPI } from "../../../../../actions/staking/availableApps/StakingAvailableAppsListAPI";
import SwitchMarketPlaceComponent from "./SwitchMarketPlaceComponent";

interface IAppComponent {
  values: any[];
  setFieldValue: any;
  propsData: any;
  errors: any;
  isError: boolean;
  isAvailable: number | null;
  blckChnAvailability: number | null;
}

const AppComponent: FC<IAppComponent> = ({ values, setFieldValue, propsData, errors, isError, isAvailable }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IStakingAvailableAppsAPIData[]>([]);
  const [updateApiData, setUpdateApiData] = useState(false);

  const getApplist = () => {
    StakingAvailableAppsListAPI()
      .then((res) => {
        if (res.status === 200) {
          for (let index of res.data.rows) {
            index.url = "";
            index.isActive = false;
          }
          setData(res.data.rows);
          //i am wait for first data is set
          setTimeout(() => {
            setUpdateApiData(true);
          }, 1000);
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getApplist();
  }, []);

  useEffect(() => {
    if (isAvailable === 3) {
      let newMainArray: any[] = [];
      let newArray: any[] = data.map((item) => {
        if (item.name === "Demo Request") {
          newMainArray = [{ availableOnApp: item._id, url: "#" }];
          return { ...item, isActive: true, url: "#" };
        } else {
          return { ...item, isActive: false, url: "" };
        }
      });
      setData(newArray);
      setFieldValue("availableOn", newMainArray);
    } // eslint-disable-next-line
  }, [isAvailable]);

  const handleForloop = () => {
    let newArray = [...data];
    for (let index of newArray) {
      for (let index1 of propsData?.availableOn) {
        if (index1.availableOnApp === index._id) {
          index.url = index1.url;
          index.isActive = true;
        }
      }
      if (!index.url) {
        index.url = "";
        index.isActive = false;
      }
    }
    setData(newArray);
  };

  useEffect(() => {
    if (updateApiData && propsData?.availableOn?.length > 0) {
      handleForloop();
    }
    // eslint-disable-next-line
  }, [updateApiData]);

  const handleSwitch = (e: boolean, index: number, id: string, name: string) => {
    // this demo request is on that time all other are off and demo request is off that time all other request true
    if (name === "Demo Request") {
      let newArray: any[] = data.map((item) => {
        if (item._id === id) {
          if (e) {
            return { ...item, isActive: true, url: "#" };
          } else {
            return { ...item, isActive: false, url: "" };
          }
        } else {
          return { ...item, isActive: false, url: "" };
        }
      });
      setData(newArray);
      // main data are update
      if (e) {
        let newMainArray: any[] = [{ availableOnApp: id, url: "#" }];
        setFieldValue("availableOn", newMainArray);
      } else {
        setFieldValue("availableOn", []);
      }
    } else {
      let newArray: any[] = data.map((item) => {
        if (item._id === id) {
          if (e) {
            return { ...item, isActive: true, url: "" };
          } else {
            return { ...item, isActive: false, url: "" };
          }
        }
        if (e) {
          // this other request is on that time demo request other are off
          if (item.name === "Demo Request") {
            return { ...item, isActive: false, url: "" };
          }
        }
        return item;
      });
      setData(newArray);

      // main data are update
      if (e) {
        let newMainArray: any[] = [...values];
        newMainArray.push({ availableOnApp: id, url: "" });
        setFieldValue("availableOn", newMainArray);
      } else {
        let newMainArray: any[] = [...values];
        const objWithIdIndex = newMainArray.findIndex((obj) => obj.availableOnApp === id);
        newMainArray.splice(objWithIdIndex, 1);
        setFieldValue("availableOn", newMainArray);
      }
    }
  };

  const handleValue = (value: string, index: number, id: string) => {
    let newArray: any[] = data.map((item) => {
      if (item._id === id) {
        return { ...item, url: value };
      }
      return item;
    });
    setData(newArray);

    let newMainArray: any[] = values.map((item) => {
      if (item.availableOnApp === id) {
        return { ...item, url: value };
      }
      return item;
    });
    setFieldValue("availableOn", newMainArray);
  };

  return (
    <Row>
      <Col sm={12} className=" position-relative">
        <div className="markeplace-form">
          {loading ? (
            <div className="markeplace-form-loadding">
              <Spinner />
              <Label className="ml-10">Loading...</Label>
            </div>
          ) : null}
          <Label for="biglogo">App Availability</Label>
          {isError ? errors?.availableOn?.length > 0 ? <p className="error-text">Minimum one should be enabled from the App Availability, please select</p> : null : null}

          {data.map((item, index) => (
            <SwitchMarketPlaceComponent key={item._id} item={item} index={index} handleSwitch={handleSwitch} handleValue={handleValue} />
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default AppComponent;
