/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import Select from "react-select";
import Switch from "react-switch";
import { Input, Label } from "reactstrap";
import * as Yup from "yup";
import { addRegion, getDetailOfRegion, getDomainsList, updateRegion } from "../../../../actions/portal/adminActions";
import { getBase64, handleTrim, toastSuccess } from "../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import CustomTooltip from "../../../common/ToolTip";
import PageHeader from "../../bvote/common/PageHeader";
interface IdropdownUniqueDomains {
  value: string;
  label: string;
}

const AddRegion = () => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [module, setModule] = useState("Edit Region");
  const [domainList, setDomainList] = useState<any>([]);
  const [searchField, setSearchField] = useState("");
  const [UserTypeOptionss, setUserTypeOptionss] = useState([]);
  const [networkDomainId, setNetworkDomainId] = useState();
  const [uniqueDomainDropdownOptions, setUniqueDomainDropdownOptions] = useState();
  const [typeOfDomain, setTypeOfDomain] = useState<any>({
    label: "---Select type of domain---",
    value: ""
  });
  const [storeFile, setStoreFile] = useState();
  const [imgUpdate, setImgUpdate] = useState(false);
  const [imgString, setImgString] = useState<any>("");

  useEffect(() => {
    const options: IdropdownUniqueDomains[] = domainList?.map((ele: any) => ({
      value: ele?.networkDomainId,
      label: ele?.name
    }));

    const optionsLabelWise = domainList?.map((ele: any) => ({
      value: ele?.id,
      label: ele?.name
    }));
    const key = "label";
    // @ts-ignore
    setUniqueDomainDropdownOptions([...new Map(optionsLabelWise?.map((item) => [item[key], item])).values()]);

    setUserTypeOptionss(options as unknown as []);
  }, [domainList]);

  const groupFormik = useFormik({
    initialValues: {
      region: "",
      disable: false,
      visible: true,
      file: "",
      default: false,
      name: "",
      newImg: "",
      networkDomainId: ""
    },
    validationSchema: Yup.object().shape({
      region: Yup.string().required(validationMessages.region.required).min(3, validationMessages.templateName.min).max(25, validationMessages.templateName.max),
      name: Yup.string().required(validationMessages.name.required).min(3, validationMessages.templateName.min).max(25, validationMessages.templateName.max),
      file: Yup.string().required(validationMessages.file.required),
      networkDomainId: Yup.string().required("Type of Domain is required")
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      if (!location.pathname.includes("edit")) {
        const formaData = new FormData();
        formaData.append("region", values.region.trim());
        formaData.append("disable", String(values.disable));
        formaData.append("visible", String(values.visible));
        formaData.append("default", String(values.default));
        formaData.append("file", values.file);
        formaData.append("networkDomainId", networkDomainId || "");
        formaData.append("name", values.name.trim() || "");

        addRegion(formaData).then((res) => {
          if (res.status === 200) {
            history.push(`/${projectNames.universe}/manage-regions`);
            toastSuccess(res.message);
            dispatch(setLoading(false));
          }
        });
      } else {
        const formaData = new FormData();
        formaData.append("region", values?.region.trim());
        formaData.append("disable", String(values?.disable));
        formaData.append("visible", String(values?.visible));
        formaData.append("default", String(values?.default));
        // @ts-ignore
        formaData.append("networkDomainId", typeOfDomain.value || networkDomainId || "");
        if (imgUpdate) {
          formaData.append("file", values?.newImg);
        }
        formaData.append("name", values?.name.trim() || "");
        updateRegion(formaData, params.id).then((res) => {
          if (res.status === 200) {
            history.push(`/${projectNames.universe}/manage-regions`);
            toastSuccess(res.message);
            dispatch(setLoading(false));
          }
        });
      }
    }
  });

  const { errors, values, handleBlur, setFieldValue, setTouched, touched } = groupFormik;

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoading(true));
      getDetailOfRegion(params?.id).then((response) => {
        if (response.status === 200) {
          setFieldValue("region", response?.data?.region);
          setFieldValue("disable", response?.data?.disable);
          setFieldValue("visible", response?.data?.visible);
          setFieldValue("file", response?.data?.file);
          setFieldValue("networkDomainId", response?.data?.networkDomainId);
          setStoreFile(response?.data?.file);
          setFieldValue("default", response?.data?.default);
          setFieldValue("networkDomainId", response?.data?.networkDomainId);
          setNetworkDomainId(response?.data?.networkDomainId);
          setFieldValue("name", response?.data?.name);
          setTypeOfDomain({ label: response?.data?.networkDomain.name });
          dispatch(setLoading(false));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const getDomainListFromApi = () => {
    dispatch(setLoading(true));
    getDomainsList({ pagination: false }).then((res) => {
      setDomainList(res.data.data);
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getDomainListFromApi();
    if (!location.pathname.includes("edit")) {
      setModule("Create Region");
    }
    setFieldValue("networkDomainId", "");
    // eslint-disable-next-line
  }, []);

  const [validation, setValidation] = useState(false);

  const handleSubmit = () => {
    groupFormik.handleSubmit();
    setValidation(true);
  };

  const handleTypeChange = (e: any) => {
    setNetworkDomainId(e.value);
    setTypeOfDomain(e);
    setFieldValue("networkDomainId", e.value);
  };

  return (
    <>
      <div className="holders">
        <PageHeader module={module} />
        <div className="rounded-10 bg-white p-20">
          <div className="row">
            <div className="col-md-6 row">
              <div className="col-md-10">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Type of Domain
                  <CustomTooltip direction={"right"} content={<p className="p-2">You can choose the domain type of region</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                  </CustomTooltip>
                </Label>
                <Select
                  name="networkDomainId"
                  placeholder="Select Type of domain"
                  value={typeOfDomain}
                  onBlur={() => setValidation(true)}
                  onChange={(e) => handleTypeChange(e)}
                  options={uniqueDomainDropdownOptions || []}
                  className={`me-2 mb-2 z-index ${validation ? (errors?.networkDomainId ? "red-c-color" : "") : ""}`}
                />
                {validation ? errors?.networkDomainId ? <span className="text-danger font-14 mt-2">{errors?.networkDomainId}</span> : null : null}
              </div>
              <div className="col-md-10">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Region Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  placeholder="Enter the region name"
                  id="region"
                  invalid={Boolean(touched?.region ? errors?.region : false)}
                  name="region"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.region}
                />
                {touched?.region ? errors?.region ? <span className="text-danger font-14 mt-2">{errors?.region}</span> : null : null}
              </div>

              <div className="col-md-10">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Name
                </Label>
                <Input
                  type="text"
                  invalid={Boolean(touched?.name ? errors?.name : false)}
                  placeholder="Enter the name"
                  id="name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                />
                {touched?.name ? errors?.name ? <span className="text-danger font-14 mt-2">{errors?.name}</span> : null : null}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="col-md-6 row">
                <div className="col-12 d-flex flex-column">
                  <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                    Visibility of Region
                    <CustomTooltip direction={"left"} content={<p className="p-2">If you enable, customers can view the region on Universe Portal</p>}>
                      <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                    </CustomTooltip>
                  </Label>
                  <Switch
                    height={20}
                    width={40}
                    onColor="#007de4"
                    offColor="#b1abab"
                    checked={values.visible}
                    onChange={(e) => setFieldValue("visible", e ? true : false)}
                    uncheckedIcon
                    checkedIcon
                    className="mr-10"
                  />
                </div>
                <div className="col-12 d-flex flex-column">
                  <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                    Disable Region
                    <CustomTooltip direction={"right"} content={<p className="p-2">If you disable, customers can see the region on Universe Portal, but cannot use it</p>}>
                      <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                    </CustomTooltip>
                  </Label>
                  <Switch
                    height={20}
                    width={40}
                    onColor="#007de4"
                    offColor="#b1abab"
                    checked={values.disable}
                    onChange={(e) => setFieldValue("disable", e ? true : false)}
                    uncheckedIcon
                    checkedIcon
                    className="mr-10"
                  />
                </div>
                <div className="col-12 d-flex flex-column">
                  <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                    Default Selected
                    <CustomTooltip direction={"left"} content={<p className="p-2">In Universe portal, region will be selected as default</p>}>
                      <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                    </CustomTooltip>
                  </Label>
                  <Switch
                    height={20}
                    width={40}
                    onColor="#007de4"
                    offColor="#b1abab"
                    checked={Boolean(values.default)}
                    onChange={(e) => setFieldValue("default", e ? true : false)}
                    uncheckedIcon
                    checkedIcon
                    className="mr-10"
                  />
                </div>
              </div>
              <div className="col-md-6 row">
                <div className="col-12 d-flex flex-column">
                  <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                    Upload Flag
                    <CustomTooltip direction={"right"} content={<p className="p-2">If you disable, customers can see the region on Universe Portal, but cannot use it</p>}>
                      <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                    </CustomTooltip>
                  </Label>
                  <Input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    placeholder="Enter the name of region"
                    id="file"
                    name="file"
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      setImgUpdate(true);
                      getBase64(e.target.files[0]).then((res) => {
                        setImgString(res);
                      });
                      setFieldValue("newImg", e.target.files[0]);
                      setFieldValue("file", e.target.files[0]);
                    }}
                  />
                  <img src={imgUpdate ? imgString : values.file} alt={values.name} width={100} height={100} className="mt-10 rounded-10" />
                  {touched?.file ? errors?.file ? <span className="text-danger font-14 mt-2">{errors?.file}</span> : null : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-10">
              <div className="col-md-12 mt-20">
                <button onClick={() => handleSubmit()} className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRegion;
