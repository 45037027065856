import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const updateBlogsAPI = (id: string, data: IupdateBlogsAPIParsms): Promise<IupdateBlogsAPI> => {
  return HTTPService.put(StakingPortalApiEndPoint.blogs.update + `/${id}`, data);
};
interface IupdateBlogsAPIParsms {
  title: string;
  shortDescription: string;
  content: string;
  isAvailable: number;
  tags: any[];
  blogImage: any[];
}

export interface IupdateBlogsAPIData {}

interface IupdateBlogsAPI {
  message: string;
  status: number;
  data: IupdateBlogsAPIData;
}
