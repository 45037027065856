export const PublicRouteComponent = ({ Route, Redirect, publicRoutes, Switch }) => {
  return (
    <Switch>
      {publicRoutes.map((route, i) => {
        return <Route exact={true} path={route.path} component={route.component} key={i} />;
      })}
      <Redirect to="/login" />
    </Switch>
  );
};
