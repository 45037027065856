export const rowsPerPage = [10, 20, 30, 40, 50];
export const debounceTimeInMilliseconds = 500;
export const defaultCountryCode = "ch";
export const dateFormat = "DD-MM-YYYY | h:mm A";
export const minDateFormat = "YYYY-MM-DD";
export const toasterPosition = "bottom-left";
export const onlyCharacterValidationRegex = /^[aA-zZ '\s]+$/;
export const navigateNumber = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
export const onlyNewpassword =
  // eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#+\$%/\^&\*])(?=.{8,16}$)/;
export const onlyNumberRegex = /^[0-9]\d*(\.\d+)?$/;
export const oneTozeroRegex = /^(1*[1-9][0-9]*(\.[0-9]+)?|1+\.[0-9]*[1-9][0-9]*)$/;
export const onlyCharacterWithLimit = /^[a-zA-z]+([\s][a-zA-Z]+)*$/; //minimum should be 3 and max can be 15 alphabets and numbers are allowed
export const CREATEDATE = "Created Date | Time";

export enum STATUS_VALUE {
  PANDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  IN_PROGRESS = 3
}

export const cookieKeys = {
  cryptoSecretKey: "edexaUser",
  cookieInitial: "edexa",
  cookieUser: "CookieUser"
};

export const localstorageKeys = {
  userInfo: "userInfo",
  userToken: "userToken",
  permissions: "permissions",
  projectName: "projectName"
};

export const toasterMessages = {
  comingSoon: "Coming Soon"
};

export const initialPaginationConfig = {
  filter: "",
  search: "",
  limit: 10,
  page: 1
};

export const validationMessages = {
  onlyAlphabets: "Please enter aplhabets only",
  onlyNumerical: "Please enter numerical value",
  firstName: {
    required: "First name is required",
    invalid: "Only alphabet are allowed",
    min: "Minimum 2 characters",
    max: "Maximum 30 characters"
  },
  lastName: {
    required: "Last name is required",
    invalid: "Only alphabet are allowed",
    min: "Minimum 2 characters",
    max: "Maximum 30 characters"
  },
  email: {
    required: "Email is required",
    invalid: "Invalid Email"
  },
  password: {
    required: "Password is required",
    matches: "Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
  },
  regFullName: {
    required: "Full Name is required"
  },
  termsConditions: {
    required: "Terms of serive is required"
  },
  formInvalid: "Please fill required fields",
  invalidFile: "Please upload only images",
  currentPassword: {
    required: "Current password is required"
  },
  oldPassword: {
    required: "Old password is required"
  },
  newPassword: {
    required: "New password is required",
    notSame: "Old password and new password cannot be same"
  },
  confirmPassword: {
    required: "Confirm new password is required",
    requiredConfirm: "Confirm password is required",
    mustMatch: "New password and confirm new password mismatched",
    passwordMatch: "Password and confirm password mismatched"
  },
  onlyCharacters: "Only alphabets are allowed for this field",
  phone: {
    required: "Phone number is required"
  },
  otp: {
    required: "OTP number is required"
  },
  fullname: {
    require: "Full name is required"
  },
  roleName: {
    required: "Role name is required",
    min: "Minimum 3 characters",
    max: "Maximum 30 characters"
  },
  permission: {
    required: "Permission name is required"
  },
  groupName: {
    required: "Group name is required",
    invalid: "Only alphabet are allowed",
    min: "Group Name must be at least 3 characters long",
    max: "Group Name should not exceed 26 characters limit"
  },
  birthDate: { required: "Birth date is required" },
  pollTitle: {
    required: "Poll title is required",
    invalid: "Only alphabet are allowed",
    min: "Poll title must be at least 3 characters long",
    max: "Poll title should not exceed 240 characters limit"
  },
  resultDate: {
    required: "Result date is required"
  },
  voteStartDate: {
    required: "Voting start date is required"
  },
  voteEndDate: {
    required: "Voting end date is required"
  },
  group: {
    required: "At least one group has to be selected",
    labelRequired: "Label is Required",
    valueRequired: "value is Required"
  },
  // Create Template Related messages
  templateName: {
    required: "Template name is required",
    min: "Minimum 3 characters",
    max: "Maximum 25 characters"
  },
  templateDescription: {
    required: "Description is required",
    min: "Minimum 25 characters",
    max: "Maximum 100 characters"
  },
  templateType: {
    required: "Template type is required",
    min: "Minimum 3 characters",
    max: "Maximum 25 characters"
  },
  title: {
    required: "Title is required"
  },
  placeholder: {
    required: "Placeholder is required"
  },
  key: {
    required: "Key is required"
  },
  default: {
    required: "Default Value is required"
  },
  type: {
    required: "Type Value is required"
  },
  domain: { required: "Domain name is required" },
  region: { required: "Region name is required" },
  file: { required: "Upload File is required" },
  name: { required: "Name is required" },
  appName: { required: "Name is required" },
  appId: { required: "App Id is required" },
  moduleName: { required: "Module Name is required" },
  imageUrl: { required: "Image  is required" },
  actions: { required: "Permissions is required" },
  redirectionUrl: { required: "Redirection Url is required" },
  userName: {
    required: "User name is required",
    invalid: "Only alphabet are allowed",
    min: "Minimum 2 characters",
    max: "Maximum 30 characters"
  }
};

export const banjiNews = {
  title: "Title is required",
  start_datetime: "Start Date/Time is required",
  end_datetime: "End Date/Time is required",
  description: "Description is required"
};
export const banjiNotification = {
  userId: {
    required: "Customer is required"
  },
  title: {
    required: "Title is required",
    min: "Title should me minimum of 10 char long",
    max: "Title can be maximum of 100 char long"
  },
  message: {
    required: "Message is required",
    min: "Message should me minimum of 10 char long",
    max: "Message can be maximum of 400 char long"
  }
};

export const rejectionReasons = [
  "Name in the Photo ID and the Name in edeXa account do not match",
  "Date of birth in the Photo id and the Date of birth in the kyc form does not match",
  "Country of issuing authority of the Photo ID is different from the nationality of the applicant",
  "Resolution of the picture is too low to confirm the information",
  "Other"
];

export const nftbanRejectionReasons = [
  "Copyright Violation",
  "Low Image Quality",
  "Inappropriate Language or Descriptions",
  "Unoriginal or Duplicate Content",
  "Fake Content",
  "Nude Content",
  "Other"
];

export const nftunbanRejectionReasons = [
  "Content Alignment with Policies",
  "User Appeal Consideration",
  "Policy Updates and Compliance",
  "Error Rectification",
  "Community Feedback Acknowledgment",
  "Legal Clearance",
  "Other"
];

export const universalRejectionReasons = ["Wrong Network Information", "Reject Invalid", "Already Requested", "User Invalid", "Other"];

export const universalStatusTypesArray = [
  // { label: "Pending", status: 0 },
  { label: "Completed", status: 1 },
  { label: "Reject", status: 2 },
  { label: "In-Process", status: 3 }
];
// comment

/**
 * @routes
 * @userPermissions
 */

/**
 * @procedure_of_adding_new_project
 * To Add a new project You have to update 4 variables
 * 1. projectNames
 * 2. projectModules
 * 3. AllEdexaAdminRoutes
 * 4. projectdropdownSelectorArray
 */

// name of all the admin panel projects
export const projectNames = {
  edexanetwork: "Staking",
  universe: "Universe",
  admin: "admin",
  bmessage: "bMessage",
  nft: "bNFT",
  bstamp: "bStamp",
  btrack: "bTrack",
  bvote: "bVote",
  byou: "bYou",
  banji: "banji"
};

// Project Modules name constant
export const projectModules = {
  [projectNames.banji]: {
    DASHBOARD: "dashboardmanagement",
    NOTIFICATION: "notificationmanagement",
    NEWS: "newsmanagement",
    CUSTOMERS: "usermanagement",
    CUSTOMERS_PLAN: "planmanagement",
    DIGITAL_TWIN: "dtwinmanagement",
    SUPPORT_REQUEST: "supportrequest",
    CUSTOMER_PLAN_REQUEST: "requestmanagement",
    PROFILE_MANAGEMENT: "dashboardmanagement",
    GLOBAL_SETTINGS: "settingmanagement",
    LABEL_MANAGEMENT: "labelmanagement"
  },
  [projectNames.edexanetwork]: {
    DASHBOARD: "dashboardmanagement",
    AVAILABLEAPPSMANAGEMENT: "availappmanagement",
    CONTACTUSMANAGEMENT: "contactusmanagement",
    DAPPSMANAGEMENT: "reqappmanagement",
    MARKETPLACEMANAGEMENT: "mrktplcmanagement",
    VALIDATOSMANAGEMENT: "validatormanagement",
    GLOBAL_SETTINGS: "settingmanagement",
    LABEL_MANAGEMENT: "labelmanagement",
    DEMO_REQUEST: "reqdemomanagement",
    BLOG_MANAGEMENT: "blogmanagement"
  },
  [projectNames.admin]: {
    DASHBOARD: "dashboardmanagement",
    API_MANAGEMENT: "apimanagement",
    APPLICATION_MANAGEMENT: "applicationmanagement",
    ADMIN_MANAGEMENT: "adminmanagement",
    CUSTOMER_MANAGEMENT: "usermanagement",
    KYC_MANAGEMENT: "kycmanagement",
    KYC_DETAILS: "kycdetails",
    ROLE_MANAGEMENT: "rolemanagement",
    MODULE_MANAGEMENT: "modulemanagement",
    PROFILE_MANAGEMENT: "dashboardmanagement",
    UNIVERSAL_MANAGEMENT: "universemanagement",
    ACCOUNTS_APP_SHORTCUTS_MANAGEMENT: "shortcutmanagement",
    MARKET_PLACE: "mrktplcmanagement",
    GLOBAL_SETTINGS: "settingmanagement"
  },
  [projectNames.bvote]: {
    DASHBOARD: "dashboardmanagement",
    REQUEST_DEMO: "reqdemomanagement",
    POLL: "pollmanagement",
    MANAGE_VOTERS: "votermanagement",
    MANAGE_GROUPS: "groupmanagement",
    POLL_HISTORY: "pollhistorymanagement",
    GLOBAL_SETTINGS: "settingmanagement"
  },
  [projectNames.bmessage]: {
    DASHBOARD: "dashboardmanagement",
    PRICE_AND_PLAN: "planmanagement",
    LABEL_MANAGEMENT: "labelmanagement",
    GLOBAL_SETTINGS: "settingmanagement"
  },
  [projectNames.nft]: {
    DASHBOARD: "dashboardmanagement",
    GLOBAL_SETTINGS: "settingmanagement",
    REQUEST_DEMO: "reqdemomanagement",
    LABEL_MANAGEMENT: "labelmanagement",
    CATEGORY_MANAGEMENT: "categorymanagement",
    NFT_MANAGEMENT: "nftmanagement",
    BLOCK_CHAIN: "blockchainmanagement",
    COUPON_MANAGEMENT: "couponmanagement",
    PLAN_MANAGEMENT: "planmanagement"
  },
  [projectNames.bstamp]: {
    DASHBOARD: "dashboardmanagement",
    LABEL_MANAGEMENT: "labelmanagement",
    GLOBAL_SETTINGS: "settingmanagement",
    PLAN_MANAGEMENT: "planmanagement",
    USER_MANAGEMENT: "usermanagement"
  },
  [projectNames.byou]: {
    USER_MANAGEMENT: "usermanagement",
    GLOBAL_SETTINGS: "settingmanagement",
    LABEL_MANAGEMENT: "labelmanagement"
  },
  [projectNames.universe]: {
    DASHBOARD: "dashboardmanagement",
    UNIVERSAL_MANAGEMENT: "universemanagement",
    SMART_CONTRACTS: "contractmanagement",
    API_MANAGEMENT: "apimanagement",
    APPLICATION_MANAGEMENT: "applicationmanagement",
    CHAIN_CODE_MANAGEMENT: "chaincodemanagement",
    CHANNEL_MANAGEMENT: "channelmanagement",
    CHANNEL_INVITATION: "channelmanagement",
    PEER_MANAGEMENT: "peermanagement",
    REQUEST_HISTORY: "reqhistorymanagement",
    TEMPLATE_LISTING: "templatemanagement",
    MANAGE_DOMAINS: "networkmanagement",
    MANAGE_REGIONS: "networkmanagement",
    TOKEN_ENGINE_MANAGEMENT: "tokenenginemanagement",
    GLOBAL_SETTINGS: "settingmanagement"
  },
  [projectNames.btrack]: {
    DASHBOARD: "dashboardmanagement",
    LABEL_MANAGEMENT: "labelmanagement",
    ISSUE_MANAGEMENT: "issuemanagement",
    GLOBAL_SETTINGS: "settingmanagement"
  }
};

// all admin routes as per project selection
// When a user selects a Project/Module as per his permissions for each module for selected project [banji, admin, bYou], these routes will render
export const AllEdexaAdminRoutes = {
  [projectNames.banji]: [
    {
      label: "Dashboard",
      path: `/${projectNames.banji}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].DASHBOARD
    },
    {
      label: "Notifications",
      path: `/${projectNames.banji}/notifications`,
      icon: <i className="fas fa-bell menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].NOTIFICATION
    },
    {
      label: "News",
      path: `/${projectNames.banji}/news`,
      icon: <i className="fas fa-newspaper menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].NEWS
    },
    {
      label: "Customers",
      path: `/${projectNames.banji}/customers`,
      icon: <i className="fas fa-users menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].CUSTOMERS
    },
    // {
    //   label: "Customer Plans",
    //   path: `/${projectNames.banji}/customer-plans`,
    //   icon: <i className="fas fa-file menu-icon transiton-02s"></i>,
    //   module: projectModules[projectNames.banji].CUSTOMERS_PLAN
    // },
    {
      label: "Digital Twin",
      path: `/${projectNames.banji}/digital-twin`,
      icon: <i className="fas fa-columns menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].DIGITAL_TWIN
    },
    {
      label: "Support Request",
      path: `/${projectNames.banji}/support-request`,
      icon: <i className="fas fa-id-badge menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].SUPPORT_REQUEST
    },
    {
      label: "Custom Plan Request",
      path: `/${projectNames.banji}/custom-plan-request`,
      icon: <i className="fas fa-question-circle menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].CUSTOMER_PLAN_REQUEST
    },
    {
      label: "Label Management",
      path: `/${projectNames.banji}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.banji}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.banji].GLOBAL_SETTINGS
    }
  ],
  [projectNames.admin]: [
    {
      label: "Dashboard",
      path: `/${projectNames.admin}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].DASHBOARD
    },
    // listing Components
    {
      label: "Customer Management",
      path: `/${projectNames.admin}/customer-management`,
      icon: <i className="fas fa-users menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].CUSTOMER_MANAGEMENT
    },
    {
      label: "KYC Management",
      path: `/${projectNames.admin}/kyc-management`,
      icon: <i className="fas fa-address-card menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].KYC_MANAGEMENT
    },

    {
      label: "Admin Management",
      path: `/${projectNames.admin}/admin-management`,
      icon: <i className="fas fa-user-tie menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].ADMIN_MANAGEMENT
    },
    {
      label: "Role Management",
      path: `/${projectNames.admin}/role-management`,
      icon: <i className="fas fa-users-cog menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].ROLE_MANAGEMENT
    },
    {
      label: "App Shortcut Management",
      path: `/${projectNames.admin}/app-shortcut-management`,
      icon: <i className="fas fa-users-cog menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].ACCOUNTS_APP_SHORTCUTS_MANAGEMENT
    },
    {
      label: "Module Management",
      path: `/${projectNames.admin}/module-management`,
      icon: <i className="fas fa-users-cog menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].MODULE_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.admin}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.admin].GLOBAL_SETTINGS
    }
  ],
  [projectNames.bstamp]: [
    {
      label: "Dashboard",
      path: `/${projectNames.bstamp}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bstamp].DASHBOARD
    },
    {
      label: "Label Management",
      path: `/${projectNames.bstamp}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bstamp].GLOBAL_SETTINGS
    },
    {
      label: "User Management",
      path: `/${projectNames.bstamp}/user-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bstamp].USER_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.bstamp}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bstamp].GLOBAL_SETTINGS
    }
  ],
  [projectNames.byou]: [
    {
      label: "User Management",
      path: `/${projectNames.byou}/user-management`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.byou].USER_MANAGEMENT
    },
    {
      label: "Label Management",
      path: `/${projectNames.byou}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.byou].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.byou}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.byou]?.GLOBAL_SETTINGS
    }
  ],
  [projectNames.edexanetwork]: [
    // {
    //   label: "Dashboard",
    //   path: `/${projectNames.staking}/dashboard`,
    //   icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
    //   module: projectModules.staking.DASHBOARD
    // },
    {
      label: "Marketplace",
      path: `/${projectNames.edexanetwork}/market-place`,
      icon: <i className="fas fa-building menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].MARKETPLACEMANAGEMENT
    },
    /* {
      label: "Available Apps Management",
      path: `/${projectNames.staking}/available-apps-management`,
      icon: <i className="fas fa-history menu-icon transiton-02s"></i>,
      module: projectModules.staking.AVAILABLEAPPSMANAGEMENT
    }, */
    /* {
      label: "dApps",
      path: `/${projectNames.edexanetwork}/dapps-management`,
      icon: <i className="fas fa-poll menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].DAPPSMANAGEMENT
    }, */
    {
      label: "Customer Request",
      path: `/${projectNames.edexanetwork}/contact-us`,
      icon: <i className="fas fa-address-card menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].CONTACTUSMANAGEMENT
    },
    {
      label: "News Management",
      path: `/${projectNames.edexanetwork}/category`,
      icon: <i className="fas fa-list-alt menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
    },
    /* {
      label: "App Submission Requests",
      path: `/${projectNames.staking}/contactus-management`,
      icon: <i className="fas fa-address-card menu-icon transiton-02s"></i>,
      module: projectModules.staking.CONTACTUSMANAGEMENT
    }, */
    /* {
      label: "App Demo Requests",
      path: `/${projectNames.staking}/demo-request`,
      icon: <i className="fas fa-user-tie menu-icon transiton-02s"></i>,
      module: projectModules.staking.CONTACTUSMANAGEMENT
    }, */

    /* {
      label: "Validator Requests",
      path: `/${projectNames.staking}/validate-management`,
      icon: <i className="fas fa-id-badge menu-icon transiton-02s"></i>,
      module: projectModules.staking.VALIDATOSMANAGEMENT
    }, */
    {
      label: "Label Management",
      path: `/${projectNames.edexanetwork}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.edexanetwork}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.edexanetwork].GLOBAL_SETTINGS
    }
  ],
  [projectNames.bvote]: [
    {
      label: "Dashboard",
      path: `/${projectNames.bvote}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].DASHBOARD
    },
    {
      label: "Poll",
      path: `/${projectNames.bvote}/poll`,
      icon: <i className="fas fa-poll menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].POLL
    },
    {
      label: "Manage Voters",
      path: `/${projectNames.bvote}/manage-voters`,
      icon: <i className="fas fa-user-friends menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].MANAGE_VOTERS
    },
    {
      label: "Manage Groups",
      path: `/${projectNames.bvote}/manage-groups`,
      icon: <i className="fas fa-users menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].MANAGE_GROUPS
    },
    {
      label: "Poll History",
      path: `/${projectNames.bvote}/poll-history`,
      icon: <i className="fas fa-history menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].POLL_HISTORY
    },
    {
      label: "Request Demo",
      path: `/${projectNames.bvote}/request-Demo`,
      icon: <i className="fas fa-id-badge menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].REQUEST_DEMO
    },
    {
      label: "Global Settings",
      path: `/${projectNames.bvote}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bvote].GLOBAL_SETTINGS
    }
  ],
  [projectNames.bmessage]: [
    {
      label: "Dashboard",
      path: `/${projectNames.bmessage}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bmessage].DASHBOARD
    },
    {
      label: "Price & Plan",
      path: `/${projectNames.bmessage}/price-plan`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bmessage].PRICE_AND_PLAN
    },
    {
      label: "Label Management",
      path: `/${projectNames.bmessage}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bmessage].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.bmessage}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.bmessage].GLOBAL_SETTINGS
    }
  ],
  [projectNames.nft]: [
    {
      label: "Dashboard",
      path: `/${projectNames.nft}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].DASHBOARD
    },
    {
      label: "Category Management",
      path: `/${projectNames.nft}/category-management`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].CATEGORY_MANAGEMENT
    },
    {
      label: "NFT Management",
      path: `/${projectNames.nft}/nft-management`,
      icon: <i className="fas fa-archive menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].NFT_MANAGEMENT
    },
    {
      label: "Blockchain",
      path: `/${projectNames.nft}/block-chain`,
      icon: <i className="fas fa-link menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].BLOCK_CHAIN
    },
    {
      label: "Request Demo",
      path: `/${projectNames.nft}/request-Demo`,
      icon: <i className="fas fa-id-badge menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].REQUEST_DEMO
    },
    {
      label: "Label Management",
      path: `/${projectNames.nft}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.nft}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.nft].GLOBAL_SETTINGS
    }
  ],
  [projectNames.universe]: [
    {
      label: "Dashboard",
      path: `/${projectNames.universe}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.universe].DASHBOARD
    },
    {
      label: "Universe Request",
      path: `/${projectNames.universe}/universe-request`,
      icon: <i className="fa fa-globe menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].UNIVERSAL_MANAGEMENT
    },
    //This routes is token engine request form
    // {
    //   label: "Token Engine",
    //   path: `/${projectNames.universe}/token-engine`,
    //   icon: <i className="fa fa-globe menu-icon transiton-02s" aria-hidden="true"></i>,
    //   module: projectModules[projectNames.universe].TOKEN_ENGINE_MANAGEMENT
    // },
    {
      label: "Smart Contracts",
      path: `/${projectNames.universe}/contracts-listing`,
      icon: <i className="fa fa-file menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].SMART_CONTRACTS
    },
    {
      label: "Channel Management",
      path: `/${projectNames.universe}/channel-management`,
      icon: <i className="fa fa-industry menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].CHANNEL_MANAGEMENT
    },
    {
      label: "Apps & API",
      path: `/${projectNames.universe}/api-management`,
      icon: <i className="fa fa-mobile menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].API_MANAGEMENT
    },
    {
      label: "Domains & Regions",
      path: `/${projectNames.universe}/manage-domains`,
      icon: <i className="fa fa-map-marker menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].MANAGE_DOMAINS
    },
    /*  {
      label: "API Management",
      path: `/${projectNames.universe}/api-management`,
      icon: <i className="fa fa-square menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].API_MANAGEMENT
    },
    {
      label: "Application Management",
      path: `/${projectNames.universe}/application-management`,
      icon: <i className="fa fa-mobile menu-icon transiton-02s" aria-hidden="true"></i>,

      module: projectModules[projectNames.universe].APPLICATION_MANAGEMENT
    }, */
    {
      label: "Request History",
      path: `/${projectNames.universe}/request-history`,
      icon: <i className="fa fa-history menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].REQUEST_HISTORY
    },

    {
      label: "Chaincode Management",
      path: `/${projectNames.universe}/chain-code-management`,
      icon: <i className="fa fa-link menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].CHANNEL_MANAGEMENT
    },
    {
      label: "Peers",
      path: `/${projectNames.universe}/peers`,
      icon: <i className="fa fa-users menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].PEER_MANAGEMENT
    },
    {
      label: "Templates",
      path: `/${projectNames.universe}/templates`,
      icon: <i className="fa fa-file menu-icon transiton-02s" aria-hidden="true"></i>,
      module: projectModules[projectNames.universe].TEMPLATE_LISTING
    },

    /* {
      label: "Manage Domains",
      path: `/${projectNames.universe}/manage-domains`,
      icon: <i className="fas fa-building menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.universe].MANAGE_DOMAINS
    }, */

    /* {
      label: "Manage Regions",
      path: `/${projectNames.universe}/manage-regions`,
      icon: <i className="fas fa-map-marker menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.universe].MANAGE_REGIONS
    }, */
    {
      label: "Global Settings",
      path: `/${projectNames.universe}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.universe].GLOBAL_SETTINGS
    }
  ],
  [projectNames.btrack]: [
    {
      label: "Dashboard",
      path: `/${projectNames.btrack}/dashboard`,
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.btrack].DASHBOARD
    },
    {
      label: "Issue Management",
      path: `/${projectNames.btrack}/issue-management`,
      icon: <i className="fas fa-exclamation-circle menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.btrack].ISSUE_MANAGEMENT
    },
    {
      label: "Label Management",
      path: `/${projectNames.btrack}/label-management`,
      icon: <i className="fas fa-tags menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.btrack].LABEL_MANAGEMENT
    },
    {
      label: "Global Settings",
      path: `/${projectNames.btrack}/global-settings`,
      icon: <i className="fas fa-cogs menu-icon transiton-02s"></i>,
      module: projectModules[projectNames.btrack].GLOBAL_SETTINGS
    }
  ]
};

export const allRoutesArray = [
  { label: "Dashboard", path: `/${projectNames.admin}/dashboard` },
  // listing Components
  { label: "API Management", path: `/${projectNames.admin}/api-management` },
  {
    label: "Application Management",
    path: `/${projectNames.admin}/application-management`
  },
  { label: "User Management", path: `/${projectNames.admin}/user-management` },
  { label: "KYC Management", path: `/${projectNames.admin}/kyc-management` },
  { label: "Role Management", path: `/${projectNames.admin}/role-management` },
  {
    label: "Admin Management",
    path: `/${projectNames.admin}/admin-management`
  },
  {
    label: "Universe Request",
    path: `/${projectNames.admin}/universe-request`
  },
  {
    label: "Module Management",
    path: `/${projectNames.admin}/module-management`
  }
];

export type allEdexaAdminRoutesType = {
  label: string;
  path: string;
  icon: string;
  module: string;
};

export const RequestDemoStatus = {
  InProgress: {
    name: "InProgress",
    no: 0
  },
  Declined: {
    name: "Declined",
    no: 1
  },
  Scheduled: {
    name: "Demo Scheduled",
    no: 2
  },
  Completed: { name: "Demo is Completed", no: 3 },
  Approved: { name: "Assigned NFT Access", no: 4 }
};

// this constant is used for rendring project names and path to - Project select dropdown in header
export const projectDropdownSelectorArray = [
  {
    label: projectNames.admin,
    path: `/${projectNames.admin}/dashboard`,
    placeholder: `${projectNames.admin}`
  },
  {
    label: projectNames.edexanetwork,
    path: `/${projectNames.edexanetwork}/market-place`,
    placeholder: `${projectNames.edexanetwork}`
  },
  {
    label: projectNames.banji,
    path: `/${projectNames.banji}/dashboard`,
    placeholder: `${projectNames.banji}`
  },
  {
    label: projectNames.bvote,
    path: `/${projectNames.bvote}/dashboard`,
    placeholder: `${projectNames.bvote}`
  },
  {
    label: projectNames.bmessage,
    path: `/${projectNames.bmessage}/dashboard`,
    placeholder: `${projectNames.bmessage}`
  },
  {
    label: projectNames.nft,
    path: `/${projectNames.nft}/dashboard`,
    placeholder: `${projectNames.nft}`
  },
  {
    label: projectNames.bstamp,
    path: `/${projectNames.bstamp}/dashboard`,
    placeholder: `${projectNames.bstamp}`
  },
  {
    label: projectNames.byou,
    path: `/${projectNames.byou}/user-management`,
    placeholder: `${projectNames.byou}`
  },
  {
    label: projectNames.universe,
    path: `/${projectNames.universe}/dashboard`,
    placeholder: `${projectNames.universe}`
  },
  {
    label: projectNames.btrack,
    path: `/${projectNames.btrack}/dashboard`,
    placeholder: `${projectNames.btrack}`
  }
];

// Permission enum of a module
export const userRolesAndPermissions = {
  VIEW: "view",
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete"
};

// react-select styling
export const ReactSelectCustomstyle = {
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#4c4f53" // Custom colour
  }),
  control: (base: any) => ({
    ...base,
    borderColor: "#dadce0 !important",
    minWidth: "250px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#dadce0 !important"
    }
  })
};

export const moduleKey = [
  { key: "adminmanagement", value: "Admin Management" },
  { key: "apimanagement", value: "API Management" },
  { key: "applicationmanagement", value: "Application Management" },
  { key: "customermanagement", value: "Customer Management" },
  { key: "dashboardmanagement", value: "Dashboard Management" },
  { key: "settingmanagement", value: "Setting Management" },
  { key: "kycmanagement", value: "KYC Management" },
  { key: "modulemanagement", value: "Module Management" },
  { key: "rolemanagement", value: "Role Management" },
  { key: "universemanagement", value: "Universe Request" },
  { key: "customers", value: "Customers" },
  { key: "customersplan", value: "Customers Plan" },
  { key: "dtwinmanagement", value: "Digital Twin Management" },
  { key: "newsmanagement", value: "News Management" },
  { key: "notificationmanagement", value: "Notification Management" },
  { key: "planrequest", value: "Plan Request" },
  { key: "supportrequest", value: "Support Request" },
  { key: "managegroups", value: "Manage Groups" },
  { key: "managevoters", value: "Manage Voters" },
  { key: "pollmanagement", value: "Poll Management" },
  { key: "mrktplcmanagement", value: "Marketplace Management" },
  { key: "shortcutmanagement", value: "Shortcut Management" },
  { key: "labelmanagement", value: "Label Management" },
  { key: "reqdemomanagement", value: "Request Demo Management" },
  { key: "categorymanagement", value: "Category Management" },
  { key: "channelmanagement", value: "Channel Management" },
  { key: "reqhistorymanagement", value: "Request History Management" },
  { key: "templatemanagement", value: "Template Management" },
  { key: "chaincodemanagement", value: "Chain-Code Management" },
  { key: "peermanagement", value: "Peer Management" },
  { key: "contractmanagement", value: "Contract Management" },
  { key: "channelinvitationmanagement", value: "Channel Invitationmanagement" },
  { key: "networkdomainmanagement", value: "Network Domain Management" },
  { key: "usermanagement", value: "User Management" },
  { key: "dappsmanagement", value: "dApps Management" },
  { key: "availappmanagement", value: "Available Apps Management" },
  { key: "contactusmanagement", value: "Contactus Management" },
  { key: "validatosmanagement", value: "Validatos Management" },
  { key: "pollhistorymanagement", value: "Poll History Management" },
  { key: "networkregionmanagement", value: "Network Region Management" },
  { key: "blockchainmanagement", value: "Blockchain Management" },
  { key: "demorequest", value: "Demo Request" },
  { key: "issuemanagement", value: "Issue Management" },
  { key: "blogmanagement", value: "Blog Management" },
  { key: "votermanagement", value: "Voter Management" },
  { key: "groupmanagement", value: "Group Management" },
  { key: "validatormanagement", value: "Validator Management" },
  { key: "reqappmanagement", value: "Request App Management" },
  { key: "orderermanagement", value: "Orderer Management" },
  { key: "tokenenginemanagement", value: "Token Engine Management" },
  { key: "networkmanagement", value: "Network Management" },
  { key: "couponmanagement", value: "Coupon Management" },
  { key: "planmanagement", value: "Plan Management" },
  { key: "requestmanagement", value: "Request Management" }
];

export const handleColuman = (permission: any, moduleName: any) => {
  return permission?.admin[moduleName]?.update || permission?.admin[moduleName]?.delete;
};

export const addLabelNote = {
  note: "Note: Add labels section is only for Developers",
  style: { fontSize: "14px", fontWeight: "bold", marginTop: ".5%" }
};

export const InputType = [
  "text",
  "email",
  "select",
  "file",
  "radio",
  "checkbox",
  "textarea",
  "button",
  "reset",
  "submit",
  "date",
  "datetime-local",
  "hidden",
  "image",
  "month",
  "number",
  "range",
  "search",
  "tel",
  "url",
  "week",
  "password",
  "datetime",
  "time",
  "color"
];

export const TableStatusFilter = [
  { value: "", label: "All" },
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "In_Progress", label: "In-Process" }
];

export enum FilterAction {
  CREATE = "create",
  UPDATE = "update",
  GET = "get",
  DELETE = "delete",
  GET_LIST = "get_list"
}

export enum BlogsAction {
  CREATE = "create",
  UPDATE = "update",
  GET = "get",
  DELETE = "delete",
  GET_LIST = "get_list"
}

export enum edeXaNetwork_page {
  BLOGS = "blogs",
  CATEGORY = "category"
}

export const validatorReasonModalOption = [
  "Insufficient technical capabilities",
  "Lack of trustworthiness",
  "Conflict of interest",
  "Insufficient resources",
  "Network capacity constraints",
  "Failure to meet governance or consensus rules",
  "Legal or regulatory restrictions",
  "Other"
];

export const validatorMailReasonModalOption = ["Invalid information", "Inappropriate organization", "Email is not valid", "Other"];

export const suspendUserRejectionReasons = [
  "Violation of Terms of Service ",
  "Violation of Community Guidelines ",
  "Spamming or Phishing ",
  "Violation of Privacy Policies",
  "Harassment or Threats",
  "Fraudulent or Suspicious Activity",
  "Failure to Verify Account Information",
  "Other"
];

// ['0', '1', '2', '3', '4', '5','6']
// 0 - pending, 1 - email sent, 2 - rejected // 3- support  4 //node added as observer   // 5 node as validator  // 6 in progress
export enum validatorStatus {
  PENDING = "0",
  EMAIL_SENT = "1",
  REJECTED = "2",
  SUPPORT_SYSTEM = "3",
  NODE_OBSERVER = "4",
  NODE_VALIDATOR = "5",
  IN_PROGRESS = "6"
}

// ['0','1','2','3']
// 0: general | 1: solution partners | 2: technology partners | 3: education partners
export enum queryParameterStatus {
  GENERAL = 0,
  SOLUTION_PARTNERS = 1,
  TECHNOLOGY_PARTNERS = 2,
  EDUCATION_PARTNERS = 3,
  ENROLL_BUSINESS = 4,
  BOUNTY_BUGS = 5
}
