import { projectModules, projectNames } from "../../common/constants";
import Group from "../../pages/main/bvote/component/group/BvoteGroup";
import Poll from "../../pages/main/bvote/component/poll/BvotePoll";
import ViewPollHistory from "../../pages/main/bvote/component/pollHistory/ViewPollHistory";
import BvoteRequestDemo from "../../pages/main/bvote/component/requestDemo/BvoteRequestDemo";
import Voter from "../../pages/main/bvote/component/voter/BvoteVoter";
import Dashboard from "../../pages/main/bvote/Dashboard";
import BVoteGlobalSetting from "../../pages/main/bvote/globalSetting/BvoteGlobalSetting";
import ManageGroups from "../../pages/main/bvote/ManageGroups";
import ManageVoters from "../../pages/main/bvote/ManageVoters";
import PollHistory from "../../pages/main/bvote/PollHistory";
import PollListing from "../../pages/main/bvote/PollListing";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import Notification from "../../pages/main/Notification";

const bvotePrivateRoutes = [
  {
    path: `/${projectNames.bvote}/dashboard`,
    component: Dashboard,
    module: projectModules[projectNames.bvote].DASHBOARD
  },
  {
    path: `/${projectNames.bvote}/poll`,
    component: PollListing,
    module: projectModules[projectNames.bvote].POLL
  },
  {
    path: `/${projectNames.bvote}/request-demo`,
    component: BvoteRequestDemo,
    module: projectModules[projectNames.bvote].REQUEST_DEMO
  },
  {
    path: `/${projectNames.bvote}/poll/add`,
    component: Poll,
    module: projectModules[projectNames.bvote].POLL
  },
  {
    path: `/${projectNames.bvote}/poll/edit`,
    component: Poll,
    module: projectModules[projectNames.bvote].POLL
  },
  {
    path: `/${projectNames.bvote}/manage-voters`,
    component: ManageVoters,
    module: projectModules[projectNames.bvote].MANAGE_VOTERS
  },
  {
    path: `/${projectNames.bvote}/manage-voters/add`,
    component: Voter,
    module: projectModules[projectNames.bvote].MANAGE_VOTERS
  },
  {
    path: `/${projectNames.bvote}/manage-voters/edit`,
    component: Voter,
    module: projectModules[projectNames.bvote].MANAGE_VOTERS
  },
  {
    path: `/${projectNames.bvote}/manage-groups`,
    component: ManageGroups,
    module: projectModules[projectNames.bvote].MANAGE_GROUPS
  },
  {
    path: `/${projectNames.bvote}/manage-groups/add`,
    component: Group,
    module: projectModules[projectNames.bvote].MANAGE_GROUPS
  },
  {
    path: `/${projectNames.bvote}/manage-groups/edit`,
    component: Group,
    module: projectModules[projectNames.bvote].MANAGE_GROUPS
  },
  {
    path: `/${projectNames.bvote}/poll-history`,
    component: PollHistory,
    module: projectModules[projectNames.bvote].POLL_HISTORY
  },
  {
    path: `/${projectNames.bvote}/poll-history/analytics`,
    component: ViewPollHistory,
    module: projectModules[projectNames.bvote].POLL_HISTORY
  },
  {
    path: "/profile",
    component: ProfileDetails
    // module: projectModules[projectNames.admin].PROFILE_MANAGEMENT,
  },
  {
    path: "/notification",
    component: Notification
  },
  {
    path: `/${projectNames.bvote}/global-settings`,
    component: BVoteGlobalSetting,
    module: projectModules[projectNames.bvote].GLOBAL_SETTINGS
  }
];
export default bvotePrivateRoutes;
