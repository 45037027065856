import React from "react";
import { useSelector } from "react-redux";

// @ts-ignore
const DeleteRestricted: React.ReactNode = ({ children, module }: RestrictedTypes) => {
  // get all the current permissions of the user
  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);
  // get the current project name which one is selected
  const currentProject = useSelector((state: any) => state.projectNameReducer.currentProject);

  // example - permissions.banji.dashboard.view = true
  // for ref go to reducer - permissionsReducer
  if (currentPermission?.[currentProject]?.[module]?.delete) {
    return children;
  }
  return <></>;
};

export default DeleteRestricted;

interface RestrictedTypes {
  children: React.ReactNode;
  permission: string;
  module: string;
}
