import { SETVISIBLE } from "./type";

const initialState = {
  isVisible: false,
  deleteId: "",
  label: "",
  deleteStatus: 0
};

const visibleReducer = (state = initialState, action: { type: string; payload: any }) => {
  let newState = { ...state };

  switch (action.type) {
    case SETVISIBLE:
      newState = action.payload;
      break;
  }
  return newState;
};

export default visibleReducer;
