import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Row, Spinner } from "reactstrap";
import { getPublicCredential } from "../../actions/portal/publicCredentialData";
import "../../assets/css/publicVerification.css";
import Close from "../../assets/images/svg/close.svg";

const PublicVerification = ({ match }) => {
  const history = useHistory();
  const [validCred, setValidCred] = useState(false);
  const [validCredData, setValidCredData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (match.params.id) {
      getPublicCredential({ keyId: match.params.id })
        .then((publicLinkResult) => {
          if (Object.keys(publicLinkResult).length > 0 && publicLinkResult.hasOwnProperty("status")) {
            setValidCred(true);
            setValidCredData(publicLinkResult.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button className={`bg-transparent position-absolute close-btn public-verify ${!validCred && "d-none"}`} onClick={() => history.push("/portal/dashboard")}>
        <img src={Close} alt="close-icon" />
      </button>
      <Row className="public-verification">
        <Col sm={4}></Col>
        <Col sm={4} className="mb-3">
          <Card style={{ marginTop: "50%", padding: "20px" }}>
            <>
              {validCred && (
                <CardHeader style={{ justifyContent: "center" }}>
                  <CardTitle className="color-black1 font-20 fw-bold mb-20 text-center">Successfully fetched credential!</CardTitle>
                </CardHeader>
              )}
              <CardBody>
                {loading ? (
                  <div className="d-flex justify-content-center my-1">
                    <Spinner color="dark" />
                  </div>
                ) : (
                  <div className="stats-card-body d-flex justify-content-center flex-column text-center pb-2 pt-2 card-body">
                    {validCred ? (
                      <>
                        <div className="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="#007DE4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </div>
                        <CardTitle className="text-bold-500 font-20">Credential Name: {validCredData.name}</CardTitle>
                        <CardText className="font-14 color-light-grey">
                          This Credential belongs to{" "}
                          <span className="fw-bold">
                            {validCredData.firstName} {validCredData.lastName}
                          </span>{" "}
                        </CardText>
                        <CardText className="font-14 color-light-grey mt-2">
                          The Status of this credential is <b>{validCredData.statusBC}</b>{" "}
                        </CardText>

                        <Button className="bg-blue mt-3 border-0" style={{ width: "100%" }}>
                          Thank You!
                        </Button>
                      </>
                    ) : (
                      <>
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#ea5455"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="warning">
                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                        </div>
                        <CardTitle>Oh Snap!</CardTitle>
                        <CardText>Data does not exist.</CardText>
                        <Button className="bg-danger border-0 mt-2" style={{ width: "100%" }} onClick={() => history.push("/portal/dashboard")}>
                          Dismiss
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </CardBody>
            </>
          </Card>
        </Col>
        <style>
          {
            "\
                .header{\
                  display:none !important;\
                }\
            "
          }
        </style>
      </Row>
    </>
  );
};

export default PublicVerification;
