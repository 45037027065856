import { FC } from "react";

interface IEmailComponent {
  email: string;
}
const EmailComponent: FC<IEmailComponent> = ({ email }) => {
  return (
    <a href={`mailto:${email}`} target="_blank" style={{ color: "#007DE4" }} rel="noreferrer">
      {email}
    </a>
  );
};

export default EmailComponent;
