import { debounce } from "lodash";
import { useCallback } from "react";
import { Input } from "reactstrap";
import { debounceTimeInMilliseconds } from "../../../../common/constants";

const SearchFilter = ({ paginationConfig, setPaginationConfig, search, setSearch, placeHolder }: SearchFilterPropTypes) => {
  const changeSearchState = (search: string) => {
    setPaginationConfig({
      ...paginationConfig,
      search,
      filter: paginationConfig.filter
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(changeSearchState, debounceTimeInMilliseconds), []);

  return (
    <div className="filters d-flex flex-sm-nowrap w-100 w-sm-auto">
      <Input
        type="text"
        name="address"
        id="exampleAddress"
        placeholder={`${placeHolder ? placeHolder : "Search..."}`}
        className="w-sm-250px w-100 font-14 search-input mb-sm-0 mb-20 heigth-btn-banji"
        value={search}
        onChange={(e) => {
          if (e.target.value.trim()) {
            setSearch(e.target.value);
            delayedSearch(e.target.value);
          } else {
            if (search !== "") {
              setSearch("");
              delayedSearch("");
            }
          }
        }}
      />
      {/* <button className="btn-sm bg-blue color-white rounded-5 py-2 font-14 border-none" style={{ marginLeft: "5px" }} type="button" onClick={() => delayedSearch}>
        <i className="fa fa-search color-white"></i>
      </button> */}
    </div>
  );
};

export default SearchFilter;

interface SearchFilterPropTypes {
  paginationConfig: any;
  setPaginationConfig: Function;
  search: string;
  setSearch: Function;
  placeHolder?: string;
}
