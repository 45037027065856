import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Col, Row, Tooltip } from "reactstrap";
import { getTemplateDetail } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import BackArrowComponent from "../../../../common/backArrow";
import { projectNames } from "../../../../common/constants";
import MetaFunction from "../../../../common/MetaTag";
import { setLoading } from "../../../../store/loader/action";
import ColumnComponent from "../../../common/ColumanComponent";
import "../../component/kycDetails.css";

export const TemplateDetail = () => {
  let history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<any>();

  useEffect(() => {
    dispatch(setLoading(true));
    getTemplateDetail(params.id)
      .then((response) => {
        if (response.status === 200) {
          setTemplateDetails(response?.data);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        history.push(`/${projectNames.universe}/templates`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <MetaFunction meta="Template Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Template Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={history.goBack} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
            <span
              style={{
                textAlign: "right",
                color: "#007de4",
                cursor: "pointer",
                marginLeft: "92%"
              }}
              onClick={() => history.push(`/${projectNames.universe}/templates-edit/${templateDetails?.id}`)}>
              Edit Template
            </span>
          </div>
        </div>
        <Row className="mt-10">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <span className="sub-title m-b-5">Template Information</span>
              </div>
              {isLoadingState ? (
                <ColumnComponent title="Loading..." value="" />
              ) : (
                <>
                  <ColumnComponent title="Template Name" value={templateDetails?.name} />
                  <ColumnComponent title="Description" value={templateDetails?.description} />
                  <ColumnComponent title="Visible" value={templateDetails?.visible === true ? "Visible" : "Not Visible"} />
                </>
              )}
            </div>
          </Col>
          <Col sm="12" lg="12" className="my-3">
            <div className="kyc-box rounded-10">
              {isLoadingState ? (
                <ColumnComponent title="Loading..." value="" />
              ) : (
                <>
                  {templateDetails?.fields?.map((individualFieldSection: any, index: number) => {
                    return (
                      <>
                        <div className="p-20">
                          <span className="sub-title m-b-5">Field Section {index + 1}</span>
                        </div>
                        <ColumnComponent title="Title" value={individualFieldSection?.title} />
                        <ColumnComponent title="Default Value" value={individualFieldSection?.default} />
                        <ColumnComponent title="Key" value={individualFieldSection?.key} />
                        <ColumnComponent title="Placeholder" value={individualFieldSection?.placeholder} />
                        <ColumnComponent title="Is Required?" value={individualFieldSection?.require === true ? "Required" : "Optional"} />
                        <ColumnComponent title="Type of the field" value={individualFieldSection?.type} />
                        <ColumnComponent title="Visibility" value={individualFieldSection?.visible === true ? "Visible" : "Not visible"} />
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
