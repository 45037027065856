import { Modal, ModalBody } from "reactstrap";

const deleteConfirmationPopup = ({
  isVisibleDeletePopup,
  storeID,
  setIsVisibleDeletePopup,
  handleConfirmation,
  typeOfModule
}: {
  isVisibleDeletePopup: boolean;
  storeID: number | null;
  setIsVisibleDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmation: Function;
  typeOfModule?: string;
}) => {
  const confirm = (storeID: number) => {
    setIsVisibleDeletePopup(false);
    handleConfirmation(storeID, typeOfModule);
  };
  return (
    <Modal centered isOpen={isVisibleDeletePopup} style={{ maxWidth: "450px" }}>
      <ModalBody>
        <div style={{ padding: "30px" }}>
          <h4 className="fw-bold text-center">Are you sure you want to delete ?</h4>
          {/* <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
            {label}
          </p> */}
        </div>
        <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
          <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={() => {}}>
            <button className="confimatio-button text-danger" onClick={() => setIsVisibleDeletePopup(false)}>
              No
            </button>
          </div>
          <div
            className="col-6 p-10 d-flex cursor-pointer justify-content-center"
            onClick={() => {
              if (storeID) {
                confirm(storeID);
              }
            }}>
            <span className={"confimatio-button text-success d-flex align-items-center  "}>Yes</span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default deleteConfirmationPopup;
