import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Modal, ModalHeader, Row, Tooltip } from "reactstrap";
import { getIsssueAPI } from "../../../actions/bTrack/issue/getIssueAPI";
import MetaFunction from "../../../common/MetaTag";
import BackArrowComponent from "../../../common/backArrow";
import { getFormattedDate } from "../../../common/common-functions";
import { CREATEDATE } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import { IRootReducerTypes } from "../../../store/rootRededucer";
import EmailComponent from "../../../utils/components/EmailComponent";
import Footer from "../../common/Footer";

const ColumnComponent = ({ title, value, color, capitalize }: any) => {
  return (
    <>
      <hr className="m-0" />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 fw-bold color-black2 fw-normal text-upper">{title}</p>
        </div>
        <div className="col-9">
          <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {title === "Email" ? <EmailComponent email={value} /> : value}
          </p>
        </div>
      </div>
    </>
  );
};

const ImageComponent = ({ title, src, name, handleSetSrc }: any) => {
  return (
    <>
      <hr className="m-0" />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 color-black2 fw-bold text-upper">{title}</p>
        </div>
        <div className="col-2 ">
          <p className="font-14 blue cursor-pointer fw-normal" onClick={() => handleSetSrc(src, title)}>
            View Document
          </p>
        </div>
      </div>{" "}
    </>
  );
};

export const GetIssueDetails = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [kycDetails, setKycDetails] = useState<any>();
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const isLoadingState = useSelector((state: IRootReducerTypes) => state?.loading?.isLoading);
  const [imgSrc, setImgSrc] = useState({
    src: "",
    title: ""
  });
  const GetData = () => {
    dispatch(setLoading(true));
    getIsssueAPI(params.id)
      .then((res) => {
        if (res.status === 200) {
          setKycDetails(res.data);
        }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.status === 400) {
          history.push("/issue-management");
        } else {
          history.goBack();
        }
      });
  };

  useEffect(() => {
    GetData();
    // eslint-disable-next-line
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSetSrc = (src: any, title: any) => {
    setImgSrc({
      src,
      title
    });
    handleToggle();
  };

  return (
    <>
      <MetaFunction meta="Issue Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Issue Details</p>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {isLoadingState ? (
          <div className="kyc-box rounded-10">
            <div className="p-20">Loading...</div>
          </div>
        ) : (
          <>
            <Row className="mt-10">
              <Col sm="12" lg="12">
                <div className="kyc-box rounded-10">
                  <div className="p-20">
                    <p className="sub-title m-b-5">Issue Information</p>
                  </div>
                  <ColumnComponent title="Group Name" value={kycDetails?.group?.name} />
                  <ImageComponent handleSetSrc={handleSetSrc} title={" Image"} src={kycDetails?.image} name={kycDetails?.group?.name} />
                  <ColumnComponent title="Reason" value={kycDetails?.reason} />
                  <ColumnComponent title="Comment" value={kycDetails?.comment} />
                  <ColumnComponent title={CREATEDATE} value={getFormattedDate(kycDetails?.createdAt)} />
                </div>
                <div className="kyc-box rounded-10 mt-20">
                  <div className="p-20">
                    <p className="sub-title m-b-5">Personal Information</p>
                  </div>
                  <ColumnComponent title="User Name" value={kycDetails?.user?.username} />
                  <ColumnComponent title="User Email" value={kycDetails?.user?.email} />
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Modal centered isOpen={open} toggle={handleToggle}>
        <ModalHeader
          toggle={handleToggle}
          className="border-0 bg-blue text-white"
          // eslint-disable-next-line
        >
          {imgSrc.title}
        </ModalHeader>
        <div
          className="px-4 pt-2 pb-4"
          style={{
            width: "100%",
            height: 500,
            padding: 15,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden"
          }}>
          <img
            alt="document of user"
            style={{
              flexShrink: 0,
              maxWidth: "100%",
              maxHeight: "100%"
            }}
            src={imgSrc.src}
            width="auto"
            height="auto"
          />
        </div>
      </Modal>
      <Footer />
    </>
  );
};
