import { bTrackURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getIsssueAPI = (id: string): Promise<IgetIsssueAPI> => {
  return HTTPService.get(bTrackURL.issue.get + id);
};

interface IgetIsssueAPI {
  message: string;
  status: number;
  data: any;
}
