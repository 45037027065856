import { bStampURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const GetbStampLabelAPI = (): Promise<IGetbStampLabelResponse> => {
  return HTTPService.get(bStampURL.getLabel);
};

export interface ILanguageObjectbStamp {
  login: string;
  create: string;
  upload: string;
}

export interface IGetLabelObjectbStamp {
  _id: string;
  createdAt: string;
  updatedAt: string;
  DE: ILanguageObjectbStamp;
  EN: ILanguageObjectbStamp;
}

export interface IGetbStampLabelResponse {
  status: number;
  message: string;
  data: IGetLabelObjectbStamp;
}
