import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingContactUsListAPI = (params: any): Promise<IStakingContactUsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.contactUs.list, { params });
};

export interface IStakingContactUsAPIData {
  companyName: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  message: string;
  updatedAt: string;
  _id: string;
}

interface IStakingContactUsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IStakingContactUsAPIData[];
  };
}
