import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, FormFeedback, Input, Label, Row, Spinner, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { getAPPDataById } from "../../../../actions/portal/getAPPDetailsById";
import { updateAPPDetails } from "../../../../actions/portal/updateApp";
import "../../../../assets/css/editAPI.css";
import { applicationAppUrlDemoImage, applicationDescriptionDemoImage, applicationFeatureDemoImage, applicationNameDemoImage } from "../../../../assets/images/application-info";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { getBase64, handleTrim, toastSuccess } from "../../../../common/common-functions";
import { setLoading } from "../../../../store/loader/action";
import CommonButton from "../../../common/Button";
import Footer from "../../../common/Footer";
import CustomTooltip from "../../../common/ToolTip";

const cssAnimationTimeOutAnimatedList = 300;

const EditApp = ({
  location: {
    state: { appId }
  }
}: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [appLoading, setAPPLoading] = useState(true);
  const [isSaveButtionDisabled, setIsSaveButtonDisabled] = useState(true);

  const apiEditFormik = useFormik({
    initialValues: {
      ErrorDescription: "",
      ErrorTypes: [],
      HTTPstatus: [],
      appFeatures: [],
      appId: "",
      appSecretKey: "",
      app_url: "",
      createdAt: "",
      description: "",
      subtitle: "",
      desktop_url: "",
      extension_url: "",
      features: "",
      ios_url: "",
      isAvailable: "",
      isInAppList: "",
      liveUrlDomain: "",
      logo: "",
      logoIcon: "",
      name: "",
      redirect_url: "",
      shortDescription: "",
      status: "",
      suggestionApplication: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is Required"),
      features: Yup.array().of(Yup.string().required("Feature is Required")),
      logoIcon: Yup.string().required("Logo Icon is Required").nullable(),
      description: Yup.string().required("API Description is Required"),
      app_url: Yup.string().url("APP URL must be a valid URL").nullable(),
      desktop_url: Yup.string().url("Desktop URL must be a valid URL").nullable(),
      extension_url: Yup.string().url("Extension URL must be a valid URL").nullable(),
      redirect_url: Yup.string().url("Redirect URL must be a valid URL").nullable(),
      ios_url: Yup.string().url("IOS URL must be a valid URL").nullable(),
      liveUrlDomain: Yup.string().url("Live URL must be a valid URL").nullable()
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      const makeAPPData = {
        id: appId,
        ...values,
        features: JSON.stringify(values.features),
        HTTPstatus: JSON.stringify(values.HTTPstatus),
        ErrorTypes: JSON.stringify(values.ErrorTypes),
        appFeatures: JSON.stringify(values.features)
      };
      // @ts-ignore
      delete makeAPPData.document_url;
      // @ts-ignore
      delete makeAPPData.logo;
      updateAPPDetails(makeAPPData).then((result) => {
        dispatch(setLoading(false));
        toastSuccess(result.message);
        history.goBack();
      });
    }
  });
  const { setValues, values, handleSubmit, setFieldValue, errors, touched } = apiEditFormik;

  const handleGetAPpData = () => {
    getAPPDataById(appId)
      .then((response) => {
        setAPPLoading(false);
        setValues(response.data).then((res) => {
          // CONVERT STRING TO array
          setFieldValue("features", response.data.features);
          // on initial load of values let the save button be disabled
          setIsSaveButtonDisabled(true);
        });
      })
      .catch((error) => {
        setAPPLoading(false);
      });
  };

  // scroll to error components if available
  // we have created an array of references
  // we will scroll to any available error component
  const errorComponentRef = useRef<HTMLDivElement[]>([]);
  const scrollToCurrentErrorComponentOnSubmit = () => {
    errorComponentRef.current?.forEach((currentItem) => {
      currentItem?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    });
  };

  useEffect(() => {
    handleGetAPpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listen for onchange event and enable the save button
  useEffect(() => {
    setIsSaveButtonDisabled(false);
  }, [values]);

  return appId ? (
    <>
      <MetaFunction meta="Edit App" />
      <div className="holders" style={{ overflow: "auto" }}>
        <Row>
          <Col>
            <div>
              <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="right" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
                Back
              </Tooltip>
              <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
                <BackArrowComponent />
              </button>
            </div>
          </Col>
          <Col>
            {!appLoading && (
              <div className="d-flex align-items-center justify-content-end">
                <CommonButton
                  title="Save"
                  primary
                  onClick={() => {
                    scrollToCurrentErrorComponentOnSubmit();
                    handleSubmit();
                  }}
                  disabled={isSaveButtionDisabled}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-20">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <p className="sub-title m-b-5">Edit APP</p>
              </div>
              {appLoading && (
                <div className="text-center mb-3">
                  <Spinner />
                </div>
              )}
              {/**
               * @api_details
               */}
              {!appLoading && (
                <Row>
                  <Col sm="12" md="6">
                    {/* name and order and is api active */}
                    <div className="d-flex flex-wrap">
                      <div className="p-20">
                        <p className="font-18 mb-1 fw-600">
                          <span>Name </span>
                          <CustomTooltip direction="right" content={<img src={applicationNameDemoImage} alt="" className="info-image" />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </p>
                        <Input
                          type="text"
                          placeholder="Api Name"
                          name="name"
                          value={values.name}
                          onChange={(event) => {
                            handleTrim(event.target.name, event.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.name) && Boolean(touched?.name)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[0] = el)}></div>
                          {errors?.name}
                        </FormFeedback>
                      </div>
                      {/* <div className="p-20">
                        <p className="font-18 mb-1 fw-600">Order</p>
                        <Input type="number" value={values.order} onChange={(e) => setFieldValue("order", e.target.value)} />
                      </div> */}
                      <Label className="d-flex align-items-center p-20 d-none">
                        <Switch
                          height={20}
                          width={40}
                          disabled={false}
                          onColor="#007de4"
                          offColor="#b1abab"
                          checked={values.status === "active"}
                          onChange={(res) => setFieldValue("status", !res ? "in-active" : "active")}
                          uncheckedIcon
                          checkedIcon
                          className="mr-10"
                        />
                        <p className="ml-20">Active</p>
                      </Label>
                    </div>

                    {/* logo */}
                    <div className="d-flex flex-wrap">
                      <div className="p-20 d-none" style={{ maxWidth: 200 }}>
                        <p className="font-18 mb-1 fw-600 text-center">Logo</p>
                        <Label className="border-1 cursor-pointer  mt-10 logoContainer" htmlFor="apiLogo">
                          {values.logo && <img src={values.logo} alt="logo" className="w-100 h-100 p-10" />}
                        </Label>
                        <Input
                          type="file"
                          className="d-none"
                          id="apiLogo"
                          onChange={(event) => {
                            event.target.files &&
                              getBase64(event.target.files?.[0]).then((res) => {
                                setFieldValue("logo", res);
                              });
                          }}
                        />
                      </div>
                      <div className="p-20" style={{ maxWidth: 200 }}>
                        <p className="font-18 mb-1 fw-600 text-center">Icon</p>
                        <Label
                          className={`border-1 cursor-pointer mt-10 logoContainer ${Boolean(errors?.logoIcon) && Boolean(touched?.logoIcon) ? "custom-invalid border-danger" : ""}`}
                          htmlFor="apiIcon">
                          {values.logoIcon && <img src={values.logoIcon} alt="logoicon" className="w-100 h-100 p-10" />}
                        </Label>
                        <Input
                          type="file"
                          className="d-none"
                          id="apiIcon"
                          onChange={(event) => {
                            event.target.files &&
                              getBase64(event.target.files?.[0]).then((res) => {
                                setFieldValue("logoIcon", res);
                              });
                          }}
                          invalid={Boolean(errors?.logoIcon) && Boolean(touched?.logoIcon)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[2] = el)}></div>
                          {errors?.logoIcon}
                        </FormFeedback>
                      </div>
                    </div>
                  </Col>

                  {/* api features and error tyeps */}
                  <Col sm="12" md="6">
                    {/* api avaibility */}
                    <div className="p-20">
                      <p className="font-18 mb-2 fw-600">
                        APP Avaibility{" "}
                        <CustomTooltip
                          direction="right"
                          content={
                            <>
                              <p className="p-2">
                                <span className="fw-bold color-blue">Available:</span> Enabling lets customers to access the application.
                              </p>

                              <p className="p-2">
                                <span className="fw-bold color-blue">Make It Visible: </span>
                                Enabling lets customers to view the application.
                              </p>
                            </>
                          }>
                          <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                        </CustomTooltip>
                      </p>
                      <div className="d-flex flex-wrap">
                        <Label className="d-flex mt-2 mr-30 align-items-center">
                          <Switch
                            height={20}
                            width={40}
                            disabled={false}
                            onColor="#007de4"
                            offColor="#b1abab"
                            checked={Boolean(parseInt(values.isAvailable))}
                            onChange={(res) => setFieldValue("isAvailable", !res ? 0 : 1)}
                            uncheckedIcon
                            checkedIcon
                            className="mr-10"
                          />
                          <p className="ml-20">Available</p>
                        </Label>
                        <Label className="d-flex mt-2 mr-30 align-items-center">
                          <Switch
                            height={20}
                            width={40}
                            disabled={false}
                            onColor="#007de4"
                            offColor="#b1abab"
                            checked={Boolean(parseInt(values.isInAppList))}
                            onChange={(res) => setFieldValue("isInAppList", !res ? 0 : 1)}
                            uncheckedIcon
                            checkedIcon
                            className="mr-10"
                          />
                          <p className="ml-20">Make it visible</p>
                        </Label>
                        {/* <Label className="d-flex mt-2 align-items-center">
                          <Switch
                            height={20}
                            width={40}
                            disabled={false}
                            onColor="#007de4"
                            ffColor="#b1abab"
                            checked={values.suggestionApplication !== "0"}
                            onChange={(res) => setFieldValue("suggestionApplication", !res ? 0 : 1)}
                            uncheckedIcon
                            checkedIcon
                            className="mr-10"
                          />
                          <p className="ml-20">Suggestion Application</p>
                        </Label> */}
                      </div>
                    </div>

                    {/* features */}
                    <div className="p-20">
                      <div className="font-18 mb-1 d-flex justify-content-between">
                        <p className="fw-600">
                          APP Features{" "}
                          <CustomTooltip direction="right" content={<img src={applicationFeatureDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </p>{" "}
                        <button
                          className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                          onClick={() => {
                            // PARSE STRING
                            // PUSH NEW VALUE TO ARRAY
                            // CONVERT TO STRING
                            // @ts-ignore because the string is already converted to array
                            setFieldValue("features", [...values.features, ""]);
                          }}>
                          <i className="fas fa-plus" />
                        </button>
                      </div>
                      {/* features */}
                      <TransitionGroup>
                        {Array.isArray(values.features) &&
                          values.features?.map((item: string, index: number) => {
                            return (
                              <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                <div className="d-flex align-items-center mt-3">
                                  <div className="me-2 flex-fill">
                                    <Input
                                      placeholder={"feature " + (index + 1)}
                                      className="list-item-box-shadow"
                                      value={item || ""}
                                      onChange={(e) => {
                                        handleTrim(`features[${index}]`, e.target.value, setFieldValue);
                                      }}
                                      // @ts-ignore
                                      invalid={Boolean(errors?.features?.[index]) && Boolean(touched?.features?.[index])}
                                    />
                                    <FormFeedback className="fw-600">
                                      <div ref={(el: HTMLDivElement) => (errorComponentRef.current[3] = el)}></div>
                                      {errors?.features?.[index]}
                                    </FormFeedback>
                                  </div>
                                  <i
                                    className="fas fa-trash-alt text-danger cursor-pointer"
                                    onClick={() => {
                                      // @ts-ignore
                                      const modifyFeatures = [...values.features];
                                      modifyFeatures.splice(index, 1);
                                      setFieldValue("features", modifyFeatures);
                                    }}
                                  />
                                </div>
                              </CSSTransition>
                            );
                          })}
                      </TransitionGroup>
                    </div>
                  </Col>

                  <Col sm="12">
                    <hr className="border-2 border-top border-secondary" />
                  </Col>

                  {/* app description */}
                  <Row className="mb-4">
                    <Col sm="12">
                      {/* description */}
                      <div className="px-4">
                        <Label className="font-18 mb-1 fw-600">
                          APP Description{" "}
                          <CustomTooltip direction="right" content={<img src={applicationDescriptionDemoImage} alt="" className="info-image" style={{ maxWidth: 500 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </Label>
                        <Input
                          type="textarea"
                          placeholder="Detailed description"
                          name="description"
                          value={values.description}
                          onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                          invalid={Boolean(errors?.description) && Boolean(touched?.description)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[4] = el)}></div>
                          {errors?.description}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="6" className="d-none">
                      <div className="px-4">
                        <Label className="font-18 mb-1 fw-600 mt-3">
                          Short Description{" "}
                          <CustomTooltip direction="right" content={<span>Not in use right now</span>}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </Label>
                        <Input
                          type="text"
                          placeholder="Short Description"
                          name="shortDescription"
                          className=""
                          value={values.shortDescription}
                          onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6" className="d-none">
                      <div className="px-4">
                        <Label className="font-18 mb-1 fw-600 mt-3">
                          Subtitle{" "}
                          <CustomTooltip direction="right" content={<span>Not in use right now</span>}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </Label>
                        <Input type="text" name="subtitle" placeholder="Subtitle" className="" value={values.subtitle} onChange={(e) => setFieldValue("subtitle", e.target.value)} />
                      </div>
                    </Col>
                  </Row>
                  <Col sm="12">
                    <hr className="border-2 border-top border-secondary" />
                  </Col>
                  {/* app urls */}
                  <Row>
                    <Label className="font-18 fw-600 ms-4">
                      App Url's{" "}
                      <CustomTooltip direction="right" content={<img src={applicationAppUrlDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                        <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                      </CustomTooltip>
                    </Label>
                    <Col sm="12" md="6">
                      <div className="px-4">
                        <div className="mt-3">
                          <Label className="font-14">APP URL</Label>
                          <Input
                            className="mt-2"
                            name="app_url"
                            value={values.app_url}
                            placeholder="APP URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.app_url) && Boolean(touched?.app_url)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[5] = el)}></div>
                            {errors?.app_url}
                          </FormFeedback>
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Desktop URL</Label>
                          <Input
                            className="mt-2"
                            name="desktop_url"
                            value={values.desktop_url}
                            placeholder="Desktop URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.desktop_url) && Boolean(touched?.desktop_url)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[6] = el)}></div>
                            {errors?.desktop_url}
                          </FormFeedback>
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Extension URL</Label>
                          <Input
                            className="mt-2"
                            name="extension_url"
                            value={values.extension_url}
                            placeholder="Extension URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.extension_url) && Boolean(touched?.extension_url)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[7] = el)}></div>
                            {errors?.extension_url}
                          </FormFeedback>
                        </div>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="px-4">
                        <div className="mt-3">
                          <Label className="font-14">IOS APP URL</Label>
                          <Input
                            className="mt-2"
                            name="ios_url"
                            value={values.ios_url}
                            placeholder="IOS URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.ios_url) && Boolean(touched?.ios_url)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[8] = el)}></div>
                            {errors?.ios_url}
                          </FormFeedback>
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Live URL</Label>
                          <Input
                            className="mt-2"
                            name="liveUrlDomain"
                            value={values.liveUrlDomain}
                            placeholder="Live URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.liveUrlDomain) && Boolean(touched?.liveUrlDomain)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[9] = el)}></div>
                            {errors?.liveUrlDomain}
                          </FormFeedback>
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Redirect URL</Label>
                          <Input
                            className="mt-2"
                            name="redirect_url"
                            value={values.redirect_url}
                            placeholder="Redirect URL"
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.redirect_url) && Boolean(touched?.redirect_url)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[10] = el)}></div>
                            {errors?.redirect_url}
                          </FormFeedback>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Col sm="12" className="mt-4">
                    <hr className="border-2 border-top border-secondary" />
                  </Col>
                  {/* ends root here */}
                </Row>
              )}
              {!appLoading && (
                <div className="d-flex align-items-center justify-content-end p-20">
                  <CommonButton
                    title="Save"
                    primary
                    onClick={() => {
                      scrollToCurrentErrorComponentOnSubmit();
                      handleSubmit();
                    }}
                    disabled={isSaveButtionDisabled}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  ) : (
    <>No Data Found</>
  );
};

export default EditApp;
