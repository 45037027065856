import { dashboardCount } from "./type";

export const total_universe_requests_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_UNIVERSE_REQUESTS, payload };
};
export const total_templates_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_TEMPLATES, payload };
};
export const total_applications_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_APPLICATIONS, payload };
};

export const total_available_applications_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_AVAILABLE_APPLICATIONS, payload };
};

export const total_apis_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_APIS, payload };
};

export const total_available_apis_count = (payload: number) => {
  return { type: dashboardCount.TOTAL_AVAILABLE_APIS, payload };
};
