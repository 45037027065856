import { bTrackURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const listIssueAPI = (params: IlistIssueAPIParams): Promise<IlistIssueAPI> => {
  return HTTPService.get(bTrackURL.issue.list, { params });
};

interface IlistIssueAPIParams {
  filter?: any;
  search?: string;
  limit: number;
  page: number;
}

interface IlistIssueAPI {
  messsage: string;
  status: number;
  data: any;
}
