import { bTrackURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const bTrackGetGloablSettingAPI = (): Promise<IbTrackGetGloablSettingAPI> => {
  return HTTPService.get(bTrackURL.globalSetting.get);
};

interface IbTrackGetGloablSettingAPIData {
  createdAt: string;
  id: string;
  underMaintenance: boolean;
  updatedAt: string;
  _id: string;
}

interface IbTrackGetGloablSettingAPI {
  message: string;
  status: number;
  data: IbTrackGetGloablSettingAPIData;
}
