import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { Input, Label, Spinner } from "reactstrap";
import * as Yup from "yup";
import { onGroupsListAPI } from "../../../../../actions/bvote/manageGroupsAction";
import { onAddAPI, onUpdateAPI, onViewAPI } from "../../../../../actions/bvote/pollAction";
import MetaFunction from "../../../../../common/MetaTag";
import { dropDownCustomStyles, getBase64, handleTrim, toastError, toastSuccess } from "../../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../../common/constants";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import CustomTooltip from "../../../../common/ToolTip";
import SwitchGlobalSetting from "../../../SwitchGlobalSetting";
import PageHeader from "../../common/PageHeader";
import FieldComponent from "./FieldComponent";
import SwitchComponent from "./SwitchComponent";
import TextError from "./TextError";
import { maxVote, validateQuestion } from "./ValidationPoll";

const createPath = `/${projectNames.bvote}/poll/add`;

const Poll = ({ location }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [Groups, setGroups] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [module, setModule] = useState("Create Poll");
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteOptions, setDeleteOptions] = useState<any[]>([]);
  // eslint-disable-next-line
  useEffect(() => setIsEditMode(location?.state?.id ? true : false), []);

  const getGroupsListAction = () => {
    dispatch(setLoading(true));
    onGroupsListAPI().then((res: any) => {
      if (res?.status === 200) {
        let temp: Array<any> = [];
        res?.data.rows.filter((element: any) => element.status === "active").map((item: any) => temp.push({ label: `${item?.name}`, value: item?.id }));
        // @ts-ignore
        setGroups(temp);
      }
      dispatch(setLoading(false));
    });
  };

  // this function is call view api of poll *** this function call only edit page of poll
  const onFetchAction = async () => {
    setStateLoading(true);
    dispatch(setLoading(true));
    await onViewAPI(location?.state?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setData(res?.data);
          getGroupsListAction();
          setStateLoading(false);
        }
      })
      .catch((error) => history.push(`/${projectNames.bvote}/poll`));
  };

  useEffect(() => {
    if (createPath === history?.location?.pathname) {
      setModule("Create Poll");
      getGroupsListAction();
    } else {
      setModule("Edit Poll");
      onFetchAction();
    }
    // eslint-disable-next-line
  }, [module, history]);

  async function downloadCSV(hashArray: any, pollName: any) {
    return new Promise((resolve: any) => {
      const blob = new Blob([hashArray], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = pollName + ".csv";
      a.click();
      resolve();
    });
  }

  const getAddAction = (values: any) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("startDate", values.startDate);
    formData.append("endDate", values.endDate);
    formData.append("resultDate", values.resultDate);
    formData.append("isBlockchain", "1");
    formData.append("status", "active");
    formData.append("type", "public");
    formData.append("withValidation", values.Identification ? "1" : "0");
    formData.append("displayResult", values.displayResult ? "1" : "0");
    formData.append("title", values.title.trim());
    if (!values.Identification) {
      formData.append("isHash", values.isHash ? "1" : "0");
    }

    if (values.multipleQue) {
      formData.append("isCommonAnswer", values.isCommonAnswer ? "1" : "0");

      if (values.isCommonAnswer) {
        let tempArray: Array<any> = [];
        values?.questions.map((item: any) => tempArray.push({ text: item?.text }));
        formData.append("questions", JSON.stringify(tempArray));
        formData.append("commonAnswers", JSON.stringify(values.commonAnswers));
      } else {
        formData.append("questions", JSON.stringify(values.questions));
      }
    } else {
      if (values?.multipleAnswers === "single") {
        formData.append("isMcq", "0");
      } else {
        formData.append("isMcq", "1");
        formData.append("maxAnswers", values?.maxAnswers);
      }

      if (values?.isHash) {
        formData.append("noOfVoter", values?.noOfVoter);
      }

      let st: number = 1;
      // eslint-disable-next-line
      values.optionImageKeyArray.map((item: any) => {
        if (!item.file) {
          st = 0;
        }
      });

      let oid: any = [];
      if (st === 0) {
        values.optionImageKeyArray.map((item: any) =>
          oid.push({
            opTitle: item?.opTitle,
            imgkey: item.imgKey,
            description: item?.description
          })
        );
        formData.append("optionImageKeyArray", JSON.stringify(oid));
      } else {
        // eslint-disable-next-line
        values.optionImageKeyArray.map((item: any, index: number) => {
          oid.push({
            opTitle: item?.opTitle,
            imgkey: item?.imgkey,
            description: item?.description
          });
          formData.append(`images[${index}]`, item?.file);
        });
        formData.append("optionImageKeyArray", JSON.stringify(oid));
      }

      let temp: Array<any> = [];
      values.optionImageKeyArray.map((item: any) => temp.push(item.opTitle));
      formData.append("options", JSON.stringify(temp));
    }

    if (values.Identification) {
      formData.append("voterGroups", JSON.stringify(values.voterGroups));
    }

    onAddAPI(formData).then((res: any) => {
      if (res?.status === 200) {
        if (res?.data?.isHash && res?.data?.isHash === 1 && res?.data?.hashes) {
          downloadCSV(JSON.parse(res?.data?.hashes), res?.data?.title);
        }
        toastSuccess(res?.message);
      }
      history.push(`/${projectNames.bvote}/poll`);
    });
  };
  // Comment Test
  const getUpdateAction = (values: any) => {
    const formData = new FormData();
    formData.append("startDate", values.startDate);
    formData.append("endDate", values.endDate);
    formData.append("resultDate", values.resultDate);
    formData.append("isBlockchain", "1");
    formData.append("status", "active");
    formData.append("type", "public");
    formData.append("withValidation", values.Identification ? "1" : "0");
    formData.append("displayResult", values.displayResult ? "1" : "0");
    formData.append("title", values.title);
    formData.append("isHash", values.isHash ? "1" : "0");
    if (deleteOptions.length > 0) {
      formData.append("deleteOptions", JSON.stringify(deleteOptions));
    }
    if (values.multipleQue) {
      formData.append("isCommonAnswer", values.isCommonAnswer ? "1" : "0");

      if (values.isCommonAnswer) {
        let tempArray: Array<any> = [];
        values?.questions.map((item: any) => tempArray.push({ text: item?.text, id: item?.id }));
        formData.append("questions", JSON.stringify(tempArray));
        formData.append("commonAnswers", JSON.stringify(values.commonAnswers));
      } else {
        formData.append("questions", JSON.stringify(values.questions));
      }
    } else {
      if (values?.multipleAnswers === "single") {
        formData.append("isMcq", "0");
      } else {
        formData.append("isMcq", "1");
        formData.append("maxAnswers", values?.maxAnswers);
      }

      if (!values.Identification) {
        formData.append("noOfVoter", values?.noOfVoter);
      }

      // options
      let options: any[] = [];
      // eslint-disable-next-line
      values.optionImageKeyArray.map((item: any, index: number) => {
        if (data.options[index]) {
          if (
            item.opTitle !== data.options[index].text ||
            item.imgkey !== data.options[index].image ||
            (item.description !== data.options[index].description && item.description.toLowerCase() !== "null" && data.options[index].description.toLowerCase() !== "null")
          ) {
            options.push({
              text: item.opTitle,
              id: item.id,
              description: item.description
            });
          }
        } else {
          options.push({ text: item.opTitle });
        }
      });

      formData.append("options", JSON.stringify(options));

      // optionImageKeyArray
      let imageArray: any[] = [];
      values.optionImageKeyArray.forEach((element: any) => {
        if (element.file) {
          imageArray.push(element.file);
        }
      });

      if (imageArray.length > 0) {
        imageArray.forEach((image, index) => {
          formData.append(`images[${index}]`, image);
        });
      }

      if (imageArray.length > 0) {
        let optionImageKeyArray: any[] = [];
        values.optionImageKeyArray.forEach((el: any, index: number) => {
          let obj: any = {};
          // array coming from api response
          if (data.options[index]) {
            if (el.imgkey !== data.options[index].image) {
              if (el.imgkey) {
                obj["imgkey"] = el.imgkey;
              }
              if (el.description) {
                obj["description"] = el.description;
              }
              obj["opTitle"] = el.opTitle;
              if (el.id) {
                obj["id"] = el.id;
              }
            }
          } else {
            // new ones added by user
            if (el.imgkey) {
              obj["imgkey"] = el.imgkey;
              obj["opTitle"] = el.opTitle;
              obj["description"] = el.description;
            }
          }
          if (Object.keys(obj).length !== 0) {
            optionImageKeyArray.push(obj);
          }
        });
        formData.append("optionImageKeyArray", JSON.stringify(optionImageKeyArray));
      } else {
        formData.append("optionImageKeyArray", JSON.stringify([]));
      }
    }

    if (values.Identification) {
      formData.append(
        "voterGroups",
        JSON.stringify(
          values.voterGroups.map((el: any) => {
            if (el.hasOwnProperty("groupId")) {
              return el.groupId;
            } else {
              return el;
            }
          })
        )
      );
    }

    dispatch(setLoading(true));

    onUpdateAPI(location?.state?.id, formData).then((res: any) => {
      if (res?.status === 200) {
        toastSuccess(res?.message);
        history.push(`/${projectNames.bvote}/poll`);
      }
    });
  };

  const multipleAns = [
    { key: "single", value: 0 },
    { key: "multiple", value: 1 }
  ];

  const initialValues = {
    title: "",
    multipleQue: false,
    questions: [
      {
        text: "",
        answers: [{ text: "" }, { text: "" }, { text: "" }]
      },
      {
        text: "",
        answers: [{ text: "" }, { text: "" }, { text: "" }]
      }
    ],
    isCommonAnswer: true,
    imageOption: true,
    describleOption: true,
    Identification: false,
    isHash: false,
    optionsAns: ["", ""],
    optionImageKeyArray: [
      {
        opTitle: "",
        imgkey: "",
        s3FileUrl: "",
        description: "",
        image: ""
      },
      {
        opTitle: "",
        imgkey: "",
        s3FileUrl: "",
        description: "",
        image: ""
      }
    ],
    voterGroups: [],
    noOfVoter: 2,
    startDate: new Date(new Date().setSeconds(0)),
    endDate: "",
    resultDate: "",
    displayResult: true,
    multipleAnswers: "single",
    maxAnswers: 2,
    file: "",
    commonAnswers: ["YES", "NO", "NONE"],
    selectGroups: []
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(validationMessages.pollTitle.required).min(3, validationMessages.pollTitle.min).max(240, validationMessages.pollTitle.max),
    startDate: Yup.date().required(validationMessages.voteStartDate.required),
    endDate: Yup.date().required(validationMessages.voteEndDate.required).min(Yup.ref("startDate"), "The end datetime must come after the start datetime"),
    resultDate: Yup.date().required(validationMessages.resultDate.required).min(Yup.ref("endDate"), "The result datetime must come after the end datetime"),
    /* selectGroups: Yup.array()
      .of(Yup.object().shape({ label: Yup.string().required(validationMessages.group.labelRequired), value: Yup.string().required(validationMessages.group.valueRequired) }))
      .test({
        message: validationMessages.group.required,
        test: (arr = []) => {
          return arr?.length !== 0;
        }
      }) */
    selectGroups: Yup.array().when("Identification", {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required(validationMessages.group.labelRequired),
            value: Yup.string().required(validationMessages.group.valueRequired)
          })
        )
        .test({
          message: validationMessages.group.required,
          test: (arr = []) => {
            return arr?.length !== 0;
          }
        })
    })
  });

  const onSubmit = (values: any) => {
    createPath === history?.location?.pathname ? getAddAction(values) : getUpdateAction(values);
  };

  // edit data
  let isCommonAnswer = true; // by default yes,no,none
  const fetchData = {
    title: data?.title,
    multipleQue: data?.questions?.length === 0 ? false : true,
    questions:
      data?.questions?.length > 0
        ? data?.questions.map((el: any) => {
            return {
              text: el.text,
              id: el.id,
              answers: el.options.map((opEl: any) => {
                isCommonAnswer = false; // customised options
                return { text: opEl.text, id: opEl.id };
              })
            };
          })
        : [
            {
              text: "",
              answers: [{ text: "" }, { text: "" }, { text: "" }]
            },
            {
              text: "",
              answers: [{ text: "" }, { text: "" }, { text: "" }]
            }
          ],

    imageOption: true,
    isCommonAnswer,
    describleOption: true,
    optionsAns: ["", ""],

    optionImageKeyArray: data?.options?.map((el: any) => {
      return {
        opTitle: el?.text,
        imgkey: el?.image ? el?.image?.replace("https://bvoting-beta.s3.eu-central-1.amazonaws.com/option/", "") : "",
        file: "",
        s3FileUrl: el?.image,
        id: el?.id,
        image: el?.image,
        description: el?.description && el?.description?.toLowerCase() !== "null" ? el?.description : ""
      };
    }),

    startDate: new Date(data?.startDate ? data?.startDate : new Date()),
    endDate: new Date(data?.endDate ? data?.endDate : new Date()),
    resultDate: new Date(data?.resultDate ? data?.resultDate : new Date()),
    multipleAnswers: data?.isMcq === 1 ? "multiple" : "single",
    file: "",
    commonAnswers: ["YES", "NO", "NONE"],

    selectGroups: data?.voterGroups?.length > 0 ? Groups?.filter((item: any) => data?.voterGroups.map((el: any) => el.groupId)?.includes(item?.value)) : [],
    displayResult: data?.displayResult === 1 ? true : false,
    Identification: data?.withValidation === 1 ? true : false,
    voterGroups: data?.voterGroups,
    votingStarted: data?.votingStarted,
    noOfVoter: data?.hashes ? data?.hashes.length : 2,
    isMcq: data?.isMcq === 1 ? true : false,
    maxAnswers: data?.maxAnswers ? data?.maxAnswers : 2,
    isHash: data?.isHash === 1 ? true : false
  };

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (createPath !== history?.location?.pathname) {
      if (data?.isResultCalculated === 1) {
        setDisabled(true);
      } else if (data?.votingStarted === 1) {
        setDisabled(true);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          {stateLoading ? (
            <div>
              <Spinner size={"sm"} />
              <span className="ml-10">Loading...</span>
            </div>
          ) : (
            <Formik initialValues={createPath === history?.location?.pathname ? initialValues : fetchData} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
              {(formik) => {
                const { values, errors, setFieldValue, handleSubmit } = formik;

                return (
                  <Form>
                    <div className="row">
                      {/* poll title section */}
                      {disabled && (
                        <p className="font-14 color-light-grey fw-bold mb-30 mt-20">
                          As the <span className="text-danger">First Vote</span> is submitted, the <span className="text-danger">Show Live Result</span> will be the only Option that can be edited
                        </p>
                      )}
                      <FieldComponent name="title" label="Poll Title" placeholder="Poll Title" disabled={disabled} />

                      <div className="col-12 row mb-3">
                        <SwitchGlobalSetting
                          checked={values.multipleQue}
                          onChange={() => {
                            if (!isEditMode && !disabled) {
                              setFieldValue("multipleQue", !values.multipleQue);
                            }
                          }}
                          label="Multiple Questions"
                          tooltip="Enabling lets you to add multiple questions in the poll"
                          disabled={disabled}
                        />

                        {/* default answers trun on off switch section */}
                        {values.multipleQue && (
                          <div className="col-md-3  d-flex flex-column ">
                            <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                              Default Answers [ YES-NO-NONE ]
                            </Label>
                            <SwitchComponent
                              disabled={disabled}
                              checked={values.isCommonAnswer}
                              onChange={() => {
                                if (!isEditMode && !disabled) {
                                  setFieldValue("isCommonAnswer", !values.isCommonAnswer);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>

                      {/* multiple questions section  *** multiple question is ON */}
                      {values.multipleQue && (
                        <>
                          <div className="col-md-12 row">
                            <FieldArray name="questions">
                              {(fieldArrayProps) => {
                                const { push, remove, form } = fieldArrayProps;
                                const { values } = form;
                                const { questions } = values;

                                return (
                                  <div className="col-md-6 mb-30">
                                    {questions.map((questions: any, index: any) => (
                                      <React.Fragment key={index}>
                                        <div className="row mt-30 mb-10">
                                          <Label htmlFor="question" className="fw-normal color-light-grey py-2">
                                            {`Question ${index + 1}`}
                                          </Label>

                                          {/* question field */}
                                          <div className="col-md-9">
                                            <Field className="form-control" validate={validateQuestion} name={`questions[${index}].text`}>
                                              {({ field, form }: any) => (
                                                <Input
                                                  placeholder={`Question ${index + 1}...`}
                                                  disabled={disabled}
                                                  {...field}
                                                  onChange={(e) => handleTrim(e.target.name, e.target.value, form.setFieldValue)}
                                                />
                                              )}
                                            </Field>

                                            <ErrorMessage name={`questions[${index}].text`}>{(error) => <TextError error={`Question ${index + 1} is ${error}`} />}</ErrorMessage>
                                          </div>

                                          {/* remove question nutton */}
                                          {index > 0 && (
                                            <button type="button" disabled={disabled} className="btn btn-primary bg-red border-0 shadow-none  col-md-1 button-w-h-close" onClick={() => remove(index)}>
                                              X
                                            </button>
                                          )}

                                          {/* add multiple question button */}
                                          {index === 0 && (
                                            <button
                                              type="button"
                                              disabled={disabled || values.questions.length === 10}
                                              className="btn btn-primary bg-blue border-0 shadow-none col-md-3 button-w-h-add"
                                              onClick={() =>
                                                push({
                                                  text: "",
                                                  answers: [{ text: "" }]
                                                })
                                              }>
                                              Add Question
                                            </button>
                                          )}
                                        </div>
                                        {/* multiple question option section */}
                                        {!values.isCommonAnswer && (
                                          <FieldArray name={`questions[${index}].answers`}>
                                            {(fieldArrayPropss) => {
                                              const { push, remove } = fieldArrayPropss;
                                              return (
                                                <React.Fragment>
                                                  <Label htmlFor="question" className="fw-normal color-light-grey py-2">
                                                    Options
                                                  </Label>
                                                  {fieldArrayPropss.form.values.questions?.[index]?.answers.map((item: any, indexAns: number) => {
                                                    return (
                                                      <React.Fragment key={indexAns}>
                                                        <div className="row mb-3">
                                                          {/* field of option  */}
                                                          <div className="col-md-6">
                                                            <Field className="form-control" validate={validateQuestion} name={`questions[${index}].answers[${indexAns}.text]`}>
                                                              {({ field, form }: any) => (
                                                                <Input
                                                                  placeholder={`Option ${indexAns + 1}...`}
                                                                  disabled={disabled}
                                                                  {...field}
                                                                  onChange={(e) => handleTrim(e.target.name, e.target.value, form.setFieldValue)}
                                                                />
                                                              )}
                                                            </Field>

                                                            <ErrorMessage name={`questions[${index}].answers[${indexAns}.text]`}>
                                                              {(error) => <TextError error={`Option ${indexAns + 1} is ${error}`} />}
                                                            </ErrorMessage>
                                                          </div>

                                                          {/* remove option button */}
                                                          {indexAns > 0 && (
                                                            <button
                                                              disabled={disabled}
                                                              type="button"
                                                              className="btn btn-primary bg-red border-0 shadow-none  col-md-1 button-w-h-close"
                                                              onClick={() => remove(indexAns)}>
                                                              X
                                                            </button>
                                                          )}
                                                          {/* Add Option Button */}
                                                          {indexAns === 0 && (
                                                            <button
                                                              disabled={disabled || fieldArrayPropss.form.values.questions?.[index]?.answers.length === 8}
                                                              type="button"
                                                              className="btn btn-primary bg-blue border-0 shadow-none col-md-3 button-w-h-add"
                                                              onClick={() => {
                                                                push({
                                                                  text: ""
                                                                });
                                                              }}>
                                                              Add Option
                                                            </button>
                                                          )}
                                                        </div>
                                                      </React.Fragment>
                                                    );
                                                  })}
                                                </React.Fragment>
                                              );
                                            }}
                                          </FieldArray>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </>
                      )}

                      {/* multiple options section with text, image , description *** multiple question is OFF  */}
                      {!values.multipleQue && (
                        <>
                          <div className="col-12 row">
                            <SwitchGlobalSetting
                              checked={values.imageOption}
                              onChange={() => setFieldValue("imageOption", !values.imageOption)}
                              label="Option With Images"
                              tooltip="Enabling lets you to add images to an options"
                              disabled={disabled}
                            />

                            <SwitchGlobalSetting
                              checked={values.describleOption}
                              onChange={() => setFieldValue("describleOption", !values.describleOption)}
                              label="Option With Description"
                              tooltip="Enabling lets you add description for the options"
                              disabled={disabled}
                            />
                          </div>

                          <FieldArray name="optionImageKeyArray">
                            {(fieldArrayPropss) => {
                              const { push, remove, form } = fieldArrayPropss;
                              const { values } = form;
                              const { optionImageKeyArray } = values;

                              return (
                                <div className="col-md-12 my-3">
                                  <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                                    Option Answer
                                  </Label>
                                  {optionImageKeyArray.map((answer: any, index: any) => (
                                    <React.Fragment key={index}>
                                      <div className="row mb-20">
                                        <div className="col-md-4">
                                          <Field className="form-control" validate={validateQuestion} name={`optionImageKeyArray[${index}].opTitle`}>
                                            {({ field, form }: any) => (
                                              <Input
                                                disabled={disabled}
                                                placeholder={`Option ${index + 1}...`}
                                                {...field}
                                                onChange={(e) => handleTrim(e.target.name, e.target.value, form.setFieldValue)}
                                              />
                                            )}
                                          </Field>
                                          <ErrorMessage name={`optionImageKeyArray[${index}].opTitle`}>{(error) => <TextError error={`Option ${index + 1} is ${error}`} />}</ErrorMessage>
                                        </div>
                                        {values.imageOption && (
                                          <div className="col-md-1">
                                            {values.optionImageKeyArray[index].image ? (
                                              <Label className="border-1 cursor-pointer logoContainer" htmlFor={`apiLogo[${index}]`}>
                                                <img src={values.optionImageKeyArray[index].image} alt="logo" title="Click on image to select a new one" className="w-100 h-100" />
                                              </Label>
                                            ) : (
                                              <Label htmlFor={`apiLogo[${index}]`} className={`btn btn-primary  border-0 bg-blue button-w-h-add ${disabled && "disabled"}`}>
                                                Browse
                                              </Label>
                                            )}
                                            <Input
                                              type="file"
                                              className="d-none"
                                              id={`apiLogo[${index}]`}
                                              accept=".png, .jpg, .jpeg"
                                              onChange={(event: any) => {
                                                if (event.target.files.length > 0) {
                                                  let file = event.target.files[0];
                                                  if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
                                                    getBase64(file).then((res) => {
                                                      setFieldValue(`optionImageKeyArray[${index}].image`, res);
                                                    });
                                                    setFieldValue(`optionImageKeyArray[${index}].imgkey`, file.name);
                                                    setFieldValue(`optionImageKeyArray[${index}].file`, file);
                                                  } else {
                                                    toastError("Only png, jpg and jpeg files allowed!");
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        )}

                                        {/* description filed of option */}
                                        {values.describleOption && (
                                          <div className="col-md-4">
                                            <Field className="form-control" name={`optionImageKeyArray[${index}].description`}>
                                              {({ field }: any) => (
                                                <Input placeholder={"Add Description"} disabled={disabled} {...field} onChange={(e) => handleTrim(e.target.name, e.target.value, form.setFieldValue)} />
                                              )}
                                            </Field>
                                          </div>
                                        )}

                                        {/* delete option button */}
                                        {index > 1 && (
                                          <button
                                            disabled={disabled}
                                            type="button"
                                            className="btn btn-primary bg-red border-0 shadow-none  col-md-1 button-w-h-close"
                                            onClick={() => {
                                              remove(index);
                                              if (isEditMode) {
                                                setDeleteOptions([...deleteOptions, answer.id]);
                                              }
                                            }}>
                                            X
                                          </button>
                                        )}

                                        {/* add options button */}
                                        {index === 0 && (
                                          <button
                                            type="button"
                                            disabled={disabled || optionImageKeyArray.length === 8}
                                            className="btn btn-primary bg-blue border-0 shadow-none col-md-3 button-w-h-add"
                                            onClick={() => {
                                              push({
                                                opTitle: "",
                                                imgkey: "",
                                                s3FileUrl: "",
                                                description: "",
                                                image: ""
                                              });
                                            }}>
                                            Add Option
                                          </button>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              );
                            }}
                          </FieldArray>

                          <div className="col-12 row mb-3">
                            {/* option selection is single or multiple section*/}
                            <div className="col-md-2 d-flex flex-column">
                              <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                                Single / Multiple Vote
                              </Label>
                              {multipleAns.map((item: any, i: number) => {
                                return (
                                  <div className="d-flex align-items-center mb-2" key={i}>
                                    <Input
                                      type="radio"
                                      disabled={disabled}
                                      id={item?.key}
                                      name="multipleAnswers"
                                      checked={Boolean(values.multipleAnswers === item.key)}
                                      onChange={(e: any) => {
                                        setFieldValue("multipleAnswers", item.key);
                                      }}
                                    />
                                    <Label htmlFor={item?.key} className="fw-normal color-light-grey ml-10">
                                      {item?.key}
                                    </Label>
                                  </div>
                                );
                              })}
                            </div>
                            {/* Multile option selection section */}
                            {values?.multipleAnswers === "multiple" && (
                              <div className="col-md-2 d-flex flex-column">
                                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                                  Maximum Multiple Votes
                                </Label>

                                <Field name="maxAnswers" validate={(e: any) => maxVote(e, values.optionImageKeyArray.length)}>
                                  {({ field, form }: any) => (
                                    <>
                                      <Input id="maxAnswers" disabled={disabled} {...field} />
                                      <span className="font-14 text-danger">{form.errors?.maxAnswers}</span>
                                    </>
                                  )}
                                </Field>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="col-12 row mb-3">
                        {/* identifification on off sections */}
                        <div className="col-md-2 d-flex flex-column">
                          <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                            With Identification
                            <CustomTooltip direction={"right"} content={<p className="p-2">If you check, then user must have to provide his identification (Either in form of email/hash/both)</p>}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                            </CustomTooltip>
                          </Label>

                          <div title="Keeping this key on will validate the voter before submitting the poll">
                            <Input
                              type="checkbox"
                              disabled={disabled}
                              name="With Identification"
                              checked={values.Identification}
                              onChange={() => {
                                setFieldValue("isHash", false);
                                setFieldValue("Identification", !values.Identification);
                              }}
                            />
                          </div>
                        </div>

                        {!values.Identification && (
                          <>
                            <div className="col-md-2  d-flex flex-column">
                              <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                                Vote secretely with hash
                                <CustomTooltip direction={"right"} content={<p className="p-2">User must have to add the hash, to prove his identification, before he tries to vote</p>}>
                                  <i
                                    className="fas fa-info-circle cursor-pointer transiton-02s position-relative"
                                    style={{
                                      marginLeft: "4px",
                                      color: "black"
                                    }}></i>
                                </CustomTooltip>
                              </Label>
                              <Input type="checkbox" disabled={disabled} name="Vote secretly with hash" checked={values.isHash} onChange={() => setFieldValue("isHash", !values.isHash)} />
                            </div>
                          </>
                        )}
                      </div>

                      {/* group select section with identification is on */}
                      {values.Identification && (
                        <div className="col-md-12 row mb-3">
                          <div className="col-md-6 pr-0">
                            <Label htmlFor="group" className="fw-normal color-light-grey py-2">
                              Select Group
                            </Label>
                            {/* @ts-ignore */}
                            <Select
                              isMulti
                              isDisabled={disabled}
                              placeholder="Select Group"
                              options={Groups}
                              styles={dropDownCustomStyles}
                              value={values.selectGroups}
                              onChange={(e) => {
                                let temp: any = [];
                                e.map((item: any) => temp.push(item.value));
                                setFieldValue("voterGroups", temp);
                                setFieldValue("selectGroups", e);
                              }}
                            />
                          </div>

                          {errors?.selectGroups ? <span className="text-danger font-14">{errors?.selectGroups}</span> : null}
                        </div>
                      )}

                      {/* number of voters with hash key */}
                      {values.isHash && (
                        <div className="col-md-12 row mb-3">
                          <FieldComponent name="noOfVoter" label="Number Of Voters" placeholder="Number Of Voters" disabled={createPath !== history?.location?.pathname} />
                        </div>
                      )}

                      {/* voting starting date */}
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="voteStartDate" className="fw-normal color-light-grey py-2">
                          Voting Start Date | Time *
                        </Label>
                        {/* <Datetime
                          dateFormat="DD/MM/YYYY"
                          onChange={(e: any) => {
                            setFieldValue("startDate", e._d);
                            setFieldValue("endDate", "");
                            setFieldValue("resultDate", "");
                          }}
                          value={values.startDate}
                          inputProps={{
                            placeholder: "Voting Start Date | Time",
                            disabled: disabled
                          }}
                          isValidDate={isStartDateValid}
                        /> */}
                        <label className={"position-relative w-100 "}>
                          <i className="fa fa-calendar position-absolute calendar-icon"></i>
                          <ReactDatePicker
                            // @ts-ignore
                            selected={values.startDate}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            id="startDate"
                            name="startDate"
                            onChange={(e: any) => {
                              setFieldValue("startDate", e);
                              setFieldValue("endDate", "");
                              setFieldValue("resultDate", "");
                            }}
                            showTimeSelect
                            placeholderText="Voting Start Date | Time"
                            className="form-control"
                            minDate={new Date()}
                          />
                        </label>
                        <ErrorMessage name="startDate">{(msg) => <div style={{ color: "#F64E60", fontSize: "12px" }}>{msg}</div>}</ErrorMessage>
                      </div>

                      {/* voting end date */}
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="voteEndDate" className="fw-normal color-light-grey py-2">
                          Voting End Date / Time *
                        </Label>
                        {/* <Datetime
                          onChange={(e: any) => {
                            setFieldValue("endDate", calculateEndDate(values.startDate, e._d));
                            setFieldValue("resultDate", "");
                          }}
                          inputProps={{
                            placeholder: "Voting End Date / Time",
                            disabled: disabled,
                            value: values.endDate === "" ? "" : moment(values.endDate).format("DD/MM/YYYY hh:mm A")
                          }}
                          isValidDate={(e) => isEndDateValid(e, values.startDate)}
                        /> */}
                        <label className={"position-relative w-100 "}>
                          <i className="fa fa-calendar position-absolute calendar-icon"></i>
                          <ReactDatePicker
                            // @ts-ignore
                            selected={values.endDate}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            id="endDate"
                            name="endDate"
                            onChange={(e: any) => {
                              setFieldValue("endDate", e);
                              setFieldValue("resultDate", "");
                            }}
                            showTimeSelect
                            placeholderText="Voting End Date / Time"
                            className="form-control"
                            minDate={new Date(new Date(values.startDate).getTime() + 24 * 60 * 60 * 1000)}
                          />
                        </label>
                        <ErrorMessage name="endDate">{(msg) => <div style={{ color: "#F64E60", fontSize: "12px" }}>{msg}</div>}</ErrorMessage>
                      </div>

                      {/* result Date section */}
                      <div className="col-md-6 mb-3">
                        <Label htmlFor="resultDate" className="fw-normal color-light-grey py-2">
                          Result Date / Time *
                        </Label>
                        {/* <Datetime
                          onChange={(e: any) => {
                            setFieldValue("resultDate", calculateResultDate(values.endDate, e?._d));
                          }}
                          inputProps={{
                            placeholder: "Result Date / Time",
                            disabled: disabled,
                            value: values.resultDate === "" ? "" : moment(values.resultDate).format("DD/MM/YYYY hh:mm A")
                          }}
                          isValidDate={(e) => isResultDateValid(e, values.endDate)}
                        /> */}
                        <label className={"position-relative w-100 "}>
                          <i className="fa fa-calendar position-absolute calendar-icon"></i>

                          <ReactDatePicker
                            // @ts-ignore
                            selected={values.resultDate}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            id="resultDate"
                            name="resultDate"
                            onChange={(e: any) => {
                              setFieldValue("resultDate", e);
                            }}
                            showTimeSelect
                            placeholderText="Result Date / Time"
                            className="form-control"
                            minDate={new Date(new Date(values.endDate ? values.endDate : new Date()).getTime() + 24 * 60 * 60 * 1000)}
                          />
                        </label>
                        <ErrorMessage name="resultDate">{(msg) => <div style={{ color: "#F64E60", fontSize: "12px" }}>{msg}</div>}</ErrorMessage>
                      </div>

                      {/* switch of live result is display are not  */}
                      <div className="col-md-6 d-flex flex-column mb-3">
                        <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                          Show Live Result
                        </Label>
                        <div title="Keeping this key on will show the poll result before its result date">
                          <SwitchComponent checked={values.displayResult} onChange={() => setFieldValue("displayResult", !values.displayResult)} />
                        </div>
                      </div>

                      {/* form end footer title */}
                      <div className="col-md-12 mt-20 py-10 mx-3 mb-3" style={{ borderBottom: "1px #0000001a solid" }}>
                        <p className="fw-normal color-light-grey fw-bold">Poll stores on blockchain</p>
                      </div>

                      <div className="col-md-12 mt-20 mb-3">
                        {/* @ts-ignore */}
                        <button type="button" disabled={disabled || !formik.isValid || !formik.dirty} onClick={handleSubmit} className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Poll;
