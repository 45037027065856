import { dashboardCount } from "./type";

const initialState: IdasbordCountReducers = {
  totalUniverseRequests: 0,
  totalTemplates: 0,
  totalApplications: 0,
  totalAvailableApplications: 0,
  totalApis: 0,
  totalAvailableApis: 0
};

const dasbordCountReducers = (state = initialState, action: { type: string; payload: any }) => {
  let newState = { ...state };

  switch (action.type) {
    case dashboardCount.TOTAL_UNIVERSE_REQUESTS:
      newState.totalUniverseRequests = action.payload;
      break;
    case dashboardCount.TOTAL_TEMPLATES:
      newState.totalTemplates = action.payload;
      break;
    case dashboardCount.TOTAL_APPLICATIONS:
      newState.totalApplications = action.payload;

      break;
    case dashboardCount.TOTAL_AVAILABLE_APPLICATIONS:
      newState.totalAvailableApplications = action.payload;
      break;
    case dashboardCount.TOTAL_APIS:
      newState.totalApis = action.payload;
      break;
    case dashboardCount.TOTAL_AVAILABLE_APIS:
      newState.totalAvailableApis = action.payload;
      break;
  }
  return newState;
};

export default dasbordCountReducers;

export interface IdasbordCountReducers {
  totalUniverseRequests: number;
  totalTemplates: number;
  totalApplications: number;
  totalAvailableApplications: number;
  totalApis: number;
  totalAvailableApis: number;
}
