import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAppOrder } from "../../../../../actions/portal/adminActions";
import { toastSuccess } from "../../../../../common/common-functions";
import { setLoading } from "../../../../../store/loader/action";

import AppShortcutsObjectComponent from "./AppShortcutsObjectComponent";

const AppshortcutsDragAndDrop: FC<any> = ({ blockChainObject, setBlockChainData }) => {
  const dispatch = useDispatch();

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const [display, setDisplay] = useState(false);

  const dragStart = (e: any, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    if (dragItem.current === dragOverItem.current) {
      dragItem.current = null;
      dragOverItem.current = null;
      return;
    }
    const copyListItems = [...blockChainObject];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setBlockChainData(copyListItems);
    setDisplay(true);
  };

  const handleSave = () => {
    dispatch(setLoading(true));
    let rank = blockChainObject?.map((data: any, index: number) => {
      data.order = index + 1;
      return { order: data.order, gateway: data.gateway, id: data.id };
    });
    updateAppOrder({ rank }).then((res: any) => {
      if (res.status === 200) {
        toastSuccess(res.message);
        setDisplay(false);
      }
      dispatch(setLoading(false));
    });
  };

  return (
    <>
      {blockChainObject &&
        blockChainObject?.map((item: any, index: number) => (
          <div
            className="row mb-4 align-items-center block-chain-object cursor-pointer"
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            onDragOver={(e) => e.preventDefault()}
            draggable>
            <AppShortcutsObjectComponent item={item} setBlockChainData={setBlockChainData} />
          </div>
        ))}
      {display && (
        //  @ts-ignore
        // <EditRestricted module={projectModules.nft.BLOCK_CHAIN}>
        <div className="d-flex flex-column justify-content-end">
          <h6 className="mb-4 text-gray font-14 blockchainNote">*Ordering of the apps will be saved when you click on save.</h6>
          <button className="btn-sm text-white btn-primary bg-blue rounded-5 py-2 px-3 font-15 border-none" onClick={() => handleSave()}>
            Save
          </button>
        </div>

        // </EditRestricted>
      )}
    </>
  );
};

export default AppshortcutsDragAndDrop;
