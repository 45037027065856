import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBstampDashboardAction } from "../../../actions/bStamp/dashboard/getDashboardAPI";
import MetaFunction from "../../../common/MetaTag";
import { projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import TitleDashboard from "../bvote/TitleDashboard";

const BStampDashboard = () => {
  const moduleName = "Dashboard";
  const dispatch = useDispatch();
  const [state, setState] = useState({
    unsignedStamps: 0,
    signedStamps: 0,
    totalPayment: 0
  });
  useEffect(() => {
    dispatch(setLoading(true));
    getBstampDashboardAction().then((res) => {
      if (res.status === 200) {
        setState(res.data);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch]);
  const { unsignedStamps, signedStamps, totalPayment } = state;
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={moduleName} />
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">{moduleName}</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard
            title="Normal Stamps"
            count={unsignedStamps}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bstamp, projectModules[projectNames.bstamp]?.DASHBOARD, "")}
          />
          <TitleDashboard
            title="Watermarked Stamps"
            count={signedStamps}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bstamp, projectModules[projectNames.bstamp]?.DASHBOARD, "")}
          />
          <TitleDashboard
            title="Total Payment"
            count={totalPayment}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bstamp, projectModules[projectNames.bstamp]?.DASHBOARD, "")}
          />
        </div>
      </div>
    </>
  );
};

export default BStampDashboard;
