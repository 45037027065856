import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { Form, Label } from "reactstrap";
import * as Yup from "yup";
import { getDomainsList } from "../../../actions/portal/adminActions";
import { getDetailOfUser, updateUser } from "../../../actions/portal/byouActions";
import { toastSuccess } from "../../../common/common-functions";
import { ReactSelectCustomstyle, initialPaginationConfig, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import CustomTooltip from "../../common/ToolTip";
import PageHeader from "../bvote/common/PageHeader";

const EditUserManagement = () => {
  interface IdropdownUniqueDomains {
    value: string;
    label: string;
  }

  const params: any = useParams();

  const history = useHistory();
  const [domainList, setDomainList] = useState<any>([]);
  const [isDropdownValueChanged, setIsDropdownValueChanged] = useState(false);
  // eslint-disable-next-line
  const [getData, setData] = useState<any>();
  // eslint-disable-next-line
  const [UserTypeOptionss, setUserTypeOptionss] = useState([]);
  // eslint-disable-next-line
  const [networkDomainId, setNetworkDomainId] = useState();
  const [getRoleInfo, setGetRoleInfo] = useState();

  const UserTypeOptions = [
    { value: "user", label: "User/Holder" },
    { value: "issuer", label: "Issuer" },
    { value: "verifier", label: "Verifier" }
  ];
  // eslint-disable-next-line
  const [uniqueDomainDropdownOptions, setUniqueDomainDropdownOptions] =
    useState();

  const [isAvailableValue, setIsAvailableValue] = useState<string>();
  // eslint-disable-next-line
  const [typeOfRole, setTypeOfRole] = useState({ label: "" });
  const [isAvailableDropdown, setIsAvailableDropdown] = useState<any>();
  // eslint-disable-next-line
  const [storeIsAvailable, setStoreIsAvailable] = useState();
  // eslint-disable-next-line
  const [storeFile, setStoreFile] = useState();

  useEffect(() => {
    const options: IdropdownUniqueDomains[] = domainList?.map((ele: any) => ({
      value: ele?.networkDomainId,
      label: ele?.name
    }));

    const optionsLabelWise = domainList?.map((ele: any) => ({
      value: ele?.id,
      label: ele?.name
    }));
    const key = "label";
    // @ts-ignore
    setUniqueDomainDropdownOptions([...new Map(optionsLabelWise?.map((item) => [item[key], item])).values()]);

    setUserTypeOptionss(options as unknown as []);
  }, [domainList]);

  // eslint-disable-next-line
  const [module, setModule] = useState("Edit User");
  const handleFil = (data: any) => {
    if (data?.label === "User/Holder") {
      setIsAvailableValue("user");
    } else if (data?.label === "Issuer") {
      setIsAvailableValue("issuer");
    } else if (data?.label === "Verifier") {
      setIsAvailableValue("verifier");
    }
  };

  const dropdownChangeOfVisibility = () => {
    if (isAvailableValue) {
      if (isAvailableValue === "user") {
        return { value: "user", label: "User" };
      } else if (isAvailableValue === "issuer") {
        return { value: "issuer", label: "Issuer" };
      } else if (isAvailableValue === "verifier") {
        return { value: "verifier", label: "Verifier" };
      }
    }
  };

  const availabilityDropdownFunction = (data: string) => {
    if (data === "user") {
      return { value: "user", label: "User" };
    } else if (data === "issuer") {
      return { value: "issuer", label: "Issuer" };
    } else if (data === "verifier") {
      return { value: "verifier", label: "Verifier" };
    }
  };

  const groupFormik = useFormik({
    initialValues: {
      role: ""
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {
      dispatch(setLoading(true));
      let parameter: any = { id: params.id };

      parameter.role = isAvailableValue || storeIsAvailable;

      updateUser(parameter).then((res) => {
        if (res.status === 200) {
          history.push(`/${projectNames.byou}/user-management`);
          toastSuccess(res.message);
          dispatch(setLoading(false));
        }
      });
    }
  });

  const { handleSubmit, setFieldValue } = groupFormik;

  const initialFIlters = {
    ...initialPaginationConfig
  };
  // eslint-disable-next-line
  const [searchField, setSearchField] = useState("");
  // eslint-disable-next-line
  const [paginationConfig, setPaginationConfig] = useState(initialFIlters);
  const dispatch = useDispatch();

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    // eslint-disable-next-line
    const { search, filter, limit, page } = paginationConfig;

    dispatch(setLoading(false));
  };

  useEffect(() => {
    getCategoryManagementListFromApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    getDetailOfUser(params.id).then((response) => {
      dispatch(setLoading(false));
      setFieldValue("firstName", response?.data?.firstName);
      setFieldValue("lastName", response?.data?.lastName);
      setFieldValue("email", response?.data?.email);
      setFieldValue("role", response?.data?.role);
      setGetRoleInfo(response?.data?.role);

      setData(response?.data);
      setFieldValue("profilePic", response?.data?.profilePic);
      setStoreFile(response?.data?.profilePic);
      let availabilityValue = availabilityDropdownFunction(response?.data?.role);
      setIsAvailableDropdown(availabilityValue);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getDomainListFromApi = () => {
    dispatch(setLoading(true));
    // eslint-disable-next-line
    const { search, filter, limit, page } = paginationConfig;

    getDomainsList(
      searchField && filter && limit && page
        ? { search: searchField, filter, limit, page }
        : !searchField && filter && limit && page
          ? { filter, limit, page }
          : searchField && !filter && limit && page
            ? { search: searchField, limit, page }
            : !searchField && !filter && limit && page && { limit, page }
    ).then((res) => {
      setDomainList(res.data.data);
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getDomainListFromApi();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="holders">
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-3">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Type of Role
                  <CustomTooltip direction={"right"} content={<p className="p-2">Choose the role, which you want to assign to user, he can access only assigned role portal</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                  </CustomTooltip>
                </Label>
                <Select
                  placeholder="Status"
                  onChange={(e) => {
                    setIsDropdownValueChanged(true);
                    handleFil(e);
                  }}
                  options={UserTypeOptions}
                  className="me-2 mb-2 z-index"
                  styles={ReactSelectCustomstyle}
                  value={isDropdownValueChanged ? dropdownChangeOfVisibility() : isAvailableDropdown}
                />
              </div>

              <div className="col-md-12 mt-20">
                <div className="col-md-12 mt-20">
                  {/* @ts-ignore */}
                  <button
                    type="submit"
                    disabled={getRoleInfo === isAvailableValue ? true : isAvailableValue === undefined ? true : false}
                    className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditUserManagement;
