import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getReqestDemoListingAPI } from "../../../../actions/nft/requestDemo/getRequestDemoListingAPI";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import SearchFilter from "../../banji/component/SearchFilter";
import { BvoteTableComponent } from "../common/ListTable";

const tableHeaders = ["Full Name", "Email", "Reference Name", "Status", CREATEDATE, "Actions"];

const NftManageRequestDemo = () => {
  const module = "Request Demo List";
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState<paginationObject>({
    page: 1,
    limit: 10,
    search: ""
  });

  useEffect(() => {
    getNftManageRequestActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const getNftManageRequestActions = () => {
    dispatch(setLoading(true));
    let paginationConfigTemp: any = {};
    if (search) {
      paginationConfigTemp = paginationConfig;
    } else {
      paginationConfigTemp.page = paginationConfig.page;
      // eslint-disable-next-line no-self-assign
      paginationConfigTemp.limit = paginationConfig.limit;
    }
    getReqestDemoListingAPI(paginationConfigTemp).then((res: any) => {
      if (res?.status === 200) {
        setTotalListCount(res.count);
        setListingData(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  const resetFilters = () => {
    if (search === "" && paginationConfig?.search === "") {
    } else {
      setSearch("");
      setPaginationConfig({ page: 1, limit: 10, search: "" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter placeHolder="Search By Reference/Full Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
              </div>
              {/* @ts-ignore */}
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"requestDemo"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getNftManageRequestActions}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default NftManageRequestDemo;

interface paginationObject {
  search?: string;
  page: number;
  limit: number;
}
