import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IbMesssageDashboardAPIData, bMesssageDashboardAPI } from "../../../actions/bMessage/bMesssageDashboardAPI";
import MetaFunction from "../../../common/MetaTag";
import { projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import Footer from "../../common/Footer";
import TitleDashboard from "../bvote/TitleDashboard";

const BMessageDashboard = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState<IbMesssageDashboardAPIData>({
    activeUsers: 0,
    publicMessages: 0,
    totalMessages: 0
  });
  const getDashboardAction = () => {
    dispatch(setLoading(true));
    bMesssageDashboardAPI().then((res) => {
      if (res.status === 200) {
        setCount({
          activeUsers: res.data.activeUsers,
          publicMessages: res.data.publicMessages,
          totalMessages: res.data.totalMessages
        });
      }
      dispatch(setLoading(false));
    });
  };
  useEffect(() => {
    getDashboardAction();
    // eslint-disable-next-line
  }, []);
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={"Dashboard"} />
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Dashboard</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard
            width="32.5%"
            title="Active Users"
            count={count.activeUsers > 999 ? "999+" : count.activeUsers}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.bmessage, projectModules[projectNames.bmessage]?.DASHBOARD, "")}
          />
          <TitleDashboard
            width="32.5%"
            title="Public Users Messages"
            count={count.publicMessages > 999 ? "999+" : count.publicMessages}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.bmessage, projectModules[projectNames.bmessage]?.DASHBOARD, "")}
          />
          <TitleDashboard
            width="32.5%"
            title="edeXa Users Messages"
            count={count.totalMessages > 999 ? "999+" : count.totalMessages}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.bmessage, projectModules[projectNames.bmessage]?.DASHBOARD, "")}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BMessageDashboard;
