import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";

const UserTypeOptions = [
  { value: "0", label: "Not-Available" },
  { value: "1", label: "Available" },
  { value: "2", label: "Coming Soon" },
  { value: "3", label: "Reuqest Demo" }
];

interface IAvailabilityComponent {
  values: null | number;
  setFieldValue: any;
  error: string | undefined;
  isError: boolean;
}

interface ISelected {
  value: string;
  label: string;
}

const AvailabilityComponent: FC<IAvailabilityComponent> = ({ values, setFieldValue, error, isError }) => {
  const [selectd, setSelectd] = useState<ISelected | null>(null);

  const handleChange = (e: any) => {
    setSelectd(e);
    setFieldValue("isAvailable", Number(e.value));
  };

  useEffect(() => {
    if (values === 0 || values) {
      if (String(values) !== selectd?.value) {
        let newValue = UserTypeOptions.find((data) => data.value === String(values));
        if (newValue) {
          setSelectd(newValue);
        }
      }
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <>
      <Label for="biglogo" className="mt-3">
        Availability of App <span className="text-red">*</span>
      </Label>
      <Select placeholder="Availability of App" options={UserTypeOptions} onChange={handleChange} className={`mt-1 z-index ovrlp  ${isError ? (error ? "parent-app" : "") : ""}`} value={selectd} />
      {isError ? error ? <p className="text-danger">{error}</p> : null : null}
    </>
  );
};

export default AvailabilityComponent;
