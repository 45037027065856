import { Label } from "reactstrap";
const LabelSt = () => {
  return (
    <Label htmlFor="title" className="fw-normal color-light-grey py-2">
      Poll Title
    </Label>
  );
};

export default LabelSt;
