import { useDispatch, useSelector } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import { moduleDropdown } from "../../common/common-functions";

import { AllEdexaAdminRoutes } from "../../common/constants";
import { toggleSidebar } from "../../store/sidebar/action";
import ViewRestricted from "./restrictions/ViewRestricted";
export const SidebarComponent = () => {
  const dispatch = useDispatch();

  const { isSidebarOpen } = useSelector((state: any) => state.sidebarToggle);
  const { currentProject } = useSelector((state: any) => state.projectNameReducer);

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar(!isSidebarOpen));
  };

  const isSubModuleExist = (moduleName: string) => {
    if (moduleName?.includes("Smart Contracts")) {
      return true;
    }

    if (moduleName?.includes("Customer Request")) {
      return true;
    }
    if (moduleName?.includes("News Management")) {
      return true;
    }

    if (moduleName?.includes("Channel Management")) {
      return true;
    }

    if (moduleName?.includes("Apps & API")) {
      return true;
    }

    if (moduleName?.includes("Domains & Regions")) {
      return true;
    }
  };

  return (
    <>
      <div className={`sidebar transiton-02s ${isSidebarOpen === true ? "show" : "hide"}`}>
        <div className="sidebar-bg-layer d-xl-none d-block" onClick={handleSidebarToggle}></div>
        <div className="sidebar-main">
          {AllEdexaAdminRoutes[currentProject].map((route: any, index: number) => {
            const subModuleExist = isSubModuleExist(route?.label);
            /**
             * @Restricted_sidebar_modules
             * 1. We will have to check which module permission are we sending
             * 2. It can be something like this - userPermissions?.[currentProject].APIManagement.view
             */
            return (
              // @ts-ignore
              <ViewRestricted module={route?.module} key={index}>
                {!isSidebarOpen ? (
                  <Link
                    to={route.path}
                    key={index}
                    title={route?.label}
                    className="sidebar-menu color-theme active-color-white d-flex flex-wrap align-items-center position-relative"
                    activeClassName="menu-active color-white">
                    {route.icon}
                    <span className="menu-text"> {isSidebarOpen === true ? route.label : ""}</span>
                  </Link>
                ) : (
                  <Link
                    to={route.path}
                    key={index}
                    className={`${
                      subModuleExist
                        ? "color-theme active-color-white d-flex flex-wrap align-items-center position-relative"
                        : "sidebar-menu color-theme active-color-white d-flex flex-wrap align-items-center position-relative"
                    }`}
                    activeClassName="menu-active color-white">
                    {!subModuleExist && route?.icon}
                    <span className="menu-text">
                      {isSidebarOpen === true ? (subModuleExist ? moduleDropdown(route?.label, route?.icon, "", isSidebarOpen, route, subModuleExist) : route?.label) : ""}
                    </span>
                  </Link>
                )}
              </ViewRestricted>
            );
          })}
        </div>
      </div>
    </>
  );
};
