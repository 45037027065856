import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlockChainDataAPI } from "../../../../actions/nft/blockChain/getBlockChainData";
import MetaFunction from "../../../../common/MetaTag";
import { projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import Footer from "../../../common/Footer";
import { EditRestricted } from "../../../common/restrictions";
import BlockChainDragAndDrop from "./BlockChainDragAndDrop";

const BlockChainListComponent = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: IRootReducerTypes) => state.loading);

  const [blockChainData, setBlockChainData] = useState([]);

  // eslint-disable-next-line
  const getBlockChainData = () => {
    dispatch(setLoading(true));
    getBlockChainDataAPI().then((res) => {
      if (res.status === 200) {
        setBlockChainData(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getBlockChainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaFunction meta="Blockchain" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Blockchain</p>
        <div className="p-20 rounded-10 bg-white blockchain-main-section">
          <div className="row mb-4 block-chain-header">
            <div className="col-4 text-break">Blockchain Name</div>
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames.nft].BLOCK_CHAIN}>
              <div className="col-3 text-break">Make it visible</div>
              <div className="col-2 text-break">Available</div>
              <div className="col-3 text-break">Coming soon</div>
            </EditRestricted>
          </div>
          {blockChainData.length === 0 ? (
            isLoading ? (
              <div className="p-1">Loading...</div>
            ) : (
              <div className="p-1">No Blockchain Module Available</div>
            )
          ) : (
            <BlockChainDragAndDrop blockChainObject={blockChainData} setBlockChainData={setBlockChainData} />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlockChainListComponent;
