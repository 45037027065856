import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { getDahboardInfo, getMonthlyRequestChartInfo, getUniversalList } from "../../../actions/portal/adminActions";
import MetaFunction from "../../../common/MetaTag";
import { getKyCStatus, handleName } from "../../../common/common-functions";
import { projectModules, projectNames } from "../../../common/constants";
import {
  total_apis_count,
  total_applications_count,
  total_available_apis_count,
  total_available_applications_count,
  total_templates_count,
  total_universe_requests_count
} from "../../../store/dashboardCount/action";
import { setLoading } from "../../../store/loader/action";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import Footer from "../../common/Footer";
import { ViewRestricted } from "../../common/restrictions";
import TitleDashboard from "../bvote/TitleDashboard";
import MonthlyRequestChart from "./monthlyRequestsChart/MonthlyRequestChart";

const UniverseDashboard = () => {
  const { totalUniverseRequests } = useSelector((state: any) => state.dasbordCountReducers);
  const { totalTemplates } = useSelector((state: any) => state.dasbordCountReducers);
  const { totalApplications } = useSelector((state: any) => state.dasbordCountReducers);
  const { totalApis } = useSelector((state: any) => state.dasbordCountReducers);
  const availableApplications = useSelector((state: any) => state.dasbordCountReducers.totalAvailableApplications);
  const availableApis = useSelector((state: any) => state.dasbordCountReducers.totalAvailableApis);
  const history = useHistory();

  const [chartInfo, setChartInfo] = useState([]);
  const { isLoading } = useSelector((state: any) => ({
    kycRejectModalOpenStatus: state.loading.kycRejectModalOpenStatus,
    isLoading: state.loading.isLoading
  }));
  // eslint-disable-next-line
  const [requestNames, setRequestNames] = useState([]);
  const [universe, setUniverse] = useState([]);
  let universemanagement: any = useSelector((state: any) => state.permissionsReducer.permissions.admin.universemanagement);

  const sendDataToApi = () => {
    let data: any = { limit: 10, search: "", page: 1, filter: 0 };
    if (data.search) {
      return data;
    } else {
      return { limit: 10, page: 1, filter: 0 };
    }
  };

  const dispatch = useDispatch();
  const getUniversalListing = () => {
    dispatch(setLoading(true));
    getUniversalList(sendDataToApi()).then((res) => {
      dispatch(setLoading(false));
      const { data } = res;
      setUniverse(data.data);
    });
  };

  useEffect(() => {
    universemanagement?.view && getUniversalListing();
    //eslint-disable-next-line
  }, []);

  const getMonthlyChartInfoFunc = () => {
    dispatch(setLoading(true));
    // eslint-disable-next-line

    setTimeout(() => {
      getMonthlyRequestChartInfo().then((res) => {
        setChartInfo(res?.data?.charts);
        setRequestNames(res?.data?.ylabel);
        dispatch(setLoading(false));
      });
    }, 0);
    dispatch(setLoading(false));
  };

  const getDahboardInfoFunc = () => {
    dispatch(setLoading(true));
    // eslint-disable-next-line

    setTimeout(() => {
      getDahboardInfo().then((res) => {
        dispatch(total_apis_count(res?.data?.api));
        dispatch(total_universe_requests_count(res?.data?.network));
        dispatch(total_applications_count(res?.data?.applicaiton));
        dispatch(total_templates_count(res?.data?.template));
        dispatch(total_available_applications_count(res?.data?.availableApplications));
        dispatch(total_available_apis_count(res?.data?.availableApi));
      });
    }, 0);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getMonthlyChartInfoFunc();
    getDahboardInfoFunc();
    // eslint-disable-next-line
  }, []);
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={"Dashboard"} />
      <div className="dashboard pb-0">
        <p className="main-title m-b-20">Dashboard</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard
            width="32.5%"
            title="Total Universe Requests"
            count={totalUniverseRequests > 100 ? "100+" : totalUniverseRequests}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].UNIVERSAL_MANAGEMENT, `/${projectNames.universe}/universe-request`, true)}
          />
          <TitleDashboard
            width="32.5%"
            title="Total Templates"
            count={totalTemplates}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].TEMPLATE_LISTING, `/${projectNames.universe}/templates`, true)}
          />
          <TitleDashboard
            width="32.5%"
            title="Total Applications"
            count={totalApplications}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].APPLICATION_MANAGEMENT, `/${projectNames.universe}/application-management`, true)}
          />
          <TitleDashboard
            marginTop="20px"
            width="32.5%"
            title="Total Available Applications"
            count={availableApplications ? availableApplications : 0}
            cursor="pointer"
            onClick={() =>
              handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].APPLICATION_MANAGEMENT, `/${projectNames.universe}/application-management?filter=available`, true)
            }
          />
          <TitleDashboard
            marginTop="20px"
            width="32.5%"
            title="Total Apis"
            count={totalApis}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].API_MANAGEMENT, `/${projectNames.universe}/api-management`, true)}
          />
          <TitleDashboard
            marginTop="20px"
            width="32.5%"
            title="Total Available Api's"
            count={availableApis ? availableApis : 0}
            cursor="pointer"
            onClick={() => handleRedireactClick(projectNames.universe, projectModules[projectNames.universe].API_MANAGEMENT, `/${projectNames.universe}/api-management`, true)}
          />
        </div>
      </div>
      <div className="dashboard pb-0">
        {/* @ts-ignore */}
        <ViewRestricted module={projectModules[projectNames.admin].UNIVERSAL_MANAGEMENT}>
          <div className="dashboard-data admin-dashboard2">
            <div className="w-100 list-box rounded-10">
              <div className="d-flex justify-content-between align-items-center">
                <p className="color-black1 font-20 fw-bold mb-20">Recent Universe Requests</p>
                <span className="blue font-14 cursor-pointer fw-bold" onClick={() => history.push(`/${projectNames.universe}/universe-request`)}>
                  View All Universe
                </span>
              </div>
              <Scrollbars
                style={{ height: 480 }}
                renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
                renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                className="table-section border-grey">
                <Table>
                  <thead>
                    <tr>
                      <th className="color-black1 font-14 fw-bold">Name</th>
                      <th className="color-black1 font-14 fw-bold">Email</th>
                      <th className="color-black1 font-14 fw-bold">Status</th>
                      <th className="color-black1 font-14 fw-bold text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {universe && universe?.length > 0 ? (
                      universe?.map((list: any, index: any, i: any) => (
                        <tr key={list?.id}>
                          <td className="font-16 color-light-grey">
                            {`${list?.userInfo?.first_name ? list?.userInfo?.first_name + " " + list?.userInfo?.last_name : handleName(list?.userInfo?.email)}`}
                          </td>
                          <td className="font-16 color-light-grey">{list?.userInfo?.email}</td>
                          <td className="font-16 color-light-grey pl-35 position-relative status-data">
                            <span className={`status ${getKyCStatus(list.status).class}`}></span>
                            {getKyCStatus(list.status).label}
                          </td>
                          <td className="font-14 blue text-center">
                            <span style={{ cursor: "pointer", fontWeight: "700" }} onClick={() => history.push(`/${projectNames.universe}/universe-request/${list.id}`)}>
                              View Details
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td>No Recent Records Found</td>}</tr>
                    )}
                  </tbody>
                </Table>
              </Scrollbars>
            </div>
          </div>
        </ViewRestricted>
      </div>
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-40">Monthly Requests</p>

        <MonthlyRequestChart chartInfo={chartInfo} />
      </div>
      <Footer />;
    </>
  );
};

export default UniverseDashboard;
