import { debounce } from "lodash";
import { useCallback } from "react";
import { Input } from "reactstrap";
import { debounceTimeInMilliseconds } from "../../../../common/constants";

const SearchFilter = ({ paginationConfig, setPaginationConfig, search, setSearch, placeHolder }: SearchFilterPropTypes) => {
  const changeSearchState = (search: string) => {
    setPaginationConfig((pravState: any) => {
      return {
        ...pravState,
        search,
        filter: pravState.filter
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(changeSearchState, debounceTimeInMilliseconds), []);

  return (
    <div>
      <Input
        type="text"
        name="address"
        id="exampleAddress"
        placeholder={`${placeHolder ? placeHolder : "Search..."}`}
        className="w-sm-250px w-100 font-14 search-input mb-sm-0 mb-20 input-hieght form-control"
        value={search}
        onChange={(e) => {
          if (e.target.value.trim()) {
            setSearch(e.target.value);
            delayedSearch(e.target.value);
          } else {
            if (search !== "") {
              setSearch("");
              delayedSearch("");
            }
          }
        }}
      />
    </div>
  );
};

export default SearchFilter;

interface SearchFilterPropTypes {
  paginationConfig: any;
  setPaginationConfig: Function;
  search: string;
  setSearch: Function;
  placeHolder?: string;
}
