import { projectModules, projectNames } from "../../common/constants";
import BStampDashboard from "../../pages/main/bStamp/BstampDashboard";
import BStampGlobalSetting from "../../pages/main/bStamp/bStampGlobalSetting/BstampGlobalSetting";
import BStampLabelManagment from "../../pages/main/bStamp/bStampLabelManagement/BstampLabelManagment";
import BStampUserManagement from "../../pages/main/bStamp/bStampUserManagement/bStampUserManagement";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import Notification from "../../pages/main/Notification";

const bStampPrivateRoutes = [
  {
    path: `/${projectNames.bstamp}/dashboard`,
    component: BStampDashboard,
    module: projectModules[projectNames.bstamp].DASHBOARD
  },
  {
    path: `/${projectNames.bstamp}/label-management`,
    component: BStampLabelManagment,
    module: projectModules[projectNames.bstamp].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.bstamp}/global-settings`,
    component: BStampGlobalSetting,
    module: projectModules[projectNames.bstamp].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.bstamp}/user-management`,
    component: BStampUserManagement,
    module: projectModules[projectNames.bstamp].USER_MANAGEMENT
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];
export default bStampPrivateRoutes;
