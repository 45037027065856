import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class PolarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          width: 380,
          type: "polarArea"
        },
        labels: [],
        fill: {
          opacity: 1
        },

        stroke: {
          width: 1,
          colors: undefined
        },
        yaxis: {
          show: false
        },
        legend: {
          position: "right"
        },

        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0
            },
            spokes: {
              strokeWidth: 0
            }
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            shadeTo: "light",
            shadeIntensity: 0.6
          }
        }
      }
    };
  }

  componentDidUpdate(previousProps) {
    if (this.props.pollOptionData !== previousProps.pollOptionData) {
      this.setState({
        options: {
          ...this.state.options,
          labels: this.props.pollOptionData.map((el) => el.text)
        },
        series: this.props.pollOptionData.map((el) => el.resultCount)
      });
    }
  }

  render() {
    return (
      <div id="polarChart">
        <Chart options={this.state.options} series={this.state.series} type="pie" height={350} />
      </div>
    );
  }
}
