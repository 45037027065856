import { Suspense } from "react";
import "./App.css";
import RoutingComponent from "./routes/Routing";

function App() {
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <RoutingComponent />
    </Suspense>
  );
}

export default App;
