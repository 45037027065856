import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const updateCategoryAPI = (id: string, data: IupdateCategoryAPIParsms): Promise<IupdateCategoryAPI> => {
  return HTTPService.patch(StakingPortalApiEndPoint.category.update + `/${id}`, data);
};
interface IupdateCategoryAPIParsms {
  name: string;
  visible: boolean;
}

export interface IupdateCategoryAPIData {}

interface IupdateCategoryAPI {
  message: string;
  status: number;
  data: IupdateCategoryAPIData;
}
