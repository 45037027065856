import { bStampURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const GetbStampGlobalSettingAPI = (): Promise<IGetbStampGlobalSettingResponse> => {
  return HTTPService.get(bStampURL.getGlobalSetting);
};

export const UpdateGlobalSettingAPI = (): Promise<IUpdateGlobalSettingResponse> => {
  return HTTPService.get(bStampURL.updateGlobalSetting);
};

export interface IGetbStampGlobalSettingResponse {
  status: number;
  message: string;
}

export interface IUpdateGlobalSettingResponse {
  status: number;
  message: string;
}
