import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Badge, Col, Row, Spinner, Tooltip } from "reactstrap";
import { getAPISubAPIListById } from "../../../../actions/portal/getAPISubAPILIstById";
import BackArrowComponent from "../../../../common/backArrow";
import { projectNames } from "../../../../common/constants";
import MetaFunction from "../../../../common/MetaTag";
import Footer from "../../../common/Footer";

const EditExplorer = ({
  location: {
    state: { apiId }
  }
}: any) => {
  const history = useHistory();

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [values, setValues] = useState<any>();
  const [isExplorerLoading, setIsExplorerLoading] = useState(true);

  const handleGetAPIData = () => {
    getAPISubAPIListById(apiId)
      .then((response) => {
        setValues(response);

        setIsExplorerLoading(false);
      })
      .catch((err) => {
        setIsExplorerLoading(false);
      });
  };

  useEffect(() => {
    handleGetAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return apiId ? (
    <>
      <MetaFunction meta="Edit Explorer" />
      <div className="holders" style={{ overflow: "auto", minHeight: "87vh" }}>
        <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="right" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
          Back
        </Tooltip>
        <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
          <BackArrowComponent />
        </button>
        <Row className="mt-20">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                {values?.data?.rows?.length > 0 ? (
                  <p className="sub-title m-b-5">{values?.apiName}</p>
                ) : !isExplorerLoading ? (
                  <div className="p-20">No API's Available</div>
                ) : (
                  <div className="text-center mb-3">
                    <Spinner />
                  </div>
                )}
              </div>
              {values?.data?.rows?.length > 0 && (
                <Row className="m-0 mx-3 mb-4">
                  {values?.data?.rows?.map((api: any, index: number) => {
                    return (
                      <Col sm="12" md="6" lg="4" key={index} className="border">
                        <div className="api-list p-20">
                          <div className="d-flex justify-content-between">
                            <p className="font-16 color-light-grey">
                              {api?.name}
                              <Badge className="bg-blue ms-2">{api?.request_type}</Badge>
                            </p>
                            <i
                              className="fas fa-edit color-blue cursor-pointer ml-10"
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick={() => {
                                history.push({
                                  pathname: `/${projectNames.universe}/api-management/explorer/edit`,
                                  state: { apiId: api?.id }
                                });
                              }}></i>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  ) : (
    <>No Data Found</>
  );
};

export default EditExplorer;
