import { FC, memo } from "react";
import { useHistory } from "react-router";
import BackArrowComponent from "../../../../common/backArrow";

const PageHeader: FC<{ module: string }> = ({ module }) => {
  const history = useHistory();
  return (
    <div className="d-flex justify-content-between mb-25">
      <button id="backButton" title="back" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
        <BackArrowComponent />
      </button>
      <p className="main-title">{module}</p>
      <span></span>
    </div>
  );
};

export default memo(PageHeader);
