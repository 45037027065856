import { FC } from "react";

interface IColumnComponent {
  title?: any;
  value?: any;
  color?: any;
  capitalize?: any;
}

const ColumnComponent: FC<IColumnComponent> = ({ title, value, color, capitalize }) => {
  return (
    <>
      <hr className="m-0" style={{ height: "0.5px" }} />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 color-black2 fw-bold fw-normal text-upper">{title}</p>
        </div>
        <div className="col-9">
          <p className={`color-black2 font-14  info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {value ? value : "-"}
          </p>
        </div>
      </div>{" "}
    </>
  );
};

export default ColumnComponent;
