import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNftDashboardAPI } from "../../../actions/nft/dashboard/dashboardAPI";
import MetaFunction from "../../../common/MetaTag";
import { projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import Footer from "../../common/Footer";
import TitleDashboard from "../bvote/TitleDashboard";

const NftDashboard = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    totalNFTs: 0,
    activeUsers: 0
  });

  useEffect(() => {
    dispatch(setLoading(true));
    getNftDashboardAPI().then((res) => {
      if (res.status === 200) {
        setState(res.data);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch]);

  const { totalNFTs, activeUsers } = state;
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={"Dashboard"} />
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Dashboard</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard cursor="pointer" width="49.3%" title="Total NFTs" count={totalNFTs} onClick={() => handleRedireactClick(projectNames.nft, projectModules[projectNames.nft]?.DASHBOARD, "")} />
          <TitleDashboard
            cursor="pointer"
            width="49.3%"
            title="Active Users"
            count={activeUsers}
            onClick={() => handleRedireactClick(projectNames.nft, projectModules[projectNames.nft]?.DASHBOARD, "")}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NftDashboard;
