import { StakingPortalApiEndPoint } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const StakingAdminInfoAPI = (): Promise<IStakingAdminInfoAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.adminInfo);
};

interface IStakingAdminInfoAPI {
  message: string;
  status: number;
  data: {
    email: string;
    clientId: string;
    _id: string;
  };
}
