import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const GetNFTLabelAPI = (): Promise<IGetNFTLabelResponse> => {
  return HTTPService.get(nftURL.getLabel);
};

export interface ILanguageObject {
  login: string;
  create: string;
  upload: string;
}

export interface IGetLabelObject {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  DE: ILanguageObject;
  EN: ILanguageObject;
}

export interface IGetNFTLabelResponse {
  status: number;
  message: string;
  data: IGetLabelObject;
}
