import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getDomainsList, getRegionsList } from "../../../../actions/portal/adminActions";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig, projectModules, projectNames } from "../../../../common/constants";
import { categoryModalIsOpenAction } from "../../../../store/categoryModal/action";
import { setLoading } from "../../../../store/loader/action";

import Footer from "../../../common/Footer";
import { CreateRestricted } from "../../../common/restrictions";
import SearchFilter from "../../banji/component/SearchFilter";
import { UniverseTableComponent } from "../common/ListTable";

const ManageRegionsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const module = "Manage Regions";
  const tableHeaders = ["Region", "Name", "Domain", "Visibility of Region", "Enabled / Disabled", CREATEDATE, "Actions"];

  const [searchField, setSearchField] = useState("");
  const [domainList, setDomainList] = useState<{ value: string; label: string }[]>([]);
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [networkDomain, setNetworkDomain] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "---Select Domain---" });
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });

  const getDomainListFromApi = () => {
    dispatch(setLoading(true));
    getDomainsList({ pagination: false }).then((res) => {
      const optionsLabelWise = res?.data?.data?.map((ele: any) => ({
        value: ele?.id,
        label: ele?.name
      }));
      optionsLabelWise.unshift({ value: "", label: "---Select Domain---" });
      setDomainList(optionsLabelWise);
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getDomainListFromApi();
    // eslint-disable-next-line
  }, []);

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    const { filter, limit, page } = paginationConfig;
    getRegionsList(
      searchField && filter && limit && page
        ? { search: searchField, filter, limit, page }
        : !searchField && filter && limit && page
        ? { filter, limit, page }
        : searchField && !filter && limit && page
        ? { search: searchField, limit, page }
        : !searchField && !filter && limit && page && { limit, page }
    ).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getCategoryManagementListFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const resetFilters = () => {
    let object = { value: "", label: "---Select Domain---" };
    if (searchField === "" && JSON.stringify(networkDomain) === JSON.stringify(object) && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig((prev) => ({ ...prev, filter: "", search: "" }));
      setNetworkDomain({ value: "", label: "---Select Domain---" });
    }
  };

  const handleFilter = (e: any) => {
    if (e) {
      setPaginationConfig({ ...paginationConfig, filter: e?.value });
      setNetworkDomain(e);
    }
  };
  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <Select
                    defaultValue={domainList[0]}
                    name="networkDomainId"
                    placeholder="Select Type of domain"
                    className="w-c-250 me-2 z-index"
                    options={domainList}
                    value={networkDomain}
                    onChange={(e) => handleFilter(e)}
                  />
                  <SearchFilter placeHolder="Search By Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => history.push(`/${projectNames.universe}/create-region`)}>
                  Create
                </button>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.nft].CATEGORY_MANAGEMENT}>
                  <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => dispatch(categoryModalIsOpenAction(true))}>
                    Add
                  </button>
                </CreateRestricted>
              </div>
              <UniverseTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"regions"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ManageRegionsList;
