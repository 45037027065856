import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const createBlogsAPI = (data: IcreateBlogsAPIParsms): Promise<IcreateBlogsAPI> => {
  return HTTPService.post(StakingPortalApiEndPoint.blogs.create, data);
};
interface IcreateBlogsAPIParsms {
  title: string;
  shortDescription: string;
  content: string;
  isAvailable: number;
  tags: any[];
  blogImage: any[];
}

export interface IcreateBlogsAPIData {}

interface IcreateBlogsAPI {
  message: string;
  status: number;
  data: IcreateBlogsAPIData;
}
