import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { GetbMessageLabelAPI, updatebMessageLabelAPI } from "../../../../actions/bMessage/getBMessageAdminInfoAPI";
import { IGetLabelObject } from "../../../../actions/nft/globalSetting/getNFTLabelAPI";
import MetaFunction from "../../../../common/MetaTag";
import { toastSuccess } from "../../../../common/common-functions";
import { addLabelNote, debounceTimeInMilliseconds, projectModules, projectNames, validationMessages } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import { useDebouncedEffect } from "../../../../utils/hooks/useDebouncedEffect";
import { CreateRestricted, EditRestricted } from "../../../common/restrictions";
import InputLabelField from "../../nft/labelManagement/InputLabelField";

const BMessageLabelManagament = () => {
  const dispatch = useDispatch();
  const permission = useSelector((state: IRootReducerTypes) => state.userDetails);

  const [search, setSearch] = useState("");
  const [addSearchInNewState, setAddSearchInNewState] = useState<string[]>([""]);
  const [noDataFoundCondition, setNoDataFoundCondition] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [labelResponse, setLabelResponse] = useState<IGetLabelObject>();
  const [addLabel, setAddLabel] = useState(false);
  const [data, setData] = useState({
    key: "",
    english: "",
    dutch: ""
  });

  // api changes
  const formik = useFormik({
    initialValues: {
      language: "EN",
      DE: {},
      EN: {},
      disable: "true",
      _id: ""
    },
    validationSchema: Yup.object().shape({
      DE: Yup.object().required(validationMessages.firstName.required),
      EN: Yup.object().required(validationMessages.firstName.required)
    }),
    onSubmit: (values: any) => {
      dispatch(setLoading(true));
      let paramater: any = {};
      paramater.EN = values.EN;
      paramater.DE = values.DE;

      updatebMessageLabelAPI(paramater).then((res: any) => {
        if (res?.status === 200) {
          toastSuccess(res?.message);
          // @ts-ignore
          setLabelResponse({ ...labelResponse, DE: values.DE, EN: values.EN });
        }
        dispatch(setLoading(false));
      });
    }
  });

  const { values, setFieldValue, handleSubmit } = formik;

  const getLabelAction = () => {
    dispatch(setLoading(true));
    GetbMessageLabelAPI().then((res: any) => {
      if (res?.status === 200) {
        setLabelResponse(res.data);
        setFieldValue("_id", res?.data?._id);
        setFieldValue("EN", res?.data?.EN);
        setFieldValue("DE", res?.data?.DE);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getLabelAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    let filteredValue = Object.keys(values.EN).filter((key: any) => {
      if (search === "") {
        return key;
      } else if (key.toLowerCase().includes(search.toLowerCase())) {
        return key;
      }
    });
    setAddSearchInNewState(filteredValue);

    if (search) {
      setNoDataFoundCondition(true);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (noDataFoundCondition) {
      if (addSearchInNewState.length === 0) {
        setNoDataFound(true);
      } else if (addSearchInNewState.length > 0) {
        setNoDataFound(false);
      }
    }
  }, [addSearchInNewState, noDataFoundCondition]);

  useDebouncedEffect(
    () => {
      //@ts-ignore
      let kay = document.getElementById(search ? search : document.getElementById("label-div")?.firstChild?.id);
      if (kay) {
        kay.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
        kay.focus();
      }
    },
    debounceTimeInMilliseconds,
    [search]
  );

  const handleChange = (e: any) => {
    if (e.target.value.trim()) {
      setData({ ...data, [e.target.name]: e.target.value });
    } else {
      //@ts-ignore
      if (data[e.target.name] !== "") {
        setData({ ...data, [e.target.name]: "" });
      }
    }
  };

  const handleAddLabel = () => {
    dispatch(setLoading(true));
    let parameter: any = {};
    parameter.EN = values.EN;
    parameter.DE = values.DE;
    parameter.EN[(data?.key).toLowerCase()] = data?.english;
    parameter.DE[(data?.key).toLowerCase()] = data?.dutch;

    setSearch(data?.key);
    setAddLabel(!addLabel);
    setTimeout(() => {
      setSearch("");
    }, 1000);

    updatebMessageLabelAPI(parameter).then((res: any) => {
      dispatch(setLoading(false));
      setData({ key: "", english: "", dutch: "" });
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (data?.key && data?.english && data?.dutch) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data?.key, data?.english, data?.dutch]);

  return (
    <>
      <MetaFunction meta="Label Management" />
      <div className="holders">
        <p className="main-title m-b-20">Label Management</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="d-flex flex-wrap">
              <Input
                type="text"
                placeholder="Search By Label Key"
                className="me-2 w-sm-250px w-100 font-14 search-input mb-sm-0 mb-20 input-hieght"
                onChange={(e) => {
                  if (e.target.value.trim()) {
                    setSearch(e.target.value);
                  } else {
                    if (search !== "") {
                      setSearch("");
                    }
                  }
                }}
                value={search}
              />
              <button className="btn border-blue button-width-com   " onClick={() => setSearch("")}>
                Clear
              </button>
            </div>

            {/* @ts-ignore */}
            <CreateRestricted module={projectModules[projectNames.bmessage].LABEL_MANAGEMENT}>
              <button
                type="button"
                disabled={Boolean(false)}
                className="btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com"
                onClick={() => {
                  setAddLabel(!addLabel);
                }}>
                Add Label
              </button>
            </CreateRestricted>
            <div className="col-12 mt-10 mb-10" id="label-div">
              {addLabel && (
                <>
                  <div className="col-12 test">
                    <div className="row">
                      <div className="col-4">
                        <Input type="text" placeholder="Label Key" name="key" value={data.key} onChange={handleChange} onKeyDown={handleKeyDown} />
                      </div>
                      <div className="col-4">
                        <Input type="text" placeholder="English Label" name="english" value={data.english} onChange={handleChange} />
                      </div>
                      <div className="col-4">
                        <Input type="text" placeholder="German Label" name="dutch" value={data.dutch} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <p style={addLabelNote.style}>{addLabelNote.note}</p>
                  <div className="col-12 mt-10 mb-10">
                    <button
                      type="button"
                      className="me-2 btn btn-primary bg-blue border-0 shadow-none button-width-com"
                      disabled={isDisabled}
                      onClick={() => {
                        handleAddLabel();
                      }}>
                      Save
                    </button>
                    <button
                      type="button"
                      disabled={Boolean(false)}
                      className="btn-sm bg-grey rounded-5 py-2 px-3 font-14 border-none button-width-com"
                      onClick={() => {
                        setData({ key: "", english: "", dutch: "" });
                        setAddLabel(!addLabel);
                      }}>
                      Remove
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row ">
              <div className="col-4 fw-bold">Label key</div>
              <div className="col-4 fw-bold">English value</div>
              <div className="col-4 fw-bold">German value</div>
            </div>

            <div className="nft-label-section mt-10">
              {values?.DE &&
                (search ? addSearchInNewState : Object.keys(values.DE)).sort().map(function (key, index) {
                  return (
                    <div className={`col-12 test ${index !== 0 && "mt-3"}`} key={index} id={key}>
                      <div className="row">
                        <div className="col-4">
                          <Input type="text" disabled name={`${values.language}`} value={key} />
                        </div>
                        <InputLabelField
                          setFieldValue={setFieldValue}
                          // @ts-ignore
                          value={values.EN[`${key}`]}
                          keyValue={key}
                          lang="EN"
                          disable={permission?.user?.permission?.banji?.labelmanagement?.update || true}
                        />
                        <InputLabelField
                          lang="DE"
                          setFieldValue={setFieldValue}
                          keyValue={key}
                          // @ts-ignore
                          value={values.DE[key]}
                          disable={permission?.user?.permission?.banji?.labelmanagement?.update || true}
                        />
                      </div>
                    </div>
                  );
                })}

              {noDataFound ? (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "3%",
                    color: "hsl(0deg 0% 80%)"
                  }}>
                  No Data Found
                </p>
              ) : null}
            </div>
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames.bmessage].LABEL_MANAGEMENT}>
              <div className="col-12 mt-10 mb-10">
                <button type="button" disabled={Boolean(values.disable)} className="btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={() => handleSubmit()}>
                  Save
                </button>
              </div>
            </EditRestricted>
          </div>
        </div>
      </div>
    </>
  );
};

export default BMessageLabelManagament;
