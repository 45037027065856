import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import Select from "react-select";
import Switch from "react-switch";
import { Form, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { addTemplate, getAPIList, getTemplateDetail, updateTemplate } from "../../../../actions/portal/adminActions";
import MetaFunction from "../../../../common/MetaTag";
import { handleTrim, toastSuccess } from "../../../../common/common-functions";
import { ReactSelectCustomstyle, projectNames, validationMessages } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import PageHeader from "../../bvote/common/PageHeader";

const EditTemplate = () => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const ReferenceOptions = [{ value: "dynamic", label: "Channel" }];
  const UserTypeOptions = [
    { value: "text", label: "String" },
    { value: "number", label: "Number" },
    { value: "dynamic", label: "Others" },
    { value: "spclAlphaNumeric", label: "spclAlphaNumeric" }
  ];

  const [applicationListing, setApplicationListing] = useState([]);
  const [module, setModule] = useState("Create Template");
  const [data, setData] = useState<any>();
  const location = useLocation();
  const [typeOfTemplates, setTypeOfTemplates] = useState<{ value: string; label: string } | null>();
  const groupFormik = useFormik({
    initialValues: {
      appId: "",
      name: "",
      description: "",
      type: "",
      disable: false,
      visible: true,
      fields: [
        {
          title: "",
          placeholder: "",
          key: "",
          default: "",
          type: UserTypeOptions[0].value,
          required: false,
          visible: true,
          reference: ReferenceOptions[0].label
        }
      ]
    },
    validationSchema: Yup.object().shape({
      appId: Yup.string().required("Application name is required"),
      name: Yup.string().required(validationMessages.templateName.required).min(3, validationMessages.templateName.min).max(25, validationMessages.templateName.max),
      description: Yup.string().required(validationMessages.templateDescription.required).min(25, validationMessages.templateDescription.min).max(100, validationMessages.templateDescription.max),
      type: Yup.string().required(validationMessages.templateType.required).min(3, validationMessages.templateType.min).max(25, validationMessages.templateType.max),
      fields: Yup.array().of(
        Yup.object({
          title: Yup.string().required(validationMessages.title.required),
          placeholder: Yup.string().required(validationMessages.placeholder.required),
          key: Yup.string().required(validationMessages.key.required),
          type: Yup.string().required(validationMessages.type.required)
        })
      )
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      if (location?.pathname.includes(`/${projectNames.universe}/templates-edit/`)) {
        let parameter: any = { id: params.id };
        if (data?.appId !== values.appId) {
          parameter.appId = values?.appId;
        }
        if (data?.name !== values.name) {
          parameter.name = values?.name;
        }
        if (data?.disable !== values.disable) {
          parameter.disable = values?.disable;
        }
        if (data?.fields !== values.fields) {
          parameter.fields = values?.fields;
        }
        if (data?.description !== values.description) {
          parameter.description = values?.description;
        }
        if (data?.type !== values.type) {
          parameter.type = values?.type;
        }
        if (data?.visible !== values.visible) {
          parameter.visible = values?.visible;
        }
        updateTemplate(parameter).then((res) => {
          if (res.status === 200) {
            history.push(`/${projectNames.universe}/templates`);
            toastSuccess(res.message);
            dispatch(setLoading(false));
          }
        });
      } else {
        const makeAPIData = {
          ...values,
          fields: values.fields
        };
        addTemplate(makeAPIData).then((res) => {
          if (res.status === 200) {
            history.push(`/${projectNames.universe}/templates`);
            toastSuccess(res.message);
            dispatch(setLoading(false));
          }
        });
      }
    }
  });

  const { errors, values, handleBlur, touched, handleSubmit, setFieldValue } = groupFormik;

  useEffect(() => {
    if (params.id) {
      dispatch(setLoading(true));
      getTemplateDetail(params.id).then((response) => {
        if (response.status === 200) {
          setData(response?.data);
          setFieldValue("appId", response?.data?.appId);
          setFieldValue("name", response?.data?.name);
          setFieldValue("description", response?.data?.description);
          setFieldValue("type", response?.data?.type);
          setFieldValue("visible", response?.data?.visible);
          setFieldValue("disable", response?.data?.disable);
          setFieldValue("fields", response?.data?.fields);
          setTypeOfTemplates({ label: response.data.appName, value: response.data.appId });
          dispatch(setLoading(false));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = (index: any, e: any) => {
    setFieldValue(`fields[${index}].type`, e.value);
  };

  const handleReferenceChange = (index: any, e: any) => {
    setFieldValue(`fields[${index}].reference`, e.label);
    setFieldValue(`fields[${index}].type`, e.value);
  };

  const defaultValueOfDropDown = (value: string) => {
    if (value === "dynamic") {
      return UserTypeOptions[2];
    } else if (value === "number") {
      return UserTypeOptions[1];
    } else if (value === "text") {
      return UserTypeOptions[0];
    }
  };

  const getApplicationList = () => {
    getAPIList().then((res) => {
      setApplicationListing(
        res.data.rows.map((item: any) => {
          return {
            value: item.appId,
            label: item.name
          };
        })
      );
    });
  };

  useEffect(() => {
    if (location.pathname.includes("create-template")) {
      setModule("Create Template");
    } else {
      setModule("Edit Template");
    }
    getApplicationList();
    // eslint-disable-next-line
  }, []);

  const handleTypeTemplate = (e: any) => {
    setTypeOfTemplates(e);
    setFieldValue("appId", e.value);
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <PageHeader module={module} />
        <div className="rounded-10 bg-white p-20">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Label htmlFor="firstName" className="fw-normal color-light-grey py-2">
                  Application Name
                </Label>
                <Select
                  value={typeOfTemplates}
                  options={applicationListing}
                  placeholder="Select application name"
                  className={`mt-1 border-danger ${touched.appId && errors.appId ? "error-block" : ""}`}
                  name="appId"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: "#4c4f53" // Placeholder color
                    }
                  })}
                  styles={ReactSelectCustomstyle}
                  onChange={(e) => handleTypeTemplate(e)}
                />
                {touched?.appId && errors.appId && <div className="error-message">{errors.appId}</div>}
              </div>
              <div className="col-md-12">
                <Label htmlFor="firstName" className="fw-normal color-light-grey py-2">
                  Template Name
                </Label>
                <Input
                  type="text"
                  placeholder="Enter the name of the template"
                  id="name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                  invalid={Boolean(touched?.name && errors.name)}
                />
                {touched?.name && errors.name && <span className="text-danger font-14 mt-2">{errors.name}</span>}
              </div>
              <div className="col-md-12">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Description
                </Label>
                <Input
                  type="textarea"
                  placeholder="Enter the description"
                  id="description"
                  name="description"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.description}
                  invalid={Boolean(touched?.description && errors.description)}
                />
                {touched?.description && errors.description && <span className="text-danger font-14 mt-2">{errors.description}</span>}
              </div>
              <div className="col-md-12">
                <Label htmlFor="type" className="fw-normal color-light-grey py-2">
                  Type
                </Label>
                <Input
                  type="text"
                  placeholder="Enter the type of the template"
                  id="type"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value.replace(/[^A-Za-z0-9]/g, ""), setFieldValue)}
                  value={values.type}
                  invalid={Boolean(touched?.type && errors.type)}
                />
                {touched?.type && errors.type && <span className="text-danger font-14 mt-2">{errors.type}</span>}
              </div>
              <div className="col-12 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Template Visibility
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  checked={values.visible}
                  onChange={() => setFieldValue("visible", !values.visible)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-3 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Disable the Template
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  checked={values.disable}
                  onChange={() => setFieldValue("disable", !values.disable)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-12 d-flex flex-column mt-4">
                <div className="row">
                  <p className="main-title col-md-6">Add Fields</p>
                  <div className="col-md-6 mt-20 text-right">
                    <button
                      onClick={() =>
                        setFieldValue("fields", [
                          ...values.fields,
                          {
                            title: "",
                            placeholder: "",
                            key: "",
                            default: "",
                            type: UserTypeOptions[0].value,
                            required: false,
                            visible: true
                          }
                        ])
                      }
                      className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                      Add Fields
                    </button>
                  </div>
                </div>
                {/* NEW FIELDS TEST */}
                {values?.fields?.map((singleField, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-3">
                        <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                          Title
                        </Label>
                        <Input
                          type="text"
                          placeholder="Title of the field"
                          id="title"
                          name="title"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleTrim(`fields[${index}].title`, e.target.value, setFieldValue);
                          }}
                          value={singleField?.title}
                          // @ts-ignore
                          invalid={Boolean(touched?.fields?.[index]?.title && errors?.fields?.[index]?.title)}
                        />
                        {/* @ts-ignore */}
                        {touched.fields?.[index]?.title && !values?.fields?.[index]?.title && errors?.fields?.[index]?.title && (
                          // @ts-ignore
                          <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.title}</span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                          Placeholder
                        </Label>
                        <Input
                          type="text"
                          placeholder="Placeholder of the field"
                          id="placeholder"
                          name="placeholder"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleTrim(`fields[${index}].placeholder`, e.target.value, setFieldValue);
                          }}
                          // @ts-ignore
                          invalid={Boolean(touched?.fields?.[index]?.placeholder && errors?.fields?.[index]?.placeholder)}
                          // @ts-ignore
                          value={singleField.placeholder}
                        />
                        {/* @ts-ignore */}
                        {touched.fields?.[index]?.title && !values?.fields?.[index]?.placeholder && errors?.fields?.[index]?.placeholder && (
                          // @ts-ignore
                          <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.placeholder}</span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                          Key
                        </Label>
                        <Input
                          type="text"
                          placeholder="Add the key of the field"
                          id="key"
                          name="key"
                          onBlur={handleBlur}
                          value={singleField.key}
                          onChange={(e) => {
                            handleTrim(`fields[${index}].key`, e.target.value, setFieldValue);
                          }}
                          // @ts-ignore
                          invalid={Boolean(touched?.fields?.[index]?.key && errors?.fields?.[index]?.key)}
                        />
                        {/* @ts-ignore */}
                        {touched.fields?.[index]?.title && !values?.fields?.[index]?.key && errors?.fields?.[index]?.key && (
                          // @ts-ignore
                          <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.key}</span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                          Default Value
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter the default value"
                          id="default"
                          name="default"
                          onBlur={handleBlur}
                          onChange={(e) => handleTrim(`fields[${index}].default`, e.target.value, setFieldValue)}
                          value={singleField.default}
                          // @ts-ignore
                          invalid={Boolean(touched?.fields?.[index]?.default && errors?.fields?.[index]?.default)}
                        />
                        {/* @ts-ignore */}
                        {!values.fields?.[index]?.default && errors?.fields?.[index]?.default && <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.default}</span>}
                      </div>
                      <div className="col-md-3">
                        <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                          Type {singleField?.type}
                        </Label>
                        <Select
                          placeholder="Select Type"
                          onChange={(e) => handleTypeChange(index, e)}
                          options={UserTypeOptions}
                          className="me-2 mb-2 z-index"
                          styles={ReactSelectCustomstyle}
                          value={defaultValueOfDropDown(singleField?.type)}
                        />
                        {/* @ts-ignore */}
                        {!values?.fields?.[index]?.type && errors?.fields?.[index]?.type && <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.type}</span>}
                      </div>
                      {singleField?.type === "dynamic" && (
                        <div className="col-md-3">
                          <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                            Reference
                          </Label>
                          <Select
                            placeholder="Select Type"
                            onChange={(e) => handleReferenceChange(index, e)}
                            options={ReferenceOptions}
                            className="me-2 mb-2 z-index"
                            styles={ReactSelectCustomstyle}
                            defaultValue={ReferenceOptions[0]}
                          />
                          {/* @ts-ignore */}
                          {!values?.fields?.[index]?.type && errors?.fields?.[index]?.type && <span className="text-danger font-14 mt-2">{errors?.fields?.[index]?.type}</span>}
                        </div>
                      )}
                      <div className="col-3 d-flex flex-column">
                        <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                          Is Required?
                        </Label>
                        <Switch
                          height={20}
                          width={40}
                          onColor="#007de4"
                          offColor="#b1abab"
                          checked={values.fields[index]?.required}
                          onChange={(e: any) => setFieldValue(`fields[${index}].required`, !!e)}
                          uncheckedIcon
                          checkedIcon
                          className="mr-10"
                        />
                      </div>
                      <div className="col-3 d-flex flex-column">
                        <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                          Field Visibility
                        </Label>
                        <Switch
                          height={20}
                          width={40}
                          onColor="#007de4"
                          offColor="#b1abab"
                          checked={values.fields[index].visible}
                          onChange={(e) => setFieldValue(`fields[${index}].visible`, !!e)}
                          uncheckedIcon
                          checkedIcon
                          className="mr-10"
                        />
                      </div>
                      {values.fields.length > 1 && (
                        <button
                          className="btn border-blue my-3  ml-10 button-width-com"
                          onClick={() => {
                            let fieldsListCopy = [...values.fields];
                            fieldsListCopy.splice(index, 1);
                            setFieldValue("fields", fieldsListCopy);
                          }}>
                          Delete
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="col-md-12 mt-20">
                <div className="col-md-12 mt-20">
                  <button type="submit" className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditTemplate;
