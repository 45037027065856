import { USERPERMISSIONS } from "./type";

// iT WILL RECEIVE AN ARRAY OF PERMISSIONS
/**
 *
 * @param payload ex. [{"banji":[{"customers":[{"view":true},{"create":false},{"edit":false},{"delete":false}]}]}]
 * @returns
 */
export const setPermissions = (payload: Object) => {
  return { type: USERPERMISSIONS, payload: payload };
};
