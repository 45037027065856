/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { createBlogsAPI } from "../../../../actions/staking/blogs/createBlogsAPI";
import { IgetBlogsAPIData, getBlogsAPI } from "../../../../actions/staking/blogs/getBlogsAPI";
import { updateBlogsAPI } from "../../../../actions/staking/blogs/updateBlogsAPI";
import { getListCategoryAPI } from "../../../../actions/staking/category/getListCategoryAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleTrim, toastError, toastSuccess } from "../../../../common/common-functions";
import { BlogsAction, ReactSelectCustomstyle, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";
import PageHeader from "../../bvote/common/PageHeader";
import { default as TextEditor } from "./TextEditor";

const BlogsForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const module: BlogsAction = location?.pathname.includes(BlogsAction.CREATE) ? BlogsAction.CREATE : BlogsAction.UPDATE;
  const moduleName = location?.pathname.includes(BlogsAction.CREATE) ? "News Create" : "News Update";
  const [data, setData] = useState<IgetBlogsAPIData>();
  const [disable, setDisable] = useState(location?.pathname.includes(BlogsAction.CREATE) ? false : true);
  const [selectValue, setSelectValue] = useState({
    value: "",
    label: "---Select---"
  });
  const [option, setOption] = useState([{ value: "", label: "---Select---" }]);
  const [updateData, setUpdateDate] = useState({
    title: "",
    shortDescription: "",
    content: "",
    categoryId: "",
    isAvailable: false,
    blogImage: [],
    tags: [],
    publishedAt: "",
    shareAvailableOn: [{ name: "", status: 0, icon: "" }]
  });
  const [image, setImage] = useState({
    image: "",
    loading: false,
    error: false
  });

  const CreateUpdatePermission = useSelector((state: IRootReducerTypes) => state.permissionsReducer?.permissions?.[projectNames?.edexanetwork]?.blogmanagement);

  const formikData = useFormik({
    initialValues: {
      title: "",
      shortDescription: "",
      content: "",
      categoryId: "",
      isAvailable: false,
      blogImage: [],
      tags: [],
      publishedAt: "",
      shareAvailableOn: [{ name: "", status: 0, icon: "" }]
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required").min(3, "Please enter minimum 3 characters").max(120, "Maximum allowed characters are 120"),
      categoryId: Yup.string().required("Category  is required"),
      shortDescription: Yup.string().required("Short description is required").min(3, "Please enter minimum 3 characters").max(250, "Maximum allowed characters are 250"),
      content: Yup.string().required("Content is required").min(3, "Please enter minimum 3 characters"),
      tags: Yup.array().required().min(1, "Please enter minimum 1 Tag").max(10, "Maximum allowed Tags are 10"),
      blogImage: Yup.array().min(1, "Image is required").nullable(),
      publishedAt: Yup.string()
        .when("isAvailable", {
          is: true,
          then: Yup.string().required("Published date  is required.").nullable()
        })
        .when("isAvailable", {
          is: false,
          then: Yup.string().nullable()
        })
        .nullable()
      // shareAvailableOn: Yup.array().of(
      //   Yup.object().shape({
      //     name: Yup.string().required("Name is required").min(3, "Please enter minimum 3 characters"),
      //     icon: Yup.string().required("Icon is requried").min(2, "Please enter minimum 2 characters")
      //   })
      // )
    }),
    onSubmit: (values) => {
      const formdata = new FormData();
      if (module === BlogsAction.CREATE) {
        Object.keys(values).forEach((key: any, value: any) => {
          if (key === "publishedAt") {
            // @ts-ignore
            if (values[key] !== "") {
              // @ts-ignore
              formdata.append(key, moment(values[key]).format("MM-DD-yyyy"));
            }
          } else {
            // @ts-ignore
            if (Array.isArray(values[key])) {
              // @ts-ignore
              formdata.append(key, JSON.stringify(values[key]));
            } else {
              // @ts-ignore
              if (values[key] !== "") {
                // @ts-ignore
                if (typeof values[key] === "string") {
                  // @ts-ignore
                  formdata.append(key, values[key].trim());
                } else {
                  // @ts-ignore
                  formdata.append(key, values[key]);
                }
              }
            }
          }
        });
        dispatch(setLoading(true));
        // @ts-ignore
        createBlogsAPI(formdata).then((res) => {
          if (res.status === 200) {
            dispatch(setLoading(false));
            toastSuccess(res.message);
            history.push(`/${projectNames.edexanetwork}/news`);
          }
        });
      } else if (module === BlogsAction.UPDATE) {
        if (params?.id) {
          Object.keys(values).forEach((key: any, value: any) => {
            // @ts-ignore
            if (values[key] !== data[key]) {
              if (key === "publishedAt") {
                // @ts-ignore
                if (values[key] !== "") {
                  // @ts-ignore
                  formdata.append(key, moment(values[key]).format("MM-DD-yyyy"));
                }
              } else {
                // @ts-ignore
                if (Array.isArray(values[key])) {
                  // @ts-ignore
                  formdata.append(key, JSON.stringify(values[key]));
                } else {
                  // @ts-ignore
                  formdata.append(key, values[key]);
                }
              }
            }
          });
          if (Array.from(formdata.keys()).length > 0) {
            dispatch(setLoading(true));
            // @ts-ignore
            updateBlogsAPI(params?.id, formdata).then((res) => {
              if (res.status === 200) {
                dispatch(setLoading(false));
                history.push(`/${projectNames.edexanetwork}/news`);
                toastSuccess(res.message);
              }
            });
          }
        }
      }
    }
  });
  const { values, errors, setFieldValue, handleBlur, touched } = formikData;

  const handleCategory = () => {
    getListCategoryAPI({ pagination: false }).then((response: any) => {
      if (response.status === 200) {
        const newArray = response.data.map((data: any) => {
          return { value: data.id, label: data.name };
        });
        setOption([...option, ...newArray]);
      }
    });
  };

  useEffect(() => {
    if (params?.id && data) {
      const optionData: any = option.find((item: any) => item.value === data.category.id);
      if (optionData) {
        setSelectValue(optionData);
      }
    }
  }, [option, data?.category.id]);

  useEffect(() => {
    handleCategory();
    if (params?.id) {
      dispatch(setLoading(true));
      getBlogsAPI(params?.id)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            Object.keys(values).forEach((key: any, value: any) => {
              // @ts-ignore
              setFieldValue(key, res.data[key]);
              setUpdateDate((preState) => {
                // @ts-ignore
                return { ...preState, [key]: res.data[key] };
              });
            });
            setFieldValue("categoryId", res.data.category.id);
            setImage({ ...image, image: res.data.blogImage, loading: true });

            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          // dispatch(setLoading(false));

          history.push(`/${projectNames.edexanetwork}/news`);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const getBase64 = (file: any) => {
    if (file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e: any) {
          var image: any = new Image();
          image.src = e.target.result;
          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height >= 924 && width >= 1640) {
              return resolve(reader.result);
            }
            toastError("Please upload the image which should be greater than these dimensions 1640px * 924px");
            return reject(false);
          };
        };
        reader.onerror = function (error) {
          reject(false);
        };
      });
    } else {
      return new Promise((resolve, reject) => resolve(""));
    }
  };

  const handleError = () => setImage({ error: true, image: "", loading: false });
  const handleLoad = () => setImage({ ...image, loading: false });
  const handleImages = async (e: any) => {
    let id = e.target.id;
    let file: any = e.target.files[0];
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/bmp"];
    if (allowedExtension?.indexOf(file?.type) > -1) {
      setImage({ ...image, loading: true });
      getBase64(file)
        .then((res: any) => {
          setImage({ error: false, image: res, loading: false });
          // @ts-ignore
          setFieldValue([id], file);
        })
        .catch((err) => {
          setImage({ ...image, loading: false });
        });
    } else {
      toastError("Please upload only image files.");
    }
  };

  useEffect(() => {
    if (!location?.pathname.includes(BlogsAction.CREATE)) {
      if (JSON.stringify(updateData) === JSON.stringify(values)) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [values, updateData, location?.pathname]);

  const handleUserRegisterFilter = (data: any) => {
    setFieldValue("categoryId", data.value);
    setSelectValue(data);
  };
  const handleSubmit = () => formikData.handleSubmit();

  return (
    <div className="holders">
      <PageHeader module={moduleName} />
      <MetaFunction meta={moduleName} />
      <div className="rounded-10 bg-white p-20">
        <Row>
          {module === BlogsAction.UPDATE ? (
            <Col md={12}>
              <Label for="title" className="mb-10 fw-bold">
                Author Name : <span className="fw-normal">{data?.authorData?.name}</span>
              </Label>
            </Col>
          ) : null}
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label for="title" className="fw-bold">
                  Title <span className="text-red">*</span>
                </Label>
                <Input
                  placeholder="Title"
                  className="mt-1"
                  id="title"
                  name="title"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.title}
                  onBlur={handleBlur}
                  invalid={Boolean(touched?.title ? errors?.title : false)}
                />
                {touched?.title ? errors?.title ? <p className="text-danger">{errors?.title}</p> : null : null}
              </Col>
              <Col md={12}>
                <Label for="shortDescription" className="mt-3 fw-bold">
                  Short Description <span className="text-red">*</span>
                </Label>
                <Input
                  type="textarea"
                  placeholder="Short Description"
                  className="mt-1"
                  name="shortDescription"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.shortDescription}
                  onBlur={handleBlur}
                  invalid={Boolean(touched?.shortDescription ? errors?.shortDescription : false)}
                />
                {touched?.shortDescription ? errors?.shortDescription ? <p className="text-danger">{errors?.shortDescription}</p> : null : null}
              </Col>
              <Col md={12}>
                <Label for="contenttext" className="mt-3 fw-bold">
                  Content <span className="text-red">*</span>
                </Label>
                <div className={`${touched?.content ? (errors?.content ? "content_red" : " content_d") : " content_d"}`}>
                  <TextEditor value={data?.content} setValue={setFieldValue} />
                </div>
                {touched?.content ? errors?.content ? <p className="text-danger">{errors?.content}</p> : null : null}
              </Col>
              <Col md={12}>
                <Label for="title" className="mt-3 fw-bold">
                  Category <span className="text-red">*</span>
                </Label>
                <Select
                  placeholder="Status"
                  onChange={(e) => handleUserRegisterFilter(e)}
                  options={option}
                  value={selectValue}
                  className={`me-2 mt-1 z-index ${touched?.categoryId ? (errors?.categoryId ? "selectTags" : "") : ""}`}
                  styles={ReactSelectCustomstyle}
                />
                {touched?.categoryId ? errors?.categoryId ? <p className="text-danger">{errors?.categoryId}</p> : null : null}
              </Col>
              <Col md={12}>
                <SwitchGlobalSetting
                  className="mb-0 fw-bold"
                  checked={values.isAvailable}
                  onChange={(e: boolean) => setFieldValue("isAvailable", e)}
                  label="Available"
                  tooltip={values?.isAvailable ? "Please disable to remove the blog from the edexa.network" : "Please enable to view the blog in the edexa.network"}
                />
              </Col>
              <Col md={12} className={`${touched?.tags ? (errors?.tags ? "ReactTagInput-error" : "") : ""}`}>
                <Label for="biglogo" className="mt-3 mb-10 fw-bold">
                  Tags <span className="text-red">*</span>
                </Label>
                <ReactTagInput
                  tags={values.tags}
                  onChange={(newTags) => setFieldValue("tags", newTags)}
                  editable={true}
                  removeOnBackspace={true}
                  maxTags={10}
                  placeholder={"Type and press enter to add tag"}
                />
                {touched?.tags ? errors?.tags ? <p className="text-danger">{errors?.tags}</p> : null : null}
              </Col>
              <div className="d-flex mt-20 mb-2">
                {CreateUpdatePermission?.create || CreateUpdatePermission?.update ? (
                  <>
                    <button type="submit" disabled={disable} onClick={() => handleSubmit()} className="btn-sm fw-bold btn btn-primary bg-blue border-0 shadow-none button-width-com">
                      {location?.pathname?.includes("create") ? "Submit" : "Update"}{" "}
                    </button>
                  </>
                ) : null}
              </div>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label for="blogImage" className="d-flex flex-column ">
                  <Label className="fw-bold">
                    Upload Image <span className="text-red">*</span>
                  </Label>
                  <div className="row">
                    <div className="col-md-6 position-relative">
                      {image?.image && image.loading ? (
                        <div className="position-absolute d-flex gap-3 justify-content-center" style={{ top: "48%", left: "42%" }}>
                          <Spinner /> <span>Loading...</span>
                        </div>
                      ) : null}
                      {image?.image && (
                        <img
                          style={{
                            objectFit: "cover",
                            border: `1px solid ${touched?.blogImage ? (errors?.blogImage ? "red" : "#ced4da") : "#ced4da"}`
                          }}
                          className="mt-1 rounded-10 w-100"
                          src={image?.image ? image?.image : ""}
                          alt="blogImage"
                          height={210}
                          width={"100%"}
                          onError={handleError}
                          onLoad={handleLoad}
                        />
                      )}
                    </div>
                  </div>
                </Label>
                <Input
                  className="mt-1"
                  style={{ display: image?.image ? "none" : "" }}
                  onChange={handleImages}
                  onBlur={handleBlur}
                  id="blogImage"
                  name="blogImage"
                  placeholder="Subtitle"
                  type="file"
                  accept=".png, .svg, .jpg, .gif, .jpeg"
                />
                <Label className="fw-bold text-secondary">Note: Upload the image which should be greater than these dimensions 1640px * 924px</Label>
                {touched?.blogImage ? errors?.blogImage ? <p className="text-danger">{errors?.blogImage}</p> : null : null}
              </Col>
              {values.isAvailable ? (
                <Col md={12}>
                  <Label for="biglogo" className="mt-3 mb-1 fw-bold">
                    Published Date <span className="text-red">*</span>
                  </Label>
                  <ReactDatePicker
                    selected={values.publishedAt ? new Date(values.publishedAt) : null}
                    dateFormat="dd/MM/yyyy"
                    id="publishedAt"
                    onBlur={handleBlur}
                    name="publishedAt"
                    onChange={(e: any) => {
                      setFieldValue("publishedAt", e);
                    }}
                    placeholderText="Published Date"
                    className={`form-control ${touched?.publishedAt ? (errors?.publishedAt ? "ReactDatePickerError" : "") : ""}`}
                    minDate={new Date()}
                  />
                  {/* </label> */}
                  {touched?.publishedAt ? errors?.publishedAt ? <p className="text-danger">{errors?.publishedAt}</p> : null : null}
                </Col>
              ) : null}
              {/* <Col md={12}>
                <Label for="biglogo" className="mt-3 fw-bold">
                  Share Available On
                </Label>
                <Row>
                  <Col md={5}>
                    <Label className="fw-bold font-13">Name</Label>
                  </Col>
                  <Col md={4}>
                    <Label className="fw-bold font-13">Icon</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="fw-bold font-13">Status</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="fw-bold font-13">Action</Label>
                  </Col>
                </Row>
                {values?.shareAvailableOn.map((data, index) => (
                  <ShareAvailableOn key={index} data={data} errors={errors} handleBlur={handleBlur} index={index} setFieldValue={setFieldValue} touched={touched} values={values} />
                ))}
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BlogsForm;
