import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getBlogsAPI = (id: string): Promise<IgetBlogsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.blogs.get + `/${id}`);
};

interface IgetBlogsAPIDataRes {
  icon: string;
  name: string;
  status: number;
}

export interface IgetBlogsAPIData {
  authorData: {
    adminId: string;
    name: string;
  };
  blogImage: string;
  content: string;
  createdAt: string;
  id: string;
  isAvailable: number;
  publishedAt: string;
  shareAvailableOn: IgetBlogsAPIDataRes[];
  shortDescription: string;
  tags: string[];
  title: string;
  category: {
    id: string;
    name: string;
  };
  updatedAt: string;
}

interface IgetBlogsAPI {
  message: string;
  status: number;
  data: IgetBlogsAPIData;
}
