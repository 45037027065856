/**
 *
 * @param {className} - {function} custom classnames
 * @param {title} - {string} title of the button
 * @param {primary} - {boolean} button style
 * @param {danger} - {boolean} button style
 * @param {rest} - {remaining props}
 * @returns button component
 */
const CommonButton = ({ className, title, primary, danger, ...rest }) => {
  return (
    <button className={`btn shadow-none border-0 ${primary ? "btn-primary bg-blue" : ""} ${danger ? "bg-grey" : ""} ${className} button-width-com text-capitalize`} {...rest}>
      {title}
    </button>
  );
};

export default CommonButton;
