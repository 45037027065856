import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { GetbStampGlobalSettingAPI } from "../../../../actions/bStamp/globalSettings/getbStampGlobalSettingsAPI";
import { UpdatebStampGlobalSettingAPI } from "../../../../actions/bStamp/globalSettings/updatebStampGlobalSettingAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames, validationMessages } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import ConfirmationModal from "../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";

const BStampGlobalSetting = () => {
  const dispatch = useDispatch();

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const formik = useFormik({
    initialValues: {
      underMaintenance: false,
      _id: ""
    },
    validationSchema: Yup.object().shape({
      DE: Yup.object().required(validationMessages.firstName.required),
      EN: Yup.object().required(validationMessages.firstName.required)
    }),
    onSubmit: (values: any) => {}
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    getGloablSettingAction();
    // eslint-disable-next-line
  }, []);

  const getGloablSettingAction = () => {
    dispatch(setLoading(true));
    GetbStampGlobalSettingAPI().then((res: any) => {
      if (res.status === 200) {
        if (res?.code === 200 || res?.status === 200) {
          setFieldValue("underMaintenance", res?.data?.underMaintenance === 1 ? true : false);
        }
        dispatch(setLoading(false));
      }
    });
  };

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);
    dispatch(setLoading(true));
    let paramater = {
      [filedName]: data ? "1" : "0"
    };
    UpdatebStampGlobalSettingAPI(paramater)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue(filedName, data);
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };

  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.underMaintenance}
              onChange={(e: boolean) => {
                handleSwitch("underMaintenance", e, "Under Maintenance");
              }}
              label="Under Maintenance"
              disabled={handleAppActionPermission(projectNames.bstamp, projectModules[projectNames.bstamp].GLOBAL_SETTINGS, "update") || false}
              tooltip="Whole bStamp cannot be accessed by customer by enabling the under maintenace"
            />
          </div>
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default BStampGlobalSetting;
