import {
  addMarketPlaceApp,
  addShortCutAPI,
  adminUserListAPI,
  apiListAPI,
  applicationListAPI,
  deleteMarketAppApi,
  deleteShortCutAPI,
  editShortCutAPI,
  getAppShortcutsAPI,
  getMarketPlaceList,
  getProfileAPI,
  kycApproveAPI,
  kycDetails,
  kycListAPI,
  onPasswordUpdateAPI,
  onProfileUpdateAPI,
  rankShortCutAPI,
  universalModuleURls,
  updateMarketPlaceApp,
  updateMarketplaceAppOrder,
  userListAPI
} from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const getKYCList = (params?: any) => {
  return HTTPService.get(kycListAPI, { params });
};

export const getApplicationList = (params?: any) => {
  return HTTPService.get(applicationListAPI, { params });
};

// App shortcuts

export const getAppShortcutsListAPI = (params?: any) => {
  return HTTPService.get(getAppShortcutsAPI, { params });
};

export const getAppShortcutOfSpecificID = (params?: any) => {
  return HTTPService.get(`${getAppShortcutsAPI}?id=${params}`);
};

export const addappShortCut = (params: any) => {
  return HTTPService.post(addShortCutAPI, params);
};

export const deleteAppShortCut = (id: number) => {
  return HTTPService.delete(`${deleteShortCutAPI}?id=${id}`);
};

export const updateAppShortcut = (data: FormData) => {
  return HTTPService.put(editShortCutAPI, data);
};

export const updateAppOrder = (params: any) => {
  return HTTPService.put(rankShortCutAPI, params);
};

// Market Place Api's

export const getMarketPlaceListAPIinfo = (params?: any) => {
  return HTTPService.get(getMarketPlaceList, { params });
};

export const getSpecificMarketPlaceListAPIinfo = (params?: any) => {
  return HTTPService.get(`${getMarketPlaceList}?id=${params}`);
};

export const updateMarketPlaceAppAPI = (data: any) => {
  return HTTPService.put(updateMarketPlaceApp, data);
};

export const deleteMarketplaceAppApi = (id: number) => {
  return HTTPService.delete(`${deleteMarketAppApi}?id=${id}`);
};

export const addNewMarketPlaceApp = (params: any) => {
  return HTTPService.post(addMarketPlaceApp, params);
};

export const updateMarketplaceAppOrderAPI = (params: any) => {
  return HTTPService.put(updateMarketplaceAppOrder, params);
};

export const getAPIList = (params?: any) => {
  return HTTPService.get(apiListAPI, { params });
};

export const getUSerList = (params?: any) => {
  return HTTPService.get(userListAPI, { params });
};

export const getAdminUSerList = (params?: any) => {
  return HTTPService.get(adminUserListAPI, { params });
};

export const approveUserKyc = (params?: any) => {
  return HTTPService.put(kycApproveAPI, params);
};

export const getKYCDetails = (params: any) => {
  return HTTPService.get(kycDetails, { params });
};

export const onChangePassword = (body: any) => {
  return HTTPService.put(onPasswordUpdateAPI, body);
};

export const onProfileUpdate = (body: any) => {
  return HTTPService.put(onProfileUpdateAPI, body);
};

export const getProfile = () => {
  return HTTPService.get(getProfileAPI);
};

// Dashboard getApi's

export const getDahboardInfo = (params?: any) => {
  return HTTPService.get(universalModuleURls.getDahboardInfo, { params });
};

export const getMonthlyRequestChartInfo = (params?: any) => {
  return HTTPService.get(universalModuleURls.getMonthlyRequestChartInfo, {
    params
  });
};

interface IgetUniversalList {
  search?: string;
  filter?: any;
  limit?: number;
  page?: number;
}

export const getUniversalList = (params?: IgetUniversalList) => {
  return HTTPService.get(universalModuleURls.getUniversalList, { params });
};

export const getUniversalDetail = (params?: any) => {
  return HTTPService.get(universalModuleURls.getUniversalList + params);
};

export const updateUniversalListStatus = (params: { status: number; id: number; reason?: string }) => {
  return HTTPService.patch(universalModuleURls.updateUniversalList + params.id, { status: params.status, reason: params.reason });
};

export const updateUniversalListStatusForApprovedRequest = (params: { status: number; id: number; values: any }) => {
  const requestBody = {
    status: params.status,
    ...(params.values?.userName && { userName: params.values?.userName }),
    ...(params.values?.password && { password: params.values?.password }),
    ...(params.values?.peerUrl && { peerUrl: params.values?.peerUrl }),
    ...(params.values?.exploreUrl && { exploreUrl: params.values?.exploreUrl }),
    ...(params.values?.certAuthUrl && { certAuthUrl: params.values?.certAuthUrl }),
    ...(params.values?.restApiUrl && { restApiUrl: params.values?.restApiUrl })
  };
  return HTTPService.patch(universalModuleURls.updateUniversalList + params.id, requestBody);
};

export const getSmartContracts = (params?: any) => {
  return HTTPService.get(universalModuleURls.getSmartContracts, { params });
};

export const getSmartContractsDetail = (params?: any) => {
  return HTTPService.get(universalModuleURls.getSmartContractsDetail + params);
};

export const updateSmartContract = (id: string, status: string, reason?: string) => {
  return HTTPService.patch(universalModuleURls.updateSmartContract + id, {
    status: +status,
    reason: reason
  });
};

export const getRequestHistoryList = (params: any) => {
  return HTTPService.get(universalModuleURls.getRequestHistoryList, { params });
};

export const getChannelList = (params?: any) => {
  return HTTPService.get(universalModuleURls.getChannelsList, { params });
};

export const smartContractsInstallations = (params?: any) => {
  return HTTPService.get(universalModuleURls.smartContractsInstallations, {
    params
  });
};

export const smartContractsInstallationsDetails = (params?: any) => {
  return HTTPService.get(universalModuleURls.smartContractsInstallationsDetails + params);
};

export const updateSmartContractsInstallations = (id: string, status: string, reason?: string) => {
  return HTTPService.patch(universalModuleURls.updateSmartContractsInstallations + id, { status: +status, reason: reason });
};

export const getChannelDetail = (params?: any) => {
  return HTTPService.get(universalModuleURls.getChannelDetail + params);
};

export const channelInvitation = (params?: any) => {
  return HTTPService.get(universalModuleURls.channelInvitation, { params });
};

export const channelInvitationDetail = (params?: any) => {
  return HTTPService.get(universalModuleURls.channelInvitationDetail + params);
};

export const updateChannelInvitation = (id: string, status: string, reason?: string) => {
  return HTTPService.patch(universalModuleURls.updateChannelInvitation + id, {
    status: +status,
    reason: reason
  });
};

export const getChaincodeList = (params: any) => {
  return HTTPService.get(universalModuleURls.getChaincodeList, { params });
};

export const getChaincodeDetail = (params: any) => {
  return HTTPService.get(universalModuleURls.getChaincodeDetail + params);
};

export const updateChaincode = (id: string, status: string, reason?: string) => {
  return HTTPService.patch(universalModuleURls.updateChaincode + id, {
    status: +status,
    reason: reason
  });
};

export const getPeers = (params: any) => {
  return HTTPService.get(universalModuleURls.getPeers, { params });
};

export const getPeerDetails = (params: any) => {
  return HTTPService.get(universalModuleURls.getPeerDetails + params);
};

export const updatePeerDetails = (id: string, status: string, reason?: string) => {
  return HTTPService.patch(universalModuleURls.updatePeerDetails + id, {
    status: +status,
    reason
  });
};

export const getTemplateListing = (params?: any) => {
  return HTTPService.get(universalModuleURls.getTemplateListing, { params });
};

export const getTemplateDetail = (params?: any) => {
  return HTTPService.get(universalModuleURls.getTemplateDetail + params);
};

export const updateTemplate = (params: any) => {
  return HTTPService.patch(universalModuleURls.updateTemplate + params.id, params);
};

export const updateChannelDetail = (params: any) => {
  return HTTPService.patch(universalModuleURls.updateChannelDetail + params.id, { status: params.status });
};

export const addTemplate = (params: any) => {
  return HTTPService.post(universalModuleURls.addTemplate, params);
};

// Domains Api's
export const getDomainsList = (params?: any) => {
  return HTTPService.get(universalModuleURls.getDomainsList, { params });
};

export const addDomain = (params: any) => {
  return HTTPService.post(universalModuleURls.addDomain, params);
};

export const getDetailOfDomainsList = (params?: any) => {
  return HTTPService.get(universalModuleURls.getDetailOfDomainsList + params);
};

export const updateDomain = ({ id, ...rest }: any) => {
  return HTTPService.patch(universalModuleURls.updateDomain + id, rest);
};

// Regions Api's

export const getRegionsList = (params?: any) => {
  return HTTPService.get(universalModuleURls.getRegionsList, { params });
};

export const addRegion = (params: FormData) => {
  return HTTPService.post(universalModuleURls.addRegion, params);
};

export const getDetailOfRegion = (params?: any) => {
  return HTTPService.get(universalModuleURls.getDetailOfRegion + params);
};

export const updateRegion = (params: FormData, id: string) => {
  return HTTPService.patch(universalModuleURls.updateRegion + id, params);
};
