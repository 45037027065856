import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Spinner } from "reactstrap";
import { projectNames } from "../common/constants";
import { setCurrentProject } from "../store/project/action";
import { PrivateRouteComponent } from "./ProtectedRouteComponent";
import { PublicRouteComponent } from "./PublicRouteComponent";
import adminPublicRoutes, { bTrackPrivateRoutes } from "./Routes";
import banjiPrivateRoutes from "./derivedPrivateRoutes/BanjiPrivateRoutes";
import bMessagePrivateRoutes from "./derivedPrivateRoutes/BmessagePrivateRoutes";
import bStampPrivateRoutes from "./derivedPrivateRoutes/BstampPrivateRoutes";
import bvotePrivateRoutes from "./derivedPrivateRoutes/BvotePrivateRoutes";
import bYouPrivateRoutes from "./derivedPrivateRoutes/ByouPrivateRoutes";
import NftPrivateRoutes from "./derivedPrivateRoutes/NftPrivateRoutes";
import StakingPrivateRoutes from "./derivedPrivateRoutes/StakingPrivateRoutes";
import universePrivateRoutes from "./derivedPrivateRoutes/UniversePrivateRoutes";

function RoutingComponent() {
  const handleErrors = () => {
    // prevent production and staging console and warnings
    if (process.env.REACT_APP_ENV === "PRODUCTION" || process.env.REACT_APP_ENV === "STAGING") {
      console.log = () => {};
      console.error = () => {};
      console.debug = () => {};
      console.warn = () => {};
    }
  };
  handleErrors();

  const dispatch = useDispatch();

  const { isLoggedIn, loading } = useSelector((state: any) => ({
    isLoggedIn: state.auth.isLoggedIN,
    loading: state.loading.isLoading
  }));
  const { currentProject } = useSelector((state: any) => state.projectNameReducer);
  // const { currentProject } = useSelector((state: any) => state.projectNameReducer);
  // check if user have the permission of current project, if not then redirect to root
  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);

  /**
   * Renders Private routes of given project
   * @param projectName
   * @returns React.Component
   */
  const renderProjectPrivateRoutes = (projectName: string) => {
    // if currentPermissions don't have the permissions for the currentSelectedProject then do not render any route
    // and It will redirect the user to dashboard
    if (Object.keys(currentPermission)?.includes(projectName)) {
      if (projectName === projectNames.edexanetwork) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={StakingPrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.bvote) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={bvotePrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.bmessage) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={bMessagePrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.nft) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={NftPrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.bstamp) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={bStampPrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.byou) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={bYouPrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.universe) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={universePrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.edexanetwork) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={StakingPrivateRoutes} Switch={Switch} />;
      } else if (projectName === projectNames.btrack) {
        return <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={bTrackPrivateRoutes} Switch={Switch} />;
      } else {
        dispatch(setCurrentProject(projectNames.admin));
      }
    } else {
      // redirect user to root if user don't have the permissions for the current selected project
      // - Although user will not see the option to go to that module, but in case he/she visit with direct url
      // clear the local storage so It will not make a infinite loop of reloading
      localStorage.clear();
      window.location.href = "/";
    }
  };
  /**
   * Renders Public routes of given project
   * @param projectName - string
   * @returns React.Component
   */
  const renderProjectPublicRoutes = (projectName: string) => {
    return <PublicRouteComponent Route={Route} Redirect={Redirect} publicRoutes={adminPublicRoutes} Switch={Switch} />;
  };

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      )}
      <Suspense fallback={<p>Loading...</p>}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {/* global toast message container */}
        <Toaster />
        <Router>{isLoggedIn ? renderProjectPrivateRoutes(currentProject) : renderProjectPublicRoutes(currentProject)}</Router>
      </Suspense>
    </>
  );
}

export default RoutingComponent;
