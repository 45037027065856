import { faApple, faChrome, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faArrowRotateForward, faGlobe, faPersonCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { deleteBlogsAPI } from "../../../../actions/staking/blogs/deleteBlogsAPI";
import { deleteCategoryAPI } from "../../../../actions/staking/category/deleteCategoryAPI";
import { StakingDeleteMarketPlaceAPI } from "../../../../actions/staking/marketPlace/stakingDeleteMarketPlaceAPI";
import AppStore from "../../../../assets/images/app-store.png";
import ChromeExtension from "../../../../assets/images/chrome-extension.png";
import PlayStore from "../../../../assets/images/playstore.png";
import WebApp from "../../../../assets/images/web-app.png";
import { getFormattedDate, getKyCStatus, handleData, handleQueryParameterData, toastSuccess } from "../../../../common/common-functions";
import { edeXaNetwork_page, projectModules, projectNames, rowsPerPage } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import EmailComponent from "../../../../utils/components/EmailComponent";
import CustomTooltip from "../../../common/ToolTip";
import { DeleteRestricted, EditRestricted } from "../../../common/restrictions";
import ConfirmationModal from "../../DeleteConfirmationModal";
import "../../component/kycDetails.css";

export const StakingTableComponent = ({ recall, listData, totalCount, tableHeaders, type, paginationConfig, setPaginationConfig, handleDrop }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: IRootReducerTypes) => ({
    isLoading: state.loading.isLoading
  }));

  const [dropdownPage, setDropdownPage] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);

  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  // eslint-disable-next-line no-unused-vars
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values: any) => {}
  });

  const handleAvailableAppImages = (availableOn: string, url: string, index: number) => {
    if (availableOn === "Chrome App") {
      return (
        <>
          <CustomTooltip direction="top" content={"Chrome Extension"}>
            <img className="app-icons cursor-pointer" src={ChromeExtension} alt="ChromeExtension" onClick={() => window.open(url)} />
          </CustomTooltip>
        </>
      );
    } else if (availableOn === "Website") {
      return (
        <>
          <CustomTooltip direction="top" content={"Web App"}>
            <img className="app-icons cursor-pointer" src={WebApp} alt="Web App" onClick={() => window.open(url)} />
          </CustomTooltip>
        </>
      );
    } else if (availableOn === "Ios App") {
      return (
        <>
          <CustomTooltip direction="top" content={"AppStore"}>
            <img className="app-icons cursor-pointer" src={AppStore} alt="AppStore" onClick={() => window.open(url)} />
          </CustomTooltip>
        </>
      );
    } else if (availableOn === "Android App") {
      return (
        <>
          <CustomTooltip direction="top" content={"PlayStore"}>
            <img className="app-icons cursor-pointer" src={PlayStore} alt="PlayStore" onClick={() => window.open(url)} />
          </CustomTooltip>
        </>
      );
    } else if (availableOn === "Android App") {
      return (
        <>
          <CustomTooltip direction="top" content={"PlayStore"}>
            <img className="app-icons cursor-pointer" src={PlayStore} alt="PlayStore" onClick={() => window.open(url)} />
          </CustomTooltip>
        </>
      );
    } else if (availableOn === "Demo Request") {
      return (
        <>
          <CustomTooltip direction="top" content={"Demo Request"}>
            <FontAwesomeIcon
              key={index}
              className={` ${url && "cursor-pointer"}  ${index && index !== 0 ? "ml-10" : null}`}
              icon={faPersonCircleQuestion}
              onClick={() => (url ? window.open(url, "_blank") : null)}
            />
          </CustomTooltip>
        </>
      );
    }
  };

  const handleIcons = (icon: string, url?: string, index?: number) => {
    if (icon?.includes("fa-apple")) {
      return (
        <FontAwesomeIcon
          title="Ios App"
          key={index}
          className={` ${url && "cursor-pointer"} ${index && index > 0 ? "ml-10" : null}`}
          icon={faApple}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    } else if (icon?.includes("fa-google-play")) {
      return (
        <FontAwesomeIcon
          title="Android App"
          key={index}
          className={` ${url && "cursor-pointer"} ${index && index > 0 ? "ml-10" : null}`}
          icon={faGooglePlay}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    } else if (icon?.includes("fa-globe")) {
      return (
        <FontAwesomeIcon
          key={index}
          title="Website"
          className={`text-primary ${url && "cursor-pointer"}  ${index && index !== 0 ? "ml-10" : null}`}
          icon={faGlobe}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    } else if (icon?.includes("fa-person-circle-question")) {
      return (
        <FontAwesomeIcon
          key={index}
          title="Demo Request"
          className={` ${url && "cursor-pointer"}  ${index && index !== 0 ? "ml-10" : null}`}
          icon={faPersonCircleQuestion}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    } else if (icon?.includes("fa-chrome")) {
      return (
        <FontAwesomeIcon
          key={index}
          title="Chrome App"
          className={` ${url && "cursor-pointer"}  ${index && index !== 0 ? "ml-10" : null}`}
          icon={faChrome}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          key={index}
          title="Redirect"
          className={` ${url && "cursor-pointer"}  ${index && index !== 0 ? "ml-10" : null}`}
          icon={faArrowRotateForward}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        />
      );
    }
  };

  const deleteCategoryAction = (id: string) => {
    dispatch(setLoading(true));
    StakingDeleteMarketPlaceAPI(id).then((res) => {
      if (res.status === 200) {
        dispatch(resetVisible());
        if (recall) {
          recall();
        }
        toastSuccess(res.message);
      }
    });
  };

  const deleteBlogAction = (id: string) => {
    dispatch(setLoading(true));
    deleteBlogsAPI(id).then((res) => {
      if (res.status === 200) {
        if (recall) {
          recall();
        }
        dispatch(resetVisible());
        toastSuccess(res.message);
      }
    });
  };
  const deleteCategoryActionBlog = (id: string) => {
    dispatch(setLoading(true));

    deleteCategoryAPI(id, {
      isDelete: true
    }).then((res) => {
      if (res.status === 200) {
        if (recall) {
          recall();
        }
        dispatch(resetVisible());
        toastSuccess(res.message);
      }
    });
  };

  const renderView = (list: any, i: any) => {
    if (type === "AvailableAppsManagement") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list.name}</td>
          <td className="font-16 color-light-grey">{handleIcons(list?.icon)}</td>
          <td className={`font-16 color-light-grey pl-35 position-relative status-data ${list.kyc === 0 && "cursor-pointer"}`}>
            <span className={`status ${getKyCStatus(list?.visible ? 4 : 5).class}`}></span>
            {getKyCStatus(list?.visible ? 4 : 5).label}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer mr-10"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => history.push(`/${projectNames.edexanetwork}/available-apps-management/${list?._id}`, { type: "availableApps" })}></i>
            <i
              className="fas fa-edit color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => {
                history.push({
                  pathname: `/${projectNames.edexanetwork}/edit-app/${list?._id}`,
                  state: { userId: list?._id }
                });
              }}></i>
          </td>
        </tr>
      );
    } else if (type === "ContactusManagement") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list?.firstName ? list?.firstName + " " + list?.lastName : list?.email}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={list?.email} />
          </td>
          <td className="font-16 color-light-grey">{list?.message || "-"}</td>
          <td className="font-16 color-light-grey">{handleQueryParameterData(list?.queryType) || "-"}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() =>
                history.push(`/${projectNames.edexanetwork}/contact-us/${list?._id}`, {
                  type: "contactUs"
                })
              }></i>
          </td>
        </tr>
      );
    } else if (type === "DappsManagement") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list?.dAppName}</td>
          <td className="font-16 color-light-grey">{list?.blockchain}</td>
          <td className="font-16 color-light-grey">{list?.companyName}</td>
          <td className="font-16 color-light-grey">{list?.fullName}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() => history.push(`/${projectNames.edexanetwork}/dapp-customer-request/${list?._id}`, { type: "dapps" })}></i>
          </td>
        </tr>
      );
    } else if (type === "marketplace") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">{list?.availableOn?.map((data: any, index: number) => handleAvailableAppImages(data?.name, data?.url, index))}</td>
          <td className="font-16 color-light-grey">
            <p className="text-primary" onClick={() => (list?.documentUrl !== "null" && list?.documentUrl ? window.open(list?.documentUrl, "_blank") : null)}>
              {list?.documentUrl === "null" ? "-" : list?.documentUrl === "" ? "-" : list?.documentUrl === null ? "-" : list?.documentUrl && <span className="cursor-pointer">Link</span>}
            </p>
          </td>
          <td className="font-14 blue fw-bold">
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames?.edexanetwork].MARKETPLACEMANAGEMENT}>
              <i
                className="fas fa-edit color-blue cursor-pointer mr-10"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() =>
                  history.push(`/${projectNames.edexanetwork}/market-place/${list?.id}`, {
                    type: "marketPlace"
                  })
                }></i>
            </EditRestricted>
            {/* @ts-ignore */}
            <DeleteRestricted module={projectModules[projectNames?.edexanetwork].MARKETPLACEMANAGEMENT}>
              <i
                className="fas fa-trash-alt color-blue cursor-pointer"
                title="Delete"
                onClick={() => {
                  dispatch(
                    setVisible({
                      isVisible: true,
                      deleteId: list?.id,
                      label: `Are you sure you want to delete ${list?.name} market-place Application?`,
                      deleteStatus: 1
                    })
                  );
                  setDeleteActionFunction({ fnCall: deleteCategoryAction });
                }}></i>
            </DeleteRestricted>
          </td>
        </tr>
      );
    } else if (type === "ValidateManagement") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list?.companyName}</td>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={list?.email} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-16 color-light-grey">{`${handleData(list?.status)}`}</td>
          {/* <td className="font-16 color-light-grey">{`${list?.status === "3" ? "Required" : "Not Required"}`}</td> */}
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() =>
                history.push(`/${projectNames.edexanetwork}/validate-request/${list?._id}`, {
                  type: "validators"
                })
              }></i>
          </td>
        </tr>
      );
    } else if (type === "demoRequest") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={list?.email} />
          </td>
          <td className="font-16 color-light-grey">{list?.organizationName}</td>
          <td className="font-16 color-light-grey">{list?.description}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-eye color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
              style={{ cursor: "pointer", fontWeight: "700" }}
              onClick={() =>
                history.push(`/${projectNames.edexanetwork}/app-demo-request/${list?.id}`, {
                  type: "validators"
                })
              }></i>
          </td>
        </tr>
      );
    } else if (type === edeXaNetwork_page.BLOGS) {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey text-truncate" title={list?.title?.length > 27 ? list?.title : null}>
            {list?.title}
          </td>
          <td className="font-16 color-light-grey text-truncate" title={list?.shortDescription?.length > 27 ? list?.shortDescription : null}>
            {list?.shortDescription}
          </td>
          <td className="font-16 color-light-grey">{list?.authorData?.name || "-"}</td>
          <td className="font-16 color-light-grey text-truncate" title={list?.category?.name?.length > 22 ? list?.category?.name : null}>
            {list?.category?.name || "-"}
          </td>
          <td className={`font-16 color-light-grey pl-35 position-relative status-data ${list.kyc === 0 && "cursor-pointer"}`}>
            <span className={`status ${getKyCStatus(list?.isAvailable ? 1 : 5).class}`}></span>
            {list?.isAvailable ? "Publish" : "Non-Publish"}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue fw-bold">
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT}>
              <i
                className="fas fa-edit color-blue cursor-pointer mr-10"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => history.push(`/${projectNames.edexanetwork}/news/${list?.id}`)}></i>
            </EditRestricted>
            {/* @ts-ignore */}
            <DeleteRestricted module={projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT}>
              <i
                className="fas fa-trash-alt color-blue cursor-pointer"
                title="Delete"
                onClick={() => {
                  dispatch(
                    setVisible({
                      isVisible: true,
                      deleteId: list?.id,
                      label: `Are you sure you want to delete ${list?.title} News?`,
                      deleteStatus: 1
                    })
                  );
                  setDeleteActionFunction({ fnCall: deleteBlogAction });
                }}></i>
            </DeleteRestricted>
          </td>
        </tr>
      );
    } else if (type === edeXaNetwork_page.CATEGORY) {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey text-truncate" title={list?.name?.length > 47 ? list?.name : null}>
            {list?.name}
          </td>
          <td className={`font-16 color-light-grey pl-35 position-relative status-data ${list.kyc === 0 && "cursor-pointer"}`}>
            <span className={`status ${getKyCStatus(list?.visible ? 1 : 5).class}`}></span>
            {!list?.visible ? "Non-Visible" : "Visible"}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue fw-bold">
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT}>
              <i
                className="fas fa-edit color-blue cursor-pointer mr-10"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => history.push(`/${projectNames.edexanetwork}/category/${list?.id}`)}></i>
            </EditRestricted>
            {/* @ts-ignore */}
            <DeleteRestricted module={projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT}>
              {list?.deleteAccess && (
                <i
                  className="fas fa-trash-alt color-blue cursor-pointer"
                  title="Delete"
                  onClick={() => {
                    dispatch(
                      setVisible({
                        isVisible: true,
                        deleteId: list?.id,
                        label: `Are you sure you want to delete ${list?.name} Category?`,
                        deleteStatus: 1
                      })
                    );
                    setDeleteActionFunction({
                      fnCall: deleteCategoryActionBlog
                    });
                  }}></i>
              )}
            </DeleteRestricted>
          </td>
        </tr>
      );
    }
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };

  const onHandleRowPerPageChange = (el: any) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  return (
    <>
      <Scrollbars
        autoHide
        style={{ height: 630 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        className="table-section border-grey bvote-table">
        <Table>
          <thead>
            <tr>
              {tableHeaders.map((header: any, index: number) => {
                return (
                  <th key={index} className="color-black1 font-14 fw-bold">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData?.length > 0 ? (
              listData?.map((list: any, i: number) => {
                return renderView(list, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination w-100 mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10" />
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount}`}</p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? goToFirstPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? handlePrevious() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          {/* @ToDo
                  This component generates error for onclick.
                */}
          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? handleNext() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? goToLastPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>

      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};
