import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { manageGroups } = bvoteURL;

const { listing, list, add, view, update, deleteURL } = manageGroups;

// gorup listing api
export const onListingAPI = (params: any) => {
  return HTTPService.get(listing, {
    params
  });
};

// gorup add api
export const onAddAPI = (body: any) => {
  return HTTPService.post(add, body);
};

// gorup view api
export const onViewAPI = (params: any) => {
  return HTTPService.get(view + params);
};

// gorup update api
export const onUpdateAPI = (params: any, body: any) => {
  return HTTPService.put(update + params, body);
};

// gorup delete api
export const onDeleteGroupAPI = (body: string) => {
  return HTTPService.post(deleteURL, body);
};

// group list api
export const onGroupsListAPI = () => {
  return HTTPService.get(list);
};
export const importVoters = (params: FormData) => {
  return HTTPService.post(bvoteURL.poll.importVoters, params);
};
