import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { getCategoryManagementAPI } from "../../../../actions/nft/categoryManagement/getCategoryAPI";
import MetaFunction from "../../../../common/MetaTag";
import { CREATEDATE } from "../../../../common/constants";
import { categoryModalCallAPIAction } from "../../../../store/categoryModal/action";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import Footer from "../../../common/Footer";
import SearchFilter from "../../banji/component/SearchFilter";
import { BvoteTableComponent } from "../common/ListTable";
import CategoryModal from "./CategoryModal";

const tableHeaders = ["Category Name", "Order", "Total NFTs", CREATEDATE, "Actions"];

const CategoryManagement = () => {
  const module = "Category Management";
  const dispatch = useDispatch();
  const categoryState = useSelector((state: IRootReducerTypes) => state.categoryModalReducer);

  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState<paginationObject>({
    page: 1,
    limit: 10,
    search: ""
  });

  useEffect(() => {
    getCategoryManagementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  useEffect(() => {
    if (categoryState.callAPI) {
      getCategoryManagementList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryState.callAPI]);
  const getCategoryManagementList = () => {
    dispatch(setLoading(true));
    let paginationConfigTemp: any = {};
    if (search) {
      paginationConfigTemp = paginationConfig;
    } else {
      paginationConfigTemp.page = paginationConfig.page;
      // eslint-disable-next-line no-self-assign
      paginationConfigTemp.limit = paginationConfig.limit;
    }
    getCategoryManagementAPI(paginationConfigTemp).then((res: any) => {
      if (res?.status === 200) {
        setTotalListCount(res.count);
        setListingData(res.data);
        dispatch(categoryModalCallAPIAction(false));
      }
      dispatch(setLoading(false));
    });
  };

  const resetFilters = () => {
    if (search === "" && paginationConfig?.search === "") {
    } else {
      setSearch("");
      setPaginationConfig({ page: 1, limit: 10, search: "" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter placeHolder="Search By Category Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                {/* <CreateRestricted module={projectModules[projectNames.nft].CATEGORY_MANAGEMENT}> */}
                  {/* <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => dispatch(categoryModalIsOpenAction(true))}>
                    Add
                  </button> */}
                {/* </CreateRestricted> */}
              </div>
              {/* @ts-ignore */}
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"category-management"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getCategoryManagementList}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
      <CategoryModal />
    </>
  );
};

export default CategoryManagement;

interface paginationObject {
  search?: string;
  page: number;
  limit: number;
}
