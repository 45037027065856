import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { poll } = bvoteURL;

const { listing, showLiveResult, view, add, update, deleteURL } = poll;

// poll listing api
export const onListingAPI = (params: any) => {
  return HTTPService.get(listing, {
    params
  });
};

// poll add api
export const onAddAPI = (body: any) => {
  return HTTPService.post(add, body);
};

// poll view api
export const onViewAPI = (params: any) => {
  return HTTPService.get(view + params);
};

// poll live result show change api
export const onShowLiveResultAPI = (body: any) => {
  return HTTPService.post(showLiveResult, body);
};

// poll update api
export const onUpdateAPI = (params: any, body: any) => {
  return HTTPService.put(update + params, body);
};

// poll delete api
export const onDeletePollAPI = (body: any) => {
  return HTTPService.post(deleteURL, body);
};
