import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingApplicationsMarketPlaceAPI = (data?: any): Promise<IStakingApplicationsMarketPlaceAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.marketPlace.applications, data);
};

interface IStakingApplicationsMarketPlaceAPIData {
  appId: string;
  id: string;
  name: string;
}

interface IStakingApplicationsMarketPlaceAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IStakingApplicationsMarketPlaceAPIData[];
  };
}
