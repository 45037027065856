/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Input, Label, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";
import { sendEmailAPI } from "../../../../../actions/staking/validators/stackingSendEmailApi";
import { handleLabel, handleTrim, toastSuccess } from "../../../../../common/common-functions";
import { validatorMailReasonModalOption, validatorReasonModalOption, validatorStatus } from "../../../../../common/constants";
import { setVisible } from "../../../../../store/confirmationModule/action";
import { setLoading } from "../../../../../store/loader/action";
import ConfirmationModal from "../../../DeleteConfirmationModal";
import { WalletAddressYupValidationSchema } from "./WalletAddressYupValidationSchema";

interface IValidatorStatusModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAction: (id: string) => void;
  data: any;
}
const ValidatorStatusModal: FC<IValidatorStatusModal> = ({ isOpen, setIsOpen, getAction, data }) => {
  const Status0 = [
    { label: "Send Email", value: validatorStatus.EMAIL_SENT },
    { label: "Rejected", value: validatorStatus.REJECTED }
  ];
  const Status3 = [
    { label: "In-progress", value: validatorStatus.IN_PROGRESS },
    { label: "Rejected", value: validatorStatus.REJECTED }
  ];
  const Status4 = [
    { label: "Node added as Validator", value: validatorStatus.NODE_VALIDATOR },
    { label: "Rejected", value: validatorStatus.REJECTED }
  ];
  const Status6 = [
    { label: "Node added as Observer", value: validatorStatus.NODE_OBSERVER },
    { label: "Rejected", value: validatorStatus.REJECTED }
  ];
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [status, setStatus] = useState<{ label: string; value: string } | null>(null);
  const [deleteActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const handleToggle = () => {
    setIsOpen(false);
    setStatus(null);
    setOptions([]);
    useReason.resetForm();
  };

  const handleStatus = (status: String) => {
    if (String(status) === validatorStatus.PENDING) {
      setOptions(Status0);
    } else {
      if (String(status) === validatorStatus.SUPPORT_SYSTEM) {
        setOptions(Status3);
      }
      if (String(status) === validatorStatus.NODE_OBSERVER) {
        setOptions(Status4);
      }
      if (String(status) === validatorStatus.NODE_VALIDATOR) {
        setOptions([{ label: "Node as Validator", value: validatorStatus.NODE_VALIDATOR }]);
      }
      if (String(status) === validatorStatus.IN_PROGRESS) {
        setOptions(Status6);
      }
    }
  };

  const dispatch = useDispatch();
  const params: any = useParams();

  const handleCloseModal = () => {
    dispatch(
      setVisible({
        isVisible: false,
        deleteId: "",
        label: "",
        deleteStatus: ""
      })
    );
  };

  const handleAction = (data: any) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: data,
        label: handleLabel(String(data?.status)),
        deleteStatus: ""
      })
    );
    setDeleteActionFunction({
      fnCall: (reason: any) => {
        dispatch(setLoading(true));
        sendEmailAPI(params?.id, { ...reason })
          .then((res: any) => {
            if (res.status === 200) {
              toastSuccess(res?.message);
              handleToggle();
              handleCloseModal();
              getAction(params?.id);
            }
          })
          .catch((err) => {
            handleToggle();
            handleCloseModal();
          });
      }
    });
  };

  const useWalletAddress = useFormik({
    initialValues: {
      walletAddress: ""
    },
    validationSchema: WalletAddressYupValidationSchema,
    onSubmit: (value) => {
      let tempData = { walletAddress: value.walletAddress, status: "6" };
      handleAction(tempData);
    }
  });
  const { errors, touched, handleBlur, setFieldValue, values, handleSubmit, resetForm } = useWalletAddress;

  const useReason = useFormik({
    initialValues: {
      reason: "",
      option: ""
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required").min(10, "Minimum 10 characters").max(250, "Maximum 250 characters"),
      option: Yup.string().required("Option is required")
    }),
    onSubmit: (value) => {
      handleAction({ status: status?.value, reason: useReason.values.reason });
    }
  });

  useEffect(() => {
    status?.value === validatorStatus?.IN_PROGRESS && resetForm();
  }, [status?.value]);

  useEffect(() => {
    isOpen && handleStatus(String(data?.status));
  }, [String(data?.status), isOpen]);

  const [optionReason, setOptionReason] = useState<String[]>([]);

  useEffect(() => {
    if (String(data?.status) === validatorStatus.PENDING) {
      setOptionReason(validatorMailReasonModalOption);
    } else {
      setOptionReason(validatorReasonModalOption);
    }
  }, [String(data?.status)]);

  return (
    <>
      <Modal isOpen={isOpen} size="lg" centered toggle={handleToggle}>
        <ModalBody className="p-4 min-height-150">
          <h3 className="fw-bold font-26">Change Status</h3>
          <Select
            isDisabled={String(data?.status) === "2" ? true : String(data?.status) === "5" ? true : false}
            placeholder="Select your status"
            value={status}
            onChange={(e) => setStatus(e)}
            options={options}
            className="z-index w-100"
          />
          {status?.value === validatorStatus.EMAIL_SENT ? <p className="text-secondary">Note: An email containing an invitation link will be sent to the customer.</p> : null}
          {status?.value === validatorStatus.IN_PROGRESS ? (
            <>
              <Input
                name="walletAddress"
                className="mt-20"
                placeholder="Enter validator wallet address"
                invalid={Boolean(touched?.walletAddress && errors?.walletAddress)}
                onBlur={handleBlur}
                value={values.walletAddress}
                onChange={(e) => handleTrim("walletAddress", e.target.value, setFieldValue)}
              />
              {touched?.walletAddress ? (
                errors?.walletAddress ? (
                  <div className="text-danger mt-1 mb-10">
                    <span>{errors?.walletAddress}</span>
                  </div>
                ) : null
              ) : null}
            </>
          ) : null}
          {status?.value === validatorStatus.REJECTED ? (
            <>
              {useReason?.touched?.option && useReason?.errors?.option ? <div className="error-section text-danger mt-10">At least one reason required</div> : null}
              {optionReason?.map((item, index) => {
                return (
                  <div className="mb-10 mt-10" key={index}>
                    <Label className="mr-15 cursor-pointer" check>
                      <Input
                        type="radio"
                        value={String(item)}
                        onClick={() => {
                          item === "Other" ? useReason?.setFieldValue("reason", "") : useReason?.setFieldValue("reason", item);
                          useReason?.setFieldValue("option", item);
                        }}
                        className="mr-10"
                        name="reason"
                        key={index}
                      />
                      {item}
                    </Label>
                  </div>
                );
              })}
              {useReason?.values?.option === "Other" ? (
                <>
                  <Input
                    type="textarea"
                    name="reason"
                    placeholder="Enter your reason"
                    invalid={Boolean(useReason?.touched?.reason && useReason?.errors?.reason)}
                    onBlur={handleBlur}
                    value={useReason?.values?.reason}
                    onChange={(e) => handleTrim("reason", e.target.value, useReason?.setFieldValue)}
                  />
                  {useReason?.touched?.reason ? (
                    useReason?.errors?.reason ? (
                      <div className="text-danger mt-1 mb-10">
                        <span>{useReason?.errors?.reason}</span>
                      </div>
                    ) : null
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
          <div className="d-flex mt-20 justify-content-end">
            <button className="btn border-blue button-width-com" onClick={handleToggle}>
              Cancel
            </button>
            {status?.value === validatorStatus.IN_PROGRESS ? (
              <button className="btn btn-primary bg-blue border-0 shadow-none  ml-10" type="button" onClick={() => handleSubmit()}>
                Add Wallet Address
              </button>
            ) : status?.value === validatorStatus.REJECTED ? (
              <button
                className="btn btn-primary bg-blue border-0 shadow-none ml-10 button-width-135"
                onClick={() => {
                  useReason.handleSubmit();
                }}>
                Rejected
              </button>
            ) : (
              <button
                disabled={Boolean(!status?.value)}
                className="btn btn-primary bg-blue border-0 shadow-none ml-10 button-width-135"
                onClick={() => {
                  handleAction({ status: status?.value });
                }}>
                {status?.value === validatorStatus.EMAIL_SENT ? "Send Email" : "Update"}
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
      <ConfirmationModal handleDeleteAction={deleteActionFunction.fnCall} />
    </>
  );
};

export default ValidatorStatusModal;
