import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bTrackGetGloablSettingAPI } from "../../../actions/bTrack/globalSetting/bTrackGetGloablSetting";
import { bTrackUpdateGloablSettingAPI } from "../../../actions/bTrack/globalSetting/bTrackUpdateGloablSetting";
import MetaFunction from "../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../common/common-functions";
import { projectModules, projectNames } from "../../../common/constants";
import { resetVisible, setVisible } from "../../../store/confirmationModule/action";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import ConfirmationModal from "../DeleteConfirmationModal";
import SwitchGlobalSetting from "../SwitchGlobalSetting";

const BtrckGlobalSetting = () => {
  const dispatch = useDispatch();
  const [globalSetting, setGlobalSetting] = useState<any>({
    underMaintenance: false,
    data: {}
  });
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);
    dispatch(setLoading(true));
    let paramater = {
      [filedName]: data
    };
    bTrackUpdateGloablSettingAPI(globalSetting.data?.id, paramater)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          // setFieldValue(filedName, data);
          setGlobalSetting({ ...globalSetting, underMaintenance: data });
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };

  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    bTrackGetGloablSettingAPI().then((res) => {
      if (res.status === 200) {
        setGlobalSetting({
          underMaintenance: res.data.underMaintenance,
          data: res.data
        });
      }
      dispatch(setLoading(false));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaFunction meta={"Global Settings"} />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              // disabled={!currentPermission[projectNames.banji]?.globalsetting?.update}
              disabled={handleAppActionPermission(projectNames.btrack, projectModules[projectNames.btrack].GLOBAL_SETTINGS, "update")}
              checked={globalSetting?.underMaintenance}
              onChange={(e: any) => handleSwitch("underMaintenance", e, "Under Maintenance")}
              label="Under Maintenance"
              tooltip="Whole bTrack cannot be accessed by customer by enabling the under maintenace"
            />
          </div>
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default BtrckGlobalSetting;
