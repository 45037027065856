import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { getFormattedDate } from "../../../../common/common-functions";
import { projectNames, rowsPerPage } from "../../../../common/constants";

interface IAdminTableComponent {
  listData: any;
  totalCount: any;
  tableHeaders: any;
  type: any;
  paginationConfig: any;
  setPaginationConfig: any;
}
export const BtrackTableComponent = ({ listData, totalCount, tableHeaders, type, paginationConfig, setPaginationConfig }: IAdminTableComponent) => {
  const { isLoading } = useSelector((state: any) => ({
    isLoading: state.loading.isLoading
  }));
  const history = useHistory();
  const [dropdownPage, setDropdownPage] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);

  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  /**
   *
   * @param {Object} list
   * @param {String} type
   * @param {number} i - index
   * @returns
   */
  const renderView = (list: any, type: any) => {
    if (type === "Issue Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.group?.name}</td>
          <td className="font-16 color-light-grey">
            <img src={list?.image} alt={list?.group?.name} width="60" className="rounded-10" />
          </td>
          <td className="font-16 color-light-grey">{list?.reason}</td>
          <td className="font-16 color-light-grey">{list?.comment}</td>
          <td className="font-16 color-light-grey">{getFormattedDate(list?.createdAt)}</td>
          <td className="font-16 color-light-grey">
            <i className="fas fa-eye color-blue  cursor-pointer ps-3" onClick={() => history.push(`/${projectNames.btrack}/issue-management/${list._id}`)} />
          </td>
        </tr>
      );
    } else if (type === "API Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.name}</td>
        </tr>
      );
    }
  };
  /**
   * @start
   * @Talbe_Pagination
   */
  const onHandleRowPerPageChange = (el: any) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };
  /**
   * @end @Table_pagination
   */

  return (
    <>
      <Scrollbars
        style={{ height: 630 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        autoHide
        className="table-section admin-table border-grey">
        <Table responsive={false}>
          <thead>
            <tr>
              {tableHeaders.map((header: any, index: any) => {
                return (
                  <th key={index} className="color-black1 font-14 fw-bold">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData.length > 0 ? (
              listData.map((list: any, i: any) => {
                // @ts-ignore
                return renderView(list, type, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td colSpan={2}>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10"></i>
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount}`}</p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? goToFirstPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? handlePrevious() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? handleNext() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? goToLastPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};
