import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getDomainsList } from "../../../../actions/portal/adminActions";
import MetaFunction from "../../../../common/MetaTag";
import { toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, initialPaginationConfig, projectModules, projectNames } from "../../../../common/constants";
import { categoryModalIsOpenAction } from "../../../../store/categoryModal/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import { CreateRestricted } from "../../../common/restrictions";
import SearchFilter from "../../bvote/component/SearchFilter";
import { UniverseTableComponent } from "../common/ListTable";
import AppShortcutsListComponent from "./dragAndDropOfDomain/AppShortcutsListComponent";

const ManageDomainList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const module = "Manage Domains";
  const tableHeaders = ["Domain Name", "Visibility of Domain", "Enabled / Disabled", CREATEDATE, "Updated Date | Time", "Actions"];

  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });
  // eslint-disable-next-line
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    const { filter, limit, page } = paginationConfig;
    getDomainsList(
      searchField && filter && limit && page
        ? { search: searchField, filter, limit, page }
        : !searchField && filter && limit && page
        ? { filter, limit, page }
        : searchField && !filter && limit && page
        ? { search: searchField, limit, page }
        : !searchField && !filter && limit && page && { limit, page }
    ).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getCategoryManagementListFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const resetFilters = () => {
    if (searchField === "" && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter placeHolder="Search By Domain Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                <div>
                  <button className="btn border-blue  ml-10 button-width-big" onClick={() => toastSuccess("Coming Soon")}>
                    {isDragAndDrop ? "Back to App shortcuts" : "Manage Order"}
                  </button>
                  <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => history.push(`/${projectNames.universe}/create-domain`)}>
                    Create
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.nft].CATEGORY_MANAGEMENT}>
                  <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => dispatch(categoryModalIsOpenAction(true))}>
                    Add
                  </button>
                </CreateRestricted>
              </div>
              {isDragAndDrop ? (
                <AppShortcutsListComponent />
              ) : (
                <UniverseTableComponent
                  listData={listingData}
                  totalCount={totalListCount}
                  tableHeaders={tableHeaders}
                  type={"domains"}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ManageDomainList;
