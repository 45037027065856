import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onDashboardAPI } from "../../../actions/bvote/dashboardAction";
import MetaFunction from "../../../common/MetaTag";
import { projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import Footer from "../../common/Footer";
import TitleDashboard from "./TitleDashboard";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const [dashboard, setDashboard] = useState({
    groupCount: 0,
    pollCount: 0,
    voterCount: 0
  });

  const gatBvoteDashboardAction = () => {
    dispatch(setLoading(true));
    onDashboardAPI().then((res: any) => {
      if (res?.status === 200) {
        setDashboard(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    gatBvoteDashboardAction();
    //eslint-disable-next-line
  }, []);
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={"Dashboard"} />
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Dashboard</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard
            title="Total Poll"
            count={dashboard.pollCount}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bvote, projectModules[projectNames.bvote]?.POLL, `/${projectNames.bvote}/poll`, true)}
          />
          <TitleDashboard
            title="Total Voters"
            count={dashboard.voterCount}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bvote, projectModules[projectNames.bvote]?.MANAGE_VOTERS, `/${projectNames.bvote}/manage-voters`, true)}
          />
          <TitleDashboard
            title="Total Groups"
            count={dashboard.groupCount}
            cursor="pointer"
            width="32.5%"
            onClick={() => handleRedireactClick(projectNames.bvote, projectModules[projectNames.bvote]?.MANAGE_GROUPS, `/${projectNames.bvote}/manage-groups`, true)}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
