import React from "react";
import { addLabelNote } from "../../../../common/constants";

import { Input } from "reactstrap";

const AddLabelKey: React.FC<IAddLabelKey> = ({ labelState, setLabelState, handleLabelStateSubmit }) => {
  return (
    <div className="row my-4">
      <div className="col-4">
        <Input
          className="col-4"
          placeholder="Enter Label Key"
          type="text"
          onChange={(e) => {
            if (e.target.value.trim()) {
              setLabelState({ ...labelState, labelKey: e.target.value });
            } else {
              if (labelState.labelKey !== "") {
                setLabelState({ ...labelState, labelKey: "" });
              }
            }
          }}
          value={labelState.labelKey}
        />
      </div>
      <div className="col-4">
        <Input
          className="col-4"
          placeholder="Enter English value"
          type="text"
          onChange={(e) => {
            if (e.target.value.trim()) {
              setLabelState({ ...labelState, english: e.target.value });
            } else {
              if (labelState.english !== "") {
                setLabelState({ ...labelState, english: "" });
              }
            }
          }}
          value={labelState.english}
        />
      </div>
      <div className="col-4">
        <Input
          className="col-4"
          placeholder="Enter German value"
          type="text"
          onChange={(e) => {
            if (e.target.value.trim()) {
              setLabelState({ ...labelState, german: e.target.value });
            } else {
              if (labelState.german !== "") {
                setLabelState({ ...labelState, german: "" });
              }
            }
          }}
          value={labelState.german}
        />
      </div>
      <div>
        <p style={addLabelNote.style}>{addLabelNote.note}</p>
        <button className="btn-sm bg-grey rounded-5 py-2 px-3 font-14 border-none button-width-com mt-10" onClick={handleLabelStateSubmit}>
          Save
        </button>{" "}
        <button className="btn-sm bg-grey rounded-5 py-2 px-3 font-14 border-none button-width-com mt-10 ml-10" onClick={() => setLabelState({ labelKey: "", english: "", german: "", add: false })}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default React.memo(AddLabelKey);
export interface ILStateabel {
  labelKey: string;
  english: string;
  german: string;
  add: boolean;
}

interface IAddLabelKey {
  labelState: ILStateabel;
  setLabelState: React.Dispatch<React.SetStateAction<ILStateabel>>;
  handleLabelStateSubmit: () => void;
}
