import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { IRootReducerTypes } from "../../../store/rootRededucer";
import { adminNotificationReset } from "../../../store/socket/action";

const HeaderNotificationIcon = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const userState = useSelector((state: IRootReducerTypes) => state.userDetails.user);
  const { isNotificationPresent } = useSelector((state: IRootReducerTypes) => state.socketReducers);

  return (
    <Dropdown isOpen={false} className="d-flex header-noti align-items-center justify-content-center header-dropdown" toggle={() => history.push("/notification")}>
      <DropdownToggle className="menu-button w-100 h-100">
        {Number(userState?.notification) > 0 || isNotificationPresent ? <span className="notification-box"></span> : ""}
        <div className="notification-icon" onClick={() => dispatch(adminNotificationReset())}>
          <i title="Notification" className={`fas fa-bell ${location.pathname === "/notification" ? "notification-active" : "notification-inactive"} mt-2`} style={{ fontSize: "19px" }}></i>
        </div>
      </DropdownToggle>
      <DropdownMenu className="rounded-10 profile-list">
        <></>
      </DropdownMenu>
    </Dropdown>
  );
};

export default HeaderNotificationIcon;
