import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { listIssueAPI } from "../../../actions/bTrack/issue/listIssueAPI";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import SearchFilter from "../banji/component/SearchFilter";
import { BtrackTableComponent } from "./common/TableComponent";

const moduleName = "Issue Management";

const IssueManagement = () => {
  const dispatch = useDispatch();
  const tableHeaders = ["Name", "Image", "Reason", "Comment", CREATEDATE, "Actions"];

  const [totalListCount, setTotalListCount] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });

  const IssueListing = () => {
    dispatch(setLoading(true));
    let data: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      data.search = paginationConfig.search;
    }
    if (paginationConfig.filter) {
      data.filter = paginationConfig.filter;
    }
    listIssueAPI(data).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    IssueListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const handleClear = () => {
    if (searchField === "" && paginationConfig?.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "" });
    }
  };

  return (
    <>
      <MetaFunction meta={moduleName} />
      <div className="holders" style={{ overflow: "auto" }}>
        <p className="main-title m-b-20">{moduleName}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex flex-wrap role-select">
                {/**
                 * @Table @Filters
                 */}
                <div className="d-flex flex-wrap mb-20">
                  <SearchFilter placeHolder="Search By Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={handleClear}>
                    Clear
                  </button>
                </div>
              </div>
              <BtrackTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={moduleName}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default IssueManagement;
