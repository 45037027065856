export const userInfoAPI = "users/user-info?role=issuer";

export const adminInfoAPI = "admin/admin-info";

export const createHolderAPI = "users/register";

export const getPublicCredentialAPI = "users/public-cred-data";

export const getHoldersAPI = "admin/holders";

export const getVerifiersAPI = "admin/verifiers";

export const getStatusListAPI = "admin/statuslist";

export const getCredentialAPI = "admin/credentials";

export const issueCredListAPI = "issue/byme";

export const schemaListAPI = "schema/list";

export const SchemaCreateAPI = "schema/create";

export const issueCredCreateAPI = "issue/create";

export const getHolderAndVerifierAPI = "issue/predata";

export const getVerifierListAPI = "admin/verifiers";

export const createVerifierAPI = "users/register";

// admin features

const loginAPI = "admin/login";
const forPasswordAPI = "admin/forgot-password";
const dashboardAPI = "admin/dashboard";
const organizationListAPI = "admin/user/organization";
const getAppShortcutsAPI = "admin/user-shortcut";
const addShortCutAPI = "admin/user-shortcut";
const editShortCutAPI = "admin/user-shortcut";
const deleteShortCutAPI = "admin/user-shortcut";

// Rank is - Put method
const rankShortCutAPI = "admin/user-shortcut/rank";

const apiListAPI = "admin/apis";
const getAPIDataByIdAPI = "admin/apis/";
const getAPISubAPIListByIdAPI = "admin/apis/explorer/";
const getAPPDataByIdAPI = "admin/applications/";
const getAPIExplorerDataByIdAPI = "admin/apis/explorer/info/";
const updateAPIDataByIdAPI = "admin/apis/";
const updateAPPDataByIdAPI = "admin/applications/";
const updateAPIDescriptionByIdAPI = "admin/apis/explorer/";
const userListAPI = "admin/user/list";
const adminUserListAPI = "admin/get";
const userReadAPI = "admin/user/get/";
const userAppPermissionsReadAPI = "auth/apps/";
const userAppPermissionsUpdateAPI = "admin/user/app-access/";
const applicationListAPI = "admin/applications";
const globalChangeAdmin = "admin/settings/update";
const getMarketPlaceList = "admin/marketplace";
const addMarketPlaceApp = "admin/marketplace";
const updateMarketPlaceApp = "admin/marketplace/";
const deleteMarketAppApi = "admin/marketplace";
const updateMarketplaceAppOrder = "admin/marketplace/rank";

const globalSetingURL = "admin/settings/get";
const suspendURl = "admin/suspend";

// notification url
const onGetNotificationAPI = "admin/notification";
const onUpdateNotificationAPI = "admin/notification";
const onDeleteNotificationAPI = "admin/notification";

// kyc urls
const kycListAPI = "admin/kyc/get";
const kycApproveAPI = "admin/kyc/update";
const kycDetails = "admin/kyc/get";

const banjiAPIURLS = {
  dashboardAPI: "admin/admindashbaord",
  notification: "admin/notificationlist",
  sendNotification: "admin/notificationsend",
  newsListing: "admin/newslisting",
  newsSave: "admin/newSave",
  planList: "admin/plan-list",
  userPlanList: "admin/user-plan-list",
  userPlanInfo: "admin/user-plan-info",
  userPlanPurchase: "admin/customer-plans",
  digitalTwin: "admin/category/static/view",
  digitalTwinAdd: "admin/category/static/add",
  digitalTwinUpdate: "admin/category/static/update",
  supportRequest: "admin/contactus",
  planInquiry: "admin/plan-enquiries",
  userListing: "admin/userlisting",
  changeSetting: "admin/changeSetting",
  getGlobalSetting: "setting/Getglobalsetting",
  getLabels: "api/labels",
  updateLabels: "admin/update-labels",
};

// admin profile update
const getProfileAPI = "admin/profile";
const onProfileUpdateAPI = "admin/profile";
const onPasswordUpdateAPI = "admin/change-password";

// roles and permissions
const rolesPermissionsURLS = {
  getListRoles: "admin/role/get",
  createRole: "admin/role/create",
  updateRole: "admin/role/update",
  deleteRole: "admin/role/delete",
  viewRole: "admin/role/get",
  activeInactive: "admin/role/active-inactive",
};

// application modules
const appModulesURLS = {
  createModule: "admin/app-module/create",
  getListModules: "admin/app-module/get",
  updateModule: "admin/app-module/update",
  deleteModule: "admin/app-module/delete",
  viewModule: "admin/app-module/get",
  viewUserModule: "admin/app-module/user",
};

// admin crate user that urls
const adminUsersURLS = {
  createUser: "admin/create",
  viewUser: "admin/get",
  updateUser: "admin/update",
  listUser: "admin/get",
  deleteUser: "admin/delete",
  resendLink: "admin/resend-link",
  verifyPasswordKey: "admin/verify-password-key",
  setUserPassword: "admin/set-password",
  activeInActive: "admin/active-inactive",
};

// Universal Modules
const universalModuleURls = {
  getDahboardInfo: "admin/dashboard",
  getMonthlyRequestChartInfo: "admin/dashboard/request-chart",
  getUniversalList: "admin/networks/",
  updateUniversalList: "admin/networks/",
  getSmartContracts: "admin/contracts",
  getSmartContractsDetail: "admin/contracts/",
  updateSmartContract: "admin/contracts/",
  smartContractsInstallations: "admin/contracts/channels",
  smartContractsInstallationsDetails: "admin/contracts/channels/",
  updateSmartContractsInstallations: "admin/contracts/channels/",
  getRequestHistoryList: "admin/request-history",
  getChannelsList: "admin/channels",
  getChannelDetail: "admin/channels/",
  updateChannelDetail: "admin/channels/",
  channelInvitation: "admin/invitations",
  channelInvitationDetail: "admin/invitation/",
  updateChannelInvitation: "admin/invitation/",
  getChaincodeList: "admin/chain-codes",
  getChaincodeDetail: "admin/chain-codes/",
  updateChaincode: "admin/chain-codes/",
  getPeers: "admin/peers",
  getPeerDetails: "admin/peers/",
  updatePeerDetails: "admin/peers/",
  getTemplateListing: "admin/templates",
  getTemplateDetail: "admin/templates/",
  addTemplate: "admin/templates",
  updateTemplate: "admin/templates/",
  getDomainsList: "admin/network-domains",
  getDetailOfDomainsList: "admin/network-domains/",
  addDomain: "admin/network-domains",
  updateDomain: "admin/network-domains/",
  getRegionsList: "admin/network-regions",
  addRegion: "admin/network-regions",
  updateRegion: "admin/network-regions/",
  getDetailOfRegion: "admin/network-regions/",
  tokenEngine: {
    list: "admin/token-engine",
    get: "admin/token-engine/",
    updateToken: "admin/token-engine/",
  },
};

const bvoteURL = {
  adminInfo: "badmin/users/admin-info",
  dashboard: "badmin/users/dashboard",
  requestDemo: {
    list: "badmin/users/contact-us",
  },
  poll: {
    listing: "bpoll/poll",
    add: "bpoll/poll",
    view: "bpoll/poll/",
    update: "bpoll/poll/",
    deleteURL: "bpoll/poll/delete",
    showLiveResult: "bpoll/poll/",
    importVoters: "bpoll/poll/import-csv",
  },
  manageVoters: {
    listing: "bvoter/voters",
    list: "bvoter/voters/group-voter-list",
    view: "bvoter/voters/",
    add: "bvoter/voters",
    update: "bvoter/voters/",
    deleteURL: "bvoter/voters/delete",
  },
  manageGroups: {
    listing: "bgroup/group",
    list: "bgroup/group/group-list",
    add: "bgroup/group",
    view: "bgroup/group/",
    update: "bgroup/group/",
    deleteURL: "bgroup/group/delete",
  },
  pollHistory: {
    listing: "bpoll/poll/public",
    view: "bpoll/poll/history/",
    dowloadPdf: "bpoll/poll/analytics",
  },
  bVoteAdmin: {
    getbVoteUndermain: "badmin/users/settings",
    updatebVoteUndermain: "badmin/users/settings",
  },
};

const bMessageURL = {
  adminInfo: "admin/info",
  dashboard: "admin/dashboard",
  globalSetting: {
    get: "admin/get",
    update: "admin/update",
  },
  getGlobalSetting: "admin/get",
  updateGlobalSetting: "admin/update",
  updateLabel: "admin/update-label",
  getLabel: "admin/get-label",
  addLabel: "admin/create-label",
  getPriceAndPlan: "plan",
  getPriceAndPlanDetailApi: "plan",
  deletePlanApi: "plan",
  updatePlanApi: "plan/",
};

const nftURL = {
  adminInfo: "admin/info",
  dashboard: "admin/dashboard",
  getGlobalSetting: "admin/get",
  updateGlobalSetting: "admin/update",
  updateLabel: "admin/update-labels",
  getLabel: "admin/get-labels",
  getRequestDemoList: "admin/get-request",
  updateRequestDemo: "admin/update-request",
  getBlockChain: "admin/blockchain",
  updateBlockChain: "admin/blockchain",
  updateBlockChainRank: "admin/blockchain/rank",
  getCategoryList: "category/list",
  getCategoryDetails: "admin/category",
  createCategory: "category/create",
  updateCategory: "category/update",
  deleteCategory: "category/delete",
  getNftList: "admin/nft",
  updateNftList: "admin/nft/",
};

const bStampURL = {
  adminInfo: "/admin/info",
  dashboard: "/admin/dashboard",
  getGlobalSetting: "/admin/get",
  getUserManagement: "/admin/users",
  getOrgManagement: "/admin/organization",
  updateGlobalSetting: "/admin/update",
  updateLabel: "/admin/update-labels",
  getLabel: "/admin/get-labels",
};

const universeURL = {
  adminInfo: "admin/admin-info",
  dashboard: "admin/dashboard",
  getGlobalSetting: "admin/global-settings",
  updateGlobalSetting: "admin/global-settings",
};

const byouURL = {
  userListInfo: "issuer/admin/users",
  getDetailInfo: "issuer/admin/users",
  updateUserInfo: "issuer/admin/users",
  getGlobalSetting: "issuer/admin/settings",
  updateGlobalSetting: "issuer/admin/settings",
  userInfoAPIOfByou: "users/user-info?role=issuer",
  adminInfo: "issuer/admin/info",
  createIssuer: "issuer/admin/users",
  getLabel: "issuer/admin/labels",
  updateLabel: "issuer/admin/labels",
};

const StakingPortalApiEndPoint = {
  adminInfo: "admin/info",
  availableApps: {
    get: "admin/available-apps/",
    create: "admin/available-apps",
    list: "admin/available-apps",
    update: "admin/available-apps/",
  },
  validators: {
    get: "admin/validators/",
    list: "admin/validators",
    sendEmail: "admin/validators",
  },
  dapps: {
    get: "admin/dapps/",
    list: "admin/dapps",
  },
  contactUs: {
    get: "admin/contact-us/",
    list: "admin/contact-us",
  },
  marketPlace: {
    get: "admin/marketplace/",
    list: "admin/marketplace",
    update: "admin/marketplace",
    delete: "admin/marketplace/",
    applications: "admin/applications",
  },
  globalSetting: {
    get: "admin/global-settings",
    update: "admin/global-settings/",
  },
  demoRequest: {
    get: "admin/request-demo/",
    list: "admin/request-demo",
  },
  label: {
    get: "admin/labels",
    update: "admin/labels",
  },
  filter: {
    get: "admin/filter",
    getList: "admin/filter",
    create: "admin/filter",
    update: "admin/filter",
    delete: "admin/filter",
  },
  blogs: {
    get: "admin/blogs",
    getList: "admin/blogs",
    create: "admin/blogs",
    update: "admin/blogs",
    delete: "admin/blogs",
  },
  category: {
    get: "admin/category",
    getList: "admin/category",
    create: "admin/category",
    update: "admin/category",
    delete: "admin/category",
  },
};

const bTrackURL = {
  userInfo: "admin/admin-info",
  globalSetting: {
    get: "admin/global-settings",
    update: "admin/global-settings/",
  },
  issue: {
    list: "admin/issues",
    get: "admin/issues/",
  },
  label: {
    update: "admin/labels",
    get: "admin/labels",
  },
};

const moduleManagementURL = {
  createAppModule: "admin/app-module/create",
  getAppModule: "admin/app-module/get",
  updateAppModule: "admin/app-module/update",
  deleteAppModule: "admin/app-module/delete",
  viewAppModuleList: "admin/app-module/get",
};

export {
  StakingPortalApiEndPoint,
  addMarketPlaceApp,
  addShortCutAPI,
  adminUserListAPI,
  adminUsersURLS,
  apiListAPI,
  appModulesURLS,
  applicationListAPI,
  bMessageURL,
  bStampURL,
  bTrackURL,
  banjiAPIURLS,
  bvoteURL,
  byouURL,
  dashboardAPI,
  deleteMarketAppApi,
  deleteShortCutAPI,
  editShortCutAPI,
  forPasswordAPI,
  getAPIDataByIdAPI,
  getAPIExplorerDataByIdAPI,
  getAPISubAPIListByIdAPI,
  getAPPDataByIdAPI,
  getAppShortcutsAPI,
  getMarketPlaceList,
  getProfileAPI,
  globalChangeAdmin,
  globalSetingURL,
  kycApproveAPI,
  kycDetails,
  kycListAPI,
  loginAPI,
  moduleManagementURL,
  nftURL,
  onDeleteNotificationAPI,
  onGetNotificationAPI,
  onPasswordUpdateAPI,
  onProfileUpdateAPI,
  onUpdateNotificationAPI,
  organizationListAPI,
  rankShortCutAPI,
  rolesPermissionsURLS,
  suspendURl,
  universalModuleURls,
  universeURL,
  updateAPIDataByIdAPI,
  updateAPIDescriptionByIdAPI,
  updateAPPDataByIdAPI,
  updateMarketPlaceApp,
  updateMarketplaceAppOrder,
  userAppPermissionsReadAPI,
  userAppPermissionsUpdateAPI,
  userListAPI,
  userReadAPI,
};
