import { FC } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface Iany {
  name: string;
  peer: number;
  Contract: number;
  Chaincode: number;
  Channel: number;
}
interface IMonthlyRequestChart {
  chartInfo: Iany[];
}
// @ts-ignore
const MonthlyRequestChart: FC<IMonthlyRequestChart> = ({ chartInfo }) => {
  return (
    <ResponsiveContainer width="118%" aspect={3}>
      <LineChart data={chartInfo} margin={{ right: 300 }}>
        <CartesianGrid />
        <XAxis dataKey="name" interval={"preserveStartEnd"} />
        <YAxis></YAxis>
        <Legend />
        <Tooltip />
        <Line dataKey="Peer" stroke="#007de4" activeDot={{ r: 8 }} />
        <Line dataKey="Chaincode" stroke="#005397" activeDot={{ r: 8 }} />
        <Line dataKey="Channel" stroke="#696969" activeDot={{ r: 8 }} />
        <Line dataKey="Contract" stroke="#AABBCC" activeDot={{ r: 8 }} />
        <Line dataKey="Network" stroke="#FFAE42" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MonthlyRequestChart;
