import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Row, Spinner, Tooltip } from "reactstrap";
import { StakingViewAPI } from "../../../../actions/staking/comman/stakingViewAPI";
import BackArrowComponent from "../../../../common/backArrow";
import { getFormattedDate, handleData } from "../../../../common/common-functions";
import { CREATEDATE, projectNames, validatorStatus } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import ValidatorStatusModal from "./components/ValidatorStatusModal";

const ColumnComponent = ({ title, value, color, capitalize }: any) => {
  return (
    <>
      <hr className="m-0" />
      <div className="row p-20">
        <div className="info-name col-2">
          <p className="font-13 fw-bold color-black2 fw-normal text-upper">{title}</p>
        </div>
        <div className="col-9">
          <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
            {title === "updatedAt" || title === "createdAt" ? getFormattedDate(value) : value ? value : "-"}
          </p>
        </div>
      </div>
    </>
  );
};

const StakingViewComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const location: any = useLocation();
  const loading = useSelector((state: IRootReducerTypes) => state.loading);
  const contactus = location?.pathname === `/${projectNames.edexanetwork}/contact-us/${params?.id}`;
  const dApps = location?.pathname === `/${projectNames.edexanetwork}/dapp-customer-request/${params?.id}`;
  const validateRequest = location?.pathname === `/${projectNames.edexanetwork}/validate-request/${params?.id}`;

  const [data, setData] = useState<any>([]);
  const [modal, setModal] = useState(false);
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    StakingViewAPI(location?.state?.type, id).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        dispatch(setLoading(false));
        let tifOptions: any = [];
        Object.keys(res.data).forEach(function (key) {
          if (key !== "_id") {
            tifOptions.push(key);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (params?.id) {
      getViewAction(params?.id);
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const infoOfTileAndSubTitle = () => {
    if (validateRequest) {
      return { title: "Validator Request", subTitle: "Validator Information" };
    } else if (dApps) {
      return { title: "dApp Details", subTitle: "dApp Information" };
    } else if (contactus) {
      return {
        title: "Contact Us Details",
        subTitle: "Contact Us Information"
      };
    }
  };
  const info = infoOfTileAndSubTitle();

  return (
    <div>
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="no-first-letter-capital-title kycDetailPageTitle">{info?.title}</p>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        <Row className="mt-10">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10 ">
              <div className="p-20">
                <p className="sub-title m-b-">{info?.subTitle}</p>
              </div>
              {loading?.isLoading ? (
                <div className="d-flex justify-content-center my-4">
                  <Spinner size={"sm"} />
                </div>
              ) : (
                <>
                  {dApps && (
                    <>
                      <ColumnComponent title="Company Name" value={data?.companyName} />
                      <ColumnComponent title="Last Name" value={data?.blockchain} />
                      <ColumnComponent title={CREATEDATE} value={getFormattedDate(data?.createdAt)} />
                      <ColumnComponent title="dApp Name" value={data?.dAppName} />
                      <ColumnComponent title="Description" value={data?.description} />
                      <ColumnComponent title="Email" value={data?.email} />
                      <ColumnComponent title="Full Name" value={data?.fullName} />
                      <ColumnComponent title="Nick Name" value={data?.nickName} />
                      <ColumnComponent title="Position" value={data?.postion} />
                      <ColumnComponent title="Project Description" value={data?.projectDescription} />
                      <ColumnComponent title="Time Zone" value={data?.timezone} />
                      <ColumnComponent title="Website" value={data?.website} />
                    </>
                  )}

                  {validateRequest && (
                    <>
                      <ColumnComponent title="Company Name" value={data?.companyName} />
                      <ColumnComponent title="Name" value={data?.name} />
                      <ColumnComponent title="Email" value={data?.email} />
                      <ColumnComponent title="Description" value={data?.description} />
                      <ColumnComponent title={CREATEDATE} value={getFormattedDate(data?.createdAt)} />
                      <hr className="m-0" />
                      <div className="row p-20">
                        <div className="info-name col-2">
                          <p className="font-13 fw-bold color-black2 fw-normal text-upper">Status</p>
                        </div>
                        <div className="col-9">
                          <p className={"color-black2  font-14 info-text"}>
                            <span className={`${String(data?.status) === validatorStatus.REJECTED ? "text-danger" : ""}`}>{handleData(data?.status)}</span>
                            {String(data?.status) === validatorStatus.IN_PROGRESS ||
                            String(data?.status) === validatorStatus.NODE_OBSERVER ||
                            String(data?.status) === validatorStatus.PENDING ||
                            String(data?.status) === validatorStatus.SUPPORT_SYSTEM ? (
                              <span className="ml-10 fw-bold blue cursor-pointer" onClick={() => setModal(true)}>
                                Change Status
                              </span>
                            ) : null}
                          </p>
                        </div>
                      </div>
                      {data?.reason ? <ColumnComponent title="Reason" value={data?.reason} /> : null}
                      {data?.walletAddress ? <ColumnComponent title="Wallet Address" value={data?.walletAddress} /> : null}
                      <ColumnComponent title="Required support of edeXa" value={data?.status === "3" ? "Required" : "Not Required"} />
                      {data?.status === "3" ? (
                        <>
                          <ColumnComponent title="Phone Number" value={data?.contactNumber} />
                          <ColumnComponent title="Request Description" value={data?.reqDescription} />
                        </>
                      ) : null}
                    </>
                  )}

                  {contactus && (
                    <>
                      <ColumnComponent title="Company Name" value={data?.companyName} />
                      <ColumnComponent title="First Name" value={data?.firstName} />
                      <ColumnComponent title="Last Name" value={data?.lastName} />
                      <ColumnComponent title="Message" value={data?.message} />
                      <ColumnComponent title="Email" value={data?.email} />
                      <ColumnComponent title={CREATEDATE} value={getFormattedDate(data?.createdAt)} />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ValidatorStatusModal isOpen={modal} setIsOpen={setModal} getAction={getViewAction} data={data} />
    </div>
  );
};

export default StakingViewComponent;
