import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "Option Wise Total Voted",
          data: []
        }
      ],
      options: {
        annotations: {
          points: [
            {
              x: "Bananas",
              seriesIndex: 0,
              label: {
                borderColor: "#775DD0",
                offsetY: 0,
                style: {
                  color: "#fff",
                  background: "#775DD0"
                },
                text: "Bananas are good"
              }
            }
          ]
        },
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"]
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [],
          tickPlacement: "on"
        },
        yaxis: {
          title: {
            text: "Votes"
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      }
    };
  }

  componentDidUpdate(previousProps) {
    if (this.props.pollOptionData !== previousProps.pollOptionData) {
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props.pollOptionData.map((el) => el.text)
          }
        },
        series: [
          {
            data: this.props.pollOptionData.map((el) => el.resultCount)
          }
        ]
      });
    }
  }
  render() {
    return (
      <div id="barChart">
        <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    );
  }
}
