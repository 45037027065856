import { getDecryptedLocalStorage } from "../../common/common-functions";
import { localstorageKeys } from "../../common/constants";
import { USERPERMISSIONS } from "./type";

// get permissions from local, if no permission found then logout the user
const userPermissions = getDecryptedLocalStorage(localstorageKeys.permissions);
const initialState = {
  permissions: {
    ...userPermissions
  }
};

const permissionsReducer = (state = initialState, action: { type: string; payload: any }) => {
  const newState = { ...state };
  switch (action.type) {
    case USERPERMISSIONS:
      newState.permissions = action.payload;
      break;

    default:
  }
  return newState;
};

export { permissionsReducer };
