import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import MetaFunction from "../../../../common/MetaTag";
import { toastSuccess } from "../../../../common/common-functions";
import { validationMessages } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import Footer from "../../../common/Footer";
import ConfirmationModal from "../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";
import { getGloableSetting, updateGloableSetting } from "./BvoteGlobalAction";

const BVoteGlobalSetting = () => {
  const dispatch = useDispatch();
  const permission = useSelector((state: IRootReducerTypes) => state.userDetails);

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values?: string) => {}
  });

  const formik = useFormik({
    initialValues: {
      isUnderMaintenance: false,
      id: ""
    },
    validationSchema: Yup.object().shape({
      emails: Yup.array().of(Yup.string().required(validationMessages.email.required).email(validationMessages.email.invalid))
    }),
    onSubmit: (values: any) => {}
  });

  const { setFieldValue, values } = formik;

  const getGlobalSetting = () => {
    dispatch(setLoading(true));
    getGloableSetting().then((res: any) => {
      if (res?.code === 200 || res?.status === 200) {
        setFieldValue("isUnderMaintenance", res?.data?.isUnderMaintenance === 1 ? true : false);
        setFieldValue("id", res?.data?.id);
      }
      dispatch(setLoading(false));
    });
  };

  // eslint-disable-next-line
  useEffect(() => getGlobalSetting(), []);

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);
    dispatch(setLoading(true));
    let paramater = {
      [filedName]: data ? "1" : "0",
      id: values.id
    };
    updateGloableSetting(paramater)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue(filedName, data);
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };
  // eslint-disable-next-line
  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.isUnderMaintenance}
              onChange={(e: boolean) => {
                handleSwitch("isUnderMaintenance", e, "Under Maintenance");
              }}
              label="Under Maintenance"
              tooltip="Whole bVote cannot be accessed by customer by enabling the under maintenace"
              disabled={!permission?.user?.permission?.bvote?.globalsetting?.update || false}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
      <Footer />
    </>
  );
};

export default BVoteGlobalSetting;
