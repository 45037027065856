import React, { useState } from "react";
import { CSVLink } from "react-csv";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { onDeleteGroupAPI } from "../../../../actions/bvote/manageGroupsAction";
import { onDeleteVoterAPI } from "../../../../actions/bvote/manageVotersActions";
import { onDeletePollAPI } from "../../../../actions/bvote/pollAction";
import { getFormattedDate, toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames, rowsPerPage } from "../../../../common/constants";
import { setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import EmailComponent from "../../../../utils/components/EmailComponent";
import { DeleteRestricted, EditRestricted } from "../../../common/restrictions";
import ConfirmationModal from "../../DeleteConfirmationModal";
import "../../component/kycDetails.css";
import BvoteLiveResultComponent from "./BvoteLiveResult";

const BvoteTableComponent = ({ listData, totalCount, tableHeaders, type, paginationConfig, setPaginationConfig, recall }) => {
  const [dropdownPage, setDropdownPage] = useState(false);
  const pageToggle = () => setDropdownPage((prevState) => !prevState);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);
  // eslint-disable-next-line
  const loader = useSelector((state) => state.loading.isLoading);

  const [newsModal, setNewsModal] = useState({
    isOpen: false,
    description: ""
  });

  //poll current status
  const getPollStatus = (statusCode) => {
    let result = {
      label: "",
      class: ""
    }; // eslint-disable-next-line
    switch (statusCode) {
      case "upcoming":
        result = {
          label: "Upcoming",
          class: "pending"
        };
        break;
      case "completed":
        result = {
          label: "Completed",
          class: "active"
        };
        break;
      case "running":
        result = {
          label: "Running",
          class: "deactive"
        };
        break;

      default:
        result = {
          label: "Not Started",
          class: "pending"
        };
        break;
    }
    return result;
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: () => {}
  });

  // group delete action
  const getDeleteGroupAction = (id, data) => {
    let temp = {
      id: []
    };
    temp?.id.push(id);
    dispatch(setLoading(true));
    onDeleteGroupAPI(temp).then((res) => {
      if (res?.status === 200) {
        dispatch(
          setVisible({
            isVisible: false,
            deleteId: "",
            label: "",
            deleteStatus: ""
          })
        );
        recall();
        setDeleteActionFunction({ fnCall: {} });
        toastSuccess(res?.message);
      }
    });
  };
  //voter delete action
  const getDeleteVoterAction = (id, data) => {
    let temp = {
      id: id
    };
    dispatch(setLoading(true));
    onDeleteVoterAPI(temp).then((res) => {
      if (res?.status === 200) {
        dispatch(
          setVisible({
            isVisible: false,
            deleteId: "",
            label: "",
            deleteStatus: ""
          })
        );
        setDeleteActionFunction({ fnCall: {} });
        recall();
        toastSuccess(res?.message);
      }
    });
  };
  // poll delete action
  const getDeletePollAction = (id, data) => {
    let temp = {
      id: []
    };
    temp?.id.push(id);
    dispatch(setLoading(true));
    onDeletePollAPI(temp).then((res) => {
      if (res?.status === 200) {
        dispatch(
          setVisible({
            isVisible: false,
            deleteId: "",
            label: "",
            deleteStatus: ""
          })
        );
        setDeleteActionFunction({ fnCall: {} });
        recall();
        toastSuccess(res?.message);
      }
    });
  };

  // delete icon click that time call this function
  // it's accept to delete id and delete status and what delete function to call
  const handleDelete = (id, data, fun) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: id,
        label: "Are you sure you want to Delete?",
        deleteStatus: "0"
      })
    );
    setDeleteActionFunction({ fnCall: fun });
  };

  // it's redirect to edit page
  const handleRedirect = (url, id) => {
    history.push({
      pathname: url,
      state: {
        id
      }
    });
  };

  const handleWinningVote = (option) => {
    let result = option.find((item) => item.isWinner === 1);
    if (result) {
      return result.text;
    } else {
      return "-";
    }
  };

  const handleWiningPercentage = (option) => {
    let result = option.find((item) => item.isWinner === 1);
    if (result) {
      return result.percentage + "%";
    } else {
      return "-";
    }
  };

  const renderView = (list, type, i) => {
    if (type === "poll") {
      return (
        <tr key={i} className="">
          <td className="font-16 color-light-grey">
            <span title={list?.title}>{`${list?.title.length > 40 ? list?.title?.substring(0, 40) + "..." : list?.title}`}</span>
          </td>
          <td className="font-16 color-light-grey">{list?.startDate && getFormattedDate(list?.startDate)}</td>
          <td className="font-16 color-light-grey">
            <BvoteLiveResultComponent isActive={list?.displayResult} id={list?.id} recall={recall} />
          </td>
          <td className="font-16 color-light-grey">
            <i
              className="fas fa-copy cursor-pointer blue"
              onClick={() => {
                navigator.clipboard.writeText(`${process.env.REACT_APP_API_DOMAIN_bVote_web}poll/${list?.id}`);
                toastSuccess("Link copied!");
              }}
              title="poll link"
            />
          </td>
          <td>
            {list?.displayResult === 0 ? (
              <p className="font-14 fw-bold">Live result is disabled</p>
            ) : (
              <button className="font-16 analytics-button" onClick={() => window.open(process.env.REACT_APP_API_DOMAIN_bVote_web + "poll-result/" + list?.id)}>
                View Result
              </button>
            )}
          </td>
          <td>
            {list?.isHash === 1 ? (
              <CSVLink data={list?.hashes} filename={`${list?.title}.csv`} target="_blank">
                <i className="fas fa-file-download cursor-pointer blue" title="Download Hashes" />
              </CSVLink>
            ) : (
              "-"
            )}
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${getPollStatus(list?.currentStatus).class}`}></span>
            {getPollStatus(list?.currentStatus).label}
          </td>
          <td className="font-14 color-light-grey">
            <EditRestricted module={projectModules[projectNames.bvote].POLL}>
              <i onClick={() => handleRedirect(`/${projectNames.bvote}/poll/edit`, list?.id)} className="fas fa-edit blue cursor-pointer" title="Edit poll" />
            </EditRestricted>
            <DeleteRestricted module={projectModules[projectNames.bvote].POLL}>
              {list?.currentStatus !== "running" && <i onClick={() => handleDelete(list?.id, "", getDeletePollAction)} className="fas fa-trash-alt blue ml-10 cursor-pointer" title="Delete poll" />}
            </DeleteRestricted>
          </td>
        </tr>
      );
    } else if (type === "requestDemo") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name}`}</td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={`${list?.email}`} />
          </td>
          <td className="font-16 color-light-grey">{`${list?.message}`}</td>
          <td className="font-16 color-light-grey">{`${list?.mobile}`}</td>
          <td className="font-16 color-light-grey">{list?.createdAt && getFormattedDate(list?.createdAt)}</td>
        </tr>
      );
    } else if (type === "voters") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.firstName} ${list?.lastName}`}</td>
          <td className="font-16 color-light-grey">{list?.createdAt && getFormattedDate(list?.createdAt)}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${list?.status === "active" ? "active" : "deactive"}`}></span>
            {list.status === "active" ? "Active" : "InActive"}
          </td>
          <td className="font-14 color-light-grey">
            <EditRestricted module={projectModules[projectNames.bvote].MANAGE_VOTERS}>
              <i onClick={() => handleRedirect(`/${projectNames.bvote}/manage-voters/edit`, list?.id)} className="fas fa-edit blue cursor-pointer" title="Edit voter" />
            </EditRestricted>
            <DeleteRestricted module={projectModules[projectNames.bvote].MANAGE_VOTERS}>
              <i onClick={() => handleDelete(list?.id, "", getDeleteVoterAction)} className="fas fa-trash-alt blue ml-10 cursor-pointer" title="Delete voter" />
            </DeleteRestricted>
          </td>
        </tr>
      );
    } else if (type === "groups") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.name}`}</td>
          <td className="font-16 color-light-grey">{list?.voterGroupCount}</td>
          <td className="font-16 color-light-grey">{list?.createdAt && getFormattedDate(list?.createdAt)}</td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${list?.status === "active" ? "active" : "deactive"}`}></span>
            {list?.status === "active" ? "Active" : "InActive"}
          </td>
          <td className="font-14 color-light-grey">
            <EditRestricted module={projectModules[projectNames.bvote].MANAGE_GROUPS}>
              <i onClick={() => handleRedirect(`/${projectNames.bvote}/manage-groups/edit`, list?.id)} className="fas fa-edit blue cursor-pointer" title="Edit group" />
            </EditRestricted>
            <DeleteRestricted module={projectModules[projectNames.bvote].MANAGE_GROUPS}>
              <i onClick={() => handleDelete(list?.id, "", getDeleteGroupAction)} className="fas fa-trash-alt blue ml-10 cursor-pointer" title="Delete group" />
            </DeleteRestricted>
          </td>
        </tr>
      );
    } else if (type === "pollHistory") {
      return (
        <tr key={i}>
          <td className="font-16 color-light-grey">{`${list?.title}`}</td>
          <td className="font-16 color-light-grey">{list?.options && handleWinningVote(list?.options)}</td>
          <td className="font-16 color-light-grey">{list?.options && handleWiningPercentage(list?.options)}</td>
          <td className="font-16 color-light-grey">{viewAnalyticsButton(list)}</td>
          <td className="font-16 color-light-grey">{list?.startDate && getFormattedDate(list?.startDate)}</td>
          <td className="font-16 color-light-grey">{list?.endDate && getFormattedDate(list?.endDate)}</td>
        </tr>
      );
    }
  };

  const viewAnalyticsButton = (list) => {
    const isWinner = list?.options.find((el) => el.isWinner === 1);
    if (isWinner) {
      return (
        <button className="font-16 analytics-button" onClick={() => handleRedirect(`/${projectNames.bvote}/poll-history/analytics`, list?.id)}>
          View Analytics
        </button>
      );
    } else {
      return "-";
    }
  };

  const onHandleRowPerPageChange = (el) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };

  const { isLoading } = useSelector((state) => ({
    isLoading: state.loading.isLoading
  }));

  return (
    <>
      <Scrollbars
        style={{ height: 630 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        className="table-section border-grey bvote-table"
        autoHide>
        <Table>
          <thead>
            <tr>
              {tableHeaders.map((header, index) => {
                if (header === "Poll Link") {
                  return (
                    <th key={index} className="color-black1 font-14 fw-bold">
                      {header}
                    </th>
                  );
                } else if (header === "Show Live Result") {
                  return (
                    <th key={index} className="color-black1 font-14 fw-bold">
                      {header}
                    </th>
                  );
                } else if (header === "Voting Date | Time") {
                  return (
                    <th key={index} className="color-black1 font-14 fw-bold">
                      {header}
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="color-black1 font-14 fw-bold">
                      {header}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData.length > 0 ? (
              listData.map((list, i) => {
                return renderView(list, type, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10" />
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">
            <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount}`}</p>
          </p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? goToFirstPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={paginationConfig.page > 1 ? handlePrevious : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          {/* @ToDo
                  This component generates error for onclick.
                */}
          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? handleNext : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center" />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={calculateLastPageRange() < totalCount ? goToLastPage : null}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />

      <Modal centered isOpen={newsModal.isOpen} style={{ maxWidth: "450px" }}>
        <ModalHeader
          toggle={() => {
            setNewsModal({
              isOpen: false,
              description: ""
            });
          }}
          className="border-0 bg-blue text-white">
          News Description
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="font-16 p-20  color-light-grey">{newsModal.description}</div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default React.memo(BvoteTableComponent);
