import { IcategoryModalIdAction } from "./reducer";
import { categoryType } from "./type";

export const categoryModalIsOpenAction = (payload: boolean) => {
  return { type: categoryType.CATEGORY_OPEN, payload };
};
export const categoryModalIdAction = (data: IcategoryModalIdAction, totalCount: number) => {
  const payload = { data, totalCount };
  return { type: categoryType.CATEGORY_ID, payload };
};
export const categoryModalResetAction = () => {
  return { type: categoryType.CATEGORY_RESET };
};

export const categoryModalCallAPIAction = (payload: boolean) => {
  return { type: categoryType.CATEGORY_CALLAPI, payload };
};
