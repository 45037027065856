import { useFormik } from "formik";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { Form, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { addDomain } from "../../../../actions/portal/adminActions";
import { handleTrim, toastSuccess } from "../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../common/constants";
import CustomTooltip from "../../../common/ToolTip";
import PageHeader from "../../bvote/common/PageHeader";

const AddDomain = () => {
  const history = useHistory();
  const module = "Create Domain";
  const groupFormik = useFormik({
    initialValues: {
      name: "",
      disable: false,
      visible: true,
      default: false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(validationMessages.domain.required).min(3, validationMessages.templateName.min).max(25, validationMessages.templateName.max)
    }),
    onSubmit: (values) => {
      const makeAPIData = {
        ...values,
        name: values.name.trim()
      };
      addDomain(makeAPIData).then((res) => {
        if (res.status === 200) {
          history.push(`/${projectNames.universe}/manage-domains`);
          toastSuccess(res.message);
        }
      });
    }
  });
  const { errors, values, handleBlur, handleSubmit, setFieldValue, touched } = groupFormik;

  return (
    <>
      {/* <MetaFunction meta={module} /> */}
      <div className="holders">
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-3">
                <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                  Domain Name
                </Label>
                <Input
                  type="text"
                  placeholder="Enter the domain name"
                  id="name"
                  name="name"
                  invalid={touched?.name ? (errors?.name ? true : false) : false}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleTrim(e.target.name, e.target.value, setFieldValue);
                  }}
                  value={values.name}
                />
                {touched?.name ? errors?.name && <span className="text-danger font-14 mt-2">{errors?.name}</span> : null}
              </div>
              <div className="col-12 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Visibility of Domain
                  <CustomTooltip direction={"right"} content={<p className="p-2">If you enable, customers can view the domain on Universe Portal</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                  </CustomTooltip>
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  checked={values.visible}
                  onChange={(e) => setFieldValue("visible", e ? true : false)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-12 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Disable Domain
                  <CustomTooltip direction={"right"} content={<p className="p-2">If you disable, customers can see the domain on Universe Portal, but cannot use it</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                  </CustomTooltip>
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  checked={Boolean(values.disable)}
                  onChange={(e) => setFieldValue("disable", e ? true : false)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-12 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Default Selected
                  <CustomTooltip direction={"left"} content={<p className="p-2">In Universe portal, domain will be selected as default</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px", color: "black" }}></i>
                  </CustomTooltip>
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  checked={Boolean(values.default)}
                  onChange={(e) => setFieldValue("default", e ? true : false)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-md-12 mt-20">
                <div className="col-md-12 mt-20">
                  <button type="submit" className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AddDomain;
