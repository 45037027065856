import { useEffect, useState } from "react";
import { projectNames } from "../../common/constants";
import CommonDropdownComponent from "./CommonDropdownComponent";

const ModuleDropdown = ({ icon, nameOfModule, type, isSidebarOpen, subModuleExist }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [customerRequestModules, setCustomerRequestModules] = useState([
    {
      name: "Contact Us",
      icon: <i className="fas fa-address-card menu-icon transiton-02s"></i>,
      path: `/${projectNames.edexanetwork}/contact-us`
    },
    {
      name: "App Demo Request",
      icon: <i className="fas fa-user-tie menu-icon transiton-02s"></i>,
      path: `/${projectNames.edexanetwork}/app-demo-request`
    },
    {
      name: "Validator Request",
      icon: <i className="fas fa-id-badge menu-icon transiton-02s"></i>,
      path: `/${projectNames.edexanetwork}/validate-request`
    },
    {
      name: "dApp Customer Request",
      icon: <i className="fa fa-list  menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.edexanetwork}/dapp-customer-request`
    }
  ]);
  // eslint-disable-next-line
  const [smartContractsModules, setSmartContractsModules] = useState([
    {
      name: "Contracts Listing",
      icon: <i className="fa fa-list  menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/contracts-listing`
    },
    {
      name: "Installations",
      icon: <i className="fa fa-download  menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/installations`
    }
  ]);
  // eslint-disable-next-line
  const [channelManagementModules, setChannelManagementModules] = useState([
    {
      name: "Channel Listing",
      icon: <i className="fa fa-list  menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/channel-listing`
    },
    {
      name: "Channel Invitations",
      icon: <i className="fa fa-paper-plane  menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/channel-invitations`
    }
  ]);

  const appModules = [
    {
      name: "API Management",
      icon: <i className="fa fa-square menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/api-management`
    },
    {
      name: "Application Management",
      icon: <i className="fa fa-mobile menu-icon transiton-02s" aria-hidden="true"></i>,
      path: `/${projectNames.universe}/application-management`
    }
  ];

  const domainAndRegions = [
    {
      name: "Manage Domains",
      icon: <i className="fas fa-building menu-icon transiton-02s"></i>,
      path: `/${projectNames.universe}/manage-domains`
    },
    {
      name: "Manage Regions",
      icon: <i className="fas fa-map-marker menu-icon transiton-02s"></i>,
      path: `/${projectNames.universe}/manage-regions`
    }
  ];

  const blogManagementArray = [
    {
      name: "Category",
      icon: <i className="fas fa-table menu-icon transiton-02s"></i>,
      path: `/${projectNames.edexanetwork}/category`
    },
    {
      name: "News",
      icon: <i className="fas fa-blog menu-icon transiton-02s"></i>,
      path: `/${projectNames.edexanetwork}/news`
    }
  ];

  const getModuleInfo = () => {
    if (nameOfModule === "Customer Request") {
      customerRequestModules?.map((ele) => ele);
    }
    if (nameOfModule === "Smart Contracts") {
      smartContractsModules?.map((ele) => ele);
    }

    if (nameOfModule === "Channel Management") {
      channelManagementModules?.map((ele) => ele);
    }

    if (nameOfModule === "Apps & API") {
      appModules?.map((ele) => ele);
    }

    if (nameOfModule === "Domains & Regions") {
      domainAndRegions?.map((ele) => ele);
    }
  };

  useEffect(() => {
    getModuleInfo();
    // eslint-disable-next-line
  }, [isOpen]);

  const openDropDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {nameOfModule === "Customer Request" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={customerRequestModules} openDropDown={openDropDown} />
      )}

      {nameOfModule === "Smart Contracts" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={smartContractsModules} openDropDown={openDropDown} />
      )}

      {nameOfModule === "Channel Management" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={channelManagementModules} openDropDown={openDropDown} />
      )}

      {nameOfModule === "Apps & API" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={appModules} openDropDown={openDropDown} />
      )}

      {nameOfModule === "Domains & Regions" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={domainAndRegions} openDropDown={openDropDown} />
      )}

      {nameOfModule === "News Management" && (
        <CommonDropdownComponent icon={icon} subModuleExist={subModuleExist} isOpen={isOpen} nameOfModule={nameOfModule} smartContractsModules={blogManagementArray} openDropDown={openDropDown} />
      )}
    </>
  );
};

export default ModuleDropdown;
