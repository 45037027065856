import { onlyNumberRegex } from "../../../../../common/constants";

export const validateQuestion = (value: any) => {
  let error;
  if (!value) {
    error = "Required";
  }
  return error;
};

export const onOfVote = (value: any, number: number) => {
  let error;
  if (!value) {
    error = "No. of voters are required";
  } else if (!onlyNumberRegex.test(value)) {
    error = "Only number are allowed";
  } else if (value < 2) {
    error = "Minimum two voter are required";
  } else if (value > 999) {
    error = "Maximum 999 voters allowed";
  }
  return error;
};

export const maxVote = (value: any, number: any) => {
  let error;
  if (!value) {
    error = "Maximum Multiple Votes is required";
  } else if (!onlyNumberRegex.test(value)) {
    error = "Only number are allowed";
  } else if (value < 2) {
    error = "Minimum two voter are required";
  } else if (value > number) {
    error = "Maximum selectable options should be less than the total options";
  }
  return error;
};
