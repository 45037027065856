import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { dashboard } = bvoteURL;

// dashboard info api
export const onDashboardAPI = (): Promise<IBvoteDashboardRes> => {
  return HTTPService.get(dashboard);
};

interface IbVoteDashboard {
  groupCount: number;
  pollCount: number;
  voterCount: number;
}
interface IBvoteDashboardRes {
  status: number;
  data: IbVoteDashboard;
}
