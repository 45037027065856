import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getNftDashboardAPI = (): Promise<IDashboard> => {
  return HTTPService.get(nftURL.dashboard);
};

interface IDashboard {
  status: number;
  message: string;
  data: {
    totalNFTs: number;
    activeUsers: number;
  };
}
