import { ADMIN_NOTIFCATION_RECEVIED, ADMIN_NOTIFCATION_RESET } from "./type";

const initialState: IsocketReducers = {
  isNotificationPresent: false
};

const socketReducers = (state = initialState, action: { type: string }) => {
  let newState = { ...state };

  switch (action.type) {
    case ADMIN_NOTIFCATION_RECEVIED:
      newState.isNotificationPresent = true;
      break;
    case ADMIN_NOTIFCATION_RESET:
      newState.isNotificationPresent = false;
      break;
  }
  return newState;
};

export default socketReducers;

export interface IsocketReducers {
  isNotificationPresent: boolean;
}
