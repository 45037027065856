import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, FormFeedback, Input, Label, Row, Spinner, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { getAPIDataById } from "../../../../actions/portal/getAPIDetailsById";
import { updateAPIDetails } from "../../../../actions/portal/updateApi";
import "../../../../assets/css/editAPI.css";
import { apiDescriptionDemoImage, apiErrorDescriptionImage, apiErrorTypesDemoImage, apiFeaturesDemoImage, apiHttpStatusDemoImage, apiNameDemoImage } from "../../../../assets/images/api-info";
import MetaFunction from "../../../../common/MetaTag";
import { getBase64, handleTrim, toastSuccess } from "../../../../common/common-functions";
import { setLoading } from "../../../../store/loader/action";
import CommonButton from "../../../common/Button";
import Footer from "../../../common/Footer";
import CustomTooltip from "../../../common/ToolTip";
import BackArrowComponent from "../../../../common/backArrow";

const cssAnimationTimeOutAnimatedList = 300;

const EditApi = ({
  location: {
    state: { apiId }
  }
}: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const errorComponentRef = useRef<HTMLDivElement[]>([]);

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [apiLoading, setAPILoading] = useState(true);
  const [isSaveButtionDisabled, setIsSaveButtonDisabled] = useState(true);

  const apiEditValidationSchema = Yup.object().shape({
    ErrorDescription: Yup.string().required("Error Description is Required"),
    ErrorTypes: Yup.array().of(Yup.string().required("Error Type is Required")),
    HTTPstatus: Yup.array().of(
      Yup.object({
        status_code: Yup.string().required("Status Code is Required"),
        message: Yup.string().required("Message is Required")
      })
    ),
    appFeatures: Yup.array().of(Yup.string().required("Feature is Required")).nullable(),
    features: Yup.array().of(Yup.string().required("Feature is Required")),
    description: Yup.string().required("API Description is Required"),
    logoIcon: Yup.string().required("Logo Icon is Required").nullable(),
    name: Yup.string().required("API Name is Required"),
    app_url: Yup.string().nullable(),
    desktop_url: Yup.string().nullable(),
    extension_url: Yup.string().nullable(),
    redirect_url: Yup.string().nullable(),
    ios_url: Yup.string().nullable(),
    liveUrlDomain: Yup.string().nullable()
  });

  const apiEditFormik = useFormik({
    initialValues: {
      ErrorDescription: "",
      ErrorTypes: [],
      HTTPstatus: [],
      appFeatures: [],
      appId: "",
      appSecretKey: "",
      app_url: "",
      description: "",
      subtitle: "",
      desktop_url: "",
      extension_url: "",
      features: "",
      ios_url: "",
      isAvailable: "",
      isInAppList: "",
      liveUrlDomain: "",
      logo: "",
      logoIcon: "",
      name: "",
      redirect_url: "",
      shortDescription: "",
      status: ""
    },
    validationSchema: apiEditValidationSchema,
    onSubmit: (values) => {
      dispatch(setLoading(true));
      const makeAPIData = {
        id: apiId,
        ...values,
        features: JSON.stringify(values.features),
        HTTPstatus: JSON.stringify(values.HTTPstatus),
        ErrorTypes: JSON.stringify(values.ErrorTypes),
        appFeatures: JSON.stringify(values?.features)
      };
      try {
        // @ts-ignore
        delete makeAPIData.document_url;
      } catch (error) {}
      // delete all the urls which are empty
      // api do not accept empty fields
      // if an url field is empty then just simple remove it from object
      ["app_url", "desktop_url", "extension_url", "redirect_url", "ios_url", "liveUrlDomain"].forEach((key: string) => {
        // @ts-ignore
        makeAPIData?.[key] === "" && delete makeAPIData?.[key];
      });

      updateAPIDetails(makeAPIData).then((result) => {
        dispatch(setLoading(false));
        toastSuccess(result.message);
        history.goBack();
      });
    }
  });
  const { setValues, values, handleSubmit, setFieldValue, errors, touched } = apiEditFormik;

  const handleGetAPIData = () => {
    getAPIDataById(apiId)
      .then((response) => {
        setValues(response.data).then((res) => {
          // setFieldValue("features", JSON.parse(response.data.features));
          setFieldValue("features", response.data.features);

          setIsSaveButtonDisabled(true);
        });
        setAPILoading(false);
      })
      .catch((err) => {
        setAPILoading(false);
      });
  };

  const scrollToCurrentErrorComponentOnSubmit = () => {
    errorComponentRef.current?.forEach((currentItem) => {
      currentItem?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    });
  };

  useEffect(() => {
    handleGetAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSaveButtonDisabled(false);
  }, [values]);

  return apiId ? (
    <>
      <MetaFunction meta="Edit API" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="right" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
          {!apiLoading && (
            <div className="d-flex me-1 align-items-center justify-content-end">
              <CommonButton
                title="Save"
                disabled={isSaveButtionDisabled}
                primary
                onClick={() => {
                  handleSubmit();
                  setTimeout(() => {
                    scrollToCurrentErrorComponentOnSubmit();
                  }, 500);
                }}
              />
            </div>
          )}
        </div>
        <Row className="mt-20">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <p className="sub-title m-b-5">Edit API</p>
              </div>
              {apiLoading && (
                <div className="text-center mb-3">
                  <Spinner />
                </div>
              )}
              {/**
               * @api_details
               */}
              {!apiLoading && values && (
                <>
                  <Row>
                    <Col sm="12" md="6">
                      <div className="d-flex flex-wrap">
                        <div className="p-20">
                          <div className="font-18 mb-1 fw-600">
                            <span>Name </span>
                            <CustomTooltip direction="right" content={<img src={apiNameDemoImage} alt="" className="info-image" />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                          <Input
                            type="text"
                            placeholder="Api Name"
                            name="name"
                            value={values.name}
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.name) && Boolean(touched?.name)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[0] = el)}></div>
                            {errors?.name}
                          </FormFeedback>
                        </div>
                        <Label className="d-flex align-items-center p-20 d-none">
                          <Switch
                            height={20}
                            width={40}
                            disabled={false}
                            onColor="#007de4"
                            offColor="#b1abab"
                            checked={values.status === "active"}
                            onChange={(res) => setFieldValue("status", !res ? "in-active" : "active")}
                            uncheckedIcon
                            checkedIcon
                            className="mr-10"
                          />
                          <p className="ml-20">Active</p>
                        </Label>
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className="p-20" style={{ maxWidth: 200 }}>
                          <p className="font-18 mb-1 fw-600 text-center">Icon</p>
                          <Label
                            className={`border-1 cursor-pointer mt-10 logoContainer ${Boolean(errors?.logoIcon) && Boolean(touched?.logoIcon) ? "custom-invalid border-danger" : ""}`}
                            htmlFor="apiIcon">
                            {values.logoIcon && <img src={values.logoIcon} alt="logoicon" className="w-100 h-100 p-10" />}
                          </Label>
                          <Input
                            type="file"
                            className="d-none"
                            id="apiIcon"
                            onChange={(event) => {
                              event.target.files &&
                                getBase64(event.target.files?.[0]).then((res) => {
                                  setFieldValue("logoIcon", res);
                                });
                            }}
                            invalid={Boolean(errors?.logoIcon) && Boolean(touched?.logoIcon)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[2] = el)}></div>
                            {errors?.logoIcon}
                          </FormFeedback>
                        </div>
                      </div>
                      <div className="p-20">
                        <div className="mb-2">
                          <Label className="font-18 mb-1 fw-600">
                            API Description{" "}
                            <CustomTooltip direction="right" content={<img src={apiDescriptionDemoImage} alt="" className="info-image" style={{ maxWidth: 500 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </Label>
                          <Input
                            type="textarea"
                            placeholder="Detailed description"
                            name="description"
                            value={values.description}
                            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                            invalid={Boolean(errors?.description) && Boolean(touched?.description)}
                          />
                          <FormFeedback className="fw-600">
                            <div ref={(el: HTMLDivElement) => (errorComponentRef.current[3] = el)}></div>
                            {errors?.description}
                          </FormFeedback>
                        </div>
                        <div className="mb-2 d-none">
                          <Label className="font-18 mb-1 fw-600 mt-3">
                            Short Description{" "}
                            <CustomTooltip direction="right" content={<span>Not in use right now</span>}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </Label>
                          <Input
                            type="text"
                            placeholder="Short Description"
                            name="shortDescription"
                            className=""
                            value={values.shortDescription}
                            onChange={(e) => setFieldValue("shortDescription", e.target.value)}
                          />
                        </div>
                        <div className="mb-2 d-none">
                          <Label className="font-18 mb-1 fw-600 mt-3">
                            Subtitle{" "}
                            <CustomTooltip direction="right" content={<span>Not in use right now</span>}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </Label>
                          <Input type="text" name="subtitle" placeholder="Subtitle" className="" value={values.subtitle} onChange={(e) => setFieldValue("subtitle", e.target.value)} />
                        </div>
                      </div>
                      <div className="p-20 d-none">
                        <Label className="font-18 mb-1 fw-600">App Url's</Label>
                        <div className="mt-3">
                          <Label className="font-14">APP URL</Label>
                          <Input className="mt-2" name="app_url" value={values.app_url} placeholder="APP URL" onChange={(e) => setFieldValue("app_url", e.target.value)} />
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Desktop URL</Label>
                          <Input className="mt-2" name="desktop_url" value={values.desktop_url} placeholder="Desktop URL" onChange={(e) => setFieldValue("desktop_url", e.target.value)} />
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Extension URL</Label>
                          <Input className="mt-2" name="extension_url" value={values.extension_url} placeholder="Extension URL" onChange={(e) => setFieldValue("extension_url", e.target.value)} />
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">IOS APP URL</Label>
                          <Input className="mt-2" name="ios_url" value={values.ios_url} placeholder="IOS URL" onChange={(e) => setFieldValue("ios_url", e.target.value)} />
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Live URL</Label>
                          <Input
                            className="mt-2"
                            name="liveUrlDomain"
                            value={values.liveUrlDomain || "" || undefined}
                            placeholder="Live URL"
                            onChange={(e) => setFieldValue("liveUrlDomain", e.target.value)}
                          />
                        </div>
                        <div className="mt-3">
                          <Label className="font-14">Redirect URL</Label>
                          <Input className="mt-2" name="redirect_url" value={values.redirect_url} placeholder="Redirect URL" onChange={(e) => setFieldValue("redirect_url", e.target.value)} />
                        </div>
                      </div>
                      <div className="p-20">
                        <div className="font-18 mb-1 d-flex justify-content-between">
                          <div className="fw-600">
                            Http Status
                            <CustomTooltip direction="right" content={<img src={apiHttpStatusDemoImage} alt="" className="info-image" style={{ maxWidth: 500 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>{" "}
                          <button
                            className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                            onClick={() => {
                              setFieldValue("HTTPstatus", [...values.HTTPstatus, ""]);
                            }}>
                            <i className="fas fa-plus" />
                          </button>
                          <i className="fas fa-plus-circle" style={{ width: "10px", height: "10px" }}></i>
                        </div>
                        <TransitionGroup>
                          {values?.HTTPstatus?.map((item, index) => {
                            return (
                              <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                <div key={index} className="d-flex align-items-stretch mt-2 position-relative">
                                  <div className="mt-2 mr-10">
                                    <Input
                                      type="number"
                                      placeholder={"Code " + index}
                                      className="list-item-box-shadow"
                                      // @ts-ignore
                                      value={item?.status_code || ""}
                                      onChange={(e) => {
                                        setFieldValue(`HTTPstatus[${index}].status_code`, e.target.value);
                                      }}
                                      // @ts-ignore
                                      invalid={Boolean(errors?.HTTPstatus?.[index]?.status_code) && Boolean(touched?.HTTPstatus?.[index]?.status_code)}
                                    />
                                    <FormFeedback className="fw-600">
                                      <div ref={(el: HTMLDivElement) => (errorComponentRef.current[7] = el)}></div>
                                      {/* @ts-ignore */}
                                      {errors?.HTTPstatus?.[index]?.status_code}
                                    </FormFeedback>
                                  </div>
                                  <div className="mt-2 mr-10 flex-fill me-4">
                                    <Input
                                      placeholder={"Name " + index}
                                      className="list-item-box-shadow"
                                      // @ts-ignore
                                      value={item?.message || ""}
                                      onChange={(e) => {
                                        handleTrim(`HTTPstatus[${index}].message`, e.target.value, setFieldValue);
                                      }}
                                      // @ts-ignore
                                      invalid={Boolean(errors?.HTTPstatus?.[index]?.message) && Boolean(touched?.HTTPstatus?.[index]?.message)}
                                    />
                                    <FormFeedback className="fw-600">
                                      <div ref={(el: HTMLDivElement) => (errorComponentRef.current[8] = el)}></div>
                                      {/* @ts-ignore */}
                                      {errors?.HTTPstatus?.[index]?.message}
                                    </FormFeedback>
                                  </div>
                                  <i
                                    className="fas fa-trash-alt blue cursor-pointer position-absolute"
                                    style={{ right: 0, top: 18 }}
                                    onClick={() => {
                                      // @ts-ignore
                                      const modifyHttpStatus = [...values.HTTPstatus];
                                      modifyHttpStatus.splice(index, 1);
                                      setFieldValue("HTTPstatus", modifyHttpStatus);
                                    }}
                                  />
                                </div>
                              </CSSTransition>
                            );
                          })}
                        </TransitionGroup>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-2 fw-600">
                          API Avaibility
                          <CustomTooltip
                            direction="right"
                            content={
                              <>
                                <p className="p-2">
                                  <span className="fw-bold color-blue">Available:</span> Enabling lets customers to access the application.
                                </p>
                                <p className="p-2">
                                  <span className="fw-bold color-blue">Make It Visible: </span>
                                  Enabling lets customers to view the application.
                                </p>
                              </>
                            }>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <div className="d-flex flex-wrap">
                          <Label className="d-flex mt-2 mr-30 align-items-center">
                            <Switch
                              height={20}
                              width={40}
                              disabled={false}
                              onColor="#007de4"
                              offColor="#b1abab"
                              checked={Boolean(parseInt(values.isAvailable))}
                              onChange={(res) => setFieldValue("isAvailable", !res ? 0 : 1)}
                              uncheckedIcon
                              checkedIcon
                              className="mr-10"
                            />
                            <p className="ml-20">Available</p>
                          </Label>
                          <Label className="d-flex mt-2 mr-30 align-items-center">
                            <Switch
                              height={20}
                              width={40}
                              disabled={false}
                              onColor="#007de4"
                              offColor="#b1abab"
                              checked={Boolean(parseInt(values.isInAppList))}
                              onChange={(res) => setFieldValue("isInAppList", !res ? 0 : 1)}
                              uncheckedIcon
                              checkedIcon
                              className="mr-10"
                            />
                            <p className="ml-20">Make It Visible</p>
                          </Label>
                        </div>
                      </div>
                      <div className="p-20">
                        <div className="font-18 mb-1 d-flex justify-content-between">
                          <div className="fw-600">
                            API Features{" "}
                            <CustomTooltip direction="right" content={<img src={apiFeaturesDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>{" "}
                          <button
                            className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                            onClick={() => {
                              // PARSE STRING
                              // PUSH NEW VALUE TO ARRAY
                              // CONVERT TO STRING
                              // @ts-ignore because the string is already converted to array
                              setFieldValue("features", [...values.features, ""]);
                            }}>
                            <i className="fas fa-plus" />
                          </button>
                        </div>
                        <TransitionGroup>
                          {Array.isArray(values.features) &&
                            values.features?.map((item: string, index: number) => {
                              return (
                                <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                  <div className="position-relative" key={index}>
                                    <div className="mt-3 me-4">
                                      <Input
                                        placeholder={"feature " + index}
                                        value={item || ""}
                                        onChange={(e) => {
                                          // @ts-ignore
                                          handleTrim(`features[${index}]`, e.target.value, setFieldValue);
                                        }}
                                        // @ts-ignore
                                        invalid={Boolean(errors?.features?.[index]) && Boolean(touched?.features?.[index])}
                                        className="list-item-box-shadow"
                                      />
                                      <FormFeedback className="fw-600">
                                        <div ref={(el: HTMLDivElement) => (errorComponentRef.current[4] = el)}></div>
                                        {errors?.features?.[index]}
                                      </FormFeedback>
                                    </div>
                                    <i
                                      className="fas fa-trash-alt blue cursor-pointer position-absolute"
                                      style={{ right: 0, top: 8 }}
                                      onClick={() => {
                                        // @ts-ignore
                                        const modifyFeatures = [...values.features];
                                        modifyFeatures.splice(index, 1);
                                        setFieldValue("features", modifyFeatures);
                                      }}
                                    />
                                  </div>
                                </CSSTransition>
                              );
                            })}
                        </TransitionGroup>
                      </div>
                      <div className="p-20">
                        <Label className="font-18 mb-1 fw-600">
                          Error Description{" "}
                          <CustomTooltip direction="right" content={<img src={apiErrorDescriptionImage} alt="" className="info-image" style={{ maxWidth: 500 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </Label>
                        <Input
                          type="textarea"
                          placeholder="Detailed description"
                          name="ErrorDescription"
                          value={values.ErrorDescription}
                          onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                          invalid={Boolean(errors?.ErrorDescription) && Boolean(touched?.ErrorDescription)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[5] = el)}></div>
                          {errors?.ErrorDescription}
                        </FormFeedback>
                      </div>
                      <div className="p-20">
                        <div className="font-18 d-flex justify-content-between pb-2">
                          <div className="fw-600">
                            Error Types{" "}
                            <CustomTooltip direction="right" content={<img src={apiErrorTypesDemoImage} alt="" className="info-image" style={{ maxWidth: 500 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>{" "}
                          <button
                            className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                            onClick={() => {
                              setFieldValue("ErrorTypes", [...values.ErrorTypes, ""]);
                            }}>
                            <i className="fas fa-plus" />
                          </button>
                        </div>

                        {
                          <TransitionGroup>
                            {/* const finalData = values.replace(/\\/g, ""); */}

                            {/* @ts-ignore */}
                            {values?.ErrorTypes.map((item, index) => {
                              return (
                                <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                  <div className="position-relative" key={index}>
                                    <div className="me-4 mt-3">
                                      <Input
                                        placeholder={"Error Type " + (index + 1)}
                                        className="mt-2 list-item-box-shadow"
                                        value={item}
                                        onChange={(e) => handleTrim(`ErrorTypes[${index}]`, e.target.value, setFieldValue)}
                                        invalid={Boolean(errors?.ErrorTypes?.[index]) && Boolean(touched?.ErrorTypes?.[index])}
                                      />
                                      <FormFeedback className="fw-600">
                                        <div ref={(el: HTMLDivElement) => (errorComponentRef.current[6] = el)}></div>
                                        {errors?.ErrorTypes?.[index]}
                                      </FormFeedback>
                                    </div>
                                    <i
                                      className="fas fa-trash-alt blue cursor-pointer position-absolute"
                                      style={{ right: 0, top: 8 }}
                                      onClick={() => {
                                        // @ts-ignore
                                        const modifyErrorTypes = [...values.ErrorTypes];
                                        modifyErrorTypes.splice(index, 1);
                                        setFieldValue("ErrorTypes", modifyErrorTypes);
                                      }}
                                    />
                                  </div>
                                </CSSTransition>
                              );
                            })}
                          </TransitionGroup>
                        }
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex p-20 align-items-center justify-content-end">
                    <CommonButton
                      title="Save"
                      disabled={isSaveButtionDisabled}
                      primary
                      onClick={() => {
                        handleSubmit();
                        setTimeout(() => {
                          scrollToCurrentErrorComponentOnSubmit();
                        }, 500);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  ) : (
    <>No Data Found</>
  );
};

export default EditApi;
