import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const UpdateNFTGlobalSettingAPI = (body: IUpdateNftGlobalSetting): Promise<IUpdateNFTGlobalSettingResponse> => {
  return HTTPService.put(nftURL.updateGlobalSetting, body);
};

export interface IUpdateNftGlobalSetting {
  underMaintenance?: number;
  commissionPercent?: number;
  exchangeRate?: number;
  minPrice?: number;
}

export interface IUpdateNFTGlobalSettingResponse {
  status: number;
  message: string;
}
