import { FC, memo } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { updateBlockChainDataAPI } from "../../../../actions/nft/blockChain/updateBlockChainData";
import menu from "../../../../assets/images/drag.png";
import { toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import CustomTooltip from "../../../common/ToolTip";
import { EditRestricted } from "../../../common/restrictions";

export interface IBlockChainListComponent {
  item: {
    _id: string;
    name: string;
    viewOn: string;
    gateway: number;
    icon: string;
    isVisible: number;
    isEnable: number;
    order: number;
    iconBlack: string;
    iconWhite: string;
  };
  setBlockChainData: any;
}

const BlockChainObjectComponent: FC<IBlockChainListComponent> = ({ item, setBlockChainData }) => {
  const dispatch = useDispatch();

  const handleState = (id: string, type: string, data: boolean, ComingSoon?: number) => {
    setBlockChainData((current: any) =>
      current.map((obj: any) => {
        if (obj._id === id) {
          return { ...obj, [type]: ComingSoon ? ComingSoon : data ? 1 : 0 };
        }
        return obj;
      })
    );
  };

  const switchHandleChange = (filedName: string, data: boolean, values: any, ComingSoon?: number) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("id", values._id);
    if (filedName === "isVisible") {
      // @ts-ignore
      formData.append("isVisible", data ? 1 : 0);
    } else {
      if (ComingSoon) {
        // @ts-ignore
        formData.append("isEnable", ComingSoon);
      } else {
        // @ts-ignore
        formData.append("isEnable", data ? 1 : 0);
      }
    }
    updateBlockChainDataAPI(formData).then((res: any) => {
      if (res?.status === 200 || res?.code === 200) {
        toastSuccess(res?.message);
        if (filedName === "isVisible") {
          handleState(values._id, "isVisible", data, ComingSoon);
        } else {
          if (ComingSoon) {
            handleState(values._id, "isEnable", data, ComingSoon);
          } else {
            handleState(values._id, "isEnable", data);
          }
        }
      }
      dispatch(setLoading(false));
    });
  };
  return (
    <>
      <div className="col-4">
        <CustomTooltip direction="top" content={item.name}>
          <img src={item.iconBlack} height="25" alt={String(item.name)} />
        </CustomTooltip>
      </div>
      {/* @ts-ignore */}
      <EditRestricted module={projectModules[projectNames.nft].BLOCK_CHAIN}>
        <div className="col-3">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            checked={item.isVisible === 1 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isVisible", e, item);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-30"
          />
        </div>
        <div className="col-2">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            disabled={!item.isVisible || item.isEnable === 2}
            checked={item.isEnable === 1 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isEnable", e, item);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-10"
          />
        </div>{" "}
        <div className="col-3 d-flex justify-content-between">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            checked={item.isEnable === 2 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isEnable", e, item, e ? 2 : 1);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-30"
          />
          <img src={menu} alt="menu" width={20} height={20} className="drag-blockchain-img" />
        </div>
      </EditRestricted>
    </>
  );
};

export default memo(BlockChainObjectComponent);
