import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getApplicationList } from "../../../actions/portal/adminActions";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, ReactSelectCustomstyle, initialPaginationConfig } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import SearchFilter from "../banji/component/SearchFilter";
import { AdminTableComponent } from "./common/TableComponent";
const AppManagement = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const filterParam = urlParams.get("filter");
  const moduleName = "Application Management";
  const tableHeaders = ["Application Name", "App Redirects", "Available", CREATEDATE, "Updated Date | Time", "Action"];
  const UserTypeOptions = [
    { value: "", label: "All" },
    { value: "available", label: "Available" },
    { value: "inaccessible", label: "Inaccessible" }
  ];

  const [listingData, setListingData] = useState([]);
  const [pageLoadCount, setPageLoadCount] = useState(0);
  const [totalListCount, setTotalListCount] = useState(null);
  const [userTypeFilterValue, setUserTypeFilterValue] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "All" });
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });

  const getAPPListing = () => {
    dispatch(setLoading(true));
    let paginationConfigModified = paginationConfig;
    if (filterParam === "available" && pageLoadCount === 0) {
      paginationConfigModified = {
        ...paginationConfig,
        filter: "1"
      };
      // @ts-ignore
      setUserTypeFilterValue({ value: "available", label: "Available" });
    } else {
      paginationConfigModified = paginationConfig;
    }
    const { filter, search, page, limit } = paginationConfigModified;
    getApplicationList(
      filter && search && page && limit
        ? paginationConfigModified
        : !filter && !search && page && limit
        ? { limit, page }
        : filter && !search && page && limit
        ? { limit, page, filter }
        : !filter && search && page && limit && { limit, page, search }
    ).then((res) => {
      setPageLoadCount(pageLoadCount + 1);
      dispatch(setLoading(false));
      const data = res?.data;
      if (data) {
        const { rows, count } = data;
        setTotalListCount(count);
        setListingData(rows);
      }
    });
  };

  const handleFilter = (data: string) => {
    if (data === "available") {
      return "1";
    } else if (data === "inaccessible") {
      return "0";
    } else {
      return "";
    }
  };

  const handleUserRegisterFilter = (data: any) => {
    setUserTypeFilterValue(data);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      filter: handleFilter(data?.value)
    });
  };

  useEffect(() => {
    getAPPListing();
    // eslint-disable-next-line
  }, [paginationConfig]);

  const [searchField, setSearchField] = useState("");
  const resetFilters = () => {
    let object = { value: "", label: "All" };
    if (searchField === "" && JSON.stringify(userTypeFilterValue) === JSON.stringify(object) && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig((prev) => ({ ...prev, filter: "", search: "" }));
      setUserTypeFilterValue({ value: "", label: "All" });
    }
  };

  return (
    <>
      <MetaFunction meta={moduleName} />
      <div className="holders" style={{ overflow: "auto" }}>
        <p className="main-title m-b-20">{moduleName}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex flex-wrap role-select">
                {/**
                 * @Table @Filters
                 */}
                <div className="filters align-self-center d-flex flex-xxl-nowrap flex-wrap w-100 w-sm-auto mb-10">
                  <Select
                    placeholder="Status"
                    onChange={(e) => handleUserRegisterFilter(e)}
                    options={UserTypeOptions}
                    className="me-2 mb-2 z-index"
                    styles={ReactSelectCustomstyle}
                    value={userTypeFilterValue}
                  />
                  <SearchFilter
                    placeHolder="Search By Application Name"
                    paginationConfig={paginationConfig}
                    setPaginationConfig={setPaginationConfig}
                    search={searchField}
                    setSearch={setSearchField}
                  />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
              </div>
              <AdminTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={moduleName}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default AppManagement;
