import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { updateMarketplaceAppOrderAPI } from "../../../../actions/portal/adminActions";
import { StakingMarketPlaceAPI } from "../../../../actions/staking/marketPlace/stakingMarketPlaceAPI";
import { StakingAdminInfoAPI } from "../../../../actions/staking/stakingAdminInfoAPI";
import MetaFunction from "../../../../common/MetaTag";
import { toastSuccess } from "../../../../common/common-functions";
import { initialPaginationConfig, projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";
import Footer from "../../../common/Footer";
import { CreateRestricted } from "../../../common/restrictions";
import SearchFilter from "../../bvote/component/SearchFilter";
import AppShortcutsListComponent from "../../component/dragAndDropCommmonComponent/AppShortcutsListComponent";
import { StakingTableComponent } from "../common/ListTable";

const StakingMarketPlacemanagement = () => {
  const module = "Marketplace";
  const tableHeaders = ["Name", "Available on", "Document Url", "Actions"];
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);
  const saveRef = useRef();
  const [disabled, setDisabled] = useState<boolean>(true);
  const { isLoading } = useSelector((state: IRootReducerTypes) => state.loading);
  const disableSaveButtonIfNoChangeInList = (display: boolean) => {
    setDisabled(display ? false : true);
  };

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    StakingMarketPlaceAPI(params).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    StakingAdminInfoAPI();
    getCategoryManagementListFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);
  const handleClear = () => {
    if (searchField === "" && paginationConfig?.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "" });
    }
  };

  const [blockChainData, setBlockChainData] = useState([]);
  // eslint-disable-next-line
  // const getBlockChainData = () => {
  //   dispatch(setLoading(true));
  //   getMarketPlaceListAPIinfo({ limit: totalListCount, page: 1 }).then((res) => {
  //     if (res.status === 200) {
  //       setBlockChainData(res.data.data);
  //     }
  //     dispatch(setLoading(false));
  //   });
  // };

  // useEffect(() => {
  //   getBlockChainData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className={"d-flex flex-wrap justify-content-between"}>
                <div className="d-flex flex-wrap mb-20">
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={handleClear}>
                    Clear
                  </button>
                </div>
                <div>
                  <button className="btn border-blue  ml-10 button-width-big  " onClick={() => toastSuccess("Coming Soon")}>
                    {isDragAndDrop ? "Back to App shortcuts" : "Manage Order"}
                  </button>
                  <>
                    {isDragAndDrop ? (
                      <button
                        disabled={disabled}
                        className="btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com"
                        // @ts-ignore
                        onClick={(e) => saveRef.current.click()}>
                        Save
                      </button>
                    ) : (
                      //@ts-ignore
                      <CreateRestricted module={projectModules[projectNames.edexanetwork].MARKETPLACEMANAGEMENT}>
                        <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => history.push(`/${projectNames.edexanetwork}/market-place/add`)}>
                          Add
                        </button>
                      </CreateRestricted>
                    )}
                  </>
                </div>
              </div>
              {isDragAndDrop ? (
                // @ts-ignore
                <AppShortcutsListComponent
                  blockChainData={blockChainData}
                  isLoading={isLoading}
                  setBlockChainData={setBlockChainData}
                  updateAppOrder={updateMarketplaceAppOrderAPI}
                  saveRef={saveRef}
                  disableSaveButtonIfNoChangeInList={disableSaveButtonIfNoChangeInList}
                  getCategoryManagementListFromApi={getCategoryManagementListFromApi}
                />
              ) : (
                <StakingTableComponent
                  listData={listingData}
                  totalCount={totalListCount}
                  tableHeaders={tableHeaders}
                  type={"marketplace"}
                  recall={getCategoryManagementListFromApi}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                  getCategoryManagementListFromApi={getCategoryManagementListFromApi}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default StakingMarketPlacemanagement;
