import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getTemplateListing } from "../../../../actions/portal/adminActions";
import MetaFunction from "../../../../common/MetaTag";
import { initialPaginationConfig, projectNames } from "../../../../common/constants";
import { total_templates_count } from "../../../../store/dashboardCount/action";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";

import Footer from "../../../common/Footer";
import SearchFilter from "../../banji/component/SearchFilter";
import { UniverseTableComponent } from "../common/ListTable";

const TemplateListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const module = "Templates";
  const tableHeaders = ["Template Name", "No. of fields", "Template visibility", "Action"];
  const categoryState = useSelector((state: IRootReducerTypes) => state.categoryModalReducer);

  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfig
  });

  useEffect(() => {
    if (categoryState.callAPI) {
      getCategoryManagementList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryState.callAPI]);

  const getCategoryManagementList = () => {
    dispatch(setLoading(true));
    const { filter, limit, page } = paginationConfig;
    getTemplateListing(
      searchField && filter && limit && page
        ? { search: searchField, filter, limit, page }
        : !searchField && filter && limit && page
        ? { filter, limit, page }
        : searchField && !filter && limit && page
        ? { search: searchField, limit, page }
        : !searchField && !filter && limit && page && { limit, page }
    ).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        dispatch(total_templates_count(res.data.count));
        setListingData(res.data.data);
        dispatch(setLoading(false));
      }
    });
  };

  const resetFilters = () => {
    if (searchField === "" && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig({ ...paginationConfig, search: "" });
    }
  };

  useEffect(() => {
    getCategoryManagementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter placeHolder="Search By Template Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => history.push(`/${projectNames.universe}/create-template`)}>
                  Create
                </button>
              </div>
              <UniverseTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"templateListing"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default TemplateListing;
