import { useSelector } from "react-redux";
import { AllEdexaAdminRoutes } from "../common/constants";
import Header from "../pages/common/Header";
import { SidebarComponent } from "../pages/common/Sidebar";
import useSocketHook from "../utils/hooks/useSocketHook";
import RoleBasedRouting from "./RoleBasedRouting";

export const PrivateRouteComponent = ({ Route, Redirect, privateRoutes, Switch }) => {
  const { isSidebarOpen } = useSelector((state) => state.sidebarToggle);
  const { currentProject } = useSelector((state) => state.projectNameReducer);
  const currentPermission = useSelector((state) => state.permissionsReducer.permissions);

  const handleRedirect = () => {
    let availableKeys = Object.keys(currentPermission?.[currentProject])?.map((key) => key);
    const filteredModules = AllEdexaAdminRoutes?.[currentProject]?.filter((element) => availableKeys.includes(element?.module));
    return <Redirect to={filteredModules[0]?.path} />;
  };

  const { userId } = useSelector((state) => state.userDetails.user);
  useSocketHook(userId);

  return (
    <>
      <Header />
      <div className="main d-flex">
        <SidebarComponent />
        <div className={`main-wrapper transiton-02s w-100 ${isSidebarOpen === true ? "" : "fullwidth"}`}>
          <Switch>
            {privateRoutes.map((route, i) => {
              return <RoleBasedRouting exact={true} path={route?.path} component={route?.component} key={i} module={route?.module} />;
            })}
            {handleRedirect()}
          </Switch>
        </div>
      </div>
    </>
  );
};
