import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { IgetRequestDemoAPIData, getRequestDemoAPI } from "../../../../../actions/bvote/requestDemoAPI";
import MetaFunction from "../../../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig } from "../../../../../common/constants";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import BvoteTableComponent from "../../common/ListTable";
import SearchFilter from "../SearchFilter";

const tableHeaders = ["Name", "Email", "Message", "Phone NO.", CREATEDATE];

const BvoteRequestDemo: FC = () => {
  const module = "Request Demo";
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState<IgetRequestDemoAPIData[]>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    search: ""
  });

  const getBvoteManageGroupsList = () => {
    dispatch(setLoading(true));
    let pagination: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search !== "") {
      pagination.search = paginationConfig.search;
    }
    getRequestDemoAPI(pagination).then((res) => {
      if (res?.status === 200) {
        setTotalListCount(res?.data?.count);
        setListingData(res?.data?.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getBvoteManageGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex flex-wrap justify-content-between mb-20">
                <div className="d-flex flex-wrap">
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button
                    className="btn border-blue  ml-10 button-width-com   "
                    onClick={() => {
                      if (search === "" && paginationConfig.search === "") {
                      } else {
                        setSearch("");
                        setPaginationConfig(initialPaginationConfig);
                      }
                    }}>
                    Clear
                  </button>
                </div>
              </div>
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"requestDemo"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getBvoteManageGroupsList}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default BvoteRequestDemo;
