import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Label } from "reactstrap";
import { universeGetGlobalSettingAPI } from "../../../../actions/universe/universeGetGlobalSetting";
import { universeUpdateGlobalSettingAPI } from "../../../../actions/universe/universeUpdateGlobalSetting";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import ConfirmationModal from "../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";

const UniverseGlobalSetting = () => {
  const dispatch = useDispatch();
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const formik = useFormik({
    initialValues: {
      underMaintenance: false,
      comingSoon: false,
      defaultPeers: "",
      _id: ""
    },
    onSubmit: (values) => {
      toastSuccess("Updated successfully");
    }
  });
  const { values, setFieldValue } = formik;

  const getGloablSettingAction = () => {
    dispatch(setLoading(true));
    universeGetGlobalSettingAPI().then((res: any) => {
      if (res.status === 200) {
        setFieldValue("underMaintenance", res?.data?.underMaintenance);
        setFieldValue("comingSoon", res?.data?.comingSoon);
        setFieldValue("defaultPeers", res?.data?.defaultPeers);
      }
      dispatch(setLoading(false));
    });
  };

  const switchHandleChange = (object: any) => {
    const { filedName, data } = JSON.parse(object);

    dispatch(setLoading(true));
    let parameter: any = {
      [filedName]: data
    };
    if (data) {
      if (filedName === "underMaintenance") {
        if (values.comingSoon) {
          parameter.comingSoon = false;
        }
      } else if (filedName === "comingSoon") {
        if (values.underMaintenance) {
          parameter.underMaintenance = false;
        }
      }
    }

    if (values.defaultPeers) {
      parameter.defaultPeers = values.defaultPeers || 0;
    }

    universeUpdateGlobalSettingAPI(parameter)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue(filedName, data);
          if (data) {
            if (filedName === "underMaintenance") {
              if (values.comingSoon) {
                setFieldValue("comingSoon", false);
              }
            } else if (filedName === "comingSoon") {
              if (values.underMaintenance) {
                setFieldValue("underMaintenance", false);
              }
            }
          }

          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };

  const handleSwitch = (name: string, flag: boolean, moduleName: string) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ filedName: name, data: flag }),
        label: `Are you sure you want to ${flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  useEffect(() => {
    getGloablSettingAction();
    // eslint-disable-next-line
  }, []);

  const handleData = () => {
    let payload;
    if (values) {
      payload = {
        comingSoon: values.comingSoon,
        underMaintenance: values.underMaintenance,
        defaultPeers: values.defaultPeers
      };
    }
    universeUpdateGlobalSettingAPI(payload)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.underMaintenance}
              onChange={(e: any) => {
                handleSwitch("underMaintenance", e, "Under Maintenance");
              }}
              label="Under Maintenance"
              tooltip="Enabling lets customers to view the edeXa Universe in Universe Portal"
              disabled={handleAppActionPermission(projectNames.universe, projectModules[projectNames.universe].GLOBAL_SETTINGS, "update")}
            />
            <SwitchGlobalSetting
              checked={values.comingSoon}
              onChange={(e: any) => {
                handleSwitch("comingSoon", e, "Coming Soon");
              }}
              label="Coming Soon"
              tooltip="Enabling lets customers to view the edeXa Universe in Universe Portal"
              disabled={handleAppActionPermission(projectNames.universe, projectModules[projectNames.universe].GLOBAL_SETTINGS, "update")}
            />
          </div>
          <div className="row">
            <div className="col-md-6 mt-10">
              <Label hstmlFor="adminEmail" className="font-16 color-light-grey">
                Default Peer{" "}
              </Label>
              <Input className="mt-10" type="number" name="subtitle" placeholder="Peer" value={values.defaultPeers} onChange={(e) => setFieldValue("defaultPeers", e.target.value)} />
              <button type="button" onClick={() => handleData()} className="mt-10 btn btn-primary bg-blue border-0 shadow-none button-width-com">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default UniverseGlobalSetting;
