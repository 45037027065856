import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input, Label, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";
import { handleTrim } from "../../../../../common/common-functions";
import { suspendUserRejectionReasons, validatorReasonModalOption } from "../../../../../common/constants";
import CommonButton from "../../../../common/Button";

interface IReasonModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reasonAction: (reason: string) => void;
  handleClose?: any;
}

const ReasonModal: FC<IReasonModal> = ({ isOpen, setIsOpen, reasonAction, handleClose }) => {
  const [optionArray, setOptionArray] = useState<String[]>([""]);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("validate-request")) {
      setOptionArray(validatorReasonModalOption);
    } else if (location.pathname.includes("customer-management")) {
      setOptionArray(suspendUserRejectionReasons);
    }
  }, [location.pathname]);
  const useReason = useFormik({
    initialValues: {
      reason: "",
      option: ""
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required").min(10, "Minimum 10 characters").max(250, "Maximum 250 characters"),
      option: Yup.string().required("Option is required")
    }),
    onSubmit: (value) => {
      reasonAction(value.reason);
    }
  });

  const { errors, touched, handleBlur, setFieldValue, values, handleSubmit, resetForm } = useReason;

  const handleToggle = () => {
    setIsOpen(false);
    resetForm();
    handleClose && handleClose();
  };

  return (
    <Modal isOpen={isOpen} centered size="sm" toggle={handleToggle} style={{ maxWidth: "450px" }}>
      <ModalBody>
        <h3 className="fw-bold font-26">Add Reason</h3>
        {optionArray?.map((item, index) => {
          return (
            <div className="mb-10" key={index}>
              <Label className="mr-15 cursor-pointer" check>
                <Input
                  type="radio"
                  value={String(item)}
                  onClick={() => {
                    setFieldValue("option", item);
                    item === "Other" ? setFieldValue("reason", "") : setFieldValue("reason", item);
                  }}
                  className="mr-10"
                  name="reason"
                  key={index}
                />
                {item}
              </Label>
            </div>
          );
        })}
        {values?.option === "Other" ? (
          <>
            <Input
              type="textarea"
              name="reason"
              placeholder="Enter your reason"
              invalid={Boolean(touched?.reason && errors?.reason)}
              onBlur={handleBlur}
              value={values.reason}
              onChange={(e) => handleTrim("reason", e.target.value, setFieldValue)}
            />
            {touched?.reason ? (
              errors?.reason ? (
                <div className="text-danger mt-1 mb-10">
                  <span>{errors?.reason}</span>
                </div>
              ) : null
            ) : null}
          </>
        ) : null}
        <div className="mt-20 d-flex justify-content-center align-items-center">
          <CommonButton title="Cancel" danger style={{ marginRight: "10px", padding: "inherit" }} onClick={handleToggle} />
          <CommonButton
            disabled={Boolean(!values?.option)}
            title="Rejected"
            primary
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReasonModal;
