import { Autocomplete } from "@autocomplete/material-ui";
import * as AWS from "aws-sdk";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Spreadsheet from "react-spreadsheet";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { importVoters } from "../../../../../actions/bvote/manageGroupsAction";
import { toastError, toastSuccess } from "../../../../../common/common-functions";
import { setLoading } from "../../../../../store/loader/action";
import { voterDialogControlsInterface } from "../../ManageVoters";
import "./ImportVotersDialog.css";

export const ImportVotersDialog = ({ votersDialogControls, handleVoterImport }: { votersDialogControls: voterDialogControlsInterface; handleVoterImport: any }) => {
  // eslint-disable-next-line
  const [selectedGroup, setSelectedGroup] = useState("");
  const [fileValue, setFileValue] = useState<File>();
  const [csvError, setCsvError] = useState("");
  const [csvErrorArray, setCsvErrorArray] = useState([]);
  const [isProcessingVoter, setIsProcessingVoterImport] = useState(false);
  const [voterImported, setVoterImported] = useState(false);
  const [downloading, setDownloading] = useState(false);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const getOptions = (q: string) => {
    let query = new RegExp(q);
    return votersDialogControls.data.map((el) => el.name.toLowerCase()).filter((o) => query.test(o.toLowerCase()));
  };

  const generateHeader = (dataArray: any[]) => {
    let header = [];
    for (var key in dataArray) {
      if (dataArray.hasOwnProperty(key)) {
        header.push({ value: key });
      }
    }
    return header;
  };

  const generateRows = (dataArray: any[]) => {
    let subContent: any[] = [];
    let data: any[] = [];
    dataArray.forEach((el) => {
      for (var val in el) {
        subContent.push({ value: el[val] });
      }
      data.push(subContent);
      subContent = [];
    });
    return data;
  };

  const [error, setError] = useState("");

  const onUploadVoters = () => {
    if (!!selectedGroup) {
      const group = votersDialogControls?.data?.find((group) => group?.name?.toLowerCase() === selectedGroup?.toLowerCase());
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", fileValue);
      group?.id ? formData.append("groupId", group?.id) : formData.append("groupName", selectedGroup);
      setIsProcessingVoterImport(true);
      // @ts-ignore
      importVoters(formData)
        .then((res) => {
          setIsProcessingVoterImport(false);
          dispatch(setLoading(false));
          if (res && res.status === 200) {
            toastSuccess(res.message);
            setVoterImported(true);
            //@ts-ignore
            setFileValue(null);
          }
        })
        .catch((error: any) => {
          setIsProcessingVoterImport(false);
          setLoading(false);
          const { data, status, message } = error?.data;
          setCsvError(message);
          if (status === 400 && data && data?.length > 0) {
            const tableHeader = generateHeader(data[0]);
            const subContent = generateRows(data);
            const mainArray: any = [tableHeader];
            subContent.map((el) => {
              return mainArray.push(el);
            });
            setCsvErrorArray(mainArray);
          }
        });
    } else {
      setError("Please select any one group");
    }
  };

  const handleSampleDownload = () => {
    AWS.config.update({
      credentials: new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_accessKeyId || "",
        secretAccessKey: process.env.REACT_APP_secretAccessKey || ""
      })
    });
    const path = process.env.REACT_APP_SAMPLE_CSV_VOTERS || "";

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_bucket }
    });

    const trimmedUrl: string = path.replace(process.env.REACT_APP_pathToBeTrimmed || "", "");
    setDownloading(true);
    // @ts-ignore
    s3.getObject({ Key: trimmedUrl }, async (err, data) => {
      if (err) {
        setDownloading(false);
      }
      if (data && data.Body) {
        setDownloading(false);
        // @ts-ignore
        const arrayBufferView = new Uint8Array(data.Body);
        const blob = new Blob([arrayBufferView], {
          type: data.ContentType
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "bvote_sample.csv";
        link.click();
      }
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let file: File = event.target.files[0];
      if (file.type === "text/csv") {
        // check on type
        setFileValue(event.target.files[0]);
      } else if (file.name) {
        // check on file extentation from name
        const lastIndex = file.name.lastIndexOf(".");
        const substring = file.name.substring(lastIndex + 1, file.name.length);
        if (substring === "csv") {
          setFileValue(file);
        } else {
          toastError("Only CSV file allowed");
        }
      } else {
        toastError("Only CSV file allowed");
      }
    }
  };

  return (
    <Modal isOpen={votersDialogControls.status} centered>
      <ModalHeader toggle={() => handleVoterImport(false)} className="voter-modal">
        Import voters from file
      </ModalHeader>

      <ModalBody>
        <input id="inputFileCsv" type="file" style={{ outline: "none" }} hidden onChange={handleFileChange} accept=".csv" />
        {/* @ts-ignore */}
        <Autocomplete
          onChange={(q: any) => {
            setError("");
            setSelectedGroup(q);
          }}
          selectOnBlur
          getOptions={getOptions}
          query={selectedGroup}
          placeholder="Select Groups"
        />

        {error && <span className="text-danger font-14">{error}</span>}

        <div className="modal-body-section mb-30">
          <button
            disabled={fileValue?.name ? true : false}
            className="btn btn-primary bg-blue border-0 shadow-none upload-voters-button mt-30 mb-30 d-flex align-items-center"
            onClick={() => document.getElementById("inputFileCsv")?.click()}>
            <i className="fas fa-plus mr-10"></i>
            <p className="align-items-center "> Upload CSV</p>
          </button>

          {fileValue && fileValue.name && (
            <div className="selected-file">
              <span className="text-primary font-16">{fileValue.name}</span>

              <i
                onClick={() => {
                  // @ts-ignore
                  setFileValue(null);
                }}
                className="fas fa-trash-alt text-danger ml-10 cursor-pointer"></i>
            </div>
          )}
          {!voterImported && (
            <div style={{ paddingTop: 15, margin: 6 }}>
              <div style={{ color: "#F64E60 ", fontWeight: 500 }}>{csvError}</div>
              {csvErrorArray && csvErrorArray.length > 0 && <Spreadsheet data={csvErrorArray} />}
            </div>
          )}
          <div className="important-notes-list color-light-grey font-14">
            <ul style={{ paddingLeft: "20px" }}>
              <li className="mb-2">Please upload the CSV file correctly formatted to avoid data mismatch</li>
              <li className="mb-2">The Date must be in the following format: DD-MM-YYYY</li>
              <li>
                The sample can be downloaded from <span onClick={handleSampleDownload}>here</span>
              </li>
            </ul>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <div className="import-voters-footer row">
          <button
            disabled={fileValue?.name ? true : false}
            className="col-md-3 mt-10 btn border-0 d-flex align-items-center shadow-none download-file-button text-white"
            onClick={() => toastSuccess("Coming Soon")}>
            <i className="fas fa-download mr-10"></i>
            <p>{downloading ? "Downloading..." : "Download Sample"}</p>
          </button>

          <button className="col-md-3 mt-10 btn btn-primary bg-blue d-flex align-items-center border-0 shadow-none upload-voters-button" onClick={onUploadVoters}>
            <i className="fas fa-cloud-upload-alt mr-10"></i>
            <p> {isProcessingVoter ? "Uploading..." : "Upload Voters"}</p>
            {isProcessingVoter && (
              <div className="upload-spinner">
                <Spinner />
              </div>
            )}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
