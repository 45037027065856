import { bStampURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const UpdatebStampLabelAPI = (body: any): Promise<IUpdatebStampLabelResponse> => {
  return HTTPService.put(bStampURL.updateLabel, body);
};

export interface IUpdatebStampLabelResponse {
  status: number;
  message: string;
  // type: any;
}
