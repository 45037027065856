import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingAddMarketPlaceAPI = (data?: any): Promise<IStakingAddMarketPlaceAPI> => {
  return HTTPService.post(StakingPortalApiEndPoint.marketPlace.list, data);
};

interface IStakingAddMarketPlaceAPI {
  message: string;
  status: number;
}
