import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Row, Tooltip } from "reactstrap";
import { channelInvitationDetail } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { STATUS_VALUE, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import useHandleUNiversalStatusChange from "../../../../utils/hooks/useHandleUNiversalStatusChange";
import ColumnComponent from "../../../common/ColumanComponent";
import "../../component/kycDetails.css";
import RadioButtonComponent from "../common/RadioButtonComponent";
import UniverseRequestStatusConfirmationPopup from '../../component/UniverseRequestStatusConfirmationPopup';

export const ChannelInvitationDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const { callApi } = useHandleUNiversalStatusChange();
  const redioButton = [{ name: "status", label: "Accepted", value: STATUS_VALUE.ACCEPTED }];
  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);

  const [status, setStatus] = useState(0);
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState();
  const [statusModal, setStatusModal] = useState(false);
  // eslint-disable-next-line
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [channelDetails, setChannelDetails] = useState<any>({
    channelName: "",
    createdAt: "",
    deletedAt: null,
    id: "",
    owner: "",
    status: 0,
    updatedAt: ""
  });

  const toggleStatusModel = () => {
    setStatusModal(!statusModal);
    setStatus(channelDetails?.status);
  };

  const handleStatusChange = (status: any) => {
    setCurrentSelectedStatus(status);
    toggleStatusModel();
  };

  const handleUniversalListStatusChange = (id: any, status: any, reason: any) => {
    callApi(id, status, reason);
    history.push(`/${projectNames.universe}/channel-invitations`);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    channelInvitationDetail(params.id)
      .then((response) => {
        if (response.status === 200) {
          setChannelDetails(response?.data);
          setStatus(response?.data?.status);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        history.push(`/${projectNames.universe}/channel-invitations`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <MetaFunction meta="Channel Invitation Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Channel Invitation Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(channelDetails).length > 0 && (
          <Row className="mt-10">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">Invitation Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    <ColumnComponent title="Channel Name" value={channelDetails?.invitationChannelId?.channelName} />
                    {channelDetails?.status === STATUS_VALUE.IN_PROGRESS || channelDetails?.status === STATUS_VALUE.PANDING ? (
                      <RadioButtonComponent handleStatusChange={handleStatusChange} setStatus={setStatus} redioButtonArray={redioButton} status={status} />
                    ) : null}
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <UniverseRequestStatusConfirmationPopup
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleUniversalListStatusChange}
          currentSelectedStatus={currentSelectedStatus}
          requestId={channelDetails?.id?.toString()}
          toggleStatusModel={toggleStatusModel}
          status={currentSelectedStatus === STATUS_VALUE.ACCEPTED ? "Accepted" : "In-process"}
        />
      </div>
    </>
  );
};
