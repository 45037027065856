import { bMessageURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const bMessageUpdateGlobalSettingAPI = (data: any): Promise<IbMessageUpdateGlobalSettingAPI> => {
  return HTTPService.put(bMessageURL.globalSetting.update, data);
};

interface IbMessageUpdateGlobalSettingAPI {
  message: string;
  status: number;
}
