import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import * as Yup from "yup";
import MetaFunction from "../../../../common/MetaTag";
import { getProfileAPI, onPasswordUpdateAPI, onProfileUpdateAPI } from "../../../../common/api-endpoints";
import { getDecryptedLocalStorage, handleLogout, setEncryptedLocalStorage, toastError, toastSuccess } from "../../../../common/common-functions";
import { localstorageKeys, onlyCharacterWithLimit, onlyNewpassword, validationMessages } from "../../../../common/constants";
import store from "../../../../store/index";
import { setLoading } from "../../../../store/loader/action";
import { onProfileUpdatedAction } from "../../../../store/user/actions";
import ColumnComponent from "./ColumanComponent";
import PasswordComponent from "./PasswordComponent";
import ReadComponent from "./ReadComponent";

const ProfileDetails = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userDetails.user);
  // eslint-disable-next-line
  const [eye, setEye] = useState({
    currentPassword: false,
    password: false,
    confirmPassword: false
  });
  const [linkOpen, setLinkOpen] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);

  const customErrorHandler = (errorResponse) => {
    dispatch(setLoading(false));
    store.dispatch(setLoading(false));
    const data = errorResponse?.data;
    if (data) {
      const { message, status } = data;
      if (status === 401) {
        handleLogout();
      } else if (status === 406) {
        handleLogout();
      } else if (status === 423) {
        handleLogout();
      } else {
        message && toastError(message);
      }
    }
  };

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required(validationMessages.oldPassword.required),
      password: Yup.string()
        .required(validationMessages.newPassword.required)
        .matches(onlyNewpassword, validationMessages.password.matches)
        .notOneOf([Yup.ref("currentPassword")], validationMessages.newPassword.notSame),
      confirmPassword: Yup.string()
        .required(validationMessages.confirmPassword.required)
        .oneOf([Yup.ref("password")], validationMessages.confirmPassword.mustMatch)
    }),
    onSubmit: (values) => {
      setEye({
        currentPassword: false,
        password: false,
        confirmPassword: false
      });
      dispatch(setLoading(true));
      let body = {
        old_password: values.currentPassword,
        new_password: values.password
      };
      const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
      axios
        .put(process.env.REACT_APP_API_DOMAIN_admin + onPasswordUpdateAPI, body, { headers: { Authorization: "Bearer " + userToken } })
        .then((response) => {
          if (response.data.status === 200) {
            toastSuccess(response.data.message);
            passwordFormik.resetForm();
            handleLogout();
          }
          dispatch(setLoading(false));
        })
        .catch((error) => {
          customErrorHandler(error.response);
        });
    }
  });
  const handleProfile = () => {
    if (user?.firstName === values.firstName && user?.lastName === values.lastName && profileImage === user?.profilePic) {
      setLinkOpen(false);
    } else {
      dispatch(setLoading(true));
      const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
      const formData = new FormData();
      values.firstName !== user.firstName && formData.append("firstName", values.firstName);
      values.lastName !== user.lastName && formData.append("lastName", values.lastName);
      profileImage !== user.profilePic && formData.append("profilePic", profileImage);
      axios
        .put(process.env.REACT_APP_API_DOMAIN_admin + onProfileUpdateAPI, formData, { headers: { Authorization: "Bearer " + userToken } })
        .then((response) => {
          dispatch(setLoading(false));
          if (response.data.status === 200) {
            toastSuccess(response.data.message);
            axios
              .get(process.env.REACT_APP_API_DOMAIN_admin + getProfileAPI, {
                headers: { Authorization: "Bearer " + userToken }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  setLinkOpen(false);
                  setEncryptedLocalStorage(localstorageKeys.userInfo, res.data.data);
                  dispatch(onProfileUpdatedAction(res.data.data));
                }
              });
          }
        })
        .catch((error) => {
          customErrorHandler(error.response);
        });
    }
  };

  const profileFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(validationMessages.firstName.required)
        .min(3, "First Name must be at least 3 characters long")
        .max(26, "First Name should not exceed 26 characters limit")
        .matches(onlyCharacterWithLimit, validationMessages.firstName.invalid),
      lastName: Yup.string()
        .required(validationMessages.lastName.required)
        .min(3, "Last Name must be at least 3 characters long")
        .max(26, "Last Name should not exceed 26 characters limit")
        .matches(onlyCharacterWithLimit, validationMessages.lastName.invalid)
    }),
    onSubmit: handleProfile
  });

  const { errors, values, handleChange, handleBlur, touched, setFieldValue } = profileFormik;

  useEffect(() => {
    if (user !== "") {
      setProfileImage(user.profilePic);
      setFieldValue("firstName", user?.firstName);
      setFieldValue("lastName", user?.lastName);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <MetaFunction meta="Profile Details" />
      <div className="holders">
        <p className="main-title m-b-20">Profile Details</p>
        <div className="row justify-content-between">
          <Row className="mt-10">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20 d-flex align-items-start justify-content-between flex-wrap info-title">
                  <div>
                    <p className="sub-title m-b-5">Profile </p>
                  </div>
                  {linkOpen ? (
                    <div className="d-inline-flex">
                      <Link to="#" className="font-14 blue fw-bold" onClick={() => setLinkOpen(false)}>
                        Cancel
                      </Link>
                      <span className="font-14 ml-10 mr-10 blue fw-bold">|</span>
                      <Link to="#" className="font-14 blue fw-bold" onClick={profileFormik.handleSubmit}>
                        Save
                      </Link>
                    </div>
                  ) : (
                    <Link to="#" className="font-14 blue fw-bold" onClick={() => setLinkOpen(true)}>
                      Edit Profile Info
                    </Link>
                  )}
                </div>
                {!linkOpen ? (
                  <ReadComponent title="First Name" value={values.firstName} />
                ) : (
                  <>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center info-field bg-white p-20">
                      <FormGroup className="d-flex align-items-center w-100">
                        <div className="info-name">
                          <p className="font-12 color-black2 fw-normal text-upper">
                            First Name
                            <span className="text-danger fw-900" style={{ fontSize: "15px", marginLeft: "2px" }}>
                              *
                            </span>
                          </p>
                        </div>
                        <Input
                          type="text"
                          className="color-black2 font-16 fw-bold b-none p-0 info-text"
                          style={{
                            outline: "none",
                            border: "none",
                            boxShadow: "none"
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="firstName"
                          value={values.firstName}
                          invalid={Boolean(touched.firstName && errors.firstName && errors.firstName)}
                        />

                        <FormFeedback invalid>{touched.firstName && errors.firstName && errors.firstName}</FormFeedback>
                      </FormGroup>
                    </div>
                  </>
                )}
                {!linkOpen ? (
                  <ReadComponent title="Last Name" value={values.lastName} />
                ) : (
                  <>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center info-field bg-white p-20">
                      <FormGroup className="d-flex align-items-center  w-100">
                        <div className="info-name">
                          <p className="font-12 color-black2 fw-normal text-upper">
                            Last Name
                            <span className="text-danger fw-900" style={{ fontSize: "15px", marginLeft: "2px" }}>
                              *
                            </span>
                          </p>
                        </div>

                        <Input
                          type="text"
                          className="color-black2 font-16 fw-bold b-none p-0 info-text"
                          style={{
                            outline: "none",
                            border: "none",
                            boxShadow: "none"
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="lastName"
                          value={values.lastName}
                          invalid={Boolean(touched.lastName && errors.lastName && errors.lastName)}
                        />

                        <FormFeedback invalid>{touched.lastName && errors.lastName && errors.lastName}</FormFeedback>
                      </FormGroup>
                    </div>
                  </>
                )}
                <ReadComponent title="My Role" value={user?.adminRole?.roleName} />
                <ColumnComponent linkOpen={linkOpen} title="photo" value="" photo={user.profilePic} profileImage={profileImage} setProfileImage={setProfileImage} />
              </div>
            </Col>
          </Row>
          <Row className="mt-30">
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20 d-flex align-items-start justify-content-between flex-wrap info-title">
                  <div>
                    <p className="sub-title m-b-5">Change Password </p>
                  </div>
                  {passwordModal ? (
                    <div className="d-inline-flex">
                      <Link to="#" className="font-14 blue fw-bold" onClick={() => setPasswordModal(false)}>
                        Cancel
                      </Link>
                      <span to="#" className="font-14 blue fw-bold ml-10 mr-10">
                        |
                      </span>
                      <Link to="#" className="font-14 blue fw-bold" onClick={passwordFormik.handleSubmit}>
                        Save
                      </Link>
                    </div>
                  ) : (
                    <div className="d-inline-flex">
                      <Link to="#" className="font-14 blue fw-bold" onClick={() => setPasswordModal(true)}>
                        Change Password
                      </Link>
                    </div>
                  )}
                </div>
                {passwordModal && (
                  <>
                    <PasswordComponent title="Old password" passwordFormik={passwordFormik} name="currentPassword" />
                    <PasswordComponent title="New password" passwordFormik={passwordFormik} name="password" />
                    <PasswordComponent title="Confirm new password" passwordFormik={passwordFormik} name="confirmPassword" />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
