import { NOTIFICATION_ON, PROFILEUPDATE, USERDETAILS } from "./type";

export const onUserDetails = (payload: any) => {
  return { type: USERDETAILS, payload };
};

export const onProfileUpdatedAction = (payload: any) => {
  return {
    type: PROFILEUPDATE,
    payload
  };
};

export const onNotifcationOn = (payload: any) => {
  return { type: NOTIFICATION_ON, payload };
};
