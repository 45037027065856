import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { GetNFTLabelAPI, IGetLabelObject } from "../../../../actions/nft/globalSetting/getNFTLabelAPI";
import { UpdateNFTLabelAPI } from "../../../../actions/nft/globalSetting/updateNFTLabelAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { debounceTimeInMilliseconds, projectModules, projectNames, validationMessages } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import { useDebouncedEffect } from "../../../../utils/hooks/useDebouncedEffect";
import { CreateRestricted, EditRestricted } from "../../../common/restrictions";
import ConfirmationModal from "../../DeleteConfirmationModal";
import AddLabelKey, { ILStateabel } from "./AddLabelKey";
import InputLabelField from "./InputLabelField";

const NftLabelManagement: React.FC = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [labelResponse, setLabelResponse] = useState<IGetLabelObject>();
  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });
  const [labelState, setLabelState] = useState<ILStateabel>({
    labelKey: "",
    english: "",
    german: "",
    add: false
  });

  const formik = useFormik({
    initialValues: {
      language: "EN",
      DE: {},
      EN: {},
      disable: "true",
      _id: ""
    },
    validationSchema: Yup.object().shape({
      DE: Yup.object().required(validationMessages.firstName.required),
      EN: Yup.object().required(validationMessages.firstName.required)
    }),
    onSubmit: (values: any) => {
      dispatch(setLoading(true));
      let paramater: any = {};
      paramater.EN = values.EN;
      paramater.DE = values.DE;
      UpdateNFTLabelAPI(paramater).then((res: any) => {
        if (res?.code === 200 || res?.status === 200) {
          toastSuccess(res?.message);
          // @ts-ignore
          setLabelResponse({ ...labelResponse, DE: values.DE, EN: values.EN });
        }
        dispatch(setLoading(false));
      });
    }
  });

  const handleAction = (va: any) => {
    dispatch(setLoading(true));
    let paramater: any = {};
    paramater.EN = values.EN;
    paramater.DE = values.DE;
    UpdateNFTLabelAPI(paramater)
      .then((res: any) => {
        if (res?.code === 200 || res?.status === 200) {
          dispatch(resetVisible());
          toastSuccess(res?.message);
          // @ts-ignore
          setLabelResponse({ ...labelResponse, DE: values.DE, EN: values.EN });
          setFieldValue("disable", true);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(resetVisible()));
  };
  const { values, setFieldValue } = formik;

  const getLabelAction = () => {
    dispatch(setLoading(true));
    GetNFTLabelAPI().then((res: any) => {
      if (res?.code === 200 || res?.status === 200) {
        setLabelResponse(res.data);
        setFieldValue("_id", res?.data?._id);
        setFieldValue("EN", res?.data?.EN);
        setFieldValue("DE", res?.data?.DE);
      }
      dispatch(setLoading(false));
    });
  };

  const handleLabelStateSubmit = () => {
    dispatch(setLoading(true));
    let paramater: any = {};
    paramater.EN = values.EN;
    paramater.DE = values.DE;
    paramater.EN[labelState.labelKey] = labelState.english;
    paramater.DE[labelState.labelKey] = labelState.german;
    UpdateNFTLabelAPI(paramater).then((res: any) => {
      if (res?.code === 200 || res?.status === 200) {
        toastSuccess(res?.message);
        // @ts-ignore
        setLabelResponse({ ...labelResponse, DE: values.DE, EN: values.EN });
        setLabelState({ add: false, labelKey: "", english: "", german: "" });
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getLabelAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(
    () => {
      // @ts-ignore
      let kay = document.getElementById(search ? search : document.getElementById("label-div")?.firstChild?.id);
      if (kay) {
        kay.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
        kay.focus();
      }
    },
    debounceTimeInMilliseconds,
    [search]
  );

  const setValue = useCallback(
    (key: any, value: any) => {
      setFieldValue(key, value);
    },
    [setFieldValue]
  );
  const handleSerach = useCallback(
    (str: string) => {
      if (str.trim()) {
        setSearch(str);
      } else {
        if (search !== "") {
          setSearch("");
        }
      }
    },
    [setSearch, search]
  );

  const handleSwitch = () => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: "",
        label: "Are you sure you want to update NFT label?",
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: handleAction });
  };

  return (
    <>
      <MetaFunction meta="Label Management" />
      <div className="holders">
        <p className="main-title m-b-20">Label Management</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="d-flex flex-wrap">
              <Input
                type="text"
                placeholder="Search By Label Key"
                className="me-2 w-sm-250px w-100 font-14 search-input mb-sm-0 mb-20 input-hieght"
                onChange={(e) => handleSerach(e.target.value)}
                value={search}
              />
              <button className="btn border-blue button-width-com   " onClick={() => setSearch("")}>
                Clear
              </button>
            </div>
            {/* @ts-ignore */}
            <CreateRestricted module={projectModules[projectNames.nft].LABEL_MANAGEMENT}>
              <button className="btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => setLabelState({ ...labelState, add: !labelState.add })}>
                Add Label
              </button>
            </CreateRestricted>
          </div>
          <div className="col-12 mt-3">
            <div className="row ">
              <div className="col-4 fw-bold">Label key</div>
              <div className="col-4 fw-bold">English value</div>
              <div className="col-4 fw-bold">German value</div>
            </div>
            {labelState.add && <AddLabelKey labelState={labelState} setLabelState={setLabelState} handleLabelStateSubmit={handleLabelStateSubmit} />}
            <div className="nft-label-section mt-10" id="label-div">
              {values.DE &&
                Object.keys(values.DE).map(function (key, index) {
                  return (
                    <div className={`col-12 test ${index !== 0 && "mt-3"}`} key={key} id={key}>
                      <div className="row">
                        <div className="col-4">
                          <Input type="text" disabled name={`${values.language}`} value={key} />
                        </div>
                        <InputLabelField
                          setFieldValue={setValue}
                          // @ts-ignore
                          value={values.EN[`${key}`]}
                          keyValue={key}
                          lang="EN"
                          disable={!handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update") || false}
                        />
                        <InputLabelField
                          lang="DE"
                          setFieldValue={setValue}
                          keyValue={key}
                          // @ts-ignore
                          value={values.DE[key]}
                          disable={!handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update") || false}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* @ts-ignore */}
            <EditRestricted module={projectModules[projectNames.nft].LABEL_MANAGEMENT}>
              <div className="col-12 mt-10 mb-10">
                <button type="button" disabled={Boolean(values.disable)} className="btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={() => handleSwitch()}>
                  Save
                </button>
              </div>
            </EditRestricted>
          </div>
        </div>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default NftLabelManagement;
