import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { onGroupsListAPI } from "../../../actions/bvote/manageGroupsAction";
import { onListingAPI } from "../../../actions/bvote/manageVotersActions";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig, projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import { CreateRestricted } from "../../common/restrictions";
import BvoteTableComponent from "./common/ListTable";
import SearchFilter from "./component/SearchFilter";
import { ImportVotersDialog } from "./component/voter/ImportVotersDialog";

const tableHeaders = ["Voter Name", CREATEDATE, "Status", "Actions"];

export interface voterDialogControlsInterface {
  status: boolean;
  data: Array<any>;
}

const ManageVoters: FC = () => {
  const module = "Manage Voters";
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);
  const [votersDialogControls, setVotersDialogControls] = useState<voterDialogControlsInterface>({ status: false, data: [] });

  const getBvoteManageVotersActions = () => {
    dispatch(setLoading(true));
    onListingAPI(paginationConfig).then((res: any) => {
      if (res?.status === 200) {
        setTotalListCount(res?.data?.count);
        setListingData(res?.data?.rows);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBvoteManageVotersActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const getGroupsList = () => {
    onGroupsListAPI().then((res) => {
      setVotersDialogControls({ ...votersDialogControls, data: res.data.rows });
    });
  };

  const resetFilters = () => {
    if (search === "" && paginationConfig.search === "") {
    } else {
      setSearch("");
      setPaginationConfig(initialPaginationConfig);
    }
  };

  const handleRedirect = () => {
    history.push(`/${projectNames.bvote}/manage-voters/add`);
  };

  const handleVoterImport = (openStatus: boolean) => {
    setVotersDialogControls({ ...votersDialogControls, status: openStatus });
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.bvote].MANAGE_VOTERS}>
                  <div className="d-flex flex-wrap">
                    <button className="btn btn-primary me-2 bg-blue border-0 shadow-none button-width-com" onClick={handleRedirect}>
                      Create
                    </button>
                    <button className="btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={() => handleVoterImport(true)}>
                      Import
                    </button>
                  </div>
                </CreateRestricted>
              </div>
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"voters"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getBvoteManageVotersActions}
              />
              <ImportVotersDialog votersDialogControls={votersDialogControls} handleVoterImport={handleVoterImport} />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ManageVoters;
