import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getbyouUserList } from "../../../actions/portal/byouActions";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, ReactSelectCustomstyle, initialPaginationConfig, projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import { CreateRestricted } from "../../common/restrictions";
import SearchFilter from "../bvote/component/SearchFilter";
import { ByouTableComponent } from "./common/ListTable";

const UserManagement = () => {
  const module = "User Management";
  const history = useHistory();
  const dispatch = useDispatch();
  const initialFIlters = {
    ...initialPaginationConfig
  };

  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState<any>([]);
  const [tableHeaders, setTableHeaders] = useState(["Name", "Email", "Phone", "Date Created"]);
  // eslint-disable-next-line
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialFIlters);
  // eslint-disable-next-line
  const [userTypeFilterValue, setUserTypeFilterValue] = useState({ value: "", label: 'All' });

  const TableStatusFilter = [
    { value: "", label: "All" },
    { value: "User/Holder", label: "User/Holder" },
    { value: "Verifier", label: "Verifier" },
    { value: "Issuer", label: "Issuer" }
  ];

  const { filter, limit, page } = paginationConfig;

  const getbYouUsersListing = () => {
    dispatch(setLoading(true));
    getbyouUserList(
      searchField && filter && limit && page
        ? { search: searchField, filter, limit, page }
        : !searchField && filter && limit && page
        ? { filter, limit, page }
        : searchField && !filter && limit && page
        ? { search: searchField, limit, page }
        : !searchField && !filter && limit && page && { limit, page }
    ).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res?.data?.data);
        dispatch(setLoading(false));
      }
    });
  };

  const callModuleAPI = (route: any) => {
    if (route === `/${projectNames.byou}/user-management`) {
      setTableHeaders(["Name", "Email", "Role", CREATEDATE, "Action"]);
      getbYouUsersListing();
    }
  };

  useEffect(() => {
    callModuleAPI(history?.location?.pathname);
    // eslint-disable-next-line
  }, [paginationConfig]);

  const handleFilter = (data: string) => {
    if (data === "User/Holder") {
      return "0";
    } else if (data === "Verifier") {
      return "1";
    } else if (data === "Issuer") {
      return "2";
    } else {
      return "";
    }
  };

  const handleUserManagementFilter = (data: any) => {
    setUserTypeFilterValue(data);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      filter: handleFilter(data?.value)
    });
  };

  const resetFilters = () => {
    if (searchField === "" && paginationConfig?.search === "" && paginationConfig?.filter === "") {
    } else {
      setSearchField("");
      setPaginationConfig({
        ...paginationConfig,
        page: 1,
        limit: 10,
        search: "",
        filter: ""
      });
      setUserTypeFilterValue({ value: "", label: "All" });
    }
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        {/* @ts-ignore */}
        <Row>
          {/* @ts-ignore */}
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex mb-20 flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <Select
                    placeholder="Status"
                    className="me-2 z-index"
                    onChange={(e) => handleUserManagementFilter(e)}
                    options={TableStatusFilter}
                    value={userTypeFilterValue}
                    styles={ReactSelectCustomstyle}
                  />
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />

                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.byou]?.USER_MANAGEMENT}>
                  <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={() => history.push("/byou/create-issuer")}>
                    Create
                  </button>
                </CreateRestricted>
              </div>
              {/* @ts-ignore */}
              <ByouTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"user-management"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default UserManagement;
