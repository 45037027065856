import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Row, Tooltip } from "reactstrap";
import { getChaincodeDetail, updateChaincode } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { getFormattedDate, toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, STATUS_VALUE, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import ColumnComponent from "../../../common/ColumanComponent";
import StatusComponent from "../../../common/StatusComponent";
import { UniversalRejectionPopup } from "../../component/UniversalRejectionPopup";
import UniverseRequestStatusConfirmationPopup from "../../component/UniverseRequestStatusConfirmationPopup";
import "../../component/kycDetails.css";
import RadioButtonComponent from "../common/RadioButtonComponent";

export const ChaincodeDetails = () => {
  const params: any = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);

  const { isLoading } = useSelector((state: any) => ({
    isLoading: state.loading.isLoading
  }));

  const [universalStatusToggle, setUniversalStatusToggle] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState();
  const redioButton = [
    { name: "status", label: "Accepted", value: STATUS_VALUE.ACCEPTED },
    { name: "status", label: "Rejected", value: STATUS_VALUE.REJECTED },
    { name: "status", label: "In-Progress", value: STATUS_VALUE.IN_PROGRESS }
  ];
  const [statusModal, setStatusModal] = useState(false);
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [status, setStatus] = useState(0);
  const [chainCodeDetails, setChainCodeDetails] = useState<any>({});

  const toggleStatusModel = () => {
    setStatusModal(!statusModal);
    setStatus(Number(chainCodeDetails?.status));
  };

  useEffect(() => {
    if (currentSelectedStatus !== "2") {
    }
  }, [currentSelectedStatus]);
  const handleStatusChange = (status: any) => {
    if (status === 2) {
      // open the reason for rejection popup
      setUniversalStatusToggle(true);
    } else {
      setCurrentSelectedStatus(status);
      toggleStatusModel();
    }
  };

  const handleChainCodeListStatusChange = (id: any, status: any, reason: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateChaincode(id, status, reason && reason).then((res) => {
      if (res.status === 200) {
        setStatusModalLoader(false);
        history.push(`/${projectNames.universe}/chain-code-management`);
        toastSuccess(res.messgae);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getChaincodeDetail(params.id).then((response) => {
      if (response.status === 200) {
        setChainCodeDetails(response?.data);
        setStatus(response.data?.status);
        dispatch(setLoading(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <MetaFunction meta="Chaincode Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Chaincode Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(chainCodeDetails).length > 0 && (
          // @ts-ignore
          <Row className="mt-10">
            {/* @ts-ignore */}
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">Chaincode Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    <StatusComponent status={chainCodeDetails?.status} />
                    {chainCodeDetails?.status === STATUS_VALUE.IN_PROGRESS || chainCodeDetails?.status === STATUS_VALUE.PANDING ? (
                      <RadioButtonComponent handleStatusChange={handleStatusChange} setStatus={setStatus} redioButtonArray={redioButton} status={status} />
                    ) : null}

                    <ColumnComponent title="Channel Name" value={chainCodeDetails?.chainCodeChannelId?.channelName} />
                    <ColumnComponent title="Template Name" value={chainCodeDetails?.chainCodeTemplateId?.name} />
                    <ColumnComponent title="Template Description" value={chainCodeDetails?.chainCodeTemplateId?.description} />
                    <ColumnComponent title="User Name" value={chainCodeDetails?.chainCodeUserId?.name} />
                    <ColumnComponent title="User Name" value={chainCodeDetails?.chainCodeUserId?.email} />
                    <ColumnComponent title={CREATEDATE} value={chainCodeDetails?.createdAt && getFormattedDate(chainCodeDetails?.createdAt)} />
                    <ColumnComponent title="Updated Date | Time" value={chainCodeDetails?.updatedAt} />
                    <ColumnComponent title="Token Name" value={chainCodeDetails?.fields.tokenName} />
                    <ColumnComponent title="Token Symbol" value={chainCodeDetails?.fields.tokenSymbol} />
                    <ColumnComponent title="Total supply" value={chainCodeDetails?.fields.totalSupply} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <UniversalRejectionPopup
          isOpen={universalStatusToggle}
          setUniversalStatusToggle={setUniversalStatusToggle}
          isLoading={isLoading}
          reason={rejectReason}
          setStatus={setStatus}
          preState={chainCodeDetails?.status}
          setReason={setRejectReason}
          id={chainCodeDetails?.id}
          handleUniversalListStatusChange={handleChainCodeListStatusChange}
        />
        <UniverseRequestStatusConfirmationPopup
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleChainCodeListStatusChange}
          currentSelectedStatus={currentSelectedStatus}
          requestId={chainCodeDetails.id}
          toggleStatusModel={toggleStatusModel}
          status={status === STATUS_VALUE.ACCEPTED ? "Accepted" : "In-Progress"}
        />
      </div>
    </>
  );
};
