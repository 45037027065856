import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { grantPermission } from "../common/common-functions";
import { AllEdexaAdminRoutes, allEdexaAdminRoutesType } from "../common/constants";

/**
 *
 * @param {JSX component, user-permissions-array, ...rest parameters}
 * @returns {Route or redirect}
 */

const RoleBasedRouting = ({ component: Component, module, path, ...rest }: PropsType) => {
  /**
   * @todo
   *  1. Get the current selected Project from the redux store.
   *  2. If we receive an array of permission loop through and get the permission [view] value of current project.
   *  3. send this value to the grantPermission function
   */
  // get all the current permissions of the user
  /**
   * It will be an array of projects which contains modules and modules have their seperate permissions
   * - Please refer to permissionReducer for more information,
   * - The initialState shape may change as per the backend data structure.
   */
  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);
  // get the current project name which one is selected
  const currentProject = useSelector((state: any) => state.projectNameReducer.currentProject);

  const handleRedirect = () => {
    let availableKeys = Object.keys(currentPermission?.[currentProject])?.map((key) => key);
    // @ts-ignore
    const filteredModules = AllEdexaAdminRoutes?.[currentProject]?.filter((element: allEdexaAdminRoutesType) => availableKeys.includes(element?.module));
    return filteredModules[0]?.path;
  };
  /* route which will be rendered always -  */
  /* user profile - route */
  if (path === "/profile")
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => (
          <>
            {/* @ts-ignore */}
            <Component {...props} />
          </>
        )}
      />
    );

  if (path === "/notification")
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => (
          <>
            {/* @ts-ignore */}
            <Component {...props} />
          </>
        )}
      />
    );

  return (
    <>
      {grantPermission(currentPermission?.[currentProject]?.[module]?.view) && (
        <Route
          {...rest}
          path={path}
          render={(props) => (
            <>
              {/* @ts-ignore */}
              <Component {...props} />
            </>
          )}
        />
      )}
      {!grantPermission(currentPermission?.[currentProject]?.[module]?.view) && (
        <Route
          render={() => (
            <>
              {/* if !permissions then return to current projects dashboard
                                    - we may need to change it In case user should be redirected to permitted project
                                */}
              <Redirect to={handleRedirect()} />
            </>
          )}
        />
      )}
    </>
  );
};
export default RoleBasedRouting;

interface PropsType {
  component: Route;
  module: string;
  path: string;
}
