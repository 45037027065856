import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { onUpdateAPI } from "../../../../actions/bvote/pollAction";
import { toastSuccess } from "../../../../common/common-functions";
import { setLoading } from "../../../../store/loader/action";
import SwitchComponent from "../component/poll/SwitchComponent";

const BvoteLiveResultComponent = ({ isActive, id, disable, rollIsActive, recall }: any) => {
  const dispatch = useDispatch();

  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);

  const [checked, setChecked] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setChecked(isActive === 1 ? true : false);
  }, [isActive]);

  const handleInputChange = (e: any) => {
    dispatch(setLoading(true));
    onUpdateAPI(id, { displayResult: isActive === 1 ? 0 : 1 }).then((res: any) => {
      if (res?.status === 200) {
        setChecked(!checked);
        setVisible(false);
        toastSuccess(res?.message);
        recall();
      }
    });
  };

  return (
    <>
      <div className="d-flex align-baseline" title="Show Live Result">
        <SwitchComponent checked={checked} onChange={() => setVisible(true)} disabled={!currentPermission?.bvote?.poll?.update} />
        <span>{checked ? "Active" : "Inactive"}</span>
      </div>
      <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center">Confirmation</h4>
            <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
              Are your sure to {checked ? "Inactive" : "Active"} Poll Live Result?
            </p>
          </div>
          <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
            <div
              className="col-6 p-10 d-flex cursor-pointer"
              style={{ borderRight: "1px solid #dadce0" }}
              onClick={() => {
                setVisible(false);
              }}>
              <button className="confimatio-button text-danger">No</button>
            </div>
            <div className="col-6 p-10 d-flex cursor-pointer" onClick={handleInputChange}>
              <button className="confimatio-button text-success">Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BvoteLiveResultComponent;
