import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateBlockChainRankAPI } from "../../../../actions/nft/blockChain/blockChainRank";
import { toastSuccess } from "../../../../common/common-functions";
import { projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { EditRestricted } from "../../../common/restrictions";
import BlockChainObjectComponent from "./BlockChainObjectComponent";

const BlockChainDragAndDrop: FC<any> = ({ blockChainObject, setBlockChainData }) => {
  const dispatch = useDispatch();

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const [display, setDisplay] = useState(false);

  const dragStart = (e: any, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    if (dragItem.current === dragOverItem.current) {
      dragItem.current = null;
      dragOverItem.current = null;
      return;
    }
    const copyListItems = [...blockChainObject];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setBlockChainData(copyListItems);
    setDisplay(true);
  };

  const handleSave = () => {
    dispatch(setLoading(true));
    let rank = blockChainObject?.map((data: any, index: number) => {
      data.order = index + 1;
      return { order: data.order, gateway: data.gateway };
    });
    updateBlockChainRankAPI({ rank }).then((res: any) => {
      if (res.status === 200) {
        toastSuccess(res.message);
        setDisplay(false);
      }
      dispatch(setLoading(false));
    });
  };

  return (
    <>
      {blockChainObject &&
        blockChainObject?.map((item: any, index: number) => (
          <div
            className="row mb-4 align-items-center block-chain-object cursor-pointer"
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            onDragOver={(e) => e.preventDefault()}
            draggable>
            <BlockChainObjectComponent item={item} setBlockChainData={setBlockChainData} />
          </div>
        ))}
      {display && (
        //  @ts-ignore
        <EditRestricted module={projectModules[projectNames.nft].BLOCK_CHAIN}>
          <div className="d-flex flex-column justify-content-end">
            <h6 className="mb-4 text-gray font-14 blockchainNote">*Supported Blockchain positions will be saved when you click on save.</h6>
            <button className="btn-sm btn-primary bg-blue rounded-5 py-2 px-3 font-15 border-none" onClick={() => handleSave()}>
              Save
            </button>
          </div>
        </EditRestricted>
      )}
    </>
  );
};

export default BlockChainDragAndDrop;
