import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import Switch from "react-switch";
import { Input, Label } from "reactstrap";
import * as Yup from "yup";
import { onAddAPI, onUpdateAPI, onViewAPI } from "../../../../../actions/bvote/manageGroupsAction";
import { onListVoterAPI } from "../../../../../actions/bvote/manageVotersActions";
import MetaFunction from "../../../../../common/MetaTag";
import { dropDownCustomStyles, handleTrim, toastSuccess } from "../../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../../common/constants";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import PageHeader from "../../common/PageHeader";

const createPath = `/${projectNames.bvote}/manage-groups/add`;

const Group = ({ location }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [module, setModule] = useState("");
  const [selectVoters, setSelectVoters] = useState<any[]>([]);
  const [Groups, setGroups] = useState<any[]>([]);
  const [data, setData] = useState({
    name: "",
    status: "",
    voterInfo: []
  });

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    onViewAPI(id)
      .then((res: any) => {
        if (res?.status === 200) {
          setData(res?.data);
        }
      })
      .catch((err: any) => {
        history.push(`/${projectNames.bvote}/manage-voters`);
      });
  };

  const getListVoters = () => {
    dispatch(setLoading(true));
    onListVoterAPI().then((res: any) => {
      if (res?.status === 200) {
        let temp: any = [];
        res?.data?.rows.map((data: any) =>
          temp.push({
            label: `${data?.firstName} ${data?.lastName} ( ${data?.email} )`,
            value: data?.id
          })
        );
        setGroups(temp);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getListVoters();
    createPath === history?.location?.pathname ? setModule("Create Group") : setModule("Edit Group");
    createPath !== history?.location?.pathname && getViewAction(location?.state?.id);
    // eslint-disable-next-line
  }, [module, history]);

  useEffect(() => {
    if (createPath !== history?.location?.pathname) {
      if (data) {
        setFieldValue("name", data?.name);
        setFieldValue("status", data?.status === "active" ? true : false);
        let selected: Array<any> = [];
        // @ts-ignore
        data?.voterInfo
          .filter((item: any) => item?.checked === true)
          .map((item: any) =>
            selected?.push({
              label: `${item.firstName} ${item.lastName} ( ${item.email} )`,
              value: item.id,
              ...(item?.voter?.id && { voterId: item.voter.id })
            })
          );
        // @ts-ignore
        setSelectVoters(selected);
        dispatch(setLoading(false));
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const getAddAction = (values: any) => {
    let temp = {
      name: values.name.trim(),
      status: values.status ? "active" : "inactive",
      addId: values.addId
    };
    dispatch(setLoading(true));
    onAddAPI(temp).then((res: any) => {
      if (res?.status === 200) {
        history.push(`/${projectNames.bvote}/manage-groups`);
        toastSuccess(res?.message);
        groupFormik?.resetForm();
      }
    });
  };

  const getUpdateAction = (values: any) => {
    let temp: any = {
      name: values.name.trim()
    };
    if (values.status !== data?.status) {
      temp.status = values.status ? "active" : "inactive";
    }

    if (values.addId.length > 0) {
      // filter uncheck values from onload, and check if from that list any new value is selected, onlyy filter and send those values
      const addItems = data.voterInfo
        .filter((voter: any) => voter.checked === false)
        .map((el: any) => el.id)
        .filter((element: any) => values.addId.includes(element));
      if (addItems.length > 0) {
        temp.addId = addItems;
      }
    }

    const deleteItems = data.voterInfo
      .filter((voter: any) => voter.checked === true)
      .filter((element: any) => !values.addId.includes(element.id))
      .map((el: any) => el.voter.id);
    if (deleteItems.length > 0) {
      // filter checked values onload and now check if they are missing from the selected array, if yes, send their voter id to delete items array
      temp.removeId = deleteItems;
    }

    dispatch(setLoading(true));
    onUpdateAPI(location?.state?.id, temp).then((res: any) => {
      if (res?.status === 200) {
        history.push(`/${projectNames.bvote}/manage-groups`);
        toastSuccess(res?.message);
      }
    });
  };

  const groupFormik = useFormik({
    initialValues: {
      name: "",
      status: true,
      addId: [],
      removeId: []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(validationMessages.groupName.required).min(3, validationMessages.groupName.min).max(100)
    }),
    onSubmit: (values) => {
      if (selectVoters?.length === 0) {
        setError("Please select a voter");
      } else {
        createPath === history?.location?.pathname ? getAddAction(values) : getUpdateAction(values);
      }
    }
  });
  const { errors, values, handleBlur, touched, handleSubmit, setFieldValue } = groupFormik;

  const handleChangeVoter = (e: any) => {
    setSelectVoters(e);
    let votersIds = e.map((data: any) => data.value);
    setFieldValue("addId", votersIds);
    setError("");
  };

  const loader = useSelector((state: any) => state?.loading?.isLoading);
  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          {loader ? (
            "Loading..."
          ) : (
            <div className="row">
              <div className="col-md-6">
                <Label htmlFor="name" className="fw-normal color-light-grey py-2">
                  Group Name
                </Label>
                <Input
                  type="text"
                  placeholder="Group Name"
                  id="groupName"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                  invalid={Boolean(touched?.name && errors.name)}
                />
                {touched?.name && errors.name && <span className="text-danger font-14 mt-2">{errors.name}</span>}
              </div>
              <div className="col-md-6">
                <Label htmlFor="voter" className="fw-normal color-light-grey py-2">
                  Select Voter
                </Label>
                {/* @ts-ignore */}
                <Select styles={dropDownCustomStyles} isMulti placeholder="Select Voter" options={Groups} value={selectVoters} onChange={handleChangeVoter} />
                {error && <span className="text-danger font-14">{error}</span>}
              </div>
              <div className="col-12 d-flex flex-column">
                <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                  Status
                </Label>
                <Switch
                  height={20}
                  width={40}
                  onColor="#007de4"
                  offColor="#b1abab"
                  // @ts-ignore
                  value={values.status}
                  checked={values.status}
                  onChange={() => setFieldValue("status", !values.status)}
                  uncheckedIcon
                  checkedIcon
                  className="mr-10"
                />
              </div>
              <div className="col-md-12 mt-20">
                {/* @ts-ignore */}
                <button onClick={handleSubmit} className="btn btn-primary bg-blue border-0 shadow-none button-width-com">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Group;
