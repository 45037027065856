import { rolesPermissionsURLS } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

// rolesPermissions api-end-points or url
let { getListRoles, createRole, deleteRole, viewRole, updateRole, activeInactive } = rolesPermissionsURLS;

// create any role that to call this api
export const onCreateRoleAPI = (data: any) => {
  return HTTPService.post(createRole, data);
};

// role in details that to call this api
// this api to accept one query-params this paramas name is id to pass id of role
export const onViewRoleAPI = (params: any) => {
  return HTTPService.get(viewRole, { params });
};

// to all roles to see that that to call this api
export const onListRolesAPI = (params: any) => {
  return HTTPService.get(getListRoles, { params });
};

// any role to edit that to call this api
export const onUpdateRoleAPI = (data: any) => {
  return HTTPService.put(updateRole, data);
};

// any role to delete thart to call this api
// this api to accept one query-params this paramas name is id to pass id of role
export const onDeleteRoleAPI = (params: any) => {
  return HTTPService.delete(deleteRole, "", { params });
};

// paginaation false pass that api call this
// this api all data show
export const onAllListRoleAPI = (params: any) => {
  return HTTPService.get(getListRoles, { params });
};

// role active or inactive
export const onRoleActiveInactive = (body: any) => {
  return HTTPService.put(activeInactive, body);
};
