import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import Switch from "react-switch";
import { Input, Label } from "reactstrap";
import * as Yup from "yup";
import { onGroupsListAPI } from "../../../../../actions/bvote/manageGroupsAction";
import { onAddAPI, onUpdateAPI, onViewVoterAPI } from "../../../../../actions/bvote/manageVotersActions";
import MetaFunction from "../../../../../common/MetaTag";
import { dropDownCustomStyles, handleTrim, toastError, toastSuccess } from "../../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../../common/constants";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import PageHeader from "../../common/PageHeader";

const createPath = `/${projectNames.bvote}/manage-voters/add`;

const Voter = ({ location }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [selectGroups, setSelectGroups] = useState([]);
  const [initialGroupArray, setInitialGroupArray] = useState<any[]>([]);
  const [module, setModule] = useState("Create Voter");
  const [Groups, setGroups] = useState([]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    status: "",
    groupInfor: []
  });

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    onViewVoterAPI(id)
      .then((res: any) => {
        if (res?.status === 200) {
          setData(res?.data);
        }
        dispatch(setLoading(false));
      })
      .catch((err: any) => {
        history.push(`/${projectNames.bvote}/manage-voters`);
      });
  };

  const getGroupsListAction = () => {
    dispatch(setLoading(true));
    onGroupsListAPI().then((res: any) => {
      if (res?.status === 200) {
        let temp: Array<any> = [];
        res?.data.rows.filter((element: any) => element.status === "active").map((item: any) => temp.push({ label: `${item?.name}`, value: item?.id }));
        // @ts-ignore
        setGroups(temp);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getGroupsListAction();

    createPath === history?.location?.pathname ? setModule("Create Voter") : setModule("Edit Voter");

    createPath !== history?.location?.pathname && getViewAction(location?.state?.id);
    // eslint-disable-next-line
  }, []);

  const getAddAction = (values: any) => {
    dispatch(setLoading(true));
    let parameter: any = {
      birthDate: values?.birthDate,
      firstName: values?.firstName.trim(),
      lastName: values?.lastName.trim(),
      email: values?.email.trim(),
      status: values?.status ? "active" : "inactive",
      role: "voter"
    };
    if (values?.groupId.length) {
      parameter.groupId = values?.groupId;
    }
    onAddAPI(parameter).then((res: any) => {
      if (res?.status === 200) {
        history.push(`/${projectNames.bvote}/manage-voters`);
        toastSuccess(res?.message);
      }
    });
  };

  const getUpdateAction = (values: any) => {
    if (initialGroupArray && initialGroupArray.length > 0 && selectGroups.length === 0) {
      return toastError("Please select a group");
    }

    dispatch(setLoading(true));
    let parameter: any = {
      birthDate: values?.birthDate,
      firstName: values?.firstName.trim(),
      lastName: values?.lastName.trim(),
      email: values?.email.trim(),
      status: values?.status ? "active" : "inactive",
      role: "voter"
    };

    if (values.groupId.length > 0) {
      parameter.groupId = selectGroups.map((group: any) => group.value);
    }

    onUpdateAPI(location?.state?.id, parameter).then((res: any) => {
      if (res?.status === 200) {
        history.push(`/${projectNames.bvote}/manage-voters`);
        groupFormik.resetForm();
        toastSuccess(res?.message);
      }
    });
  };

  const groupFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      birthDate: "",
      status: true,
      groupId: []
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(validationMessages.firstName.required).min(2, validationMessages.firstName.min).max(100),
      lastName: Yup.string().required(validationMessages.lastName.required).min(2, validationMessages.lastName.min).max(100),
      email: Yup.string().required(validationMessages.email.required).email(validationMessages.email.invalid),
      birthDate: Yup.string().required(validationMessages.birthDate.required)
    }),
    onSubmit: (values) => {
      createPath === history?.location?.pathname ? getAddAction(values) : getUpdateAction(values);
    }
  });

  const { errors, values, handleBlur, touched, handleSubmit, setFieldValue } = groupFormik;

  const handleChangeVoter = (e: any) => {
    setSelectGroups(e);
    let addIds = e.map((item: any) => item?.value);
    if (addIds.length) {
      setFieldValue("groupId", addIds);
    }
    setError("");
  };

  useEffect(() => {
    if (createPath !== history?.location?.pathname) {
      if (data) {
        setFieldValue("firstName", data?.firstName);
        setFieldValue("lastName", data?.lastName);
        setFieldValue("email", data?.email);
        setFieldValue("birthDate", data?.birthDate);
        setFieldValue("status", data?.status === "active" ? true : false);
        //@ts-ignore
        if (data?.groupInfo?.length > 0) {
          let tempArray: Array<any> = [];
          //@ts-ignore
          data?.groupInfo.filter((item: any) => item?.checked === true).map((item: any) => tempArray.push({ label: item?.name, value: item?.id }));
          //@ts-ignore
          setSelectGroups(tempArray);
          setInitialGroupArray(tempArray);
        }
      }
    }
    //eslint-disable-next-line
  }, [data]);

  const triggerDatePicker = () => {
    const birthDate = document.getElementById("birthDate");
    birthDate?.click();
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          <div className="row">
            <div className="col-md-6">
              <Label htmlFor="firstName" className="fw-normal color-light-grey py-2">
                First Name
              </Label>
              <Input
                type="text"
                placeholder="First Name"
                id="firstName"
                name="firstName"
                onBlur={handleBlur}
                onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                value={values.firstName}
                invalid={Boolean(touched?.firstName && errors.firstName)}
              />
              {touched?.firstName && errors.firstName && <span className="text-danger font-14 mt-2">{errors.firstName}</span>}
            </div>

            <div className="col-md-6">
              <Label htmlFor="lastName" className="fw-normal color-light-grey py-2">
                Last Name
              </Label>
              <Input
                type="text"
                placeholder="Last Name"
                id="lastName"
                name="lastName"
                onBlur={handleBlur}
                onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                value={values.lastName}
                invalid={Boolean(touched?.lastName && errors.lastName)}
              />
              {touched?.lastName && errors.lastName && <span className="text-danger font-14 mt-2">{errors.lastName}</span>}
            </div>

            <div className="col-md-6" onClick={triggerDatePicker}>
              <Label htmlFor="birthDate" className="fw-normal color-light-grey py-2">
                Birth Date
              </Label>
              <label className={"position-relative w-100 "}>
                <i className="fa fa-calendar position-absolute calendar-icon"></i>
                <ReactDatePicker
                  // @ts-ignore
                  selected={values.birthDate}
                  onBlur={handleBlur}
                  id="birthDate"
                  name="birthDate"
                  onChange={(date) => setFieldValue("birthDate", date)}
                  placeholderText="Enter the Birth Date"
                  className="form-control"
                  maxDate={new Date()}
                />
              </label>
              {touched?.birthDate && errors.birthDate && <span className="text-danger font-14 mt-2">{errors.birthDate}</span>}
            </div>

            <div className="col-md-6">
              <Label htmlFor="email" className="fw-normal color-light-grey py-2">
                Email
              </Label>
              <Input
                type="text"
                placeholder="Email"
                disabled={createPath !== history?.location?.pathname}
                id="email"
                name="email"
                onBlur={handleBlur}
                onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                value={values.email}
                invalid={Boolean(touched?.email && errors.email)}
              />
              {touched?.email && errors.email && <span className="text-danger font-14 mt-2">{errors.email}</span>}
            </div>

            <div className="col-md-6 cursor-pointer">
              <Label htmlFor="group" className="fw-normal color-light-grey py-2">
                Select Group
              </Label>
              {/* @ts-ignore */}
              <Select styles={dropDownCustomStyles} isMulti placeholder="Select Group" className="cursor-pointer" options={Groups} value={selectGroups} onChange={handleChangeVoter} />
              {error && <span className="text-danger font-14">{error}</span>}
            </div>

            <div className="col-12 d-flex flex-column">
              <Label htmlFor="status" className="fw-normal color-light-grey py-2">
                Status
              </Label>
              <Switch
                height={20}
                width={40}
                onColor="#007de4"
                offColor="#b1abab"
                checked={values.status}
                onChange={() => setFieldValue("status", !values.status)}
                uncheckedIcon
                checkedIcon
                className="mr-10"
              />
            </div>

            <div className="col-md-12 mt-20">
              {/* @ts-ignore */}
              <button onClick={handleSubmit} className="btn btn-primary bg-blue border-0 shadow-none button-width-com" disabled={!groupFormik.isValid}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Voter;
