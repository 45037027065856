import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { deleteAppShortCut, deleteMarketplaceAppApi } from "../../../../actions/portal/adminActions";
import { onDeleteRoleAPI } from "../../../../actions/portal/rolesPermission/rolesPermissionActions";
import "../../../../assets/css/adminTable.css";
import { apiIcon, directionIcon } from "../../../../assets/images";
import AppStore from "../../../../assets/images/app-store.png";
import ChromeExtension from "../../../../assets/images/chrome-extension.png";
import PlayStore from "../../../../assets/images/playstore.png";
import WebApp from "../../../../assets/images/web-app.png";
import WindowsIcon from "../../../../assets/images/windows.png";
import { getFormattedDate, goToPage, handleName, isStringEmptyOrNull, toastSuccess } from "../../../../common/common-functions";
import { handleColuman, projectModules, projectNames, rowsPerPage } from "../../../../common/constants";
import { setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import { idOfSpecificAppShortcut } from "../../../../store/shortcutapp/action";
import EmailComponent from "../../../../utils/components/EmailComponent";
import ModuleStausComponent from "../../../common/ModuleStatusComponennt";
import RollStausComponent from "../../../common/RollStatusComponent";
import CustomTooltip from "../../../common/ToolTip";
import { DeleteRestricted, EditRestricted } from "../../../common/restrictions";
import ConfirmationModal from "../../DeleteConfirmationModal";
import DeleteConfirmationPopup from "../appshortcutManagement/DeleteConfirmationPopup";
import ImageComponent from "./ImageComponent";
import SuspendComponent from "./SanpendComponent";

export const AdminTableComponent = ({
  listData,
  totalCount,
  tableHeaders,
  type,
  paginationConfig,
  setPaginationConfig,
  RoleListing,
  ModuleListing,
  getCategoryManagementListFromApi
}: //
IAdminTableComponent) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isVisibleDeletePopup, setIsVisibleDeletePopup] = useState(false);
  const adminRole = useSelector((state: any) => state?.userDetails?.user?.adminRole);
  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);
  const [storeID, setStoreID] = useState<number>();
  const { isLoading } = useSelector((state: any) => ({
    isLoading: state.loading.isLoading
  }));

  const [dropdownPage, setDropdownPage] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(rowsPerPage[0]);
  const [typeOfModule, setTypeOfModule] = useState("");

  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  const handleDelete = (id: number, type: string) => {
    setIsVisibleDeletePopup(true);
    setStoreID(id);
    setTypeOfModule(type);
  };

  const handleConfirmation = (storeID: number, typeOfModuleAsAProp?: string) => {
    dispatch(setLoading(true));
    if (typeOfModuleAsAProp === "marketplace") {
      deleteMarketplaceAppApi(storeID).then((res) => {
        if (res.status === 200) {
          setIsVisibleDeletePopup(false);
          getCategoryManagementListFromApi();
          toastSuccess(res.message);
          dispatch(setLoading(false));
        }
      });
    } else if (typeOfModuleAsAProp === "appShortCuts") {
      deleteAppShortCut(storeID).then((res) => {
        if (res.status === 200) {
          setIsVisibleDeletePopup(false);
          getCategoryManagementListFromApi();
          toastSuccess(res.message);
          dispatch(setLoading(false));
        }
      });
    }
  };

  /**
   *
   * @param {Object} renderApplicationAccessIcons
   * @returns {Image} - app icon image url
   */
  const renderApplicationAccessIcons = (renderApplicationAccessIcons: any) => {
    return renderApplicationAccessIcons
      ?.sort((a: any, b: any) => a.appPermission.order - b.appPermission.order)
      ?.map((app: any, index: any) => {
        return (
          <CustomTooltip direction="top" content={app.appPermission.name} key={app.appId}>
            {/* <img className="app-icons" src={app.appPermission.logoIcon} alt={app.appId} /> */}
            <ImageComponent app={app} />
          </CustomTooltip>
        );
      });
  };

  // @ts-ignore
  const renderAvailableApps = (apps) => {
    const availableApps = apps?.map((app: any) => {
      return (
        <CustomTooltip direction="top" content={app.name} key={app.id}>
          {/* <img className="app-icons" src={app.appPermission.logoIcon} alt={app.appId} /> */}
          {app?.name === "Web App" && app.url ? (
            <span
              className="table-width"
              onClick={() => {
                window.open(app?.url, "_blank");
              }}>
              <img className="app-icons cursor-pointer" src={WebApp} alt="AppStore" title="" />
            </span>
          ) : app?.name === "Ios App" && app.url ? (
            <span
              className="table-width"
              onClick={() => {
                window.open(app?.url, "_blank");
              }}>
              <img className="app-icons cursor-pointer" src={AppStore} alt="AppStore" title="" />
            </span>
          ) : app?.name === "Android App" && app.url ? (
            <span
              className="table-width"
              onClick={() => {
                window.open(app?.url, "_blank");
              }}>
              <img className="app-icons cursor-pointer" src={PlayStore} alt="AppStore" title="" />
            </span>
          ) : app?.name === "Desktop App" && app.url ? (
            <span
              className="table-width"
              onClick={() => {
                window.open(app?.url, "_blank");
              }}>
              <img className="app-icons cursor-pointer" src={WindowsIcon} alt="AppStore" title="" />
            </span>
          ) : (
            app?.name === "Chrome App" && app.url && <img className="app-icons cursor-pointer" src={ChromeExtension} alt="AppStore" title="" />
          )}
        </CustomTooltip>
      );
    });
    return availableApps;
  };

  const handleEdit = (moduleName: any) => {
    if (moduleName === "usermanagement") {
      return currentPermission?.admin[moduleName]?.update;
    } else {
      return currentPermission?.Universe[moduleName]?.update;
    }
  };

  const openLink = (url: any) => {
    window.open(url, "_blank");
  };

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const roleDelete = (roleId: string) => {
    dispatch(setLoading(true));
    let params = {
      id: roleId,
      status: 1
    };
    onDeleteRoleAPI(params).then((res) => {
      if (res.status === 200) {
        RoleListing(res.message);
        dispatch(
          setVisible({
            isVisible: false,
            deleteId: "",
            label: "",
            deleteStatus: ""
          })
        );
      }
    });
  };

  /**
   *
   * @param {Object} list
   * @param {String} type
   * @param {number} i - index
   * @returns
   */
  const renderView = (list: any, type: any) => {
    if (type === "Customer Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey min-width-80">
            <span style={{ marginRight: "5px" }}>{`${list?.first_name ? list?.first_name + " " + list?.last_name : handleName(list?.email)}`}</span>
            <CustomTooltip
              direction="right"
              content={
                <>
                  {list?.kyc?.isApproved === 1 && <p className="p-1 fst-normal">KYC Verified</p>}
                  {/* {list?.kyc === null && <p className="p-1 fst-normal">KYC Not Verified</p>} */}
                </>
              }>
              {list?.kyc?.isApproved === 1 && <i className="fa fa-check-circle color-blue cursor-pointer" data-toggle="tooltip" data-placement="bottom"></i>}
              {list?.kyc === null && ""}
            </CustomTooltip>
          </td>
          <td className="font-16 color-light-grey">
            <EmailComponent email={list?.email} />
          </td>
          <td className="font-16 color-light-grey" style={{ textTransform: "capitalize" }}>
            {list?.loginType}
          </td>
          <td className="font-16 color-light-grey" style={{ textTransform: "capitalize" }}>
            {list?.owner_org ? "Organization" : "Individual"}
          </td>
          <td className="font-16 color-light-grey">{list?.registeredFrom}</td>
          <td>{renderApplicationAccessIcons(list?.userPermission)}</td>
          {adminRole?.roleLevel === 0 && (
            <td className="font-16 color-light-grey d-flex">
              <SuspendComponent checked={list?.status} user_id={list?.userId} setDeleteActionFunction={setDeleteActionFunction} />
            </td>
          )}
          {handleEdit(projectModules[projectNames.admin].CUSTOMER_MANAGEMENT) && (
            <td className="font-14">
              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.admin].CUSTOMER_MANAGEMENT}>
                <i
                  className="fas fa-edit  color-blue  cursor-pointer ps-3"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit Application access"
                  onClick={() => {
                    history.push({
                      pathname: `/${projectNames.admin}/customer-management/edit-customer`,
                      state: { userId: list.userId }
                    });
                  }}></i>
              </EditRestricted>
            </td>
          )}
        </tr>
      );
    } else if (type === "API Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">
            {list.isAvailable === 1 ? (
              <Link to="#" className="blue cursor-pointer" onClick={() => goToPage(process.env.REACT_APP_portal_apiDetail_Beta + list?.id)}>
                Link
              </Link>
            ) : (
              "-"
            )}
          </td>
          <td className="font-16 color-light-grey">
            {list.isAvailable === 1 ? (
              <span onClick={() => goToPage(process.env.REACT_APP_portal_apiDetail_Live + list?.id)} className="blue cursor-pointer">
                Link
              </span>
            ) : (
              "-"
            )}
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${list.isAvailable ? "active" : "deactive"}`}></span>
            {list.isAvailable ? "Available" : "Inaccessible"}
          </td>
          <td className="font-16 color-light-grey created-date">{list?.createdAt && getFormattedDate(list.createdAt)}</td>
          {handleEdit(projectModules[projectNames.universe].API_MANAGEMENT) && (
            <td className="font-14 color-light-grey">
              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.universe].API_MANAGEMENT}>
                <div className="d-flex">
                  <CustomTooltip direction="top" content={"Edit Api Details"}>
                    <img
                      className="fas fa-edit color-blue cursor-pointer mr-10"
                      alt=""
                      style={{
                        width: 22
                      }}
                      src={apiIcon}
                      onClick={() => {
                        history.push({
                          pathname: `/${projectNames.universe}/api-management/edit-api`,
                          state: { apiId: list.id }
                        });
                      }}></img>
                  </CustomTooltip>
                  <CustomTooltip direction="top" content={"Edit Api Explorer"}>
                    <img
                      className="fas fa-edit color-blue cursor-pointer mr-10"
                      alt=""
                      style={{
                        width: 22
                      }}
                      src={directionIcon}
                      onClick={() => {
                        history.push({
                          pathname: `/${projectNames.universe}/api-management/explorer`,
                          state: { apiId: list.id }
                        });
                      }}></img>
                  </CustomTooltip>
                </div>
              </EditRestricted>
            </td>
          )}
        </tr>
      );
    } else if (type === "appShortCuts") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-16 color-light-grey">
            {list?.isAvailable === 0 ? "Hidden" : list?.isAvailable === 1 ? "Visible" : list?.isAvailable === 2 ? "Available & New" : list?.isAvailable === 3 && "Coming Soon"}
          </td>
          <td className="font-14 blue">
            <i
              className="fas fa-edit color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              onClick={() => {
                history.push(`/${projectNames.admin}/app-shortcut-management/${list?.id}`);
                dispatch(idOfSpecificAppShortcut(list?.id));
              }}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
            <i className="fas fa-trash-alt blue" style={{ cursor: "pointer", fontWeight: "700" }} title="Delete" onClick={() => handleDelete(list?.id, "appShortCuts")}></i>
          </td>
        </tr>
      );
    } else if (type === "User Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.clientId}</td>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">{list?.org?.name || list?.org?.email}</td>
          <td className="font-16 color-light-grey">{list?.publicAddress}</td>
        </tr>
      );
    } else if (type === "marketplace") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td>{list?.availableOn?.length > 0 && renderAvailableApps(list?.availableOn)}</td>
          <td>
            {list?.documentUrl ? (
              <>
                <span
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                    color: "#007de4"
                  }}
                  onClick={() => {
                    window.open(list?.documentUrl, "_blank");
                  }}>
                  Link
                </span>
              </>
            ) : (
              "-"
            )}
          </td>
          <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
          <td className="font-14 blue">
            <i
              className="fas fa-edit color-blue cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              onClick={() => {
                history.push(`/${projectNames.admin}/market-place/${list?.id}`);
                dispatch(idOfSpecificAppShortcut(list?.id));
              }}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "700"
              }}></i>
            <i className="fas fa-trash-alt blue" style={{ cursor: "pointer", fontWeight: "700" }} title="Delete" onClick={() => handleDelete(list?.id, "marketplace")}></i>
          </td>
        </tr>
      );
    } else if (type === "Application Management") {
      return (
        <tr key={list.id}>
          <td className="font-16 color-light-grey">{list?.name}</td>
          <td className="font-16 color-light-grey">
            <div className={`app-icon-wrapper ${list.isAvailable === 0 ? "disabled" : ""}`}>
              {!isStringEmptyOrNull(list?.app_url) && (
                <CustomTooltip direction="top" content={"Android Application"}>
                  <img className="app-icons cursor-pointer" src={PlayStore} alt="PlayStore" onClick={() => openLink(list.app_url)} title="" />
                </CustomTooltip>
              )}
              {!isStringEmptyOrNull(list?.ios_url) && (
                <CustomTooltip direction="top" content={"IOS Application"}>
                  <img className="app-icons cursor-pointer" src={AppStore} alt="AppStore" onClick={() => openLink(list.ios_url)} title="" />
                </CustomTooltip>
              )}
              {!isStringEmptyOrNull(list?.redirect_url) && (
                <CustomTooltip direction="top" content={"Web Application"}>
                  <img className="app-icons cursor-pointer" src={WebApp} alt="WebApp" onClick={() => openLink(list.redirect_url)} title="" />
                </CustomTooltip>
              )}
              {!isStringEmptyOrNull(list?.extension_url) && (
                <CustomTooltip direction="top" content={"Chrome Extension"}>
                  <img className="app-icons cursor-pointer" src={ChromeExtension} alt="ChromeExtension" onClick={() => openLink(list.extension_url)} title="" />
                </CustomTooltip>
              )}
              {!isStringEmptyOrNull(list?.desktop_url) && (
                <CustomTooltip direction="top" content={"Desktop Application"}>
                  <img className="app-icons cursor-pointer" src={WindowsIcon} alt="Desktop Application" onClick={() => openLink(list.desktop_url)} title="" />
                </CustomTooltip>
              )}
            </div>
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span className={`status ${list.isAvailable ? "active" : "deactive"}`}></span>
            {list.isAvailable ? "Available" : "Inaccessible"}
          </td>
          <td className="font-16 color-light-grey created-date">{list?.createdAt && getFormattedDate(list.createdAt)}</td>
          <td className="font-16 color-light-grey created-date">{list?.updatedAt && getFormattedDate(list.updatedAt)}</td>
          {handleEdit(projectModules[projectNames.universe].APPLICATION_MANAGEMENT) && (
            <td className="font-14 color-light-grey">
              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.universe].APPLICATION_MANAGEMENT}>
                <div className="d-flex">
                  <CustomTooltip direction="top" content={"Edit APP Details"}>
                    <i
                      className="fas fa-edit blue cursor-pointer mr-10 fw-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={() => {
                        history.push({
                          pathname: `/${projectNames.universe}/application-management/app-edit`,
                          state: { appId: list.id }
                        });
                      }}></i>
                  </CustomTooltip>
                </div>
              </EditRestricted>
            </td>
          )}
        </tr>
      );
    } else if (type === "Role Management") {
      return (
        <tr key={list?.roleId}>
          <td className="font-16 color-light-grey">{list?.roleName}</td>
          <td className="font-16 color-light-grey ">{list?.adminCount}</td>
          <td className="font-16 color-light-grey">{list?.roleCreatedBy?.firstName ? list?.roleCreatedBy?.firstName : "-"}</td>
          <td className="font-16 color-light-grey created-date">{list?.createdAt && getFormattedDate(list?.createdAt)}</td>
          <td className="font-16 color-light-grey">
            <RollStausComponent isActive={list?.isActive} id={list?.roleId} />
          </td>
          {handleColuman(currentPermission, projectModules[projectNames.admin].ROLE_MANAGEMENT) && (
            <td className="font-14 color-light-grey d-flex" style={{ width: "100px" }}>
              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.admin].ROLE_MANAGEMENT}>
                <CustomTooltip direction="top" content={"Edit Role"}>
                  <i
                    className="fas fa-edit color-blue cursor-pointer"
                    data-toggle="tooltip"
                    data-placement="top"
                    onClick={() =>
                      history.push({
                        pathname: `/${projectNames.admin}/role-management/edit-role`,
                        state: { roleId: list?.roleId }
                      })
                    }></i>
                </CustomTooltip>
              </EditRestricted>
              {/* @ts-ignore */}
              <DeleteRestricted module={projectModules[projectNames.admin].ROLE_MANAGEMENT}>
                <CustomTooltip direction="top" content={`${list?.adminCount > "0" ? "Role cannot be deleted as it has users" : "Delete Role"}`}>
                  <i
                    className={`fas fa-trash-alt blue ml-10 ${list?.adminCount > "0" ? "" : "cursor-pointer"} `}
                    style={{ opacity: `${list?.adminCount > "0" ? 0.5 : 1}` }}
                    onClick={() => {
                      if (list?.adminCount > "0") {
                      } else {
                        dispatch(
                          setVisible({
                            isVisible: true,
                            deleteId: list?.roleId,
                            label: "Are you sure you want to Delete?",
                            deleteStatus: ""
                          })
                        );
                        setDeleteActionFunction({ fnCall: roleDelete });
                      }
                    }}></i>
                </CustomTooltip>
              </DeleteRestricted>
            </td>
          )}
        </tr>
      );
    } else if (type === "Module Management") {
      return (
        <tr key={list?.id}>
          <td className="font-16 color-light-grey">{list?.appName}</td>
          <td className="font-16 color-light-grey ">{list?.moduleName}</td>
          <td className="font-16 color-light-grey">{list.actions.toString().replaceAll(",", ", ")}</td>
          <td className="font-16 color-light-grey" style={{ width: "100px" }}>
            <ModuleStausComponent status={list?.status} id={list?.id} />
          </td>
          {handleColuman(currentPermission, projectModules[projectNames.admin].MODULE_MANAGEMENT) && (
            <td className="font-14 color-light-grey d-flex justify-content-center" style={{ width: "85px" }}>
              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.admin].MODULE_MANAGEMENT}>
                <CustomTooltip direction="top" content={"Edit Module"}>
                  <i
                    className="fas fa-edit color-blue cursor-pointer"
                    data-toggle="tooltip"
                    data-placement="top"
                    onClick={() =>
                      history.push({
                        pathname: `/${projectNames.admin}/module-management/${list?.id}`,
                        state: { moduleId: list?.id }
                      })
                    }></i>
                </CustomTooltip>
              </EditRestricted>
            </td>
          )}
        </tr>
      );
    }
  };

  /**
   * @start
   * @Talbe_Pagination
   */
  const onHandleRowPerPageChange = (el: any) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el
    });
  };

  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: Math.ceil(totalCount / paginationConfig.limit)
    });
  };

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page;
    if (lastPage > totalCount) {
      return totalCount;
    } else {
      return lastPage;
    }
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };
  /**
   * @end @Table_pagination
   */

  return (
    <>
      <Scrollbars
        autoHide
        style={{ height: 630 }}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        className={`table-section ${type === "Module Management" ? "module-management-table" : "admin-table"}  border-grey`}>
        <Table responsive>
          <thead>
            <tr>
              {tableHeaders.map((header: any, index: any) => {
                return (
                  <th key={index} className="color-black1 font-14 fw-bold">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData && listData.length > 0 ? (
              listData.map((list: any, i: any) => {
                // @ts-ignore
                return renderView(list, type, i);
              })
            ) : (
              <tr>{isLoading ? <td className="font-16 color-light-grey">Loading...</td> : <td colSpan={2}>No Records Found</td>}</tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      <div className="d-flex justify-content-between flex-wrap pagination mt-20">
        <div className="d-flex align-items-center">
          <p className="font-13 color-light-grey m-0">Rows per page</p>
          <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center ml-10 mr-10">
            <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
              {rowPerPage}
              <i className="fas fa-chevron-down color-light-grey font-12 ml-10"></i>
            </DropdownToggle>
            <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
              <div style={{ cursor: "pointer" }}>
                {rowsPerPage.map((el, index) => {
                  return (
                    <p key={index} onClick={() => onHandleRowPerPageChange(el)} className="color-dark-grey hover-page font-14 p_5 m-0 page-name">
                      {el}
                    </p>
                  );
                })}
              </div>
            </DropdownMenu>
          </Dropdown>
          <p className="font-13 color-light-grey m-0">{!isLoading && `${calculateFirstPageRange()} - ${calculateLastPageRange()} of ${totalCount}`}</p>
        </div>
        <Pagination className="pagination-section">
          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? goToFirstPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number mr-10 ${paginationConfig.page === 1 && "disabled"}`} onClick={() => (paginationConfig.page > 1 ? handlePrevious() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>

          {/* @ToDo
                  This component generates error for onclick.
                */}
          <PaginationItem className={`pagination-number mr-10 ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? handleNext() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={`pagination-number ${calculateLastPageRange() === totalCount && "disabled"}`} onClick={() => (calculateLastPageRange() < totalCount ? goToLastPage() : null)}>
            <PaginationLink href="#" className="p_5 grey-border rounded-5 d-flex align-items-center justify-content-center">
              <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />

      <DeleteConfirmationPopup
        isVisibleDeletePopup={isVisibleDeletePopup}
        storeID={storeID || null}
        setIsVisibleDeletePopup={setIsVisibleDeletePopup}
        handleConfirmation={handleConfirmation}
        typeOfModule={typeOfModule}
      />
    </>
  );
};

export interface IAdminTableComponent {
  listData: any;
  totalCount: any;
  tableHeaders: any;
  type: any;
  paginationConfig: any;
  setPaginationConfig: any;
  RoleListing?: any;
  ModuleListing?: any;
  getCategoryManagementListFromApi?: any;
}
