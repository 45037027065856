import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getListBlogsAPI = (params: IgetListBlogsAPIParsms): Promise<IgetListBlogsAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.blogs.get, {
    params
  });
};
interface IgetListBlogsAPIParsms {
  page: number;
  serach?: string;
  limit: number;
}

export interface IgetListBlogsAPIData {
  authorData: {};
  createdAt: string;
  id: string;
  isAvailable: number;
  shortDescription: string;
  title: string;
}

interface IgetListBlogsAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IgetListBlogsAPIData;
  };
}
