import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const GetNFTGlobalSettingAPI = (): Promise<IGetNFTGlobalSettingResponse> => {
  return HTTPService.get(nftURL.getGlobalSetting);
};

export interface IGetNFTGlobalSettingResponse {
  status: number;
  message: string;
}
