import { Link, useHistory } from "react-router-dom";

const CommonDropdownComponent = ({ isOpen, smartContractsModules, nameOfModule, icon, openDropDown, subModuleExist }: any) => {
  const history = useHistory();
  return (
    <>
      <Link
        to="#"
        className="color-theme active-color-white d-flex flex-wrap align-items-center position-relative mb-2 dropdown-siderbar-hover"
        onClick={openDropDown}
        style={{
          padding: `${subModuleExist && subModuleExist ? "15px 20px" : ""}`
        }}>
        {icon}
        <span style={{ marginLeft: "10px" }}>{nameOfModule}</span>
        <span className="menu-text" style={{ marginLeft: "40px" }}>
          {isOpen ? <i className="fa fa-caret-up" aria-hidden="true"></i> : <i className="fa fa-caret-down" aria-hidden="true"></i>}
        </span>
      </Link>
      {isOpen && (
        <>
          {smartContractsModules?.map((singleEle: any, index: number) => {
            return (
              <Link
                key={index}
                to={singleEle?.path}
                className={`${
                  singleEle?.path === history.location.pathname
                    ? "sidebar-menu color-theme d-flex flex-wrap align-items-center position-relative menu-active color-white"
                    : "sidebar-menu color-theme d-flex flex-wrap align-items-center position-relative"
                }`}
                style={{ marginBottom: "10px", marginLeft: "10px" }}>
                {singleEle?.icon}
                {singleEle?.name}
              </Link>
            );
          })}
        </>
      )}
    </>
  );
};

export default CommonDropdownComponent;
