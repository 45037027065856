import { bMessageURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const bMessageGetGloablSettingAPI = (): Promise<IbMessageGetGloablSettingAPI> => {
  return HTTPService.get(bMessageURL.globalSetting.get);
};

export interface IbMessageGetGloablSettingAPIData {
  underMaintenance: number;
}

interface IbMessageGetGloablSettingAPI {
  message: string;
  status: number;
  data: IbMessageGetGloablSettingAPIData;
}
