import { bMessageURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { adminInfo, getLabel, updateLabel, addLabel, getPriceAndPlan, getPriceAndPlanDetailApi, deletePlanApi, updatePlanApi } = bMessageURL;

export const GetBMessageAdminInfoAPI = () => {
  return HTTPService.get(adminInfo);
};

export const GetbMessageLabelAPI = () => {
  return HTTPService.get(getLabel);
};

export const updatebMessageLabelAPI = (body: any) => {
  return HTTPService.put(updateLabel, body);
};

export const addbMessageLabelAPI = (body: any) => {
  return HTTPService.post(addLabel, body);
};

export const getPriceAndPlanList = (params: any) => {
  return HTTPService.get(getPriceAndPlan, { params });
};

export const getPriceAndPlanDetail = (params: any) => {
  return HTTPService.get(getPriceAndPlanDetailApi + "?id=" + params);
};

export const deletePlan = (id: string) => {
  return HTTPService.delete(deletePlanApi + "?id=" + id);
};

export const updatePlan = (body: any) => {
  return HTTPService.put(updatePlanApi, body);
};
