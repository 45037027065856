import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { Modal, ModalBody } from "reactstrap";
import { onDeleteAppModuleAPI } from "../../actions/portal/moduleManagement/createAppModule";
import { toastSuccess } from "../../common/common-functions";
import { projectModules, projectNames } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import { EditRestricted } from "./restrictions";

const ModuleStausComponent = ({ status, id }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setChecked(status === "0" ? true : false);
  }, [status]);

  const handleInputChange = (e) => {
    dispatch(setLoading(true));
    let params = {
      id: id,
      status: checked ? "1" : "0"
    };
    onDeleteAppModuleAPI(params).then((res) => {
      if (res?.status === 200) {
        toastSuccess(res?.message);
        setChecked(!checked);
        setVisible(false);
      }
      dispatch(setLoading(false));
    });
  };

  return (
    <>
      <div className="d-flex align-baseline" data-toggle="tooltip" data-placement="top">
        <EditRestricted module={projectModules[projectNames.admin].MODULE_MANAGEMENT}>
          <Switch height={18} width={34} onColor="#007de4" offColor="#b1abab" checked={checked} onChange={() => setVisible(true)} uncheckedIcon checkedIcon className="mr-10" />
        </EditRestricted>
        <span>{checked ? "Active" : "Inactive"}</span>
      </div>
      <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center">Confirmation</h4>
            <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
              Are your sure want to {checked ? "Inactive" : "Active"} the module?
            </p>
          </div>
          <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
            <div
              className="col-6 p-10 d-flex cursor-pointer"
              style={{ borderRight: "1px solid #dadce0" }}
              onClick={() => {
                setVisible(false);
              }}>
              <button className="confimatio-button text-danger">No</button>
            </div>
            <div className="col-6 p-10 d-flex cursor-pointer" onClick={handleInputChange}>
              <button className="confimatio-button text-success">Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModuleStausComponent;
