import { useRef, useState } from "react";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { nftbanRejectionReasons } from "../../../../../common/constants";

export const NFTRejectReasonModal = ({ checked, isOpen, isLoading, toggleVisibility, handleRejectReason, setVisible }) => {
  const inputRef = useRef(0);

  const [reason, setReason] = useState("");
  const [isOther, setIsOther] = useState(false);
  const [error, setError] = useState("");

  const handleReason = (reason) => {
    if (reason !== "Other") {
      setReason(reason);
      setIsOther(false);
    } else {
      setIsOther(true);
      setReason("");
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const handleSave = () => {
    setVisible(false);
    if (isOther) {
      if (reason.trim().length > 180) {
        setError("Maximum 180 characters allowed");
      } else {
        toggleVisibility();
        handleRejectReason(reason.trim());
      }
    } else {
      toggleVisibility();
      handleRejectReason(reason.trim());
      setIsOther(false);
      setReason("");
    }
  };

  return (
    <Modal isOpen={!checked && isOpen} className={"custom-model"} centered>
      <ModalHeader
        toggle={() => {
          setIsOther(false);
          toggleVisibility();
        }}
        className="border-0 bg-blue text-white">
        Reason For Ban NFT
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="radio-buttons">
          {nftbanRejectionReasons.map((reason, index) => {
            return (
              <FormGroup check key={index}>
                <Input type="radio" name={"radio1"} id={index} onChange={() => handleReason(reason)} style={{ cursor: "pointer" }} />
                <Label check htmlFor={index}>
                  {reason}
                </Label>
              </FormGroup>
            );
          })}
          {isOther && (
            <>
              <Input
                innerRef={inputRef}
                type="textarea"
                className="other-reason"
                placeholder="Enter your reason......."
                onChange={(e) => {
                  if (e.target.value.trim() !== "") {
                    setReason(e.target.value);
                  } else {
                    setReason("");
                  }
                  setError("");
                }}
              />
              {error && <span className="text-danger">{error}</span>}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="border-0">
        <div className="fotter-layout">
          {isLoading && <Spinner className="color-blue mx-3" />}
          <Button className="border-0 bg-blue button-width-com" disabled={!Boolean(reason)} onClick={handleSave}>
            Save
          </Button>{" "}
        </div>
      </ModalFooter>
    </Modal>
  );
};
