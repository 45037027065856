import { combineReducers } from "redux";
import { applicationReducer } from "./application/reducer";
import { authReducer } from "./auth/reducer";
import categoryModalReducer, { IcategoryModalReducer } from "./categoryModal/reducer";
import visibleReducer from "./confirmationModule/reducer";
import dasbordCountReducers, { IdasbordCountReducers } from "./dashboardCount/reducer";
import { loadingReducer } from "./loader/reducer";
import { permissionsReducer } from "./permissions/reducer";
import { projectNameReducer } from "./project/reducer";
import { rolePermissionReducer } from "./rolesPermissions/reducer";
import { appShortCutReducer, IidOfSpecificAppShortcut } from "./shortcutapp/reducer";
import { sideBarToggleReducer } from "./sidebar/reducer";
import socketReducers, { IsocketReducers } from "./socket/reducer";
import { userDetailsReducer } from "./user/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  userDetails: userDetailsReducer,
  sidebarToggle: sideBarToggleReducer,
  projectNameReducer: projectNameReducer,
  permissionsReducer: permissionsReducer,
  rolePermissions: rolePermissionReducer,
  applicationData: applicationReducer,
  visible: visibleReducer,
  categoryModalReducer: categoryModalReducer,
  dasbordCountReducers: dasbordCountReducers,
  socketReducers: socketReducers,
  appShortCutReducer: appShortCutReducer
});

export default rootReducer;

export type IRootReducerTypes = {
  auth: any;
  loading: any;
  userDetails: any;
  sidebarToggle: any;
  projectNameReducer: any;
  permissionsReducer: any;
  rolePermissions: any;
  applicationData: any;
  visible: any;
  categoryModalReducer: IcategoryModalReducer;
  dasbordCountReducers: IdasbordCountReducers;
  socketReducers: IsocketReducers;
  appShortCutReducer: IidOfSpecificAppShortcut;
};
