import { useState } from "react";
import "../../assets/css/customTooltip.css";

/**
 *
 * @param props
 * @param1 - {content} - childrens to be rendered as tooltip content
 * @param2 - {position} - string - top | right | bottom | right
 * @param3 - {delay} - numbers - delay befor tooltip renders
 * @returns JSX element
 */
const CustomTooltip = (props: any) => {
  let timeout: any;

  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {props.children}
      {active && <div className={`Tooltip-Tip ${props.direction || "top"}`}>{props.content}</div>}
    </div>
  );
};

export default CustomTooltip;
