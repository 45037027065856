import { adminUsersURLS } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

// admin created user That rigrading URL end-points
let { createUser, viewUser, updateUser, deleteUser, listUser, setUserPassword, verifyPasswordKey, resendLink, activeInActive } = adminUsersURLS;

// admin create user that user create API
export const onCreateAdminUserAPI = (body: any) => {
  return HTTPService.post(createUser, body);
};

// admin create user that user view API
export const onViewAdminUserAPI = (params: any) => {
  return HTTPService.get(viewUser, { params });
};

// admin create user that user update API
export const onUpdateAdminUserAPI = (body: any) => {
  return HTTPService.put(updateUser, body);
};

// admin create user that user listing API
export const onListingAmdinUserAPI = (params: any) => {
  return HTTPService.get(listUser, params);
};

// admin created user that user delete API
export const onDeleteAdminUserAPI = (params: any) => {
  return HTTPService.delete(deleteUser, "", { params });
};

// admin create user that user verify key API
export const onGetVerifyPasswordKey = (params: any) => {
  return HTTPService.get(verifyPasswordKey, { params });
};

// admin create user that user set password API
export const onPasswordSetUserAPI = (body: any) => {
  return HTTPService.post(setUserPassword, body);
};

// resend user link API
export const onResendLinkAPI = (params: any) => {
  return HTTPService.get(resendLink, { params });
};

export const onactiveInActiveAPI = (body: any) => {
  return HTTPService.put(activeInActive, body);
};
