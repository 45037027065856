import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { universalRejectionReasons } from "../../../common/constants";

export const UniversalRejectionPopup = ({ isOpen, preState, isLoading, reason, setStatus, setReason, setUniversalStatusToggle, id, handleUniversalListStatusChange }) => {
  const inputRef = useRef(0);
  const location = useLocation();

  const [isOther, setIsOther] = useState(false);
  const [error, setError] = useState("");

  const handleReason = (reason) => {
    if (reason !== "Other") {
      setReason(reason);
      setIsOther(false);
    } else {
      setIsOther(true);
      setReason("");
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const handleSave = () => {
    function submit() {
      handleUniversalListStatusChange(id.toString(), "2", reason.trim());
      setUniversalStatusToggle(false);
    }
    if (isOther) {
      if (reason.trim().length > 180) {
        setError("Maximum 180 characters allowed");
      } else {
        submit();
        setIsOther(false);
      }
    } else {
      submit();
      setIsOther(false);
    }
  };

  const toggle = () => {
    setIsOther(false);
    setReason("");
    setStatus(preState);
    setUniversalStatusToggle(false);
  };

  return (
    <Modal isOpen={isOpen} className={"custom-model"} centered>
      <ModalHeader toggle={() => toggle()} className="border-0 bg-blue text-white">
        Reason For {location?.pathname.includes("request-demo") ? "Status" : "Rejection"}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="radio-buttons">
          {universalRejectionReasons.map((reason, index) => {
            return (
              <FormGroup check key={index}>
                <Input className="cursor-pointer" type="radio" name={"radio1"} id={index} onChange={() => handleReason(reason)} style={{ cursor: "pointer" }} />
                <Label className="cursor-pointer" check htmlFor={index}>
                  {reason}
                </Label>
              </FormGroup>
            );
          })}
          {isOther && (
            <>
              <Input
                innerRef={inputRef}
                type="textarea"
                className="other-reason"
                placeholder="Enter your reason......."
                onChange={(e) => {
                  if (e.target.value.trim() !== "") {
                    setReason(e.target.value);
                  } else {
                    setReason("");
                  }
                  setError("");
                }}
                value={reason}
              />
              {error && <span className="text-danger">{error}</span>}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="border-0">
        <div className="fotter-layout">
          {isLoading && <Spinner className="color-blue mx-3" />}
          {/* eslint-disable-next-line  */}
          <Button
            className="border-0 bg-blue"
            disabled={!Boolean(reason.trim() !== "")}
            onClick={handleSave}
            // eslint-disable-next-line
          >
            Save
          </Button>{" "}
        </div>
      </ModalFooter>
    </Modal>
  );
};
