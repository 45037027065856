import { Modal, ModalBody, Spinner } from "reactstrap";
import { RequestDemoStatus } from "../../../../common/constants";
import LabelDemo from "../../bvote/common/Label";

const RequestDemoStatusConfirmationPopup = ({
  isVisible,
  loader,
  status,
  handleConfirmation,
  currentSelectedStatus,
  toggleStatusModel,
  requestId
}: {
  isVisible: boolean;
  loader: boolean;
  status: string;
  currentSelectedStatus: number;
  handleConfirmation: Function;
  toggleStatusModel: Function;
  requestId: string;
}) => {
  const getKyCStatus = (statusCode: any) => {
    let result: {
      label: string;
      class: string;
      color?: string;
    } = {
      label: "",
      class: ""
    }; // eslint-disable-next-line
    switch (statusCode) {
      case "0":
        result = {
          label: RequestDemoStatus.InProgress.name,
          class: "pending",
          color: "#eaac20"
        };
        break;

      case "1":
        result = {
          label: RequestDemoStatus.Declined.name,
          class: "deactive",
          color: "#ea2027"
        };
        break;
      case "2":
        result = {
          label: RequestDemoStatus.Scheduled.name,
          class: "inprocess",
          color: "#20eadb"
        };
        break;

      case "3":
        result = {
          label: RequestDemoStatus.Completed.name,
          class: "active",
          color: "#28c76f"
        };
        break;

      case "4":
        result = {
          label: RequestDemoStatus.Approved.name,
          class: "active",
          color: "#2d7de4"
        };
        break;

      default:
        result = {
          label: "Not Started",
          class: "pending"
        };
        break;
    }
    return result;
  };

  return (
    <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
      <ModalBody>
        <div style={{ padding: "30px" }}>
          <h4 className="fw-bold text-center">Change status to {getKyCStatus(status).label}</h4>
          <p className="fw-bold text-center font-18 " style={{ color: "#868686" }}>
            <LabelDemo />
          </p>
        </div>
        <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
          <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={() => toggleStatusModel()}>
            <button className="confimatio-button text-danger">No</button>
          </div>
          <div className="col-6 p-10 d-flex cursor-pointer justify-content-center" onClick={() => handleConfirmation(requestId, currentSelectedStatus)}>
            <span className={`confimatio-button text-success d-flex align-items-center ${loader && "disable"} `}>
              Yes
              {loader && (
                <Spinner className="ml-20" color="primary" size={"sm"}>
                  Loading...
                </Spinner>
              )}
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestDemoStatusConfirmationPopup;
