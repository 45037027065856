import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { pollHistory } = bvoteURL;

const { listing, view, dowloadPdf } = pollHistory;

// pollHistory listing api
export const onListingAPI = (params: any) => {
  return HTTPService.get(listing, {
    params
  });
};

// pollHistory view api
export const onViewAPI = (params: any) => {
  return HTTPService.get(view + params);
};

//doload poll history pdf api
export const onDowloadPDFPollHistory = (body: any) => {
  return HTTPService.post(dowloadPdf, body);
};
