import { APPLICATIONDATA } from "./type";

const initialState = {
  applicationData: { type: "", data: {}, orderArray: [] }
};

export const applicationReducer = (state = initialState, action: any) => {
  const newState = { ...state };
  switch (action.type) {
    case APPLICATIONDATA:
      newState.applicationData = action.payload;
      break;

    default:
  }
  return newState;
};
