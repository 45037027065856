import React from "react";
import MetaTags from "react-meta-tags";

function MetaFunction({ meta }) {
  return (
    <MetaTags>
      <title>{meta.charAt(0).toUpperCase() + meta.slice(1)} | Central Admin</title>
    </MetaTags>
  );
}
export default React.memo(MetaFunction);
