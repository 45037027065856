import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { GetNFTGlobalSettingAPI } from "../../../../actions/nft/globalSetting/getNFTGlobalSettingAPI";
import { UpdateNFTGlobalSettingAPI } from "../../../../actions/nft/globalSetting/updateNFTGlobalSettingAPI";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { navigateNumber, oneTozeroRegex, onlyNumberRegex, projectModules, projectNames } from "../../../../common/constants";
import { resetVisible, setVisible } from "../../../../store/confirmationModule/action";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import { EditRestricted } from "../../../common/restrictions";
import ConfirmationModal from "../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../SwitchGlobalSetting";

const NftGlobalSetting = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      requestDemo: false,
      underMaintenance: false,
      price: false,
      disable: true,
      nftCollection: false,
      customNft: false,
      commissionPercent: 0,
      iosCommissionPercent: 0,
      stripeMinPrice: 0,
      paypalMinPrice: 0,
      exchangeRate: 0,
      minPrice: 0,
      _id: ""
    },
    validationSchema: Yup.object().shape({
      commissionPercent: Yup.string().required("Commission percent is a required").matches(navigateNumber, "Commission Percent must be in between 0 to 100 "),
      iosCommissionPercent: Yup.number()
        .moreThan(29.9999999999999, "Commission percent of IOS must be in between 30 to 100")
        .lessThan(100.0000000000001, "Commission percent of IOS must be in between 0 to 100"),
      stripeMinPrice: Yup.number().required("Minimum price of stripe is a required").moreThan(0.9999999999, "Minimum price of stripe must be more than 0.5"),
      paypalMinPrice: Yup.number().moreThan(0.9999999999, "Minimum Amount of the Credit must be more than 0.9999"),
      exchangeRate: Yup.string().required("Exchange rate is a required").matches(onlyNumberRegex, "Only number are allowed"),
      minPrice: Yup.string().required("Min price is a required").matches(oneTozeroRegex, "Min price more then 1")
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let paramater = {
        commissionPercent: Number(values.commissionPercent),
        exchangeRate: Number(values.exchangeRate),
        minPrice: Number(values.minPrice),
        iosCommissionPercent: Number(values.iosCommissionPercent),
        paypalMinPrice: Number(values.paypalMinPrice),
        stripeMinPrice: Number(values.stripeMinPrice)
      };
      UpdateNFTGlobalSettingAPI(paramater).then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue("disable", false);
          setFieldValue("price", false);
          toastSuccess(res?.message);
        }
        dispatch(setLoading(false));
      });
    }
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    getGloablSettingAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const [globalSettingResponse, setGlobalSettingResponse] = useState({
    iosCommissionPercent: 0,
    stripeMinPrice: 0,
    paypalMinPrice: 0,
    commissionPercent: 0,
    exchangeRate: 0,
    minPrice: 1,
    requestDemo: 0,
    underMaintenance: 0
  });

  const getGloablSettingAction = () => {
    dispatch(setLoading(true));
    GetNFTGlobalSettingAPI().then((res: any) => {
      if (res.status === 200) {
        if (res?.code === 200 || res?.status === 200) {
          setGlobalSettingResponse(res.data);
          setFieldValue("requestDemo", res?.data?.requestDemo === 1 ? true : false);
          setFieldValue("nftCollection", res?.data?.nftCollection === 1 ? true : false);
          setFieldValue("customNft", res?.data?.customNft === 1 ? true : false);
          setFieldValue("underMaintenance", res?.data?.underMaintenance === 1 ? true : false);
          setFieldValue("commissionPercent", res?.data?.commissionPercent);
          setFieldValue("exchangeRate", res?.data?.exchangeRate);
          setFieldValue("minPrice", res?.data?.minPrice);
          setFieldValue("iosCommissionPercent", res?.data?.iosCommissionPercent);
          setFieldValue("stripeMinPrice", res?.data?.stripeMinPrice);
          setFieldValue("paypalMinPrice", res?.data?.paypalMinPrice);
        }
        dispatch(setLoading(false));
      }
    });
  };

  const switchHandleChange = (string: string) => {
    const { name, flag } = JSON.parse(string);
    dispatch(setLoading(true));
    let paramater = {
      [name]: flag ? "1" : "0"
    };
    UpdateNFTGlobalSettingAPI(paramater)
      .then((res: any) => {
        if (res?.status === 200 || res?.code === 200) {
          setFieldValue(name, flag);
          toastSuccess(res?.message);
          dispatch(resetVisible());
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(resetVisible());
      });
  };

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (value: string) => {}
  });

  const handleSwitch = ({ name, flag, moduleName }: { name: string; flag: boolean; moduleName: string }) => {
    dispatch(
      setVisible({
        isVisible: true,
        deleteId: JSON.stringify({ name: name, flag: flag }),
        label: `Are you sure you want to ${name === "nftCollection" || name === "customNft" ? (!flag ? "enable" : "disable") : flag ? "enable" : "disable"} ${moduleName}?`,
        deleteStatus: 1
      })
    );
    setDeleteActionFunction({ fnCall: switchHandleChange });
  };

  useEffect(() => {
    if (formik.errors.stripeMinPrice || formik.errors.commissionPercent || formik.errors.iosCommissionPercent || formik.errors.minPrice || formik.errors.paypalMinPrice || formik.errors.exchangeRate) {
      setFieldValue("disable", true);
    }
  }, [formik.errors, setFieldValue]);

  return (
    <>
      <MetaFunction meta="Global Settings" />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Global Settings</p>
        <div className="p-20 rounded-10 bg-white">
          <div className="row">
            <SwitchGlobalSetting
              checked={values.underMaintenance}
              disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
              onChange={(e: boolean) => {
                handleSwitch({
                  name: "underMaintenance",
                  flag: e,
                  moduleName: "Under Maintenance"
                });
              }}
              label="Under Maintenance"
              tooltip="Whole NFT cannot be accessed by customer by enabling the under maintenace"
            />
            <SwitchGlobalSetting
              checked={values.requestDemo}
              disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
              onChange={(e: boolean) => {
                handleSwitch({
                  name: "requestDemo",
                  flag: e,
                  moduleName: "Request Demo"
                });
              }}
              label="Request Demo"
              tooltip="Enabling lets customers to request for a demo"
            />
            <SwitchGlobalSetting
              checked={!values.nftCollection}
              disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
              onChange={(e: boolean) => {
                handleSwitch({
                  name: "nftCollection",
                  flag: !e,
                  moduleName: "NFT Collection"
                });
              }}
              label="NFT Collection"
              tooltip="Enabling lets customers to view the NFT collections in NFT Portal"
            />{" "}
            <SwitchGlobalSetting
              checked={!values.customNft}
              onChange={(e: boolean) => {
                handleSwitch({
                  name: "customNft",
                  flag: !e,
                  moduleName: "Custom NFT"
                });
              }}
              disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
              label="Custom NFT"
              direaction="botttom"
              tooltip="Enabling lets customers to create Custom NFT in NFT Portal"
            />
          </div>
        </div>
        <p className="main-title mt-4 m-b-20">Manage NFT Pricing</p>
        <div className="nft-price">
          <SwitchGlobalSetting checked={values.price} onChange={(e: boolean) => setFieldValue("price", e)} label="NFT Pricing Setting" tooltip=" Enable NFT pricing to manage NFT's pricing" />
          {values.price && (
            <>
              <div className="nft-price-input">
                <label htmlFor="commissionPercent">Commission Percent</label>
                <Input
                  type="number"
                  id="commissionPercent"
                  name="commissionPercent"
                  disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
                  onChange={(e) => {
                    setFieldValue("disable", false);
                    formik.handleChange(e);
                  }}
                  value={values.commissionPercent}
                />
                {formik.errors.commissionPercent && <span className="text-danger font-14">{formik.errors.commissionPercent}</span>}
                <div className="font-14 text-gray mt-1" style={{ color: "gray" }}>
                  <span className="fw-bold ">Note: </span> Percentage of the edeXa commission on the total gas fee.
                </div>
              </div>
              <div className="nft-price-input mt-10">
                <label htmlFor="exchangeRate">Exchange Rate(Ether to USD)</label>
                <Input
                  type="number"
                  id="exchangeRate"
                  name="exchangeRate"
                  disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
                  onChange={(e) => {
                    setFieldValue("disable", false);
                    formik.handleChange(e);
                  }}
                  value={values.exchangeRate}
                />
                {formik.errors.exchangeRate && <span className="text-danger font-14">{formik.errors.exchangeRate}</span>}
                <div className="font-14 text-gray mt-1" style={{ color: "gray" }}>
                  <span className="fw-bold">Note: </span> Based on the exchange rate of Ether to USD,
                  <a href="https://bit.ly/3tRVDvw" target={"_blank"} rel="noreferrer">
                    {" "}
                    check Exchange rates now
                  </a>
                </div>
              </div>
              <div className="nft-price-input mt-10">
                <label htmlFor="minPrice">Minimum Price</label>
                <Input
                  type="number"
                  id="minPrice"
                  name="minPrice"
                  disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
                  onChange={(e) => {
                    setFieldValue("disable", false);
                    formik.handleChange(e);
                  }}
                  value={values.minPrice}
                />
                {formik.errors.minPrice && <span className="text-danger font-14">{formik.errors.minPrice}</span>}
                <div className="font-14 mt-1" style={{ color: "gray" }}>
                  <span className="fw-bold ">Note: </span>A minimum price is required for minting NFT USD 1
                  {/* We will charge a minimum of ${values.minPrice} per transaction/NFT(If the gas fee is lower */}
                  {/* than $1.00) */}
                </div>
              </div>
              {/* New Fields */}
              <div className="nft-price-input mt-10">
                <label htmlFor="iosCommissionPercent">Commission Percent (IOS)</label>
                <Input
                  type="number"
                  id="iosCommissionPercent"
                  name="iosCommissionPercent"
                  disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
                  onChange={(e) => {
                    setFieldValue("disable", false);
                    setFieldValue("iosCommissionPercent", Number(e.target.value));
                  }}
                  value={values.iosCommissionPercent}
                />
                {formik.errors.iosCommissionPercent && <span className="text-danger font-14">{formik.errors.iosCommissionPercent}</span>}
                <div className="font-14 mt-1" style={{ color: "gray" }}>
                  <span className="fw-bold ">Note: </span>When a user mints from an iOS device, we Charge extra {values.iosCommissionPercent}% Commission of the App Store
                </div>
              </div>
              <div className="nft-price-input mt-10">
                <label htmlFor="paypalMinPrice">Adding Credit - Minimum Amount</label>
                <Input
                  type="number"
                  id="paypalMinPrice"
                  name="paypalMinPrice"
                  disabled={handleAppActionPermission(projectNames.nft, projectModules[projectNames.nft].GLOBAL_SETTINGS, "update")}
                  onChange={(e) => {
                    setFieldValue("disable", false);
                    setFieldValue("stripeMinPrice", Number(e.target.value));
                    setFieldValue("paypalMinPrice", Number(e.target.value));
                  }}
                  value={values.paypalMinPrice}
                />
                {formik.errors.paypalMinPrice ? <span className="text-danger font-14">{formik.errors.paypalMinPrice}</span> : null}
              </div>

              {/* @ts-ignore */}
              <EditRestricted module={projectModules[projectNames.nft].GLOBAL_SETTINGS}>
                <button
                  disabled={values.disable}
                  className={`btn-sm ${values.disable ? "bg-disable-blue" : "bg-blue"} text-white rounded-5 py-2 px-3 font-14 border-none button-width-com mt-20`}
                  onClick={() => formik.handleSubmit()}>
                  Save
                </button>
              </EditRestricted>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />
    </>
  );
};

export default NftGlobalSetting;
