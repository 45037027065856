import { KYCREJECTMODALLOADER, NFTREJECTMODALLOADER, SETLOADING } from "./type";

export const setLoading = (payload: boolean) => {
  return { type: SETLOADING, payload };
};

export const setKycModalOpenStatus = (payload: boolean) => {
  return { type: KYCREJECTMODALLOADER, payload };
};

export const setNftModalOpenStatus = (payload: boolean) => {
  return { type: NFTREJECTMODALLOADER, payload };
};
