import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, FormFeedback, Input, Label, Row, Spinner, Tooltip } from "reactstrap";
import * as Yup from "yup";
import { getAPIExplorerDataById } from "../../../../actions/portal/getAPIExplorerDetailById";
import { updateAPIDescription } from "../../../../actions/portal/updateApiDescription";
import "../../../../assets/css/editAPI.css";
import {
  explorerAttributesDemoImage,
  explorerBodyDemoImage,
  explorerDescriptionDemoImage,
  explorerEndpointDemoImage,
  explorerHeadersDemoImage,
  explorerMessagesDemoImage,
  explorerNameDemoImage,
  explorerResponseDemoImage,
  explorerUrlDemoImage
} from "../../../../assets/images/api-explorer";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { IsValidJsonString, capitalizeFirstLetter, handleTrim, toastSuccess } from "../../../../common/common-functions";
import { InputType } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import CommonButton from "../../../common/Button";
import Footer from "../../../common/Footer";
import CustomTooltip from "../../../common/ToolTip";

const cssAnimationTimeOutAnimatedList = 300;

const EditExplorerApi = ({
  location: {
    state: { apiId }
  }
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const errorComponentRef = useRef<HTMLDivElement[]>([]);

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [isSaveButtionDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isExplorerLoading, setIsExplorerLoading] = useState(true);

  const handleFormChangeEventAndEnableSaveButton = () => setIsSaveButtonDisabled(false);

  const explorerValidationSchema = Yup.object().shape({
    attributes: Yup.object({
      info: Yup.array().of(Yup.string().required("Info is Required")),
      optionalField: Yup.array().of(
        Yup.object({
          key: Yup.string().required("Key is Required"),
          description: Yup.string().required("Description is Required")
        })
      ),
      requireField: Yup.array().of(
        Yup.object({
          key: Yup.string().required("Key is Required"),
          description: Yup.string().required("Description is Required")
        })
      )
    }),
    body: Yup.object({
      optinalParams: Yup.object({
        mode: Yup.string().required(),
        raw: Yup.array().of(
          Yup.object({
            key: Yup.string().required("Key is Required"),
            type: Yup.string().required("Type is Required"),
            value: Yup.string().nullable()
          })
        )
      }),
      requireParams: Yup.object({
        mode: Yup.string().required(),
        raw: Yup.array().of(
          Yup.object({
            key: Yup.string().required("Key is Required"),
            type: Yup.string().required("Type is Required"),
            value: Yup.string().nullable()
          })
        )
      })
    }),
    header: Yup.array().of(
      Yup.object({
        key: Yup.string().required("Key is Required"),
        type: Yup.string().required("Type is Required "),
        value: Yup.string().required("Value is Required")
      })
    ),
    messages: Yup.object({
      important: Yup.array().of(Yup.string().required("Message is Required")),
      notes: Yup.array().of(Yup.string().required("Message is Required")),
      tips: Yup.array().of(Yup.string().required("Message is Required"))
    }),
    decription: Yup.string().required("Description is Required"),
    endpoint: Yup.string().required("EndPoint is Required"),
    liveUrl: Yup.string().required("Live URL is Required"),
    name: Yup.string().required("Name is Required"),
    request_type: Yup.string().required("Request Type is Required"),
    status: Yup.string().required("Status is Required"),
    url: Yup.string().required("URL is Required")
  });

  const apiExplorerEditFormik = useFormik({
    initialValues: {
      attributes: {
        info: [""],
        optionalField: [{ key: "", description: "" }],
        requireField: [{ key: "", description: "" }]
      },
      body: {
        optinalParams: { mode: "", raw: [{ key: "", type: "", value: "" }] },
        requireParams: { mode: "raw", raw: [{ key: "", type: "", value: "" }] }
      },
      decription: "",
      endpoint: "",
      header: [{ key: "", type: "", value: "" }],
      liveUrl: "",
      messages: { important: [""], notes: [""], tips: [""] },
      name: "",
      request_type: "",
      requiredField: "",
      response: { code: 200, data: [""], error: [""] },
      status: "active",
      url: ""
    },
    validationSchema: explorerValidationSchema,
    onSubmit: (values) => {
      dispatch(setLoading(true));
      const makeAPIData = {
        id: apiId,
        ...values,
        messages: JSON.stringify(values.messages),
        response: JSON.stringify(values.response),
        header: JSON.stringify(values.header),
        body: JSON.stringify(values.body),
        attributes: JSON.stringify(values.attributes)
      };

      updateAPIDescription(makeAPIData).then((result) => {
        dispatch(setLoading(false));
        toastSuccess(result.message);
        history.goBack();
      });
    }
  });
  const { setValues, values, handleSubmit, setFieldValue, errors, touched } = apiExplorerEditFormik;

  const handleGetAPIData = () => {
    getAPIExplorerDataById(apiId)
      .then((response) => {
        setValues(response.data).then((data) => {
          /* setFieldValue("messages", JSON.parse(response.data.messages));
          setFieldValue("header", JSON.parse(response.data.header));
          setFieldValue("body", JSON.parse(response.data.body));
          setFieldValue("attributes", JSON.parse(response.data.attributes));
          setFieldValue("response", JSON.parse(response.data.response)); */
        });
        setIsExplorerLoading(false);
      })
      .catch((err) => {
        setIsExplorerLoading(false);
      });
  };

  const scrollToCurrentErrorComponentOnSubmit = () => {
    errorComponentRef.current?.forEach((currentItem) => {
      currentItem?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    });
  };

  useEffect(() => {
    handleGetAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return apiId ? (
    <>
      <MetaFunction meta="Edit Explorer" />
      <div className="holders">
        <div className="d-flex justify-content-between align-items-center">
          <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="right" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
            Back
          </Tooltip>
          <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
            <BackArrowComponent />
          </button>
          <div className="d-flex me-1 align-items-center justify-content-end">
            {!isExplorerLoading && (
              <CommonButton
                title="Save"
                type="button"
                disabled={isSaveButtionDisabled}
                primary
                onClick={() => {
                  handleSubmit();
                  setTimeout(() => {
                    scrollToCurrentErrorComponentOnSubmit();
                  }, 500);
                }}
              />
            )}
          </div>
        </div>
        <Row className="mt-20">
          <Col sm="12" lg="12">
            <div className="kyc-box rounded-10">
              <div className="p-20">
                <p className="sub-title m-b-5">{values?.name}</p>
              </div>
              {isExplorerLoading && (
                <div className="text-center mb-3">
                  <Spinner />
                </div>
              )}
              {!isExplorerLoading && (
                <>
                  <Row>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Name </span>
                          <CustomTooltip direction="right" content={<img src={explorerNameDemoImage} alt="" className="info-image" style={{ width: 300 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="text"
                          placeholder="Api Name"
                          name="name"
                          value={values.name}
                          onChange={(e) => {
                            handleFormChangeEventAndEnableSaveButton();
                            handleTrim(e.target.name, e.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.name) && Boolean(touched?.name)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[0] = el)}></div>
                          {errors?.name}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Endpoint </span>
                          <CustomTooltip direction="right" content={<img src={explorerEndpointDemoImage} alt="" className="info-image" style={{ maxWidth: 300 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="text"
                          placeholder="Enter Endpoint path"
                          name="endpoint"
                          value={values.endpoint}
                          onChange={(e) => {
                            handleFormChangeEventAndEnableSaveButton();
                            handleTrim(e.target.name, e.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.endpoint) && Boolean(touched?.endpoint)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[1] = el)}></div>
                          {errors?.endpoint}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Description </span>
                          <CustomTooltip direction="right" content={<img src={explorerDescriptionDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="textarea"
                          placeholder="Description..."
                          name="decription"
                          value={values.decription}
                          onChange={(e) => {
                            handleFormChangeEventAndEnableSaveButton();
                            handleTrim(e.target.name, e.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.decription) && Boolean(touched?.decription)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[2] = el)}></div>
                          {errors?.decription}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="3">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Request Type </span>
                          <CustomTooltip direction="right" content={<p>API request type ex. Get, Post, Put etc.</p>}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="select"
                          name="request_type"
                          value={values.request_type}
                          onChange={(event) => {
                            handleFormChangeEventAndEnableSaveButton();
                            setFieldValue("request_type", event.target.value);
                          }}
                          invalid={Boolean(errors?.request_type) && Boolean(touched?.request_type)}>
                          <option value="" disabled defaultChecked>
                            Select Request Type
                          </option>
                          <option value="POST">Post</option>
                          <option value="GET">Get</option>
                          <option value="PUT">Put</option>
                          <option value="PATCH">Patch</option>
                          <option value="DELETE">Delete</option>
                        </Input>
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[3] = el)}></div>
                          {errors?.request_type}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="3">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Status</span>
                        </div>
                        <Label className="d-flex align-items-center pt-2">
                          <Switch
                            height={20}
                            width={40}
                            disabled={false}
                            onColor="#007de4"
                            offColor="#b1abab"
                            checked={values.status === "active"}
                            onChange={(res: boolean) => {
                              handleFormChangeEventAndEnableSaveButton();
                              setFieldValue("status", !res ? "in-active" : "active");
                            }}
                            uncheckedIcon
                            checkedIcon
                            className="mr-10"
                          />
                          <p className="ml-20">Active</p>
                        </Label>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>URL </span>
                          <CustomTooltip direction="right" content={<img src={explorerUrlDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="text"
                          name="url"
                          placeholder="Enter URL"
                          value={values.url}
                          onChange={(e) => {
                            handleFormChangeEventAndEnableSaveButton();
                            handleTrim(e.target.name, e.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.url) && Boolean(touched?.url)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[4] = el)}></div>
                          {errors?.url}
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="p-20">
                        <div className="font-18 mb-1 fw-600">
                          <span>Live URL </span>
                          <CustomTooltip direction="right" content={<img src={explorerUrlDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                            <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                          </CustomTooltip>
                        </div>
                        <Input
                          type="text"
                          name="liveUrl"
                          placeholder="Enter Live URL"
                          value={values.liveUrl}
                          onChange={(e) => {
                            handleFormChangeEventAndEnableSaveButton();
                            handleTrim(e.target.name, e.target.value, setFieldValue);
                          }}
                          invalid={Boolean(errors?.liveUrl) && Boolean(touched?.liveUrl)}
                        />
                        <FormFeedback className="fw-600">
                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[5] = el)}></div>
                          {errors?.liveUrl}
                        </FormFeedback>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="px-3">
                    <Col>
                      <div className="font-18 mb-1 fw-600 p-20">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div>
                            <span className="font-20">Headers </span>
                            <CustomTooltip direction="right" content={<img src={explorerHeadersDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                          <button
                            className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                            onClick={() => {
                              setFieldValue("header", [...values.header, { key: "", type: "", value: "" }]);
                            }}>
                            <i className="fas fa-plus" />
                          </button>
                        </div>
                        {/* @ts-ignore */}
                        <TransitionGroup>
                          {Array.isArray(values.header) &&
                            values.header?.map((item, index) => {
                              return (
                                <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                  <Row key={index} className="my-3 py-4 pt-3 bg-lightblue rounded-5 position-relative">
                                    {/* remove button */}
                                    <i
                                      className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute right-0"
                                      style={{
                                        right: 13,
                                        width: 18,
                                        top: 12
                                      }}
                                      onClick={() => {
                                        // @ts-ignore
                                        const modifyHeaders = [...values.header];
                                        modifyHeaders.splice(index, 1);
                                        setFieldValue("header", modifyHeaders);
                                      }}></i>

                                    <Col sm="12" md="4" className="mt-sm-4">
                                      <p className="mb-2">Key</p>
                                      <Input
                                        type="text"
                                        name="key"
                                        placeholder="Enter Key"
                                        value={item.key}
                                        onChange={(e) => {
                                          handleFormChangeEventAndEnableSaveButton();
                                          handleTrim(`header[${index}].key`, e.target.value, setFieldValue);
                                        }}
                                        // @ts-ignore
                                        invalid={Boolean(errors?.header?.[index]?.key) && Boolean(touched?.header?.[index]?.key)}
                                      />
                                      <FormFeedback>
                                        <div ref={(el: HTMLDivElement) => (errorComponentRef.current[6] = el)}></div>
                                        {/* @ts-ignore */}
                                        {errors?.header?.[index]?.key}
                                      </FormFeedback>
                                    </Col>
                                    <Col sm="12" md="4" className="mt-sm-4">
                                      <p className="mb-2">Type</p>
                                      <Input
                                        type="select"
                                        name="type"
                                        placeholder="Enter Type"
                                        value={item.type}
                                        onChange={(event) => {
                                          handleFormChangeEventAndEnableSaveButton();
                                          setFieldValue(`header[${index}].type`, event.target.value);
                                        }}
                                        // @ts-ignore
                                        invalid={Boolean(errors?.header?.[index]?.type) && Boolean(touched?.header?.[index]?.type)}>
                                        <option value="" defaultChecked disabled>
                                          Select Type
                                        </option>
                                        {InputType.map((type, index) => {
                                          return (
                                            <option value={type} key={index}>
                                              {capitalizeFirstLetter(type)}
                                            </option>
                                          );
                                        })}
                                      </Input>
                                      <FormFeedback>
                                        <div ref={(el: HTMLDivElement) => (errorComponentRef.current[7] = el)}></div>
                                        {/* @ts-ignore */}
                                        {errors?.header?.[index]?.type}
                                      </FormFeedback>
                                    </Col>
                                    <Col sm="12" md="4" className="mt-sm-4">
                                      <p className="mb-2">Value</p>
                                      <Input
                                        type="text"
                                        name="value"
                                        placeholder="Enter Value"
                                        value={item.value}
                                        // @ts-ignore
                                        invalid={Boolean(errors?.header?.[index]?.value) && Boolean(touched?.header?.[index]?.value)}
                                        onChange={(e) => {
                                          handleFormChangeEventAndEnableSaveButton();
                                          handleTrim(`header[${index}].value`, e.target.value, setFieldValue);
                                        }}
                                      />
                                      <FormFeedback>
                                        <div ref={(el: HTMLDivElement) => (errorComponentRef.current[7] = el)}></div>
                                        {/* @ts-ignore */}
                                        {errors?.header?.[index]?.value}
                                      </FormFeedback>
                                    </Col>
                                  </Row>
                                </CSSTransition>
                              );
                            })}
                        </TransitionGroup>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="font-18 mb-1 fw-600 p-20">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div>
                            <span className="font-20">Messages </span>
                            <CustomTooltip direction="right" content={<img src={explorerMessagesDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                        </div>
                        <Row>
                          <Col sm="12" md="4">
                            <div className="my-3 p-2 px-3 pt-3 bg-lightblue rounded-5 position-relative">
                              <div className="mb-2 d-flex justify-content-between algin-items-center">
                                <span className="font-18 mb-1 fw-600">Important </span>
                                <div>
                                  <i
                                    className="fas fa-plus-circle ms-auto text-primary cursor-pointer"
                                    style={{ left: "97%", top: "6%" }}
                                    onClick={() => {
                                      setFieldValue("messages.important", [...values.messages.important, ""]);
                                    }}></i>
                                </div>
                              </div>
                              <TransitionGroup>
                                {Array.isArray(values.messages?.important) &&
                                  values.messages.important?.map((item, index) => {
                                    return (
                                      <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                        <div key={index} className="position-relative">
                                          <div key={index} className="me-4 mb-3">
                                            <Input
                                              type="text"
                                              className="my-2"
                                              name="key"
                                              placeholder="Enter Message"
                                              value={item}
                                              onChange={(e) => {
                                                handleFormChangeEventAndEnableSaveButton();
                                                handleTrim(`messages.important[${index}]`, e.target.value, setFieldValue);
                                              }}
                                              // @ts-ignore
                                              invalid={Boolean(errors?.messages?.important?.[index]) && Boolean(touched?.messages?.important?.[index])}
                                            />
                                            <FormFeedback style={{ marginTop: -6 }}>
                                              <div ref={(el: HTMLDivElement) => (errorComponentRef.current[8] = el)}></div>
                                              {errors?.messages?.important?.[index]}
                                            </FormFeedback>
                                          </div>
                                          <i
                                            className="fas fa-trash-alt ms-2 blue cursor-pointer position-absolute"
                                            style={{ right: 0, top: 10 }}
                                            onClick={() => {
                                              // @ts-ignore
                                              const modifyImportantMessages = [...values.messages.important];
                                              modifyImportantMessages.splice(index, 1);
                                              setFieldValue("messages.important", modifyImportantMessages);
                                            }}></i>
                                        </div>
                                      </CSSTransition>
                                    );
                                  })}
                              </TransitionGroup>
                            </div>
                          </Col>
                          <Col sm="12" md="4">
                            <div className="my-3 p-2 px-3 pt-3 bg-lightblue rounded-5 position-relative">
                              <div className="mb-2 d-flex justify-content-between algin-items-center">
                                <span className="font-18 mb-1 fw-600">Notes </span>
                                <div>
                                  <i
                                    className="fas fa-plus-circle ms-auto text-primary cursor-pointer"
                                    style={{ left: "97%", top: "6%" }}
                                    onClick={() => {
                                      setFieldValue("messages.notes", [...values.messages.notes, ""]);
                                    }}></i>
                                </div>
                              </div>
                              <TransitionGroup>
                                {Array.isArray(values.messages?.notes) &&
                                  values.messages.notes?.map((item, index) => {
                                    return (
                                      <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                        <div key={index} className="position-relative">
                                          <div className="me-4 mb-3">
                                            <Input
                                              type="text"
                                              className="my-2"
                                              name="key"
                                              placeholder="Enter Message"
                                              value={item}
                                              onChange={(e) => {
                                                handleFormChangeEventAndEnableSaveButton();
                                                handleTrim(`messages.notes[${index}]`, e.target.value, setFieldValue);
                                              }}
                                              // @ts-ignore
                                              invalid={Boolean(errors?.messages?.notes?.[index]) && Boolean(touched?.messages?.notes?.[index])}
                                            />
                                            <FormFeedback style={{ marginTop: -6 }}>
                                              <div ref={(el: HTMLDivElement) => (errorComponentRef.current[9] = el)}></div>
                                              {errors?.messages?.notes?.[index]}
                                            </FormFeedback>
                                          </div>
                                          <i
                                            className="fas fa-trash-alt ms-2 blue cursor-pointer position-absolute"
                                            style={{ right: 0, top: 10 }}
                                            onClick={() => {
                                              // @ts-ignore
                                              const modifyNotesMessages = [...values.messages.notes];
                                              modifyNotesMessages.splice(index, 1);
                                              setFieldValue("messages.notes", modifyNotesMessages);
                                            }}></i>
                                        </div>
                                      </CSSTransition>
                                    );
                                  })}
                              </TransitionGroup>
                            </div>
                          </Col>
                          <Col sm="12" md="4">
                            <div className="my-3 p-2 px-3 pt-3 bg-lightblue rounded-5 position-relative">
                              <div className="mb-2 d-flex justify-content-between algin-items-center">
                                <span className="font-18 mb-1 fw-600">Tips </span>
                                <div>
                                  <i
                                    className="fas fa-plus-circle ms-auto text-primary cursor-pointer"
                                    style={{ left: "97%", top: "6%" }}
                                    onClick={() => {
                                      setFieldValue("messages.tips", [...values.messages.tips, ""]);
                                    }}></i>
                                </div>
                              </div>
                              <TransitionGroup>
                                {Array.isArray(values.messages?.tips) &&
                                  values.messages.tips?.map((item, index) => {
                                    return (
                                      <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                        <div className="position-relative" key={index}>
                                          <div className="me-4 mb-3">
                                            <Input
                                              type="text"
                                              className="my-2"
                                              name="key"
                                              placeholder="Enter Message"
                                              value={item}
                                              onChange={(e) => {
                                                handleFormChangeEventAndEnableSaveButton();
                                                handleTrim(`messages.tips[${index}]`, e.target.value, setFieldValue);
                                              }}
                                              // @ts-ignore
                                              invalid={Boolean(errors?.messages?.tips?.[index]) && Boolean(touched?.messages?.tips?.[index])}
                                            />
                                            <FormFeedback style={{ marginTop: -6 }}>
                                              <div ref={(el: HTMLDivElement) => (errorComponentRef.current[10] = el)}></div>
                                              {errors?.messages?.tips?.[index]}
                                            </FormFeedback>
                                          </div>
                                          <i
                                            className="fas fa-trash-alt ms-2 blue cursor-pointer position-absolute"
                                            style={{ right: 0, top: 10 }}
                                            onClick={() => {
                                              // @ts-ignore
                                              const modifytipsMessages = [...values.messages.tips];
                                              modifytipsMessages.splice(index, 1);
                                              setFieldValue("messages.tips", modifytipsMessages);
                                            }}></i>
                                        </div>
                                      </CSSTransition>
                                    );
                                  })}
                              </TransitionGroup>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="font-18 mb-1 fw-600 p-20">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div>
                            <span className="font-20">Response </span>
                            <CustomTooltip direction="right" content={<img src={explorerResponseDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                        </div>
                        <Row className="my-3 py-4 mx-1 pt-3 rounded-5 position-relative" style={{ background: "#16202d" }}>
                          <Col sm="12">
                            <textarea
                              value={JSON.stringify(values.response, null, 4)}
                              className="text-white bg-transparent border-0 form-control"
                              rows={10}
                              onChange={(event) => {
                                handleFormChangeEventAndEnableSaveButton();
                                IsValidJsonString(event.target.value) && setFieldValue("response", JSON.parse(event.target.value));
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="font-18 mb-1 fw-600 p-20">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div>
                            <span className="font-20">Body </span>
                            <CustomTooltip direction="right" content={<img src={explorerBodyDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                        </div>
                        <div className="border rounded-5">
                          <Row>
                            <Col sm="12">
                              <p className="mb-2 d-flex justify-content-between align-items-center p-10 font-18 fw-bolder">
                                <span>Required Params</span>{" "}
                                <button
                                  className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                                  onClick={() => {
                                    setFieldValue("body.requireParams.raw", [...values.body.requireParams.raw, { key: "", type: "", value: "" }]);
                                  }}>
                                  <i className="fas fa-plus" />
                                </button>
                              </p>
                            </Col>
                            <Col sm="12" md="6">
                              <div className="p-20 pt-1">
                                <div className="font-18 mb-1 fw-600">
                                  <span>Mode </span>
                                </div>
                                <Input
                                  type="select"
                                  value={values.body?.requireParams?.mode}
                                  name="body.requireParams.mode"
                                  onChange={(event) => {
                                    handleFormChangeEventAndEnableSaveButton();
                                    setFieldValue(event.target.name, event.target.value);
                                  }}
                                  invalid={Boolean(errors?.body?.requireParams?.mode) && Boolean(touched?.body?.requireParams?.mode)}>
                                  <option value="" defaultChecked disabled>
                                    Select Type
                                  </option>
                                  <option value="raw">JSON</option>
                                  <option value="formData">Form-Data</option>
                                </Input>
                                <FormFeedback>
                                  <div ref={(el: HTMLDivElement) => (errorComponentRef.current[11] = el)}></div>
                                  {errors?.body?.requireParams?.mode}
                                </FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <TransitionGroup>
                            {Array.isArray(values.body?.requireParams?.raw) &&
                              values.body.requireParams?.raw?.map((item, index) => {
                                return (
                                  <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                    <Row key={index} className="my-3 py-4 mx-3 pt-3 bg-lightblue rounded-5 position-relative">
                                      <i
                                        className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute"
                                        style={{
                                          right: 13,
                                          width: 18,
                                          top: 12
                                        }}
                                        onClick={() => {
                                          // @ts-ignore
                                          const modifyRequiredParams = [...values.body.requireParams.raw];
                                          modifyRequiredParams.splice(index, 1);
                                          setFieldValue("body.requireParams.raw", modifyRequiredParams);
                                        }}></i>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Key</p>
                                        <Input
                                          type="text"
                                          name="key"
                                          placeholder="Enter Key"
                                          value={item.key}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`body.requireParams.raw[${index}].key`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.body?.requireParams?.raw?.[index]?.key) && Boolean(touched?.body?.requireParams?.raw?.[index]?.key)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[12] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.body?.requireParams?.raw?.[index]?.key}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Type</p>
                                        <Input
                                          type="select"
                                          name="type"
                                          placeholder="Enter Type"
                                          value={item.type}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            setFieldValue(`body.requireParams.raw[${index}].type`, event.target.value);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.body?.requireParams?.raw?.[index]?.type) && Boolean(touched?.body?.requireParams?.raw?.[index]?.type)}>
                                          <option value="" defaultChecked disabled>
                                            Select Type
                                          </option>
                                          {InputType.map((type, index) => {
                                            return (
                                              <option value={type} key={index}>
                                                {capitalizeFirstLetter(type)}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[13] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.body?.requireParams?.raw?.[index]?.type}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Value</p>
                                        <Input
                                          type="text"
                                          name="value"
                                          placeholder="Enter Value"
                                          value={item.value}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`body.requireParams.raw[${index}].value`, event.target.value, setFieldValue);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </CSSTransition>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                        <div className="border rounded-5 mt-4">
                          <Row>
                            <Col sm="12">
                              <p className="mb-2 d-flex justify-content-between align-items-center p-10 font-18 fw-bolder">
                                <span>Optional Params</span>{" "}
                                <button
                                  className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                                  onClick={() => {
                                    setFieldValue("body.optinalParams.raw", [...values?.body?.optinalParams?.raw, { key: "", type: "", value: "" }]);
                                  }}>
                                  <i className="fas fa-plus" />
                                </button>
                              </p>
                            </Col>
                            <Col sm="12" md="6">
                              <div className="p-20 pt-1">
                                <div className="font-18 mb-1 fw-600">
                                  <span>Mode </span>
                                </div>
                                <Input
                                  type="select"
                                  value={values?.body?.optinalParams?.mode || ""}
                                  name="body.optinalParams.mode"
                                  onChange={(event) => {
                                    handleFormChangeEventAndEnableSaveButton();
                                    setFieldValue(event.target.name, event.target.value);
                                  }}
                                  // @ts-ignore
                                  invalid={Boolean(errors?.body?.optinalParams?.mode) && Boolean(touched?.body?.optinalParams?.mode)}>
                                  <option value="" defaultChecked disabled>
                                    Select Type
                                  </option>
                                  <option value="raw">JSON</option>
                                  <option value="formData">Form-Data</option>
                                </Input>
                                {/* @ts-ignore */}
                                <FormFeedback>
                                  <div ref={(el: HTMLDivElement) => (errorComponentRef.current[14] = el)}></div>
                                  {errors?.body?.optinalParams?.mode}
                                </FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <TransitionGroup>
                            {Array.isArray(values.body?.optinalParams?.raw) &&
                              values.body.optinalParams?.raw?.map((item, index) => {
                                return (
                                  <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                    <Row key={index} className="my-3 py-4 mx-3 pt-3 bg-lightblue rounded-5 position-relative">
                                      <i
                                        className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute right-0"
                                        style={{
                                          right: 13,
                                          width: 18,
                                          top: 12
                                        }}
                                        onClick={() => {
                                          // @ts-ignore
                                          const modifyOptionalParams = [...values.body.optinalParams.raw];
                                          modifyOptionalParams.splice(index, 1);
                                          setFieldValue("body.optinalParams.raw", modifyOptionalParams);
                                        }}></i>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Key</p>
                                        <Input
                                          type="text"
                                          name="key"
                                          placeholder="Enter Key"
                                          value={item.key}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`body.optinalParams.raw[${index}].key`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.body?.optinalParams?.raw?.[index]?.key) && Boolean(touched?.body?.optinalParams?.raw?.[index]?.key)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[15] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.body?.optinalParams?.raw?.[index]?.key}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Type</p>
                                        <Input
                                          type="select"
                                          name="type"
                                          placeholder="Enter Type"
                                          value={item.type}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            setFieldValue(`body.optinalParams.raw[${index}].type`, event.target.value);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.body?.optinalParams?.raw?.[index]?.type) && Boolean(touched?.body?.optinalParams?.raw?.[index]?.type)}>
                                          <option value="" defaultChecked disabled>
                                            Select Type
                                          </option>
                                          {InputType.map((type, index) => {
                                            return (
                                              <option value={type} key={index}>
                                                {capitalizeFirstLetter(type)}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[16] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.body?.optinalParams?.raw?.[index]?.type}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="4" className="mt-sm-4">
                                        <p className="mb-2">Value</p>
                                        <Input
                                          type="text"
                                          name="value"
                                          placeholder="Enter Value"
                                          value={item.value}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`body.optinalParams.raw[${index}].value`, event.target.value, setFieldValue);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </CSSTransition>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="font-18 mb-1 fw-600 p-20">
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div>
                            <span className="font-20">Attributes </span>
                            <CustomTooltip direction="right" content={<img src={explorerAttributesDemoImage} alt="" className="info-image" style={{ maxWidth: 400 }} />}>
                              <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative"></i>
                            </CustomTooltip>
                          </div>
                        </div>
                        <div className="border rounded-5">
                          <Row>
                            <Col>
                              <p className="mb-2 d-flex justify-content-between align-items-center p-10 font-18 fw-bolder">
                                <span>Required Field</span>{" "}
                                <button
                                  className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                                  onClick={() => {
                                    setFieldValue("attributes.requireField", [...values.attributes.requireField, { key: "", description: "" }]);
                                  }}>
                                  <i className="fas fa-plus" />
                                </button>
                              </p>
                            </Col>
                          </Row>
                          <TransitionGroup>
                            {Array.isArray(values.attributes?.requireField) &&
                              values.attributes?.requireField?.map((item, index) => {
                                return (
                                  <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                    <Row key={index} className="my-3 py-4 mx-3 pt-3 bg-lightblue rounded-5 position-relative">
                                      <i
                                        className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute right-0"
                                        style={{
                                          right: 13,
                                          width: 18,
                                          top: 12
                                        }}
                                        onClick={() => {
                                          // @ts-ignore
                                          const modifyRequiredField = [...values.attributes.requireField];
                                          modifyRequiredField.splice(index, 1);
                                          setFieldValue("attributes.requireField", modifyRequiredField);
                                        }}></i>
                                      <Col sm="12" md="6" className="mt-sm-4">
                                        <p className="mb-2">Key</p>
                                        <Input
                                          type="text"
                                          name="key"
                                          placeholder="Enter Key"
                                          value={item.key}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`attributes.requireField[${index}].key`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.attributes?.requireField?.[index]?.key) && Boolean(touched?.attributes?.requireField?.[index]?.key)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[17] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.attributes?.requireField?.[index]?.key}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="6" className="mt-sm-4">
                                        <p className="mb-2">Description</p>
                                        <Input
                                          type="text"
                                          name="type"
                                          placeholder="Enter Type"
                                          value={item.description}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`attributes.requireField[${index}].description`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.attributes?.requireField?.[index]?.description) && Boolean(touched?.attributes?.requireField?.[index]?.description)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[18] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.attributes?.requireField?.[index]?.description}
                                        </FormFeedback>
                                      </Col>
                                    </Row>
                                  </CSSTransition>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                        <div className="border rounded-5 mt-4">
                          <Row>
                            <Col>
                              <p className="mb-2 d-flex justify-content-between align-items-center p-10 font-18 fw-bolder">
                                <span>Optional Field</span>{" "}
                                <button
                                  className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                                  onClick={() => {
                                    setFieldValue("attributes.optionalField", [...values.attributes.optionalField, { key: "", description: "" }]);
                                  }}>
                                  <i className="fas fa-plus" />
                                </button>
                              </p>
                            </Col>
                          </Row>
                          <TransitionGroup>
                            {Array.isArray(values.attributes?.optionalField) &&
                              values.attributes?.optionalField?.map((item, index) => {
                                return (
                                  <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                    <Row key={index} className="my-3 py-4 mx-3 pt-3 bg-lightblue rounded-5 position-relative">
                                      {/* remove button */}
                                      <i
                                        className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute right-0"
                                        style={{
                                          right: 13,
                                          width: 18,
                                          top: 12
                                        }}
                                        onClick={() => {
                                          // @ts-ignore
                                          const modifyRequiredField = [...values.attributes.optionalField];
                                          modifyRequiredField.splice(index, 1);
                                          setFieldValue("attributes.optionalField", modifyRequiredField);
                                        }}></i>
                                      <Col sm="12" md="6" className="mt-sm-4">
                                        <p className="mb-2">Key</p>
                                        <Input
                                          type="text"
                                          name="key"
                                          placeholder="Enter Key"
                                          value={item.key}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`attributes.optionalField[${index}].key`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.attributes?.optionalField?.[index]?.key) && Boolean(touched?.attributes?.optionalField?.[index]?.key)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[19] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.attributes?.optionalField?.[index]?.key}
                                        </FormFeedback>
                                      </Col>
                                      <Col sm="12" md="6" className="mt-sm-4">
                                        <p className="mb-2">Description</p>
                                        <Input
                                          type="text"
                                          name="type"
                                          placeholder="Enter Type"
                                          value={item.description}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            handleTrim(`attributes.optionalField[${index}].description`, event.target.value, setFieldValue);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.attributes?.optionalField?.[index]?.description) && Boolean(touched?.attributes?.optionalField?.[index]?.description)}
                                        />
                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[20] = el)}></div>
                                          {/* @ts-ignore */}
                                          {errors?.attributes?.optionalField?.[index]?.description}
                                        </FormFeedback>
                                      </Col>
                                    </Row>
                                  </CSSTransition>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                        <div className="border rounded-5 mt-4 d-none">
                          <Row>
                            <Col>
                              <p className="mb-2 d-flex justify-content-between align-items-center p-10 font-18 fw-bolder">
                                <span>Info</span>{" "}
                                <button
                                  className="font-15 text-white bg-blue ml-3 cursor-pointer rounded-circle"
                                  onClick={() => {
                                    setFieldValue("attributes.info", [...values.attributes.info, ""]);
                                  }}>
                                  <i className="fas fa-plus" />
                                </button>
                              </p>
                            </Col>
                          </Row>
                          <TransitionGroup>
                            {Array.isArray(values.attributes?.info) &&
                              values.attributes?.info?.map((item, index) => {
                                return (
                                  <CSSTransition key={index} timeout={cssAnimationTimeOutAnimatedList} classNames="animatedListItem">
                                    <Row key={index} className="my-3 py-4 mx-3 pt-3 bg-lightblue rounded-5 position-relative">
                                      <i
                                        className="fas fa-trash-alt ms-auto blue cursor-pointer position-absolute right-0"
                                        style={{
                                          right: 13,
                                          width: 18,
                                          top: 12
                                        }}
                                        onClick={() => {
                                          // @ts-ignore
                                          const modifyRequiredField = [...values.attributes.info];
                                          modifyRequiredField.splice(index, 1);
                                          setFieldValue("attributes.info", modifyRequiredField);
                                        }}></i>

                                      <Col sm="12">
                                        <Input
                                          type="text"
                                          name="key"
                                          placeholder="Enter Key"
                                          value={item}
                                          onChange={(event) => {
                                            handleFormChangeEventAndEnableSaveButton();
                                            setFieldValue(`attributes.info[${index}]`, event.target.value);
                                          }}
                                          // @ts-ignore
                                          invalid={Boolean(errors?.attributes?.info?.[index]) && Boolean(touched.attributes?.info?.[index])}
                                        />

                                        <FormFeedback>
                                          <div ref={(el: HTMLDivElement) => (errorComponentRef.current[21] = el)}></div>
                                          {errors?.attributes?.info?.[index]}
                                        </FormFeedback>
                                      </Col>
                                    </Row>
                                  </CSSTransition>
                                );
                              })}
                          </TransitionGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="d-flex p-20 align-items-center justify-content-end">
                      <CommonButton
                        title="Save"
                        disabled={isSaveButtionDisabled}
                        type="button"
                        primary
                        onClick={() => {
                          handleSubmit();
                          setTimeout(() => {
                            scrollToCurrentErrorComponentOnSubmit();
                          }, 500);
                        }}
                      />
                    </div>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  ) : (
    <>No Data Found</>
  );
};

export default EditExplorerApi;
