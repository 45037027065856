import { projectModules, projectNames } from "../../common/constants";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import Notification from "../../pages/main/Notification";
import StakingAddAvailableAppsManagement from "../../pages/main/staking/availableAppsManagement/StakingAddAvailableAppsManagement";
import StakingAvailableAppsManagement from "../../pages/main/staking/availableAppsManagement/StakingAvailableAppsManagement";
import Blogs from "../../pages/main/staking/blogs/Blogs";
import BlogsForm from "../../pages/main/staking/blogs/BlogsForm";
import Category from "../../pages/main/staking/category/Category";
import CategoryForm from "../../pages/main/staking/category/CategoryForm";
import StakingRequestDemoViewComponent from "../../pages/main/staking/common/StakingRequestDemoViewComponent";
import StakingViewComponent from "../../pages/main/staking/common/StakingViewComponent";
import StakingContactusManagement from "../../pages/main/staking/contactusManagement/StakingContactusManagement";
import StakingDappsManagement from "../../pages/main/staking/dappsManagement/StakingDappsManagement";
import StakingGlobalSettings from "../../pages/main/staking/globalSettings/StakingGlobalSettings";
import StakingLabelManagement from "../../pages/main/staking/labelManagement/StakingLabelManagement";
import StakingMarketPlacemanagement from "../../pages/main/staking/marketPlacemanagement/StakingMarketPlacemanagement";
import StakingMarketPlacemanagementView from "../../pages/main/staking/marketPlacemanagement/view/StakingMarketPlacemanagementView";
import StakingDemoRequestList from "../../pages/main/staking/requestDemo/StakingDemoRequestList";
import StakingValidateManagement from "../../pages/main/staking/validateManagement/StakingValidateManagement";

const StakingPrivateRoutes = [
  {
    path: `/${projectNames.edexanetwork}/available-apps-management`,
    component: StakingAvailableAppsManagement,
    module: projectModules[projectNames.edexanetwork].AVAILABLEAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/add-app`,
    component: StakingAddAvailableAppsManagement,
    module: projectModules[projectNames.edexanetwork].AVAILABLEAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/edit-app/:id`,
    component: StakingAddAvailableAppsManagement,
    module: projectModules[projectNames.edexanetwork].AVAILABLEAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/contact-us`,
    component: StakingContactusManagement,
    module: projectModules[projectNames.edexanetwork].CONTACTUSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/news`,
    component: Blogs,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/news/create`,
    component: BlogsForm,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/news/:id`,
    component: BlogsForm,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/category`,
    component: Category,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/category/create`,
    component: CategoryForm,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/category/:id`,
    component: CategoryForm,
    module: projectModules[projectNames.edexanetwork].BLOG_MANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/dapp-customer-request`,
    component: StakingDappsManagement,
    module: projectModules[projectNames.edexanetwork].DAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/market-place`,
    component: StakingMarketPlacemanagement,
    module: projectModules[projectNames.edexanetwork].MARKETPLACEMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/validate-request`,
    component: StakingValidateManagement,
    module: projectModules[projectNames.edexanetwork].VALIDATOSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/app-demo-request`,
    component: StakingDemoRequestList,
    module: projectModules[projectNames.edexanetwork].DEMO_REQUEST
  },
  {
    path: `/${projectNames.edexanetwork}/app-demo-request/:id`,
    component: StakingRequestDemoViewComponent,
    module: projectModules[projectNames.edexanetwork].DEMO_REQUEST
  },
  {
    path: `/${projectNames.edexanetwork}/global-settings`,
    component: StakingGlobalSettings,
    module: projectModules[projectNames.edexanetwork].GLOBAL_SETTINGS
  },
  {
    path: `/${projectNames.edexanetwork}/available-apps-management/:id`,
    component: StakingViewComponent,
    module: projectModules[projectNames.edexanetwork].AVAILABLEAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/contact-us/:id`,
    component: StakingViewComponent,
    module: projectModules[projectNames.edexanetwork].CONTACTUSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/dapp-customer-request/:id`,
    component: StakingViewComponent,
    module: projectModules[projectNames.edexanetwork].DAPPSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/market-place/:id`,
    component: StakingMarketPlacemanagementView,
    module: projectModules[projectNames.edexanetwork].MARKETPLACEMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/market-place/add`,
    component: StakingMarketPlacemanagementView,
    module: projectModules[projectNames.edexanetwork].MARKETPLACEMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/validate-request/:id`,
    component: StakingViewComponent,
    module: projectModules[projectNames.edexanetwork].VALIDATOSMANAGEMENT
  },
  {
    path: `/${projectNames.edexanetwork}/label-management`,
    component: StakingLabelManagement,
    module: projectModules[projectNames.edexanetwork].LABEL_MANAGEMENT
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];

export default StakingPrivateRoutes;
