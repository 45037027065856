import { projectModules, projectNames } from "../../common/constants";
import Notification from "../../pages/main/Notification";
import ApiManagement from "../../pages/main/admin/ApiManagement";
import AppManagement from "../../pages/main/admin/AppManagement";
import EditApi from "../../pages/main/admin/component/EditApi";
import EditApp from "../../pages/main/admin/component/EditApp";
import EditExplorerApi from "../../pages/main/admin/component/EditExplorerApi";
import EditExplorer from "../../pages/main/admin/component/EditExplorerParent";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import RequestHistory from "../../pages/main/universe/RequestHistory/RequestHistory";
import { SmartContractDetail } from "../../pages/main/universe/SmartContracts/SmartContractDetail";
import { SmartContractInstallationDetail } from "../../pages/main/universe/SmartContracts/SmartContractInstallationDetail";
import SmartContractsInstallations from "../../pages/main/universe/SmartContracts/SmartContractInstallations";
import SmartContractsListing from "../../pages/main/universe/SmartContracts/SmartContractListing";
import UniverseDashboard from "../../pages/main/universe/UniverseDashboard";
import { ChaincodeDetails } from "../../pages/main/universe/chaincodeManagement/ChainCodeDetail";
import ChaincodeManagement from "../../pages/main/universe/chaincodeManagement/ChaincodeManagement";
import { ChannelInvitationDetail } from "../../pages/main/universe/channelInvitation/ChannelInvitationDetail";
import ChannelInvitations from "../../pages/main/universe/channelInvitation/ChannelInvitationList";
import { ChannelDetailComponent } from "../../pages/main/universe/channelManagement/Channel-details";
import ChannelManagement from "../../pages/main/universe/channelManagement/ChannelManagement";
import UniverseGlobalSetting from "../../pages/main/universe/globalSetting/UniverGlobalSetting";
import AddDomain from "../../pages/main/universe/manageDomain/AddDomain";
import EditDomain from "../../pages/main/universe/manageDomain/EditDomain";
import ManageDomainList from "../../pages/main/universe/manageDomain/ManageDomainList";
import EditRegion from "../../pages/main/universe/manageRegions/EditRegion";
import ManageRegionsList from "../../pages/main/universe/manageRegions/ManageRegionList";
import { PeerDetailsComponent } from "../../pages/main/universe/peer/PeerDetails";
import Peers from "../../pages/main/universe/peer/Peers";
import EditTemplate from "../../pages/main/universe/templates/EditTemplate";
import { TemplateDetail } from "../../pages/main/universe/templates/TemplateDetail";
import TemplateListing from "../../pages/main/universe/templates/TemplateListing";
import { UniversalDetailsComponent } from "../../pages/main/universe/universeManagement/UniversalDetails";
import UniverseManagement from "../../pages/main/universe/universeManagement/UniverseManagement";

const universePrivateRoutes = [
  {
    path: `/${projectNames.universe}/dashboard`,
    component: UniverseDashboard,
    module: projectModules[projectNames.universe].DASHBOARD
  },
  {
    path: `/${projectNames.universe}/universe-request`,
    component: UniverseManagement,
    module: projectModules[projectNames.universe].UNIVERSAL_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/universe-request/:id`,
    component: UniversalDetailsComponent,
    module: projectModules[projectNames.admin].UNIVERSAL_MANAGEMENT
  },
  // this routes is token engine routes
  // {
  //   path: `${projectNames.universe}universe/token-engine",
  //   component: TokenEngineManagement,
  //   module: projectModules[projectNames.universe].TOKEN_ENGINE_MANAGEMENT
  // },
  // {
  //   path: `${projectNames.universe}universe/token-engine/:id",
  //   component: TokenEngineDetailsComponent,
  //   module: projectModules[projectNames.universe].TOKEN_ENGINE_MANAGEMENT
  // },
  {
    path: `/${projectNames.universe}/api-management`,
    component: ApiManagement,
    module: projectModules[projectNames.universe].API_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/contracts-listing`,
    component: SmartContractsListing,
    module: projectModules[projectNames.universe].SMART_CONTRACTS
  },
  {
    path: `/${projectNames.universe}/contracts-listing/:id`,
    component: SmartContractDetail,
    module: projectModules[projectNames.universe].SMART_CONTRACTS
  },
  {
    path: `/${projectNames.universe}/installations`,
    component: SmartContractsInstallations,
    module: projectModules[projectNames.universe].SMART_CONTRACTS
  },
  {
    path: `/${projectNames.universe}/installations/:id`,
    component: SmartContractInstallationDetail,
    module: projectModules[projectNames.universe].SMART_CONTRACTS
  },
  {
    path: `/${projectNames.universe}/api-management/edit-api`,
    component: EditApi,
    module: projectModules[projectNames.universe].API_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/api-management/explorer`,
    component: EditExplorer,
    module: projectModules[projectNames.universe].API_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/api-management/explorer/edit`,
    component: EditExplorerApi,
    module: projectModules[projectNames.universe].API_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/api-management/explorer/edit`,
    component: EditExplorerApi,
    module: projectModules[projectNames.universe].API_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/application-management`,
    component: AppManagement,
    module: projectModules[projectNames.universe].APPLICATION_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/application-management/app-edit`,
    component: EditApp,
    module: projectModules[projectNames.universe].APPLICATION_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/chain-code-management`,
    component: ChaincodeManagement,
    module: projectModules[projectNames.universe].CHAIN_CODE_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/chain-code-management/:id`,
    component: ChaincodeDetails,
    module: projectModules[projectNames.universe].CHAIN_CODE_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/channel-listing`,
    component: ChannelManagement,
    module: projectModules[projectNames.universe].CHANNEL_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/channel-listing/:id`,
    component: ChannelDetailComponent,
    module: projectModules[projectNames.universe].CHANNEL_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/channel-invitations`,
    component: ChannelInvitations,
    module: projectModules[projectNames.universe].CHANNEL_INVITATION
  },
  {
    path: `/${projectNames.universe}/channel-invitations/:id`,
    component: ChannelInvitationDetail,
    module: projectModules[projectNames.universe].CHANNEL_INVITATION
  },
  {
    path: `/${projectNames.universe}/request-history`,
    component: RequestHistory,
    module: projectModules[projectNames.universe].REQUEST_HISTORY
  },
  {
    path: `/${projectNames.universe}/peers`,
    component: Peers,
    module: projectModules[projectNames.universe].PEER_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/peers/:id`,
    component: PeerDetailsComponent,
    module: projectModules[projectNames.universe].PEER_MANAGEMENT
  },
  {
    path: `/${projectNames.universe}/templates`,
    component: TemplateListing,
    module: projectModules[projectNames.universe].TEMPLATE_LISTING
  },
  {
    path: `/${projectNames.universe}/templates/:id`,
    component: TemplateDetail,
    module: projectModules[projectNames.universe].TEMPLATE_LISTING
  },
  {
    path: `/${projectNames.universe}/create-template`,
    component: EditTemplate,
    module: projectModules[projectNames.universe].TEMPLATE_LISTING
  },
  {
    path: `/${projectNames.universe}/templates-edit/:id`,
    component: EditTemplate,
    module: projectModules[projectNames.universe].TEMPLATE_LISTING
  },
  {
    path: `/${projectNames.universe}/manage-domains`,
    component: ManageDomainList,
    module: projectModules[projectNames.universe].MANAGE_DOMAINS
  },
  {
    path: `/${projectNames.universe}/create-domain`,
    component: AddDomain,
    module: projectModules[projectNames.universe].MANAGE_DOMAINS
  },
  {
    path: `/${projectNames.universe}/domain-edit/:id`,
    component: EditDomain,
    module: projectModules[projectNames.universe].MANAGE_DOMAINS
  },

  {
    path: `/${projectNames.universe}/manage-regions`,
    component: ManageRegionsList,
    module: projectModules[projectNames.universe].MANAGE_REGIONS
  },
  {
    path: `/${projectNames.universe}/create-region`,
    component: EditRegion,
    module: projectModules[projectNames.universe].MANAGE_REGIONS
  },
  {
    path: `/${projectNames.universe}/region-edit/:id`,
    component: EditRegion,
    module: projectModules[projectNames.universe].MANAGE_REGIONS
  },
  {
    path: `/${projectNames.universe}/global-settings`,
    component: UniverseGlobalSetting,
    module: projectModules[projectNames.universe].GLOBAL_SETTINGS
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];

export default universePrivateRoutes;
