import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const StakingUpdateGlobalSetting = (id: string, data: any): Promise<IStakingUpdateGlobalSetting> => {
  return HTTPService.put(StakingPortalApiEndPoint.globalSetting.update + id, data);
};

export interface IStakingUpdateGlobalSettingData {
  id: string;
  _id: string;
  email: string;
  trendingAppLimit: string;
  trendingAppPrecedence: string;
}

interface IStakingUpdateGlobalSetting {
  message: string;
  status: number;
  data: IStakingUpdateGlobalSettingData;
}
