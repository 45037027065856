import html2canvas from "html2canvas";
import moment from "moment";
import QRCodeGenerator from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";
import { onViewAPI } from "../../../../../actions/bvote/pollHistoryAction";
import MetaFunction from "../../../../../common/MetaTag";
import { projectNames } from "../../../../../common/constants";
import HTTPService from "../../../../../common/httpservice";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import PageHeader from "../../common/PageHeader";
import Verified from "./../../../../../assets/images/edexa-verified.png";
import BarChart from "./charts/BarChart";
import PolarChart from "./charts/PolarChart";

const ViewPollHistory = ({ location }: any) => {
  // eslint-disable-next-line
  const [module, setModule] = useState("Poll History");
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<any>(null);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  // eslint-disable-next-line
  const barChartRef = useRef(null);
  // eslint-disable-next-line
  const polarChartRef = useRef(null);

  // eslint-disable-next-line
  let barChartOptions = {
    chart: {
      type: "bar",
      height: 350
    },
    series: [
      {
        name: "sales",
        data: [30, 40, 35, 50, 49, 60, 70, 91]
      }
    ],
    xaxis: {
      categories: ["A", "B", "C", "D", "E", "F", "G", "H"]
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },

    div: {
      row: {
        colors: ["#fff", "#f2f2f2"]
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    }
  };

  // eslint-disable-next-line
  let polarChartOptions = {
    chart: {
      width: 450,
      type: "polarArea"
    },
    series: [1, 2, 2, 3, 2],
    labels: ["A", "B", "C", "D", "E"],
    fill: {
      opacity: 1
    },
    stroke: {
      width: 1,
      colors: undefined
    },
    yaxis: {
      show: false
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0
        },
        spokes: {
          strokeWidth: 0
        }
      }
    },
    legend: {
      show: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return val + "%";
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: "light",
        shadeIntensity: 0.6
      }
    }
  };

  // this function to fetch poll-history data and this related call api
  const getViewAction = () => {
    dispatch(setLoading(true));
    onViewAPI(location?.state?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setData(res?.data);
        }
        dispatch(setLoading(false));
      })
      .catch((err: any) => {
        history.push(`/${projectNames.bvote}/poll-history`);
      });
  };

  // page render first to run this code
  // eslint-disable-next-line
  useEffect(() => getViewAction(), []);

  const makeBarChartImage = async () => {
    const barChart: any = document.getElementById("barChart");
    return new Promise((resolve) => {
      html2canvas(barChart, { allowTaint: true, useCORS: true }).then((canvas: any) => {
        const barChartImage = canvas.toDataURL();
        resolve(barChartImage);
      });
    });
  };

  const callAnalyticsAPI = (ejsData: any) => {
    HTTPService.postQR("bpoll/poll/analytics", ejsData, {
      responseType: "blob"
    })
      .then((res) => {
        setDownloadingPdf(false);
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ejsData.title);
        document.body.appendChild(link);
        link.click();
        link.remove();
        document.body.style.overflow = "unset";
      })
      .catch((err) => {
        document.body.style.overflow = "unset";
        setDownloadingPdf(false);
      });
  };

  const downloadMultiQuestionPdf = () => {
    setDownloadingPdf(true);
    // @ts-ignore
    const QRCodeForResultHashImage = document.getElementById("qr-gen").toDataURL();
    const ejsData = {
      title: data?.title,
      createdAt: moment(data?.createdAt).fromNow(),
      endDate: moment(data?.endDate).format("DD/MM/YYYY hh:mm A"),
      totalAnswer: data?.totalAnswer < 10 ? `0${data?.totalAnswer}` : data?.totalAnswer,
      questions: data?.questions,
      resultTxId: data?.resultTxId,
      QRCodeForResultHashImage,
      type: "multiple"
    };
    callAnalyticsAPI(ejsData);
  };

  const makePolarChartGraph = async () => {
    const polarChart: any = document.getElementById("polarChart");
    return new Promise((resolve) => {
      html2canvas(polarChart, { allowTaint: true, useCORS: true }).then((canvas) => {
        const polarChartImage = canvas.toDataURL();
        resolve(polarChartImage);
      });
    });
  };

  const downloadSingleQuestionPDF = async () => {
    setDownloadingPdf(true);
    const barChartImage = await makeBarChartImage();
    const polarGraphChartImage = await makePolarChartGraph();
    // @ts-ignore
    const QRCodeForResultHashImage = document.getElementById("qr-gen").toDataURL();
    const ejsData = {
      title: data?.title,
      createdAt: moment(data?.createdAt).fromNow(),
      endDate: moment(data?.endDate).format("DD/MM/YYYY hh:mm A"),
      totalAnswer: data?.totalAnswer < 10 ? `0${data?.totalAnswer}` : data?.totalAnswer,
      options: data?.options,
      resultTxId: data?.resultTxId,
      barChartImage,
      polarGraphChartImage,
      QRCodeForResultHashImage,
      type: "single"
    };
    callAnalyticsAPI(ejsData);
  };

  const onDownloadPdf = () => {
    if (data?.questions && data?.questions.length) {
      downloadMultiQuestionPdf();
    } else {
      downloadSingleQuestionPDF();
    }
  };

  // dowload QR image function
  const downloadQrCode = (qrValue: any, pollName: any) => {
    const canvas: any = document.getElementById("qr-gen");
    let context = canvas.getContext("2d");

    context.fillStyle = "blue";
    context.font = "bold 16px Arial";
    context.fillText({ qrValue }, canvas.width / 2 - 17, canvas.height / 2 + 8);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");

    downloadLink.href = pngUrl;
    downloadLink.download = `${pollName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // find what question is winning
  const findWinningVote = (optionsArray: any) => {
    let winningPercentage = 0;
    for (let option of optionsArray) {
      if (option.percentage > winningPercentage) {
        winningPercentage = option.percentage;
      }
    }
    let winningVote = optionsArray.find((el: any) => el.percentage === winningPercentage);
    return winningVote["text"];
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders" style={{ minHeight: "85vh" }}>
        <PageHeader module={module} />

        <div className="bg-white p-20 rounded-10">
          {/* poll history header part it's deisg only flexbox classes use to bootstrap  */}
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <div className="d-flex">
                <p className="poll-title">{data?.title}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="poll-text">Cretaed At : {moment(data?.createdAt).fromNow()}</p>
                <p className="poll-text">End Date : {moment(data?.endDate).format("DD-MM-YYYY | h:mm A")}</p>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end">
              <button className="poll-dowload-button" onClick={onDownloadPdf}>
                {downloadingPdf ? (
                  <>
                    <Spinner animation="border" role="status"></Spinner>
                    &nbsp;&nbsp; Generating PDF...
                  </>
                ) : (
                  "Download PDF"
                )}
              </button>
            </div>
          </div>

          {/* section how many vote ,QR code and hash */}
          <div className="d-flex justify-content-between">
            {/* number voter is submmited section */}
            <div style={{ width: "33%" }} className="poll-normal p-20 d-flex">
              <div className="m-auto">
                <h1 className="poll-title text-center"> {data?.totalAnswer}</h1>
                <p className="poll-sub-title">Total Vote Submited</p>
              </div>
            </div>

            {/* certificate of image and hash section */}
            <div style={{ width: "33%" }} className="poll-normal p-20 d-flex">
              <div className="m-auto">
                <p className="poll-sub-title">Poll Hash Code Here</p>
                <div className="text-center">
                  <img src={Verified} alt="logo" />
                </div>
                <p className="poll-text"> {data?.resultTxId}</p>
              </div>
            </div>

            {/* QR code and dowload of QR code section */}
            <div style={{ width: "33%" }} className="poll-normal p-20 d-flex">
              <div className="m-auto d-flex flex-column">
                <p className="poll-sub-title text-center" style={{ fontWeight: "400" }}>
                  Poll
                </p>
                <p className="poll-sub-title text-center">QR CODE</p>
                <p className="poll-sub-title text-center" style={{ fontWeight: "400" }}>
                  Here
                </p>
                <div className="d-flex mt-10">
                  <div className="m-auto poll-qr-dowload" onClick={() => downloadQrCode(data?.resultTxId, data?.title)}>
                    <i className="fas fa-download m-auto"></i>
                  </div>
                </div>
              </div>
              <div className="m-auto">
                {/* @ts-ignore */}
                <QRCodeGenerator value={`https://${window.location.hostname}/validate-hash/${data?.resultTxId}`} size={150} id="qr-gen" fgColor="#00519a" level={"H"} />
              </div>
            </div>
          </div>

          {data?.questions && data?.questions.length ? (
            <>
              {/* multiple question sections */}
              <div className="row poll-normal pb-20">
                <div className="col-12 p-20">
                  <h4 className="poll-sub-title">Mostly Submitted Answer</h4>
                </div>

                {data?.questions.map((question: any, index: number) => (
                  <div className="col-12 row py-15" key={index}>
                    <div className="col-sm-7 row">
                      <span className="col-1 poll-span"></span>
                      <p className="col-11 poll-text">{question.text}</p>
                    </div>
                    <div className="col-sm-5 text-right">
                      <button className="poll-span-button"> {findWinningVote(question.options)}</button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="row p-20">
                <div className="poll-option col-12 row">
                  <h4 className="col-12 text-white text-uppercase fw-normal font-26">poll candidate member</h4>
                  <div className="col-12 row mx-1 my-3">
                    {data &&
                      data?.options.map((option: any, index: number) => {
                        return (
                          <div key={option.id} className="col-3 row bg-white rounded-10 p-10 mr-30">
                            <div className="col-3">{option.image ? <img src={option.image} alt="profile" width="100%" /> : <h4 className="poll-sub-title">0{index + 1}</h4>}</div>
                            <div className="col-7">
                              <div className="poll-sub-title font-12">{option.text}</div>
                              <div className="poll-text fw-normal">{option.resultCount} Votes</div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/* chart section */}
              <div className="row">
                <div className="col-md-6 p-20">
                  <div className=" poll-normal" style={{ height: "500px" }}>
                    <div id="barChart">
                      <BarChart pollOptionData={data?.options} />
                    </div>
                    <h5 className="poll-sub-title text-center py-lg-5">Option Wise Total Voted</h5>
                  </div>
                </div>
                <div className="col-md-6 p-20">
                  <div className=" poll-normal" style={{ height: "500px" }}>
                    <PolarChart pollOptionData={data?.options} />
                    <h5 className="poll-sub-title text-center py-lg-5">Percentage Wise Total Voted</h5>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* footer section */}
          <div className="row justify-content-between poll-footer">
            <p className="col-sm-6">Copyright ©{new Date().getFullYear()} by Edexa | All rights Reserved</p>
            <p className="col-sm-6 text-sm-right">Verified By edexa.com</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewPollHistory;
