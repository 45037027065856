import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { onListingAPI } from "../../../actions/bvote/manageGroupsAction";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig, projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import { CreateRestricted } from "../../common/restrictions";
import BvoteTableComponent from "./common/ListTable";
import SearchFilter from "./component/SearchFilter";

const tableHeaders = ["Group Name", "Voter Count", CREATEDATE, "Status", "Actions"];

const ManageGroups: FC = () => {
  const module = "Manage Groups";
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listingData, setListingData] = useState([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);

  const getBvoteManageGroupsList = () => {
    dispatch(setLoading(true));
    onListingAPI(paginationConfig).then((res: any) => {
      if (res?.status === 200) {
        setTotalListCount(res?.data?.count);
        setListingData(res?.data?.rows);
      }
      dispatch(setLoading(false));
    });
  };
  const resetFilters = () => {
    if (search === "" && paginationConfig.search === "") {
    } else {
      setSearch("");
      setPaginationConfig(initialPaginationConfig);
    }
  };

  const handleRedirect = () => {
    history.push(`/${projectNames.bvote}/manage-groups/add`);
  };

  useEffect(() => {
    getBvoteManageGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex flex-wrap justify-content-between mb-20">
                <div className="d-flex flex-wrap">
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.bvote].MANAGE_GROUPS}>
                  <button className="btn btn-primary bg-blue border-0 shadow-none  ml-10 button-width-com" onClick={handleRedirect}>
                    Create
                  </button>
                </CreateRestricted>
              </div>
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"groups"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getBvoteManageGroupsList}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ManageGroups;
