import { FC, useEffect, useState } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import { getBase64 } from "../../../../../common/common-functions";
const edexaLogo = "https://michiganhr.org/wp-content/uploads/2015/01/insert-logo-375x360.png";
const edexaSmallLogo = "https://michiganhr.org/wp-content/uploads/2015/01/insert-logo-375x360.png";
interface ISetImageComponent {
  data: any;
  setFiledValue: any;
  errors?: any;
  isError: boolean;
}

const SetImageComponent: FC<ISetImageComponent> = ({ data, setFiledValue, errors, isError }) => {
  const [images, setImages] = useState({
    logoIconBig: "",
    logoIcon: ""
  });
  const [loading, setLoading] = useState({
    logoIconBig: false,
    logoIcon: false
  });
  const handleImages = (e: any) => {
    let id = e.target.id;
    let file = e.target.files[0];
    setLoading({ ...loading, [id]: true });
    getBase64(file).then((res: any) => {
      setImages({ ...images, [id]: res });
      setLoading({ ...loading, [id]: false });
      setFiledValue([id], file);
    });
  };

  const handleError = (e: any) => {
    let id = e.target.alt;
    setImages({ ...images, [id]: "" });
    setLoading({ ...loading, [id]: false });
  };

  const handleLoad = (e: any) => {
    let id = e.target.atl;
    setLoading({ ...loading, [id]: false });
  };

  const handleIm = () => {
    let imagesObj = { logoIconBig: "", logoIcon: "" };
    if (typeof data?.logoIconBig === "string") {
      imagesObj.logoIconBig = data?.logoIconBig;
    } else {
      imagesObj.logoIconBig = "";
    }
    if (typeof data?.logoIcon === "string") {
      imagesObj.logoIcon = data?.logoIcon;
    } else {
      imagesObj.logoIcon = "";
    }
    setImages(imagesObj);
  };

  useEffect(() => {
    handleIm();
    // eslint-disable-next-line
  }, [data]);

  return (
    <Row className="mt-3">
      <Col md={6}>
        <Label for="logoIconBig" className="d-flex flex-column position-relative">
          {loading.logoIconBig ? <Spinner className="text-white position-absolute" style={{ top: "50%", left: "44%" }} /> : null}
          <h6 className="fw-600">
            Upload Logo (Bit bigger size) <span className="text-red">*</span>
          </h6>
          {images.logoIconBig && (
            <img
              style={{ objectFit: "cover", border: "1px solid #ced4da" }}
              className="mt-1 rounded-10"
              src={images.logoIconBig ? images.logoIconBig : edexaLogo}
              alt="logoIconBig"
              height={250}
              width={"100%"}
              onError={handleError}
              onLoad={handleLoad}
            />
          )}
        </Label>
        <Input className="mt-1" style={{ display: images.logoIconBig ? "none" : "" }} onChange={handleImages} id="logoIconBig" placeholder="Subtitle" type="file" accept=".png, .svg, .jpg, .gif" />
        {isError ? <p className="text-danger">{errors?.logoIconBig}</p> : null}
      </Col>
      <Col md={6}>
        <Label for="logoIcon" className="d-flex flex-column position-relative">
          {loading.logoIcon ? <Spinner className="text-white position-absolute" style={{ top: "50%", left: "44%" }} /> : null}
          <h6 className="fw-600">
            Upload Logo (Small size)<span className="text-red">*</span>
          </h6>
          {images.logoIcon && (
            <img
              style={{ objectFit: "cover", border: "1px solid #ced4da" }}
              className="mt-1 rounded-10"
              src={images.logoIcon ? images.logoIcon : edexaSmallLogo}
              alt="logoIcon"
              height={100}
              width={"50%"}
              onError={handleError}
              onLoad={handleLoad}
            />
          )}
        </Label>
        <Input className="mt-1" onChange={handleImages} style={{ display: images.logoIcon ? "none" : "" }} id="logoIcon" placeholder="Subtitle" type="file" accept=".png, .svg, .jpg, .gif" />
        {isError ? <p className="text-danger">{errors?.logoIcon}</p> : null}
      </Col>
    </Row>
  );
};

export default SetImageComponent;
