import { APP_SHORTCUT_ID } from "./type";

const initialState = {
  idOfSpecificAppShortcut: 0
};

const appShortCutReducer = (state = initialState, action: { type: string; payload: number }) => {
  const newState = { ...state };
  switch (action.type) {
    case APP_SHORTCUT_ID:
      newState.idOfSpecificAppShortcut = action.payload;
      break;
  }
  return newState;
};

export { appShortCutReducer };

export interface IidOfSpecificAppShortcut {
  idOfSpecificAppShortcut: number | null;
}
