import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getAPIList } from "../../../actions/portal/adminActions";
import MetaFunction from "../../../common/MetaTag";
import { CREATEDATE, initialPaginationConfig } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import SearchFilter from "../banji/component/SearchFilter";
import { AdminTableComponent } from "./common/TableComponent";

const ApiManagement = () => {
  const dispatch = useDispatch();
  const moduleName = "API Management";
  const tableHeaders = ["API Name", "Beta Url", "Live Url", "Available", CREATEDATE, "Actions"];

  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);
  const [totalListCount, setTotalListCount] = useState(null);
  const [listingData, setListingData] = useState([]);
  const { filter, search, page, limit } = paginationConfig;

  const getAPIListing = () => {
    dispatch(setLoading(true));
    getAPIList(
      filter && search && page && limit
        ? paginationConfig
        : !filter && !search && page && limit
        ? { limit, page }
        : filter && !search && page && limit
        ? { limit, page, filter }
        : !filter && search && page && limit && { limit, page, search }
    ).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res?.data?.count);
        setListingData(res?.data?.rows);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getAPIListing();
    // eslint-disable-next-line
  }, [paginationConfig]);
  const [searchField, setSearchField] = useState("");
  const resetFilters = () => {
    if (searchField === "" && paginationConfig.search === "") {
    } else {
      setSearchField("");
      setPaginationConfig((prev) => ({ ...prev, filter: "", search: "" }));
    }
  };
  return (
    <>
      <MetaFunction meta={moduleName} />
      <div className="holders" style={{ overflow: "auto" }}>
        <p className="main-title m-b-20">{moduleName}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex  flex-wrap">
                {/**
                 * @Table @Filters
                 */}
                <div className="filters align-self-center d-flex flex-xxl-nowrap flex-wrap w-100 w-sm-auto mb-20">
                  <SearchFilter placeHolder="Search By API Name" paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={searchField} setSearch={setSearchField} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com  " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
              </div>
              <AdminTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={moduleName}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ApiManagement;
