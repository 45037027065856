import { bvoteURL } from "../../../../common/api-endpoints";
import HTTPService from "../../../../common/httpservice";

export const getGloableSetting = () => {
  return HTTPService.get(bvoteURL.bVoteAdmin.getbVoteUndermain);
};

export const updateGloableSetting = (body: any) => {
  return HTTPService.put(bvoteURL.bVoteAdmin.updatebVoteUndermain, body);
};
