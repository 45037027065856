import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select, { SingleValue } from "react-select";
import { Col, Row } from "reactstrap";
import { onListingAPI } from "../../../actions/bvote/pollAction";
import MetaFunction from "../../../common/MetaTag";
import { ReactSelectCustomstyle, initialPaginationConfig, projectModules, projectNames } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import Footer from "../../common/Footer";
import { CreateRestricted } from "../../common/restrictions";
import BvoteTableComponent from "./common/ListTable";
import SearchFilter from "./component/SearchFilter";

const UserTypeOptions = [
  { value: "", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "running", label: "Running" },
  { value: "upcoming", label: "Upcoming" }
];

const tableHeaders = ["Poll Title", "Voting Date | Time", "Show Live Result", "Poll Link", "Poll Result Link", "Download Hashes", "Status", "Action"];
// customized pagiantion configs
const customerPaginationConfig = {
  ...initialPaginationConfig,
  status: ""
};
// delete extra parameter
// delete customerPaginationConfig.filter;

const PollListing: FC = () => {
  const module = "Poll";
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>("");
  const [listingData, setListingData] = useState([]);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [userTypeFilterValue, setUserTypeFilterValue] = useState<SingleValue<{ value: string; label: string }>>({ value: "", label: "All" });
  const [paginationConfig, setPaginationConfig] = useState(customerPaginationConfig);

  /**
   * @api_calling
   */
  const getBvotePollAction = () => {
    dispatch(setLoading(true));
    onListingAPI(paginationConfig).then((res: any) => {
      dispatch(setLoading(false));
      if (res?.status === 200) {
        setTotalListCount(res?.data?.count);
        setListingData(res?.data?.rows);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getBvotePollAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const filterStatus = (value: any) => {
    setPaginationConfig({
      ...paginationConfig,
      status: value
    });
  };

  const resetFilters = () => {
    let filter = { value: "", label: "All" };
    if (search === "" && paginationConfig.search === "" && JSON.stringify(userTypeFilterValue) === JSON.stringify(filter)) {
    } else {
      setUserTypeFilterValue(filter);
      setSearch("");
      setPaginationConfig(customerPaginationConfig);
    }
  };

  const handleRedirect = () => {
    history.push(`/${projectNames.bvote}/poll/add`);
  };

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex justify-content-between flex-wrap mb-20">
                <div className="d-flex flex-wrap role-select">
                  <Select
                    placeholder="Status"
                    onChange={(e: any) => {
                      filterStatus(e?.value);
                      setUserTypeFilterValue(e);
                    }}
                    options={UserTypeOptions}
                    className="me-2 z-index"
                    styles={ReactSelectCustomstyle}
                    value={userTypeFilterValue}
                  />
                  <SearchFilter paginationConfig={paginationConfig} setPaginationConfig={setPaginationConfig} search={search} setSearch={setSearch} />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com   " onClick={() => resetFilters()}>
                    Clear
                  </button>
                </div>
                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.bvote]?.POLL}>
                  <button className="btn btn-primary bg-blue border-0 shadow-none  button-width-com" onClick={handleRedirect}>
                    Create
                  </button>
                </CreateRestricted>
              </div>
              <BvoteTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={"poll"}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                recall={getBvotePollAction}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default PollListing;
