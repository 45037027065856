import { FC, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Col, Input, Label, Row, Table, Tooltip } from "reactstrap";
import { getChannelDetail, updateChannelDetail } from "../../../../actions/portal/adminActions";
import "../../../../assets/css/universe-detail-page.css";
import MetaFunction from "../../../../common/MetaTag";
import BackArrowComponent from "../../../../common/backArrow";
import { getFormattedDate, handleAppActionPermission, nameBeforeAtTheRateInEmail, toastSuccess } from "../../../../common/common-functions";
import { CREATEDATE, projectModules, projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import useHandleUNiversalStatusChange from "../../../../utils/hooks/useHandleUNiversalStatusChange";
import CustomTooltip from "../../../common/ToolTip";
import "../../component/kycDetails.css";
import UniverseRequestStatusConfirmationPopup from '../../component/UniverseRequestStatusConfirmationPopup';

export const ChannelDetailComponent = () => {
  const params: any = useParams();
  const { callApi } = useHandleUNiversalStatusChange();

  const history = useHistory();
  const dispatch = useDispatch();
  const module = "Invitations";

  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);

  const isLoadingState = useSelector((state: any) => state?.loading?.isLoading);
  // eslint-disable-next-line
  const [tableHeaders, setTableHeaders] = useState([
    "Id",
    CREATEDATE,
    "Status",
    "Actions",
  ]);
  // eslint-disable-next-line
  const [participantListHeaders, setParticipantListHeaders] = useState([
    "Name",
    "Email",
  ]);

  const [backToolTipStatus, setBackToolTipStatus] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState();

  const [statusModal, setStatusModal] = useState(false);
  const [statusModalLoader, setStatusModalLoader] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [invitationsList, setInvitationsList] = useState();
  const [participantsList, setParticipantsList] = useState();
  // eslint-disable-next-line
  const [invitationListLength, setInvitationListLength] = useState();

  const [channelDetails, setChannelDetails] = useState<any>({
    channelName: "",
    createdAt: "",
    deletedAt: null,
    id: "",
    owner: "",
    status: 0,
    updatedAt: ""
  });

  interface IColumnComponent {
    title?: any;
    value?: any;
    color?: any;
    capitalize?: any;
  }

  const ColumnComponent: FC<IColumnComponent> = ({ title, value, color, capitalize }) => {
    return (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20">
          <div className="info-name col-2">
            <p className="font-13 color-black2 fw-bold fw-normal text-upper">{title}</p>
          </div>
          <div className="col-9">
            <p className={`color-black2 font-14  info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
              {value}
            </p>
          </div>
        </div>{" "}
      </>
    );
  };

  interface IStatusComponent {
    capitalize?: any;
    color?: any;
  }
  const StatusComponent: FC<IStatusComponent> = ({ capitalize, color }) => {
    return (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20">
          <div className="info-name col-2">
            <p className="font-14 fw-bold color-black2 fw-normal text-upper">Status</p>
          </div>
          <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: getKyCStatus(channelDetails?.status).color,
                width: 10,
                height: 10,
                borderRadius: 5,
                marginRight: 5
              }}></div>
            <p className={`color-black2 font-14 info-text ${capitalize ? "text-capitalize" : ""}`} style={{ color: color }}>
              {getKyCStatus(channelDetails?.status).label}
            </p>
          </div>
        </div>
      </>
    );
  };

  const toggleStatusModel = () => setStatusModal(!statusModal);

  useEffect(() => {
    if (currentSelectedStatus !== "2") {
    }
  }, [currentSelectedStatus]);

  const handleStatusChange = (status: any) => {
    setCurrentSelectedStatus(status);
    toggleStatusModel();
  };

  const DropDownComponent = () => {
    function decideAction(status: any) {
      if (status === 0 || status === 3) {
        return [
          { label: "Reject", status: 2 },
          { label: "Approved", status: 1 }
        ];
      } else {
        return null;
      }
    }

    let statusArray = decideAction(channelDetails.status);

    return statusArray && statusArray.length > 0 ? (
      <>
        <hr className="m-0" style={{ height: "0.5px" }} />
        <div className="row p-20" style={{ alignItems: "center" }}>
          <div className="info-name col-2">
            <p className="font-14 fw-bold color-black2 fw-normal text-upper">Change Status</p>
          </div>
          <div className="col-6 row align-items-center">
            {statusArray.map((option, index) => {
              return (
                <Label key={index} role="button" className="status-radio-buttons" style={{ marginRight: "10px" }}>
                  <Input
                    type="radio"
                    disabled={!currentPermission?.Universe?.channelmanagement?.update}
                    value={option.status}
                    name="status"
                    className="me-2"
                    onChange={(e: any) => {
                      handleStatusChange(e.target.value);
                    }}
                    checked={channelDetails.status === option.status}
                  />
                  <CustomTooltip
                    direction="bottom"
                    content={option?.label === "Reject" ? "Current request of the channel will get rejected" : option?.label === "Approved" && "Current request of the channel will get approved"}>
                    {option?.label}
                  </CustomTooltip>
                </Label>
              );
            })}
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  const getKyCStatus = (statusCode: any) => {
    let result: any = {
      label: "",
      class: ""
    }; // eslint-disable-next-line
    switch (statusCode) {
      case 3:
        result = {
          label: "In-progress",
          class: "pending",
          color: "#eaac20"
        };
        break;
      case 1:
        result = {
          label: "Approved",
          class: "active",
          color: "#28c76f"
        };
        break;
      case 2:
        result = {
          label: "Rejected",
          class: "deactive",
          color: "#ea2027"
        };
        break;

      default:
        result = {
          label: "In-progress",
          class: "pending",
          color: "#eaac20"
        };
        break;
    }
    return result;
  };

  const handleUniversalListStatusChange = (id: any, status: any, reason: any) => {
    // change status of universal records from here.  Reason field is optional, only applied while rejecting a request with some reason
    dispatch(setLoading(true));
    setStatusModalLoader(true);
    setStatusModal(false);
    updateChannelDetail({ id, status, ...(reason && { reason }) }).then((res) => {
      if (res.status === 200) {
        dispatch(setLoading(false));
        setStatusModalLoader(false);
        setUpdateDetails(!updateDetails);
        toastSuccess(res.message);
      }
    });
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoading(true));
      getChannelDetail(params.id)
        .then((response) => {
          if (response.status === 200) {
            setChannelDetails(response?.data);
            setInvitationsList(response?.data?.channelInvitation);
            setParticipantsList(response?.data?.channelsId);
            setInvitationListLength(response?.data?.channelInvitation.length);
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          history.push(`/${projectNames.universe}/channel-listing`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateDetails]);

  // @ts-ignore
  const renderView = (list, i) => {
    return (
      <tr key={i}>
        <td className="font-16 color-light-grey">{`${list?.id}`}</td>

        <td className="font-16 color-light-grey">{`${list?.createdAt && getFormattedDate(list?.createdAt)}`}</td>
        <td className="font-16 color-light-grey pl-35 position-relative status-data">
          <span className={`status ${getKyCStatus(list.status).class}`}></span>
          {getKyCStatus(list.status).label}
        </td>
        <td className="font-14 blue" style={{ cursor: "pointer", fontWeight: "700" }}>
          <i
            className="fas fa-eye color-blue cursor-pointer"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Edit admin"
            onClick={() => history.push(`/${projectNames.universe}channel-invitations/${list.id}`)}
            style={{ marginRight: "10px" }}></i>
          {list.status !== 1 && <i className="fa fa-check-circle color-blue cursor-pointer" data-toggle="tooltip" data-placement="bottom" title="Approve" onClick={() => callApi(list.id, 1)}></i>}
        </td>
      </tr>
    );
  };

  const getParticipantsList = (list: any, i: number) => {
    return (
      <tr key={i}>
        <td className="font-16 color-light-grey">{list?.name || nameBeforeAtTheRateInEmail(list?.email)}</td>
        <td className="font-16 color-light-grey">{`${list?.email}`}</td>
      </tr>
    );
  };

  return (
    <>
      <MetaFunction meta="Channel Details" />
      <div className="holders" style={{ overflow: "auto" }}>
        <div className="row justify-content-between">
          <div className="col-12 kycDetailsTitleDiv">
            <p className="main-title kycDetailPageTitle">Channel Details</p>
            {/* @ts-ignore */}
            <Tooltip isOpen={backToolTipStatus} target={"backButton"} placement="top" toggle={() => setBackToolTipStatus(!backToolTipStatus)}>
              Back
            </Tooltip>
            <button id="backButton" onClick={() => history.goBack()} style={{ backgroundColor: "#f8f8f8" }}>
              <BackArrowComponent />
            </button>
          </div>
        </div>
        {Object.keys(channelDetails).length > 0 && (
          // @ts-ignore
          <Row className="mt-10">
            {/* @ts-ignore */}
            <Col sm="12" lg="12">
              <div className="kyc-box rounded-10">
                <div className="p-20">
                  <p className="sub-title m-b-5">Channel Information</p>
                </div>
                {isLoadingState ? (
                  <ColumnComponent title="Loading..." value="" />
                ) : (
                  <>
                    <ColumnComponent title="Channel Name" value={channelDetails?.channelName} />
                    <ColumnComponent title="Channel Owner" value={channelDetails?.channelOwner?.name || nameBeforeAtTheRateInEmail(channelDetails?.channelOwner?.email || "")} />
                    <ColumnComponent title={CREATEDATE} value={channelDetails?.createdAt ? getFormattedDate(channelDetails?.createdAt) : "-"} />
                    <ColumnComponent title="Updated Date | Time" value={channelDetails?.updatedAt ? getFormattedDate(channelDetails?.updatedAt) : "-"} />
                    <StatusComponent />

                    {/*  @ts-ignore */}
                    {handleAppActionPermission(projectNames.universe, projectModules[projectNames.universe].CHANNEL_MANAGEMENT, "update") === false && <DropDownComponent />}
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}

        {channelDetails?.channelsId?.length > 0 ? (
          <>
            <p className="sub-title my-4">Participants</p>

            <Scrollbars
              autoHide
              style={{ height: 300, marginTop: "10px" }}
              renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
              renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
              className="table-section border-grey bvote-table">
              <Table>
                <thead>
                  <tr>
                    {participantListHeaders.map((header, index) => {
                      return (
                        <th key={index} className="color-black1 font-14 fw-bold">
                          {header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {/* @ts-ignore */}
                  {participantsList?.map((list, i) => {
                    return getParticipantsList(list, i);
                  })}
                </tbody>
              </Table>
            </Scrollbars>
          </>
        ) : (
          <p
            style={{
              textAlign: "center",
              marginTop: "3%",
              color: "hsl(0deg 0% 80%)"
            }}>
            No Participants
          </p>
        )}

        {channelDetails?.channelInvitation?.length > 0 ? (
          <>
            <p className="sub-title my-4">{module}</p>

            <Scrollbars
              autoHide
              style={{ height: 300, marginTop: "10px" }}
              renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal vertical" />}
              renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
              className="table-section border-grey bvote-table">
              <Table>
                <thead>
                  <tr>
                    {tableHeaders.map((header, index) => {
                      return (
                        <th key={index} className="color-black1 font-14 fw-bold">
                          {header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {/* @ts-ignore */}
                  {invitationsList?.map((list, i) => {
                    return renderView(list, i);
                  })}
                </tbody>
              </Table>
            </Scrollbars>
          </>
        ) : (
          <p
            style={{
              textAlign: "center",
              marginTop: "3%",
              color: "hsl(0deg 0% 80%)"
            }}>
            No Invitations
          </p>
        )}

        <UniverseRequestStatusConfirmationPopup
          isVisible={statusModal}
          loader={statusModalLoader}
          handleConfirmation={handleUniversalListStatusChange}
          currentSelectedStatus={currentSelectedStatus}
          requestId={channelDetails?.id?.toString()}
          toggleStatusModel={toggleStatusModel}
          status={currentSelectedStatus === "1" ? "Completed" : "Rejected"}
        />
      </div>
    </>
  );
};
