import { projectModules, projectNames } from "../../common/constants";
import BYouLabelManagement from "../../pages/main/byou/byouLabelManagement/ByouLabelmanagement";
import CreateIssuer from "../../pages/main/byou/CreateIssuer";
import EditUserManagement from "../../pages/main/byou/EditUserManagement";
import GlobalSettingsOfByou from "../../pages/main/byou/GlobalSettings";
import UserManagement from "../../pages/main/byou/UserManagement";
import ProfileDetails from "../../pages/main/component/profile/ProfileDetails";
import Notification from "../../pages/main/Notification";

export const bYouPrivateRoutes = [
  {
    path: `/${projectNames.byou}/user-management`,
    component: UserManagement,
    module: projectModules[projectNames.byou].USER_MANAGEMENT
  },
  {
    path: `/${projectNames.byou}/user-edit/:id`,
    component: EditUserManagement,
    module: projectModules[projectNames.byou].USER_MANAGEMENT
  },
  {
    path: `/${projectNames.byou}/global-settings`,
    component: GlobalSettingsOfByou,
    module: projectModules[projectNames.byou].USER_MANAGEMENT
  },
  {
    path: `/${projectNames.byou}/label-management`,
    component: BYouLabelManagement,
    module: projectModules[projectNames.byou].USER_MANAGEMENT
  },
  {
    path: `/${projectNames.byou}/create-issuer`,
    component: CreateIssuer,
    module: projectModules[projectNames.byou].USER_MANAGEMENT
  },
  {
    path: "/profile",
    component: ProfileDetails
  },
  {
    path: "/notification",
    component: Notification
  }
];
export default bYouPrivateRoutes;
