import { Field } from "formik";
import { Input, Label } from "reactstrap";
import { handleTrim } from "../../../../../common/common-functions";

const FieldComponent = ({ name, label, disabled, ...rest }: any) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <div className="col-md-6 mb-3">
          <Label htmlFor={name} className="fw-normal color-light-grey py-2">
            {label}
          </Label>
          <Input
            type="text"
            id={name}
            {...rest}
            disabled={disabled}
            {...field}
            onChange={(e) => handleTrim(e.target.name, e.target.value, form.setFieldValue)}
            error={form.errors[name] && form.touched[name]}
          />
          <span className="font-14 text-danger"> {form.touched[name] ? form.errors[name] : null}</span>
        </div>
      )}
    </Field>
  );
};

export default FieldComponent;
