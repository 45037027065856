import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const createCategoryAPI = (data: IcreateCategoryAPIParsms): Promise<IcreateCategoryAPI> => {
  return HTTPService.post(StakingPortalApiEndPoint.category.create, data);
};
interface IcreateCategoryAPIParsms {
  name: string;
  visible?: boolean;
}

export interface IcreateCategoryAPIData {}

interface IcreateCategoryAPI {
  message: string;
  status: number;
  data: IcreateCategoryAPIData;
}
