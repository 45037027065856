/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getbstampOrganizationList, getbstampUserList } from "../../../../actions/bStamp/getbstampUserListAPI";
import MetaFunction from "../../../../common/MetaTag";
import { ReactSelectCustomstyle, initialPaginationConfig } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import Footer from "../../../common/Footer";
import { AdminTableComponent } from "../../admin/common/TableComponent";
import SearchFilter from "../../banji/component/SearchFilter";

const moduleName = "User Management";
const initialFIlters = {
  ...initialPaginationConfig
};
const BStampUserManagement = () => {
  const [paginationConfig, setPaginationConfig] = useState(initialFIlters);
  const [searchField, setSearchField] = useState("");
  const [listingData, setListingData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState(["User Id", "Name", "Organization", "Wallet Address"]);
  const [profile, setProfile] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(null);

  const dispatch = useDispatch();

  const clearFilters = () => {
    if (searchField.trim() === "" && paginationConfig?.search?.trim() === "" && profile?.label === "") {
    } else {
      setSearchField("");
      setProfile([]);
      setPaginationConfig(initialFIlters);
    }
  };

  const getUserListing = () => {
    dispatch(setLoading(true));
    const updatedPaginationConfig = {
      ...paginationConfig,
      search: paginationConfig.search.trim() !== "" ? paginationConfig.search.trim() : undefined,
      filter: paginationConfig?.filter !== "" ? paginationConfig?.filter : undefined,
      organization: profile?.value !== "" ? profile?.value : undefined
    };
    getbstampUserList(updatedPaginationConfig).then((res) => {
      dispatch(setLoading(false));
      const { data, count } = res.data;
      setTotalListCount(count);
      setListingData(data);
    });
  };

  const getOrganizationListing = () => {
    dispatch(setLoading(true));
    const updatedPaginationConfig = {
      ...paginationConfig,
      limit: 20,
      page: 1,
      search: profile?.label !== "" ? profile?.label : undefined,
      filter: undefined
    };
    getbstampOrganizationList(updatedPaginationConfig).then((res) => {
      dispatch(setLoading(false));
      const newOption = res.data.data.map((data: any) => {
        return {
          label: data.email,
          value: data.clientId
        };
      });
      setOptions(newOption);
    });
  };

  useEffect(() => {
    getUserListing();
  }, [paginationConfig, profile]);

  useEffect(() => {
    getOrganizationListing();
  }, []);

  return (
    <>
      <MetaFunction meta="User Management" />
      <div className="holders" style={{ overflow: "auto" }}>
        <p className="main-title m-b-20">{moduleName}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex flex-wrap mb-20">
                <div className="filters align-self-center d-flex flex-xxl-nowrap flex-wrap w-100 w-sm-auto role-select">
                  <Select
                    placeholder="Organization"
                    options={options}
                    value={profile}
                    onChange={(data) => {
                      setProfile(data);
                    }}
                    className="me-2 z-index"
                    styles={ReactSelectCustomstyle}
                  />
                  <SearchFilter
                    placeHolder="Search By Wallet Address"
                    paginationConfig={paginationConfig}
                    setPaginationConfig={setPaginationConfig}
                    search={searchField.trim()}
                    setSearch={setSearchField}
                  />
                  {/**
                   * @clear_filters
                   * @button
                   */}
                  <button className="btn border-blue  ml-10 button-width-com" onClick={() => clearFilters()}>
                    Clear
                  </button>
                </div>
              </div>
              <AdminTableComponent
                listData={listingData}
                totalCount={totalListCount}
                tableHeaders={tableHeaders}
                type={moduleName}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default BStampUserManagement;
