import { bTrackURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const UpdateNFTLabelAPI = (body: any): Promise<IUpdateNFTLabelResponse> => {
  return HTTPService.put(bTrackURL.label.update, body);
};

export interface IUpdateNFTLabelResponse {
  status: number;
  message: string;
}
