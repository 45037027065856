import { bStampURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getBstampDashboardAction = (): Promise<IDashBoardRes> => {
  return HTTPService.get(bStampURL.dashboard);
};

interface IDashBoardRes {
  status: number;
  message: string;
  data: {
    signedStamps: number;
    totalPayment: number;
    unsignedStamps: number;
  };
}
