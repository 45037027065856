import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getCategoryManagementAPI = (params: IgetCategoryManagementAPI): Promise<IgetCategoryManagementRES> => {
  return HTTPService.get(nftURL.getCategoryList, { params });
};

export interface IgetCategoryManagementAPI {
  id?: string;
  page: number;
  limit: number;
}

export interface IgetCategoryManagementRESData {
  fullName: string;
  phoneNumber: string;
  description: string;
  status: number;
  reference: string;
  remarks: string;
  _id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export interface IgetCategoryManagementRES {
  status: number;
  message: string;
  count: number;
  data: IgetCategoryManagementRESData[];
}
