import { bvoteURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

const { manageVoters } = bvoteURL;

const { listing, list, add, view, deleteURL, update } = manageVoters;

// voter listing api
export const onListingAPI = (params: any) => {
  return HTTPService.get(listing, {
    params
  });
};

// voter add api
export const onAddAPI = (body: any) => {
  return HTTPService.post(add, body);
};

// voter view api
export const onViewVoterAPI = (params: any) => {
  return HTTPService.get(view + params);
};

// voter update api
export const onUpdateAPI = (params: any, body: any) => {
  return HTTPService.put(update + params, body);
};

// voter delete api
export const onDeleteVoterAPI = (body: any) => {
  return HTTPService.post(deleteURL, body);
};

export const onListVoterAPI = () => {
  return HTTPService.get(list);
};
