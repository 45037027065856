import { bStampURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const UpdatebStampGlobalSettingAPI = (body: any): Promise<IUpdatebStampGlobalSettingResponse> => {
  return HTTPService.put(bStampURL.updateGlobalSetting, body);
};

export interface IUpdateNftGlobalSetting {
  underMaintenance?: number;
}

export interface IUpdatebStampGlobalSettingResponse {
  status: number;
  message: string;
}
