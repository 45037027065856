import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { IGetLabelObject } from "../../../../actions/nft/globalSetting/getNFTLabelAPI";
import { getlabelListing, updatebYouLabel } from "../../../../actions/portal/byouActions";
import MetaFunction from "../../../../common/MetaTag";
import { handleAppActionPermission, toastSuccess } from "../../../../common/common-functions";
import { addLabelNote, projectModules, projectNames, validationMessages } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import { CreateRestricted } from "../../../common/restrictions";
import InputLabelField from "../../nft/labelManagement/InputLabelField";

const BYouLabelManagement = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [labelResponse, setLabelResponse] = useState<IGetLabelObject>();
  const [newLabelFields, setNewLabelFields] = useState({
    labelKey: "",
    englishFieldValue: "",
    germanFieldValue: "",
    addFields: false
  });

  const formik = useFormik({
    initialValues: {
      language: "EN",
      DE: {},
      EN: {},
      disable: "true",
      _id: ""
    },
    validationSchema: Yup.object().shape({
      DE: Yup.object().required(validationMessages.firstName.required),
      EN: Yup.object().required(validationMessages.firstName.required)
    }),
    onSubmit: (values: any) => {
      dispatch(setLoading(true));
      let paramater: any = {};
      paramater.EN = values.EN;
      paramater.DE = values.DE;
      updatebYouLabel(paramater).then((res: any) => {
        if (res?.code === 200 || res?.status === 200) {
          toastSuccess(res?.message);
          // @ts-ignore
          setLabelResponse({ ...labelResponse, DE: values.DE, EN: values.EN });
        }
        dispatch(setLoading(false));
      });
    }
  });
  const { values, setFieldValue, handleSubmit } = formik;
  // eslint-disable-next-line
  const getLabelAction = () => {
    dispatch(setLoading(true));
    getlabelListing().then((res: any) => {
      if (res?.code === 200 || res?.status === 200) {
        setLabelResponse(res.data);
        setFieldValue("_id", res?.data?._id);
        setFieldValue("EN", res?.data?.EN);
        setFieldValue("DE", res?.data?.DE);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    getLabelAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeOut = () => {
    setSearch("");
  };

  const handleAddNewLabel = () => {
    let parameter: any = {};
    parameter.EN = values?.EN;
    parameter.DE = values?.DE;
    parameter.EN[newLabelFields?.labelKey] = newLabelFields?.englishFieldValue.trim();
    parameter.DE[newLabelFields?.labelKey] = newLabelFields?.germanFieldValue.trim();
    setNewLabelFields({
      ...newLabelFields,
      englishFieldValue: "",
      germanFieldValue: "",
      labelKey: ""
    });
    setSearch(newLabelFields.labelKey);
    setTimeout(timeOut, 3000);
    updatebYouLabel(parameter).then((res: any) => {
      debugger;
      if (res?.status === 200) {
        toastSuccess(res?.message);
      }
    });
  };

  const [state, setState] = useState([""]);
  const data = Object.keys(values.DE);

  useEffect(() => {
    let searchedValues = Object.keys(values.DE)
      .filter((key) => {
        if (search === "") {
          return key;
          // If key is matched with the search, then we return only that specific key which has been matched
        } else if (key.toLowerCase().includes(search.toLowerCase())) {
          return key;
        }
        return false;
      })
      .sort();
    setState(searchedValues);
  }, [search, values.DE]);
  return (
    <>
      <MetaFunction meta="Label Management" />
      <div className="holders">
        <p className="main-title m-b-20">Label Management</p>
        <div className="p-20 rounded-10 bg-white">
          {labelResponse && (
            <>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex flex-wrap">
                  <Input
                    type="text"
                    placeholder="Search By Label Key"
                    className="me-2 w-sm-250px w-100 font-14 search-input mb-sm-0 mb-20 input-hieght"
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setSearch(e.target.value);
                      } else {
                        if (search !== "") {
                          setSearch("");
                        }
                      }
                    }}
                    value={search}
                  />
                  <button className="btn border-blue  ml-10 button-width-com" onClick={() => setSearch("")}>
                    Clear
                  </button>
                </div>

                {/* @ts-ignore */}
                <CreateRestricted module={projectModules[projectNames.byou]?.LABEL_MANAGEMENT}>
                  <button
                    type="button"
                    disabled={Boolean(false)}
                    className="btn btn-primary bg-blue shadow-none button-width-com"
                    onClick={() =>
                      setNewLabelFields({
                        ...newLabelFields,
                        addFields: !newLabelFields.addFields
                      })
                    }>
                    Add Label
                  </button>
                </CreateRestricted>
              </div>
              <div className="col-12 mt-10 mb-10">
                {newLabelFields.addFields && (
                  <>
                    <div className="col-12 test">
                      <div className="row">
                        <div className="col-4">
                          <Input
                            type="text"
                            placeholder="Label Key"
                            value={newLabelFields?.labelKey}
                            onChange={(e) => {
                              if (e.target.value.trim()) {
                                setNewLabelFields({
                                  ...newLabelFields,
                                  labelKey: e.target.value.trim().toLowerCase()
                                });
                              } else {
                                if (newLabelFields?.labelKey !== "") {
                                  setNewLabelFields({
                                    ...newLabelFields,
                                    labelKey: ""
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <Input
                            type="text"
                            placeholder="English Label"
                            value={newLabelFields?.englishFieldValue}
                            onChange={(e) => {
                              if (e.target.value.trim()) {
                                setNewLabelFields({
                                  ...newLabelFields,
                                  englishFieldValue: e.target.value
                                });
                              } else {
                                if (newLabelFields?.englishFieldValue !== "") {
                                  setNewLabelFields({
                                    ...newLabelFields,
                                    englishFieldValue: ""
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <Input
                            type="text"
                            placeholder="German Label"
                            value={newLabelFields?.germanFieldValue}
                            onChange={(e) => {
                              if (e.target.value.trim()) {
                                setNewLabelFields({
                                  ...newLabelFields,
                                  germanFieldValue: e.target.value
                                });
                              } else {
                                if (newLabelFields?.germanFieldValue !== "") {
                                  setNewLabelFields({
                                    ...newLabelFields,
                                    germanFieldValue: ""
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <p style={addLabelNote.style}>{addLabelNote.note}</p>
                    <div className="col-12 mt-10 mb-10">
                      <button
                        type="button"
                        disabled={Boolean(newLabelFields?.labelKey && newLabelFields?.englishFieldValue && newLabelFields?.germanFieldValue ? false : true)}
                        className="me-2 btn btn-primary bg-blue border-0 shadow-none button-width-com"
                        onClick={() => {
                          handleAddNewLabel();
                        }}>
                        Save
                      </button>
                      <button
                        type="button"
                        disabled={Boolean(false)}
                        className="btn-sm bg-grey rounded-5 py-2 px-3 font-14 border-none button-width-com"
                        onClick={() =>
                          setNewLabelFields({
                            ...newLabelFields,
                            addFields: !newLabelFields.addFields,
                            englishFieldValue: "",
                            germanFieldValue: "",
                            labelKey: ""
                          })
                        }>
                        Remove
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="col-12 mt-3">
                <div className="row ">
                  <div className="col-4 fw-bold">Label key</div>
                  <div className="col-4 fw-bold">English value</div>
                  <div className="col-4 fw-bold">German value</div>
                </div>
                <div className="nft-label-section mt-10">
                  {values?.DE &&
                    (search === "" ? data.sort() : state).map((key, index) => {
                      return (
                        <div className={`col-12 test ${index !== 0 && "mt-3"}`} key={key} id={key}>
                          <div className="row">
                            <div className="col-4">
                              <Input type="text" disabled name={`${values.language}`} value={key} />
                            </div>
                            <InputLabelField
                              setFieldValue={setFieldValue}
                              // @ts-ignore
                              value={values.EN[`${key}`]}
                              keyValue={key}
                              lang="EN"
                              disable={!handleAppActionPermission(projectNames.byou, projectModules[projectNames.byou].GLOBAL_SETTINGS, "update") || false}
                            />
                            <InputLabelField
                              lang="DE"
                              setFieldValue={setFieldValue}
                              keyValue={key}
                              // @ts-ignore
                              value={values.DE[key]}
                              disable={!handleAppActionPermission(projectNames.byou, projectModules[projectNames.byou].GLOBAL_SETTINGS, "update") || false}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {state.length <= 0 ? (
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "3%",
                        color: "hsl(0deg 0% 80%)"
                      }}>
                      No Data found
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 mt-10 mb-10">
                  <button type="button" disabled={Boolean(values.disable)} className="btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={() => handleSubmit()}>
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BYouLabelManagement;
