import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { addNewAppType, getAppTypeInfo, updateAppType } from "../../../../actions/staking/availableApps/StakingAvailableAppsListAPI";
import { toastSuccess } from "../../../../common/common-functions";
import { projectNames } from "../../../../common/constants";
import { setLoading } from "../../../../store/loader/action";
import PageHeader from "../../bvote/common/PageHeader";

const StakingAddAvailableAppsManagement = () => {
  const location: any = useLocation();
  const editId: string = location?.state?.userId;
  const dispatch = useDispatch();
  const history = useHistory();

  const formikData = useFormik({
    initialValues: {
      name: "",
      icon: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is Required"),
      icon: Yup.string().required("Icon is Required")
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      const parameter = {
        name: values.name,
        icon: values.icon,
        _id: editId
      };

      if (location?.pathname === `/${projectNames.edexanetwork}/add-app`) {
        addNewAppType(parameter).then((result) => {
          dispatch(setLoading(false));
          toastSuccess(result.message);
          history.push(`/${projectNames.edexanetwork}/available-apps-management`);
        });
      } else {
        updateAppType(parameter).then((result) => {
          dispatch(setLoading(false));
          toastSuccess(result.message);
          history.push(`/${projectNames.edexanetwork}/available-apps-management`);
        });
      }
    }
  });

  const { values, handleSubmit, setFieldValue, errors } = formikData;

  const getEditViewInfo = (id: string) => {
    dispatch(setLoading(true));
    getAppTypeInfo(id).then((res) => {
      if (res?.status === 200) {
        setFieldValue("name", res?.data?.name);
        setFieldValue("icon", res?.data?.icon);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    if (editId) {
      getEditViewInfo(editId);
    }
    // eslint-disable-next-line
  }, [editId]);

  return (
    <div>
      <div>
        <div className="holders">
          <PageHeader module={location?.pathname === `/${projectNames.edexanetwork}/add-app` ? "Add Application " : "Edit Application "} />
          <div className="rounded-10 bg-white p-20">
            <Row>
              <Col lg={4}>
                <Label>Name</Label>
                <Input type="text" placeholder="Enter Name of App" name="name" value={values?.name} onChange={(event) => setFieldValue("name", event.target.value)} />
                {errors.name && <span className="text-danger font-14">{errors.name}</span>}
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Label className="mt-3">Icon</Label>
                <Input
                  type="text"
                  placeholder="Example:: <i class='fa-solid fa-person-circle-question'></i>"
                  name="name"
                  value={values?.icon}
                  onChange={(event: any) => setFieldValue("icon", event.target.value)}
                />
                {errors.icon && <span className="text-danger font-14">{errors.icon}</span>}
              </Col>
            </Row>

            <button className="btn-sm btn btn-primary bg-blue border-0 shadow-none my-3 button-width-com" onClick={() => handleSubmit()}>
              {location?.pathname === `/${projectNames.edexanetwork}/add-app` ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingAddAvailableAppsManagement;
