import { memo } from "react";
import { Link } from "react-router-dom";
import { goToPage } from "../../common/common-functions";

function Footer() {
  return (
    <div className="d-flex justify-content-between align-items-center w-100 flex-wrap footer">
      <p className="font-13 color-light-grey m-0 test">Powered by edeXa Business Blockchain © {new Date().getFullYear()}</p>
      <div className="d-flex">
        <Link to="#" className="font-13 color-light-grey" onClick={() => goToPage(process.env.REACT_APP_privacy)}>
          Privacy Policy
        </Link>
        <Link to="#" className="font-13 color-light-grey" onClick={() => goToPage(process.env.REACT_APP_tCondition)}>
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
}

export default memo(Footer);
