import * as Yup from "yup";

// address validation with yup
Yup.addMethod(Yup.string, "validateEtherAddress", function (errorMessage) {
  return this.test("validate-eth-wallet-address", errorMessage, function (value) {
    const { path, createError } = this;
    // @ts-ignore
    return WAValidator?.validate(value, "eth") || createError({ path, message: errorMessage });
  });
});

export const WalletAddressYupValidationSchema = Yup.object({
  // @ts-ignore
  walletAddress: Yup.string().required("Wallet address is required").validateEtherAddress("This Address is not a valid Ethereum wallet address")
});
