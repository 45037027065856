import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import * as io from "socket.io-client";
import { getBanjiAdminInfo } from "../../actions/auth";
import { bMessageAdminInfoAPI } from "../../actions/bMessage/bMessageAdminInfo";
import { GetbStampAdminInfoAPI } from "../../actions/bStamp/getbStampAdminInfoAPI";
import { bTrackUserInfoAPI } from "../../actions/bTrack/bTrackUserInfo";
import { callBVoteAdminInfo } from "../../actions/bvote/adminInfo";
import { callByouAdminInfo } from "../../actions/byou/user-info";
import { StakingAdminInfoAPI } from "../../actions/staking/stakingAdminInfoAPI";
import { universeGetAdminInfoAPI } from "../../actions/universe/univerUserInfo";
import DefaultProfile from "../../assets/images/default_profile.png";
import Logo from "../../assets/images/svg/edexa-blue.svg";
import { ReactComponent as ToggleMenu } from "../../assets/images/svg/toggle-menu.svg";
import { compare, getDecryptedLocalStorage, goToPage, handleLogout, handleProjectName, setEncryptedLocalStorage, titleCase, toastSuccess } from "../../common/common-functions";
import { localstorageKeys, projectDropdownSelectorArray, projectNames } from "../../common/constants";
import { setLoading } from "../../store/loader/action";
import { setCurrentProject } from "../../store/project/action";
import { toggleSidebar } from "../../store/sidebar/action";
import { onNotifcationOn } from "../../store/user/actions";
import HeaderNotificationIcon from "./header/HeaderNotificationIcon";

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSidebarOpen } = useSelector((state: any) => state.sidebarToggle);
  const { currentProject } = useSelector((state: any) => state.projectNameReducer);

  const userData = useSelector((state: any) => state.userDetails.user);
  const userDetails = useSelector((state: any) => state.userDetails.user);
  const currentPermission = useSelector((state: any) => state.permissionsReducer.permissions);

  const [dropdownPage, setDropdownPage] = useState(false);
  const [dropdownOpenProfile, setDropdownOpenProfile] = useState(false);
  const [currentModule, setCurrentModule] = useState(titleCase(currentProject));
  const profileToggle = () => {
    setDropdownOpenProfile((prevState) => !prevState);
  };

  const pageToggle = () => setDropdownPage((prevState) => !prevState);
  const handleModuleClick = async (path: string, module: string, placeholder: string) => {
    dispatch(setLoading(true));
    // call admin-info api to make a bridge [let the particular project server know that this user is logged in]
    // if we do not call this api then It will through an unauthorized error and user will be logout first.
    // also make sure this admin-info get called first and the rest of the api's after that.
    switch (module) {
      case projectNames.admin:
        window.open("https://admin.io-world.com/login", "_blank");
        dispatch(setLoading(false));
        break;
      case projectNames.banji:
        window.open("https://admin.io-world.com/login", "_blank");
        dispatch(setLoading(false));
        break;
      case projectNames.bvote:
        setEncryptedLocalStorage(localstorageKeys.projectName, placeholder);
        callBVoteAdminInfo()
          .then(() => {
            dispatch(setLoading(false));
            dispatch(setCurrentProject(module));
            setCurrentModule(titleCase(placeholder));
            history.push(path);
          })
          .catch((err) => {
            toastSuccess("Coming Soon");
            setEncryptedLocalStorage(localstorageKeys.projectName, currentModule);
          });
        break;
      case projectNames.bmessage:
        setEncryptedLocalStorage(localstorageKeys.projectName, placeholder);
        bMessageAdminInfoAPI()
          .then(() => {
            dispatch(setLoading(false));
            dispatch(setLoading(false));
            dispatch(setCurrentProject(module));
            setCurrentModule(titleCase(placeholder));
            history.push(path);
          })
          .catch((err) => {
            setEncryptedLocalStorage(localstorageKeys.projectName, currentModule);
          });
        break;
      case projectNames.nft:
        window.open("https://admin.io-world.com/login", "_blank");
        dispatch(setLoading(false));
        break;
      case projectNames.bstamp:
        window.open("https://admin.io-world.com/login", "_blank");
        dispatch(setLoading(false));
        break;
      case projectNames.byou:
        setEncryptedLocalStorage(localstorageKeys.projectName, placeholder);
        callByouAdminInfo()
          .then(() => {
            dispatch(setLoading(false));
            dispatch(setCurrentProject(module));
            setCurrentModule(titleCase(placeholder));
            dispatch(setLoading(false));
            history.push(path);
          })
          .catch((err) => {
            setEncryptedLocalStorage(localstorageKeys.projectName, currentModule);
          });
        break;

      case projectNames.universe:
        window.open("https://admin.io-world.com/login", "_blank");
        dispatch(setLoading(false));
        break;
      case projectNames.edexanetwork:
        setEncryptedLocalStorage(localstorageKeys.projectName, placeholder);
        StakingAdminInfoAPI()
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setCurrentProject(module));
            setCurrentModule(titleCase(placeholder));
            history.push(path);
          })
          .catch((err) => {
            setEncryptedLocalStorage(localstorageKeys.projectName, currentModule);
          });
        break;

      case projectNames.btrack:
        setEncryptedLocalStorage(localstorageKeys.projectName, placeholder);
        bTrackUserInfoAPI()
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setCurrentProject(module));
            setCurrentModule(titleCase(placeholder));
            history.push(path);
          })
          .catch((err) => {
            setEncryptedLocalStorage(localstorageKeys.projectName, currentModule);
          });
        break;

      default:
        setEncryptedLocalStorage(localstorageKeys.projectName, projectNames.universe);
        dispatch(setLoading(false));
        dispatch(setCurrentProject(projectNames.universe));
        setCurrentModule(titleCase(placeholder));
    }
    pageToggle();
  };

  const handleUserLogout = () => {
    handleLogout();
    let socket = io.io(process.env.REACT_APP_APIURL || "", {
      path: "/admin/socket.io",
      transports: ["websocket", "polling"]
    });
    if (socket) {
      socket.emit("logout", {
        userId: userDetails?.userId,
        token: getDecryptedLocalStorage(localstorageKeys.userToken)
      });
    }
  };

  useEffect(() => {
    setCurrentModule(currentProject);
    let socket = io.io(process.env.REACT_APP_APIURL || "", {
      path: "/auth/socket.io",
      transports: ["websocket", "polling"]
    });
    socket.on(`adminNotification_${userDetails?.userId}`, (data) => {
      let userData = getDecryptedLocalStorage(localstorageKeys?.userInfo);
      if (data?.message) {
        dispatch(onNotifcationOn(userData?.notification + 1));
        setEncryptedLocalStorage(localstorageKeys?.userInfo, userData);
      }
    });
    // eslint-disable-next-line
  }, [currentProject]);

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar(!isSidebarOpen));
  };

  return (
    <div className="d-flex justify-content-between align-items-center w-100 header">
      <div className="d-flex justify-content-center align-items-center">
        <img src={Logo} alt="logo" className="cursor-pointer" onClick={() => history.push("/")} />
        <div title="Menu" className="sidebar-toggle" onClick={handleSidebarToggle}>
          <ToggleMenu title="Menu" />
        </div>
      </div>
      <div className="d-flex">
        <Dropdown isOpen={dropdownPage} toggle={pageToggle} className="d-flex align-items-center justify-content-center bg-blue  border-0 mt-3 mt-md-0" style={{ width: 160 }}>
          <DropdownToggle className="bg-transparent w-100 h-100 d-flex align-items-center shadow-none">
            {handleProjectName(currentModule)}
            <i className="fas fa-chevron-down color-white font-12 ml-10"></i>
          </DropdownToggle>
          <DropdownMenu className="p-0 rounded-0 w-100 border-grey scrollbox">
            <Scrollbars
              autoHide
              renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
              renderThumbVertical={(props) => <div {...props} className="scrollbox" />}
              autoHeight
              className="border-grey">
              <div className="p_5">
                {projectDropdownSelectorArray?.sort(compare).map((module, index) => {
                  if (!Object.keys(currentPermission)?.includes(module.label)) return "";
                  return (
                    <p
                      key={index}
                      className="color-dark-grey font-14 p-20 page-name"
                      onClick={() => {
                        handleModuleClick(module.path, module.label, module.placeholder);
                      }}>
                      {handleProjectName(module.placeholder)}
                    </p>
                  );
                })}
              </div>
            </Scrollbars>
          </DropdownMenu>
        </Dropdown>

        <HeaderNotificationIcon />

        <Dropdown
          isOpen={dropdownOpenProfile}
          toggle={profileToggle}
          onClick={() => setDropdownOpenProfile(!dropdownOpenProfile)}
          className="d-flex align-items-center justify-content-center header-dropdown">
          <DropdownToggle className="menu-profile">
            {userData?.profilePic ? (
              <img
                title="My Profile"
                src={userData?.profilePic || DefaultProfile}
                alt="profile"
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px"
                }}
              />
            ) : (
              <i className="fas fa-user menu-icon"></i>
            )}
            <i className="fas fa-chevron-down down-arrow"></i>
          </DropdownToggle>
          <DropdownMenu className="rounded-10 profile-list">
            <DropdownItem className="project-list">
              <p className="font-11 color-black2 bg-lightblue text-center profile-title rounded-2">
                This account is managed by edeXa.
                <Link to="#" onClick={() => goToPage(process.env.REACT_APP_edexaDomain)} className="color-blue fw-bold">
                  {" "}
                  Learn more
                </Link>
              </p>
              <div className="profile-detail">
                <img src={userData?.profilePic || DefaultProfile} alt="profile" width="80px" height="80px" />
                <p className="color-black1 font-16 fw-bold">{`${userDetails?.firstName} ${userDetails?.lastName}`}</p>
                <p className="color-black1 font-14 fw-normal">{userDetails?.email}</p>
                <button className="font-13 color-white fw-600 bg-blue rounded-2 p-1 w-100" onClick={() => history.push("/profile")}>
                  Manage My Profile
                </button>
              </div>
            </DropdownItem>
            <div className="profile-footer w-100">
              <div className="d-flex justify-content-center align-items-center logout">
                <button className="font-13 color-black-2 p-1 fw-600 rounded-2 border-blue w-100" onClick={handleUserLogout}>
                  Logout
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center policy">
                <Link to="#" className="font-12 blue fw-bold" onClick={() => goToPage(process.env.REACT_APP_privacy)}>
                  Privacy Policy
                </Link>
                <span className="font-12" style={{ padding: "0 10px" }}>
                  -
                </span>
                <Link to="#" className="font-12 blue fw-bold" onClick={() => goToPage(process.env.REACT_APP_tCondition)}>
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
