import axios from "axios";
import { getDecryptedLocalStorage } from "../common/common-functions";
import { localstorageKeys } from "../common/constants";

export const onGetNotification = (params: any) => {
  const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
  if (userToken) {
    let token = userToken;
    return axios.get(`${process.env.REACT_APP_API_DOMAIN_admin}admin/notification`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: params
    });
  }
};

export const onUpdateNotification = (body: any) => {
  const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
  if (userToken) {
    let token = userToken;
    return axios.put(`${process.env.REACT_APP_API_DOMAIN_admin}admin/notification`, body, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
  }
};

export const onDeleteNotification = (body: any) => {
  const userToken = getDecryptedLocalStorage(localstorageKeys.userToken);
  if (userToken) {
    let token = userToken;
    return axios.post(`${process.env.REACT_APP_API_DOMAIN_admin}admin/notification`, body, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
  }
};
