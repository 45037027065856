import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Form, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { createNewIssuer } from "../../../actions/portal/byouActions";

import { handleTrim, toastSuccess } from "../../../common/common-functions";
import { projectNames, validationMessages } from "../../../common/constants";
import { setLoading } from "../../../store/loader/action";
import PageHeader from "../bvote/common/PageHeader";

const CreateIssuer = () => {
  const dispatch = useDispatch();
  interface IdropdownUniqueDomains {
    value: string;
    label: string;
  }

  const history = useHistory();
  // eslint-disable-next-line
  const [domainList, setDomainList] = useState<any>([]);
  // eslint-disable-next-line
  const [UserTypeOptionss, setUserTypeOptionss] = useState([]);
  // eslint-disable-next-line
  const [networkDomainId, setNetworkDomainId] = useState();
  // eslint-disable-next-line
  const [uniqueDomainDropdownOptions, setUniqueDomainDropdownOptions] =
    useState();
  // eslint-disable-next-line
  const handleTypeChange = (e: any) => {
    setNetworkDomainId(e.value);
  };

  useEffect(() => {
    const options: IdropdownUniqueDomains[] = domainList?.map((ele: any) => ({
      value: ele?.networkDomainId,
      label: ele?.name
    }));

    const optionsLabelWise = domainList?.map((ele: any) => ({
      value: ele?.id,
      label: ele?.name
    }));
    const key = "label";
    // @ts-ignore
    setUniqueDomainDropdownOptions([...new Map(optionsLabelWise?.map((item) => [item[key], item])).values()]);

    setUserTypeOptionss(options as unknown as []);
  }, [domainList]);

  // eslint-disable-next-line
  const [module, setModule] = useState("Create Issuer");

  const groupFormik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(validationMessages.email.invalid).required(validationMessages.email.required)
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      let parameter: any = {};
      parameter.email = values.email;

      createNewIssuer(parameter).then((res) => {
        if (res.status === 200) {
          history.push(`/${projectNames.byou}/user-management`);
          toastSuccess(res.message);
          dispatch(setLoading(false));
        }
      });
    }
  });

  const { errors, values, handleBlur, handleSubmit, setFieldValue, touched } = groupFormik;

  return (
    <>
      {/* <MetaFunction meta={module} /> */}
      <div className="holders">
        <PageHeader module={module} />

        <div className="rounded-10 bg-white p-20">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-7">
                <Label htmlFor="email" className="fw-normal color-light-grey py-2">
                  Email
                </Label>
                <ul>
                  <li className="color-light-grey">You can invite the Issuer by adding their email address</li>
                  <li className="color-light-grey">An email will be sent to the issuer </li>
                  <li className="color-light-grey">Once an organisation confirms and sets up the final password, they can begin using bYou-Issuer and issue credentials</li>
                  <li className="color-light-grey">From the Issuer web portal, organisation can also invite holders</li>
                </ul>
                <Input
                  type="text"
                  placeholder="Enter the email"
                  id="email"
                  className="w-750"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleTrim(e.target.name, e.target.value, setFieldValue);
                  }}
                  invalid={Boolean(touched?.email && errors.email)}
                  value={values.email}
                />
                {touched?.email && errors.email && <span className="text-danger font-14">{errors.email}</span>}
              </div>

              <div className="col-md-12 mt-20">
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary bg-blue border-0 shadow-none button-width-com w-140">
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CreateIssuer;
