// Purpose of this Hook:I have to change the status of the invitation at Channel Detail, Channel Invitation listing and Channel Invitation detail page, so to avoid repeated code, I had to create this Custom hook.

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as io from "socket.io-client";
import { adminNotificationReceived } from "../../store/socket/action";

const useSocketHook = (userId: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      const socket = io.io(process.env.REACT_APP_API_DOMAIN_universe || "", {
        path: "",
        transports: ["websocket", "polling"]
      });

      socket.on(`adminNotification_${userId}`, (data) => {
        if (data) {
          dispatch(adminNotificationReceived());
        }
      });
    }
    // eslint-disable-next-line
  }, [userId]);

  return {};
};

export default useSocketHook;
