import { FC, memo } from "react";

import menu from "../../../../assets/images/drag.png";

export interface IBlockChainListComponent {
  item: {
    _id: string;
    name: string;
    viewOn: string;
    gateway: number;
    icon: string;
    isVisible: number;
    isEnable: number;
    order: number;
    iconBlack: string;
    iconWhite: string;
  };
  setBlockChainData: any;
}

const AppShortcutsObjectComponent: FC<IBlockChainListComponent> = ({ item, setBlockChainData }) => {
  return (
    <>
      <div className="col-12">
        {/* <img src={item.iconBlack} height="25" alt={String(item.name)} /> */}
        <div className="d-flex justify-content-between">
          <p>{item.name}</p>
          <img src={menu} alt="menu" width={20} height={20} className="drag-blockchain-img" />
        </div>
      </div>
      {/* @ts-ignore */}
      {/* <EditRestricted module={projectModules.nft.BLOCK_CHAIN}>
        <div className="col-3">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            checked={item.isVisible === 1 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isVisible", e, item);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-30"
          />
        </div>
        <div className="col-2">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            disabled={!item.isVisible || item.isEnable === 2}
            checked={item.isEnable === 1 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isEnable", e, item);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-10"
          />
        </div>{" "}
        <div className="col-3 d-flex justify-content-between">
          <Switch
            height={18}
            width={32}
            onColor="#007de4"
            offColor="#b1abab"
            checked={item.isEnable === 2 ? true : false}
            onChange={(e: boolean) => {
              switchHandleChange("isEnable", e, item, e ? 2 : 1);
            }}
            uncheckedIcon
            checkedIcon
            className="ml-30"
          />
          <img src={menu} alt="menu" width={20} height={20} className="drag-blockchain-img" />
        </div>
      </EditRestricted> */}
    </>
  );
};

export default memo(AppShortcutsObjectComponent);
