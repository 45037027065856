import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { FormFeedback, Input, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";
import { createCategoryAPI } from "../../../../actions/nft/categoryManagement/createCategory";
import { updateCategoryAPI } from "../../../../actions/nft/categoryManagement/updateCategory";
import { handleTrim, toastSuccess } from "../../../../common/common-functions";
import { onlyCharacterWithLimit } from "../../../../common/constants";
import { categoryModalCallAPIAction, categoryModalResetAction } from "../../../../store/categoryModal/action";
import { setLoading } from "../../../../store/loader/action";
import { IRootReducerTypes } from "../../../../store/rootRededucer";

const CategoryModal: FC = () => {
  const dispatch = useDispatch();

  const categoryState = useSelector((state: IRootReducerTypes) => state.categoryModalReducer);

  const categoryFormik = useFormik({
    initialValues: { name: "", rank: 0, highlight: false, id: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Catetory Name required")
        .matches(onlyCharacterWithLimit, "Only character are allow")
        .min(2, "Minimum 2 character are allow")
        .max(50, "Maximum 50 character are allow"),
      rank: (categoryState.data._id) ?  Yup.number().required("Order required").min(1, "Min value 1").max(categoryState.totalCount, `Max value ${categoryState.totalCount}`) : Yup.number()
    }),
    onSubmit: (values) => {
      if (categoryState.data._id) {
        dispatch(setLoading(true));
        if (categoryState.data._id) {
          let data = {
            ...values,
            name: values?.name.trim()
          };
          updateCategoryAPI(data).then((res) => {
            if (res.status === 200) {
              dispatch(categoryModalResetAction());
              dispatch(categoryModalCallAPIAction(true));
              categoryFormik.resetForm();
            }
            toastSuccess(res.message);
          });
        }
      } else {
        createCategoryAction();
      }
    }
  });

  const createCategoryAction = () => {
    const data = {
      name: values.name.trim()
    };
    createCategoryAPI(data).then((res) => {
      if (res.status === 200) {
        dispatch(categoryModalResetAction());
        dispatch(categoryModalCallAPIAction(true));
        categoryFormik.resetForm();
      }
      toastSuccess(res.message);
    });
  };

  const { values, errors, setFieldValue, handleSubmit, handleChange, touched, handleBlur } = categoryFormik;

  useEffect(() => {
    if (categoryState.data._id) {
      setFieldValue("name", categoryState.data.name);
      setFieldValue("rank", categoryState.data.rank);
      setFieldValue("id", categoryState.data._id);
      setFieldValue("highlight", categoryState.data.highlight);
    }
  }, [categoryState.data._id, categoryState.data.highlight, categoryState.data.name, categoryState.data.rank, setFieldValue]);

  return (
    <Modal
      isOpen={categoryState.isOpen}
      toggle={() => {
        dispatch(categoryModalResetAction());
        categoryFormik.resetForm();
      }}
      centered
      style={{ maxWidth: "600px" }}>
      <ModalBody className="p-5 position-relative">
        <i
          onClick={() => {
            dispatch(categoryModalResetAction());
            categoryFormik.resetForm();
          }}
          className="fas fa-plus k category-icon-position cursor-pointer"
          title={`${values.name} category will be selected by default in the Explore page in NFT Portal`}
          style={{
            marginTop: "4px",
            color: "gray",
            transform: "rotate(45deg)"
          }}></i>
        <p className="main-title m-b-20 text-center">{categoryState.data._id ? "Category Details" : "Add Category"}</p>
        <div className="form-group w-100 mt-10 mb-10 ">
          <label className="text-secondary fw-bold">Category Name</label>
          <Input
            type="text"
            className="form-control mt-1"
            placeholder="Enter category name"
            name="name"
            onBlur={handleBlur}
            onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
            value={values.name}
            invalid={touched?.name && errors?.name ? true : false}
          />
          <FormFeedback>{errors?.name}</FormFeedback>
        </div>
        {categoryState.data._id && (
          <>
            <div className="row mt-10 mb-10">
              <div className="form-group col-6">
                <label className="text-secondary fw-bold">Order</label>
                <Input type="number" className="form-control mt-1" name="rank" onChange={handleChange} value={values.rank} invalid={touched?.rank && errors?.rank ? true : false} />
                <FormFeedback>{errors?.rank}</FormFeedback>
              </div>
              <div className="col-6">
                <label className="text-secondary fw-bold">
                  Defualt Selected
                  <i
                    className="fas fa-info-circle ml-10"
                    title={`${values.name} category will be selected by default in the Explore page in NFT Portal`}
                    style={{ marginTop: "4px", color: "black" }}></i>
                </label>
                <div>
                  <Switch
                    height={18}
                    width={32}
                    onColor="#007de4"
                    offColor="#b1abab"
                    checked={values.highlight}
                    onChange={(e: boolean) => setFieldValue("highlight", e)}
                    uncheckedIcon
                    checkedIcon
                    className="mt-10"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <button className="btn-sm btn-primary bg-blue rounded-5 py-2 px-3 font-15 border-none w-100 my-3 fw-bold" onClick={() => handleSubmit()}>
          Save
        </button>
      </ModalBody>
    </Modal>
  );
};

export default CategoryModal;
