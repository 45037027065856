import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getListCategoryAPI = (params?: IgetListCategoryAPIParsms): Promise<IgetListCategoryAPI> => {
  return HTTPService.get(StakingPortalApiEndPoint.category.get, {
    params
  });
};
interface IgetListCategoryAPIParsms {
  page?: number;
  serach?: string;
  limit?: number;
  pagination?: boolean;
}

export interface IgetListCategoryAPIData {
  createdAt: string;
  deleteAccess: boolean;
  id: string;
  name: string;
  updatedAt: string;
  visible: boolean;
  _id: string;
}

interface IgetListCategoryAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IgetListCategoryAPIData[];
  };
}
