import { useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

export const RequestDemoRejectionPopup = ({ isOpen, isLoading, reason, setReason, setUniversalStatusToggle, id, handleUniversalListStatusChange }) => {
  const inputRef = useRef(0);

  // eslint-disable-next-line no-unused-vars
  const [isOther, setIsOther] = useState(false);
  const [error, setError] = useState("");

  const handleSave = () => {
    function submit() {
      handleUniversalListStatusChange(id.toString(), "2", reason.trim());
      setUniversalStatusToggle(false);
    }
    if (isOther) {
      if (reason.trim().length > 180) {
        setError("Maximum 180 characters allowed");
      } else {
        submit();
      }
    } else {
      submit();
    }
  };

  return (
    <Modal isOpen={isOpen} className={"custom-model"} centered>
      <ModalHeader toggle={() => setUniversalStatusToggle(false)} className="border-0 bg-blue text-white">
        Reason
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="radio-buttons">
          <>
            <Input
              innerRef={inputRef}
              type="textarea"
              className="other-reason"
              placeholder="Enter your reason..."
              onChange={(e) => {
                setReason(e.target.value);
                setError("");
              }}
            />
            {error && <span className="text-danger">{error}</span>}
          </>
        </div>
      </ModalBody>
      <ModalFooter className="border-0">
        <div className="fotter-layout">
          {isLoading && <Spinner className="color-blue mx-3" />}
          <Button className="border-0 bg-blue" disabled={!Boolean(reason)} onClick={handleSave}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
