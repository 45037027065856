import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { projectNames } from "../../common/constants";
import { setVisible } from "../../store/confirmationModule/action";
import "./confirmationModal.css";
import ReasonModal from "./staking/common/components/ReasonModal";

/**
 *
 * @param {handleOnClickAction} - a function () => {}
 * @returns null
 *
 * This modal will take a function and title as Input.
 * 1. If user click on yes then the function will execute and the respective action will be performed.
 * 2. The title, deleteId, visibility and deleteStatus are fetched from redux store.
 *
 * usages example -
 *
 * const apiSubscriptionToggle = () => {
 *   @todo - perform your operations here
 * }
 * <ConfirmationModal handleDeleteAction={apiSubscriptionToggle} />
 *
 * You can also pass an state to handleDeleteAction which can contain a function, you may use state to send dynamic function as per the user action
 *
 * for example -
 *  const [addRemoveFunction, setAddRemoveFunction] = useState<any>({fnCall: apiSubscriptionToggle});
 *
 *  Now you can pass this state to ConfirmationModal component-
 *  <ConfirmationModal handleDeleteAction={addRemoveFunction} />
 */

const ConfirmationModal = ({ handleDeleteAction }: propTye) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const adminCustomer = `/${projectNames.admin}/customer-management`;

  const visible = useSelector((state: any) => state.visible);
  const loader = useSelector((state: any) => state.loading.isLoading);
  const [disable, setDisable] = useState(false);
  const [isReason, setIsReason] = useState(false);
  const [notify, setNotify] = useState(false);

  const { isVisible, deleteId, label, deleteStatus } = visible;

  useEffect(() => {
    if (!isVisible) {
      setDisable(false);
    }
  }, [isVisible]);

  const reasonAction = (reason: string) => {
    handleDeleteAction({ ...deleteId, reason, notify });
    setIsReason(false);
    setNotify(false);
  };
  const handleClose = () => {
    dispatch(
      setVisible({
        isVisible: false,
        deleteId: "",
        label: "",
        deleteStatus: ""
      })
    );
    setNotify(false);
    setIsReason(false);
    setDisable(false);
  };

  return (
    <>
      <Modal centered isOpen={isVisible} style={{ maxWidth: "450px" }}>
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center">Confirmation</h4>
            <p className="fw-bold text-center font-18 mb-10 " style={{ color: "#868686" }}>
              {label}
            </p>
            {adminCustomer === location.pathname ? (
              <div className="d-flex justify-content-center align-content-center">
                <span className="mr-10 fw-bold color-light-grey">Notify user: </span>
                <input
                  checked={notify}
                  className="form-check-input"
                  type="checkbox"
                  name="notify-user"
                  onChange={(e) => {
                    setNotify(e.target.checked);
                  }}
                  onClick={() => {
                    setIsReason(!deleteId.status ? true : false);
                    dispatch(
                      setVisible({
                        isVisible: deleteId.status ? true : false,
                        deleteId: deleteId,
                        label: label,
                        deleteStatus: ""
                      })
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
            <div
              className={`col-6 p-10 ${loader ? "disable" : ""} d-flex cursor-pointer`}
              style={{ borderRight: "1px solid #dadce0" }}
              onClick={() => {
                handleClose();
              }}>
              <button className="confimatio-button text-danger">No</button>
            </div>
            <div
              className={`col-6 p-10 d-flex ${loader ? "disable" : ""} cursor-pointer justify-content-center`}
              onClick={() => {
                if (!disable) {
                  adminCustomer === location.pathname ? handleDeleteAction({ ...deleteId, notify: notify }) : handleDeleteAction(deleteId, deleteStatus);
                  setDisable(!disable);
                  // handleClose();
                }
              }}>
              <span className="confimatio-button text-success d-flex align-items-center">
                Yes
                {loader && (
                  <Spinner className="ml-20" size={"sm"} color="black">
                    Loading...
                  </Spinner>
                )}
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {location.pathname.includes("customer-management") ? <ReasonModal isOpen={isReason} setIsOpen={setIsReason} reasonAction={reasonAction} handleClose={handleClose} /> : null}
    </>
  );
};

export default ConfirmationModal;

interface propTye {
  handleDeleteAction: any;
}
