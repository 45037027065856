import { projectModules, projectNames } from "../../../common/constants";
import MetaFunction from "../../../common/MetaTag";
import useRedirectHook from "../../../utils/hooks/useRedirectHook";
import Footer from "../../common/Footer";
import TitleDashboard from "../bvote/TitleDashboard";

const BtrckDashboard = () => {
  const [handleRedireactClick] = useRedirectHook();

  return (
    <>
      <MetaFunction meta={"Dashboard"} />
      <div className="dashboard" style={{ minHeight: "85vh" }}>
        <p className="main-title m-b-20">Dashboard</p>
        <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
          <TitleDashboard title="Total Track" count={0} cursor="poniter" width="32.5%" onClick={() => handleRedireactClick(projectNames.btrack, projectModules[projectNames.btrack]?.DASHBOARD, "")} />
          <TitleDashboard title="Total User" count={0} cursor="poniter" width="32.5%" onClick={() => handleRedireactClick(projectNames.btrack, projectModules[projectNames.btrack]?.DASHBOARD, "")} />
          <TitleDashboard title="Total Groups" count={0} cursor="poniter" width="32.5%" onClick={() => handleRedireactClick(projectNames.btrack, projectModules[projectNames.btrack]?.DASHBOARD, "")} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BtrckDashboard;
