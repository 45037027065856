import { SETVISIBLE } from "./type";

export const setVisible = (payload: any) => {
  return { type: SETVISIBLE, payload };
};

export const resetVisible = () => {
  return setVisible({
    isVisible: false,
    deleteId: "",
    label: "",
    deleteStatus: ""
  });
};
