import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Label } from "reactstrap";
import { StakingApplicationsMarketPlaceAPI } from "../../../../../actions/staking/marketPlace/stakingApplicationsMarketPlaceAPI";
import { setLoading } from "../../../../../store/loader/action";

interface ISelectedAppsComponent {
  values: string;
  setFieldValue: any;
  error: string | undefined;
  isError: boolean;
}

interface ISelected {
  value: string;
  label: string;
}

const SelectedAppsComponent: FC<ISelectedAppsComponent> = ({ values, setFieldValue, error, isError }: any) => {
  const dispatch = useDispatch();
  const [selectd, setSelectd] = useState<ISelected | null>(null);
  const [userTypeOptions, setUserTypeOptions] = useState<any[]>([]);
  const handleChange = (e: any) => {
    setSelectd(e);
    setFieldValue("appId", e.value);
  };

  const StakingAvailableAppsListAction = () => {
    dispatch(setLoading(true));
    StakingApplicationsMarketPlaceAPI().then((res) => {
      if (res.status === 200) {
        setUserTypeOptions(res.data.data);
        let newArray = res.data.data.map((item) => {
          return { value: item.appId, label: item.name };
        });
        setUserTypeOptions(newArray);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    StakingAvailableAppsListAction();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values === 0 || values) {
      if (String(values) !== selectd?.value) {
        let newValue = userTypeOptions.find((data) => data.value === String(values));
        if (newValue) {
          setSelectd(newValue);
        }
      }
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <>
      <Label for="biglogo" className="mt-3">
        Selected Parent App <span className="text-red">*</span>
      </Label>
      <Select placeholder="Availability of App" options={userTypeOptions} onChange={handleChange} className={`mt-1 z-index ${isError ? (error ? "parent-app" : "") : ""}`} value={selectd} />
      {isError ? error ? <p className="text-danger">{error}</p> : null : null}
    </>
  );
};

export default SelectedAppsComponent;
