import { nftURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const getReqestDemoListingAPI = (params: IGetRequestDemoParams): Promise<IGetRequestDemoResponse> => {
  return HTTPService.get(nftURL.getRequestDemoList, { params });
};

export interface IGetRequestDemoParams {
  id?: string;
  page: number;
  limit: number;
}

export interface IGetRequestDemoDataResponse {
  fullName: string;
  phoneNumber: string;
  description: string;
  status: number;
  reference: string;
  remarks: string;
  _id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetRequestDemoResponse {
  status: number;
  message: string;
  count: number;
  data: IGetRequestDemoDataResponse[];
}
