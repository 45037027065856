import { StakingPortalApiEndPoint } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

export const deleteBlogsAPI = (id: string): Promise<IdeleteBlogsAPI> => {
  return HTTPService.delete(StakingPortalApiEndPoint.blogs.delete + `/${id}`);
};

export interface IdeleteBlogsAPIData {}

interface IdeleteBlogsAPI {
  message: string;
  status: number;
  data: IdeleteBlogsAPIData;
}
