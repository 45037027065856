import { moduleManagementURL } from "../../../common/api-endpoints";
import HTTPService from "../../../common/httpservice";

// end-point of back-end url
let { createAppModule, getAppModule, updateAppModule, deleteAppModule, viewAppModuleList } = moduleManagementURL;

// create application modules api
export const onCreateAppModuleAPI = (body: any) => {
  return HTTPService.post(createAppModule, body);
};

// get all application modules data api
export const onViewAppModuleAPI = () => {
  return HTTPService.get(getAppModule);
};

// get particular application module list api
export const onViewAppModuleListAPI = (params: any) => {
  return HTTPService.get(viewAppModuleList, { params });
};

// update application modules api
export const onUpdateAppModuleAPI = (body: any) => {
  return HTTPService.put(updateAppModule, body);
};

// delete application modules api
export const onDeleteAppModuleAPI = (params: any) => {
  return HTTPService.delete(deleteAppModule, "", { params });
};
