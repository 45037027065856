import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toastSuccess } from "../../common/common-functions";
import { IRootReducerTypes } from "../../store/rootRededucer";

const useRedirectHook = () => {
  const history = useHistory();
  let permissions: any = useSelector((state: IRootReducerTypes) => state.permissionsReducer.permissions);

  const handlePermission = (projectName: string, moduleName: string) => {
    return permissions[projectName][moduleName]?.view;
  };

  function handleRedireactClick(projectName?: string, moduleName?: string, redirect?: string, redirectStatus?: boolean, state?: any) {
    if (projectName && moduleName && redirect && redirectStatus) {
      if (handlePermission(projectName, moduleName)) {
        history.push(redirect, state);
      } else {
        toastSuccess("coming soon");
      }
    } else {
      toastSuccess("coming soon");
    }
  }

  return [handleRedireactClick];
};

export default useRedirectHook;
