import { useFormik } from "formik";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { StakingViewAPI } from "../../../../../actions/staking/comman/stakingViewAPI";
import { StakingAddMarketPlaceAPI } from "../../../../../actions/staking/marketPlace/stakingAddMarketPlaceAPI";
import { StakingMarketPlaceUpdateAPI } from "../../../../../actions/staking/marketPlace/stakingMarketPlaceUpdateAPI";
import { handleTrim, toastSuccess, validURL } from "../../../../../common/common-functions";
import { projectNames, validationMessages } from "../../../../../common/constants";
import { resetVisible, setVisible } from "../../../../../store/confirmationModule/action";
import { setLoading } from "../../../../../store/loader/action";
import CustomTooltip from "../../../../common/ToolTip";
import ConfirmationModal from "../../../DeleteConfirmationModal";
import SwitchGlobalSetting from "../../../SwitchGlobalSetting";
import PageHeader from "../../../bvote/common/PageHeader";

import AppComponent from "./AppComponent";
import AvailabilityComponent from "./AvailabilityComponent";
import BlockChainAvailabilityComponent from "./BlockChainAvailabilityComponent";
import FeaturesComponent from "./FeaturesComponent";
import SelectedAppsComponent from "./SelectedAppsComponent ";
import SetImageComponent from "./SetImageComponent";

export const MainData = createContext<any>({});

const StakingMarketPlacemanagementView = () => {
  // eslint-disable-next-line
  const module = "Edit Marketplace Application";
  const params: any = useParams();
  const location: any = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorOfDocumentURL, setErrorOfDocumentURL] = useState(false);

  const [data, setData] = useState<any>();
  const [isError, setIsError] = useState(false);

  const getViewAction = (id: string) => {
    dispatch(setLoading(true));
    StakingViewAPI(location?.state?.type, id).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    if (location?.pathname !== `/${projectNames.edexanetwork}/market-place/add`) {
      if (params?.id) {
        getViewAction(params?.id);
      }
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const formikData = useFormik({
    initialValues: {
      name: "",
      subtitle: "",
      categoryName: "",
      isAvailable: null,
      blckChnAvailability: null,
      isTrending: false,
      appId: "",
      availableOn: [],
      order: 0,
      description: "",
      id: "",
      shortDescription: "",
      features: [""],
      documentUrl: "",
      postmanScript: "",
      logoIconBig: "",
      logoIcon: "",
      availableofapp: []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(validationMessages.appName.required).min(3, "Please enter minimum 3 characters").max(60, "Maximum allowed characters are 60"),
      subtitle: Yup.string().required("Subtitle is required").min(3, "Please enter minimum 3 characters").max(100, "Maximum allowed characters are 100"),
      description: Yup.string().required("Description is required").min(25, "Please enter minimum 25 characters").max(10000, "Maximum allowed characters are 10000"),
      shortDescription: Yup.string().required("Short Description is required").min(10, "Please enter minimum 10 characters").max(10000, "Maximum allowed characters are 10000"),
      isAvailable: Yup.number().required("Please select any one").typeError("Please select any one"),
      blckChnAvailability: Yup.number().required("Please select any one").typeError("Please select any one"),
      categoryName: Yup.string().required("Category Name is required").min(3, "Please enter minimum 3 characters").max(60, "Maximum allowed characters are 60"),
      appId: Yup.string().required("Please select any one"),
      logoIconBig: Yup.string().required("Big logo is required"),
      logoIcon: Yup.string().required("Small logo is required"),
      features: Yup.array().of(Yup.string().required("Feature value is required").min(2, "Please enter minimum 2 characters").max(100, "Maximum allowed characters are 100")),
      availableOn: Yup.array()
        .of(
          Yup.object().shape({
            availableOnApp: Yup.string().required(),
            url: Yup.string().required()
          })
        )
        .min(1)
        .required()
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      const formData = new FormData();
      formData.append("name", values?.name.trim());
      formData.append("subtitle", values?.subtitle.trim());
      formData.append("categoryName", values?.categoryName.trim());
      if (values.isAvailable === 0 || values.isAvailable) {
        formData.append("isAvailable", values.isAvailable);
      }
      if (values.blckChnAvailability === 0 || values.blckChnAvailability) {
        formData.append("blckChnAvailability", values.blckChnAvailability);
      }
      formData.append("appId", values.appId);
      formData.append("description", values?.description.trim());
      formData.append("isTrending", String(values?.isTrending));
      formData.append("shortDescription", values?.shortDescription.trim());

      formData.append("features", JSON.stringify(values?.features));
      if (location?.pathname === `/${projectNames.edexanetwork}/market-place/add`) {
        formData.append("postmanScript", values?.postmanScript.trim());
        formData.append("documentUrl", values?.documentUrl.trim());
        formData.append("logoIconBig", values?.logoIconBig);
        formData.append("logoIcon", values?.logoIcon);
        formData.append("availableOn", JSON.stringify(values.availableOn));
        StakingAddMarketPlaceAPI(formData).then((res) => {
          if (res.status === 200) {
            toastSuccess(res.message);
            history.push(`/${projectNames.edexanetwork}/market-place`);
            dispatch(setLoading(false));
          }
        });
      } else {
        let newArray = values.availableOn.map((data) => {
          // @ts-ignore
          return { availableOnApp: data?.availableOnApp, url: data?.url };
        });
        formData.append("availableOn", JSON.stringify(newArray));
        if (values?.logoIconBig !== data?.logoIconBig) {
          formData.append("logoIconBig", values?.logoIconBig);
        }
        if (values?.logoIcon !== data?.logoIcon) {
          formData.append("logoIcon", values?.logoIcon);
        }
        if (values?.postmanScript) {
          formData.append("postmanScript", values?.postmanScript);
        }
        if (values.documentUrl) {
          if (values.documentUrl === "null") {
            setErrorOfDocumentURL(false);
          } else if (validURL(values.documentUrl)) {
            // @ts-ignore
            formData.append("documentUrl", values.documentUrl);
          } else {
            setErrorOfDocumentURL(true);
          }
        }

        if (!errorOfDocumentURL) {
          StakingMarketPlaceUpdateAPI(values.id, formData).then((res) => {
            if (res.status === 200) {
              history.push(`/${projectNames.edexanetwork}/market-place`);
              toastSuccess(res.message);
              formikData.resetForm();
              dispatch(resetVisible());
              setIsError(false);
              dispatch(setLoading(false));
            }
          });
        }
      }
    }
  });

  const { values, errors, setFieldValue, handleSubmit } = formikData;

  const actionSubmit = () => {
    setIsError(true);
    if (location?.pathname === `/${projectNames.edexanetwork}/market-place/add`) {
      handleSubmit();
    } else {
      if (Object.keys(errors).length === 0) {
        dispatch(
          setVisible({
            isVisible: true,
            deleteId: "1",
            label: `Are you sure you want to update ${values?.name} market-place Application?`,
            deleteStatus: 1
          })
        );
        setDeleteActionFunction({ fnCall: handleSubmit });
      }
    }
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setFieldValue("name", data?.name);
        setFieldValue("subtitle", data?.subtitle);
        setFieldValue("description", data?.description);
        setFieldValue("shortDescription", data?.shortDescription);
        setFieldValue("postmanScript", data?.postmanScript);
        setFieldValue("documentUrl", data?.documentUrl);
        setFieldValue("features", data?.features);
        setFieldValue("logoIconBig", data?.logoIconBig);
        setFieldValue("logoIcon", data?.logoIcon);
        setFieldValue("id", data?.id);
        setFieldValue("appId", data?.appId);
        setFieldValue("appId", data?.appId);
        setFieldValue("isAvailable", data?.isAvailable);
        setFieldValue("blckChnAvailability", data?.blckChnAvailability);
        setFieldValue("categoryName", data?.categoryName === null ? "" : data?.categoryName);
        setFieldValue("isTrending", data?.isTrending);
        setFieldValue("availableOn", data?.availableOn);
      }, 100);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (values.documentUrl) {
      if (values.documentUrl === "null") {
        setErrorOfDocumentURL(false);
      } else if (validURL(values.documentUrl)) {
        // @ts-ignore
        setErrorOfDocumentURL(false);
      } else {
        setErrorOfDocumentURL(true);
      }
    }

    if (!values.documentUrl) {
      setErrorOfDocumentURL(false);
    }
  }, [values?.documentUrl]);

  const [deletActionFunction, setDeleteActionFunction] = useState({
    fnCall: (values: any) => {}
  });
  return (
    <MainData.Provider value={location?.pathname === `/${projectNames.edexanetwork}/market-place/add` ? "Create Marketplace Application " : "Edit Marketplace Application"}>
      <div>
        <div className="holders markeplace-form-main">
          <PageHeader module={location?.pathname === `/${projectNames.edexanetwork}/market-place/add` ? "Create Marketplace Application " : "Edit Marketplace Application"} />
          <div className="rounded-10 bg-white p-20">
            <Row>
              <Col md={6}>
                {location?.pathname === `/${projectNames.edexanetwork}/market-place/add` ? (
                  <SelectedAppsComponent error={errors?.appId} setFieldValue={setFieldValue} isError={isError} values={values.appId} />
                ) : null}
                <Label for="biglogo" className="mt-3">
                  Name <span className="text-red">*</span>
                </Label>
                <Input
                  placeholder="Name"
                  className="mt-1"
                  name="name"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.name}
                  invalid={Boolean(isError && errors?.name)}
                />
                {isError ? errors?.name ? <p className="text-danger">{errors?.name}</p> : null : null}

                <Label for="biglogo" className="mt-3">
                  Subtitle <span className="text-red">*</span>
                </Label>
                <Input
                  placeholder="Subtitle"
                  invalid={Boolean(isError && errors?.subtitle)}
                  className="mt-1"
                  name="subtitle"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.subtitle}
                />
                {isError ? errors?.subtitle ? <p className="text-danger">{errors?.subtitle}</p> : null : null}

                <Label for="biglogo" className="mt-3">
                  Document Url
                  <CustomTooltip direction={"right"} content={<p className="p-2">Please add the document link (If is there any)</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px" }}></i>
                  </CustomTooltip>
                </Label>
                <Input
                  placeholder="Document Url"
                  value={values?.documentUrl === "null" ? "" : values?.documentUrl}
                  className="mt-1"
                  name="documentUrl"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                />
                {errorOfDocumentURL && <span className="text-danger font-14 mt-2">Please add valid URL</span>}
                <br />
                <Label for="biglogo" className="mt-3">
                  Postman Api Url
                  <CustomTooltip direction={"right"} content={<p className="p-2">Add a postman Api url which is related to the App</p>}>
                    <i className="fas fa-info-circle cursor-pointer transiton-02s position-relative" style={{ marginLeft: "4px" }}></i>
                  </CustomTooltip>
                </Label>
                <Input
                  placeholder="Postman Api Url"
                  className="mt-1"
                  name="postmanScript"
                  value={values?.postmanScript === "null" ? "" : values?.postmanScript}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                />

                <Label for="biglogo" className="mt-3">
                  Short Description <span className="text-red">*</span>
                </Label>
                <Input
                  value={values.shortDescription}
                  type="textarea"
                  placeholder="Short Description"
                  className="mt-1"
                  name="shortDescription"
                  invalid={Boolean(isError && errors?.shortDescription)}
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                />
                {isError ? errors?.shortDescription ? <p className="text-danger">{errors?.shortDescription}</p> : null : null}

                <Label for="biglogo" className="mt-3">
                  Description <span className="text-red">*</span>
                </Label>
                <Input
                  type="textarea"
                  placeholder="Description"
                  className="mt-1"
                  invalid={Boolean(isError && errors?.description)}
                  name="description"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.description}
                />
                {isError ? errors?.description ? <p className="text-danger">{errors?.description}</p> : null : null}

                <FeaturesComponent setFiledValue={setFieldValue} values={values.features} errors={errors} isError={isError} />
              </Col>

              <Col md={6}>
                <AvailabilityComponent values={values.isAvailable} setFieldValue={setFieldValue} error={errors?.isAvailable} isError={isError} />

                <Label for="biglogo" className="mt-3">
                  Category Name <span className="text-red">*</span>
                </Label>
                <Input
                  placeholder="Category Name"
                  className="mt-1"
                  name="categoryName"
                  onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                  value={values.categoryName}
                  invalid={Boolean(isError && errors?.categoryName)}
                />
                {isError ? errors?.categoryName ? <p className="text-danger">{errors?.categoryName}</p> : null : null}

                {/* Availability of BlockChain Component */}
                <BlockChainAvailabilityComponent values={values.blckChnAvailability} setFieldValue={setFieldValue} error={errors?.blckChnAvailability} isError={isError} />

                <SwitchGlobalSetting
                  checked={values?.isTrending}
                  // @ts-ignore
                  className="w-100"
                  onChange={(e: any) => {
                    setFieldValue("isTrending", e);
                  }}
                  label={"Trending"}
                  direaction="left"
                  tooltip={"If you enable this, this app will be one of the trending app (Which appears in the top section of the dapps portal)"}
                  disabled={false}
                />
                {/* Image Component */}
                <SetImageComponent data={data} setFiledValue={setFieldValue} errors={errors} isError={isError} />

                <AppComponent
                  values={values.availableOn}
                  propsData={data}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  isError={isError}
                  isAvailable={values.isAvailable}
                  blckChnAvailability={values.blckChnAvailability}
                />
              </Col>
            </Row>
            <div className="d-flex mt-20 mb-2">
              <button type="submit" className="btn-sm btn btn-primary bg-blue border-0 shadow-none button-width-com" onClick={actionSubmit}>
                {location?.pathname === `/${projectNames.edexanetwork}/market-place/add` ? "Submit" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {!errorOfDocumentURL && <ConfirmationModal handleDeleteAction={deletActionFunction.fnCall} />}
    </MainData.Provider>
  );
};

export default StakingMarketPlacemanagementView;
