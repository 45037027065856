import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getPriceAndPlanList } from "../../../../../actions/bMessage/getBMessageAdminInfoAPI";
import MetaFunction from "../../../../../common/MetaTag";
import { initialPaginationConfig } from "../../../../../common/constants";
import { setLoading } from "../../../../../store/loader/action";
import Footer from "../../../../common/Footer";
import ListTable from "../../common/ListTable";

const PriceAndPlan = () => {
  const module = "Price & Plan";
  const tableHeaders = ["Plan Name", "Price", "Status", "Actions"];
  const dispatch = useDispatch();

  const [listingData, setListingData] = useState<any>([]);
  const [totalListCount, setTotalListCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState(initialPaginationConfig);

  const getCategoryManagementListFromApi = () => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    getPriceAndPlanList(params).then((res) => {
      if (res.status === 200) {
        setTotalListCount(res.data.count);
        setListingData(res.data.pricingPlan);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getCategoryManagementListFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <>
      <MetaFunction meta={module} />
      <div className="holders">
        <p className="main-title m-b-20">{module}</p>
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className={"d-flex flex-wrap justify-content-between"}>
                <ListTable
                  listData={listingData}
                  totalCount={totalListCount}
                  tableHeaders={tableHeaders}
                  type={"pricePlan"}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                  recall=""
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default PriceAndPlan;
