import { bMessageURL } from "../../common/api-endpoints";
import HTTPService from "../../common/httpservice";

export const bMessageAdminInfoAPI = (): Promise<IbMessageAdminInfoAPI> => {
  return HTTPService.get(bMessageURL.adminInfo);
};

interface IbMessageAdminInfoAPI {
  message: string;
  status: number;
  data: {
    email: string;
    clientId: string;
    _id: string;
  };
}
